import { EvnSetup } from './environment';
import { environment as stage } from './environment.staging';
import { environment as prod } from './environment.prod';

const isProd = false;
const hosts = [
  'hub.avaturn.me',
  'demo.avaturn.dev',
  'flamapp.avaturn.dev',
  'eikonikos.avaturn.dev',
  'scenic.avaturn.dev',
  'headless-6nj.pages.dev',
  'elijah.avaturn.dev',
];
export const environment: EvnSetup = {
  ...stage,
  resourceUrl: isProd ? prod.resourceUrl : stage.resourceUrl,
  baseUrl: isProd ? prod.baseUrl : stage.baseUrl,
  hostname_override: hosts[0],
  externalScanHost: isProd ? prod.externalScanHost : stage.externalScanHost,
  scanApiHost: isProd ? prod.scanApiHost : stage.scanApiHost,
  firebaseConfig: isProd ? prod.firebaseConfig : stage.firebaseConfig,
} as const;

export default environment;
