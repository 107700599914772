import { createContext } from 'react';
import { createDefaultAssetsList } from '../common';
import { useProjcetAssets } from '../hooks';

export const AssetsContext = createContext<ReturnType<typeof useProjcetAssets>>({
  load: () => Promise.resolve([]),
  list: { project: [], market: [] },
  listByCategory: createDefaultAssetsList(),
  loaded: false,
  add: () => Promise.resolve(),
  addAnimation: () => Promise.resolve(),
  shareToProject: () => Promise.resolve(),
  delete: () => Promise.resolve(),
  projectListById: {},
});
