export enum BaseRoutes {
  Root = '/',
  Developers = '/developers',
  Hub = '/',
  Editor = '/editor',
  Login = '/login',
  IframeHub = '/iframe',
  IframeEditor = '/iframe/editor',
  EmbedEditor = '/embed-editor',
  Gallery = '/gallery',
  Scan = '/scan',
  ModelsTest = '/models-test',
  Continue = '/continue',
  DevPortal = '/dev-portal',
  RedirectTimer = '/redirect-timer',
  Create = '/create',
}

export enum CreateRoutes {
  Root = '/',
  Options = '/create/new',
  QrCode = '/create/scan',
  Upload = '/create/upload',
  Proceed = '/create/proceed',
}
export enum CreatePaths {
  Options = '/new',
  QrCode = '/scan',
  Upload = '/upload',
  Proceed = '/proceed',
}
