import { HaircapIdType } from '@in3d/common';
import { action, computed, makeObservable, observable } from 'mobx';

import { customizationUpdater } from '../addons/customization_updater';
import { currentState } from './CurrentState';

class EditorParams {
  constructor() {
    makeObservable(this, {
      _skinBrightness: observable,
      skinBrightness: computed,

      _eyeColor: observable,
      eyeColor: computed,

      _hairColor: observable,
      hairColor: computed,

      _glassesColor: observable,
      glassesColor: computed,

      _boneScale: observable,
      setScale: action.bound,

      _haircap_id: observable,
      haircapId: computed,
    });
  }

  //
  // Skin contrast
  //
  _boneScale = {
    head: 50,
    arms: 50,
    // legs: 50,
    chest: 50,
  };

  setScale(type: keyof typeof this._boneScale, value: number) {
    this._boneScale[type] = value;
    currentState.changeScale(type, value);
    customizationUpdater.update_customization();
  }


  //
  // Skin contrast
  //
  _skinBrightness = 50;

  get skinBrightness() {
    return this._skinBrightness;
  }
  set skinBrightness(value: number) {
    this._skinBrightness = value;
    currentState.changeSkinBrightness(value);
    customizationUpdater.update_customization();
  }

  //
  // Eye color
  //
  _eyeColor: string;
  get eyeColor() {
    return this._eyeColor;
  }
  set eyeColor(color: string) {
    // The color should be a hex color, starting with #
    this._eyeColor = color;
    currentState.changeEyeColor(color);
    customizationUpdater.update_customization();
  }

  //
  // Hair color
  //
  _hairColor: string;
  get hairColor() {
    return this._hairColor;
  }
  set hairColor(color: string) {
    // The color should be a hex color, starting with #
    this._hairColor = color;
    currentState.changeHairColor(color);
    customizationUpdater.update_customization();
  }

  //
  // Glasses color
  //
  _glassesColor: string;
  get glassesColor() {
    return this._glassesColor;
  }
  set glassesColor(color: string) {
    this._glassesColor = color;
    if (color == 'transparent') {
      currentState.changeGlassesColor('#000000', true);
    } else if (color == 'black') {
      currentState.changeGlassesColor('#000000', false);
    } else {
      currentState.changeGlassesColor(color, false);
    }
    customizationUpdater.update_customization();
  }

  //
  // Haircap Id
  //
  _haircap_id: HaircapIdType;
  get haircapId() {
    return this._haircap_id;
  }
  set haircapId(id: HaircapIdType) {
    this._haircap_id = id;
    currentState.changeHaircap(id);
    customizationUpdater.update_customization();
  }
}

const editorParams = new EditorParams();

export default editorParams;
