import { AuthClient } from './lib/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import env from '@in3d/environment';

export * from './lib/api';
export * from './lib/avatars';
export * from './lib/auth';
export * from './lib/settings';
export * from './lib/google-analytics';
export * from './lib/dev-portal';
export * from './lib/admin';

export const firebaseConfig = env.firebaseConfig;

export const firebaseApp = initializeApp(firebaseConfig);

export const authClient = new AuthClient(firebaseApp);
export const db = getFirestore(firebaseApp);
