import { CategoryName } from '../types';

export const categoryToType = (category: CategoryName) => {
  const types: { [key in CategoryName]: 'animations' | 'eyes' | 'head' | 'look' | 'shoes' } = {
    [CategoryName.Body]: 'look',
    [CategoryName.Hair]: 'head',
    [CategoryName.Shoes]: 'shoes',
    [CategoryName.Outfit]: 'look',
    [CategoryName.Glasses]: 'head',
    [CategoryName.Animations]: 'animations',
    [CategoryName.FaceAnimations]: 'animations',
  };
  return types[category];
};
