import {
  BufferGeometry,
  DataTexture,
  FloatType,
  Material,
  Mesh,
  Object3D,
  PerspectiveCamera,
  RGBAFormat,
  ShaderChunk,
  ShaderLib,
  ShaderMaterial,
  SkinnedMesh,
  UniformsUtils,
  Vector4,
} from 'three';

export const getVisibleChildren = (object: Object3D) => {
  const queue = [object];
  const objects = [];

  while (queue.length !== 0) {
    const mesh = queue.shift();
    if ((mesh as Mesh).material) objects.push(mesh);

    for (const c of (mesh as Mesh).children) {
      if (c.visible) queue.push(c);
    }
  }

  return objects;
};

export const saveBoneTexture = (object: SkinnedMesh<BufferGeometry, ShaderMaterial>) => {
  let boneTexture = object.material.uniforms['prevBoneTexture'].value;

  if (boneTexture && boneTexture.image.width === object.skeleton.boneTexture?.image.width) {
    boneTexture = object.material.uniforms['prevBoneTexture'].value;
    boneTexture.image.data.set(object.skeleton.boneTexture?.image.data);
    boneTexture.needsUpdate = true;
  } else {
    boneTexture?.dispose();

    const boneMatrices = object.skeleton.boneTexture?.image.data.slice();
    const size = object.skeleton.boneTexture?.image.width;

    boneTexture = new DataTexture(boneMatrices, size, size, RGBAFormat, FloatType);
    object.material.uniforms['prevBoneTexture'].value = boneTexture;

    boneTexture.needsUpdate = true;
  }
};

export const updateVelocityDepthNormalMaterialBeforeRender = (
  c: SkinnedMesh<BufferGeometry, ShaderMaterial>,
  camera: PerspectiveCamera
) => {
  if (c.skeleton?.boneTexture) {
    c.material.uniforms['boneTexture'].value = c.skeleton.boneTexture;

    if (!('USE_SKINNING' in c.material.defines)) {
      c.material.defines['USE_SKINNING'] = '';
      c.material.defines['BONE_TEXTURE'] = '';

      c.material.needsUpdate = true;
    }
  }

  c.modelViewMatrix.multiplyMatrices(camera.matrixWorldInverse, c.matrixWorld);

  c.material.uniforms['velocityMatrix'].value.multiplyMatrices(camera.projectionMatrix, c.modelViewMatrix);
};

export const updateVelocityDepthNormalMaterialAfterRender = (c: SkinnedMesh<BufferGeometry, ShaderMaterial>, camera: PerspectiveCamera) => {
  c.material.uniforms['prevVelocityMatrix'].value.multiplyMatrices(camera.projectionMatrix, c.modelViewMatrix);

  if (c.skeleton?.boneTexture) saveBoneTexture(c);
};

//

export const isGroundProjectedEnv = (c: any) => {
  return c.material.fragmentShader?.includes(
    'float intersection2 = diskIntersectWithBackFaceCulling( camPos, p, h, vec3( 0.0, 1.0, 0.0 ), radius );'
  );
};

export const isChildMaterialRenderable = (c: Mesh<BufferGeometry, ShaderMaterial>, material = c.material) => {
  return (
    material.visible &&
    material.depthWrite &&
    material.depthTest &&
    (!material.transparent || material.opacity > 0) &&
    !isGroundProjectedEnv(c)
  );
};

const materialProps = [
  'vertexTangent',
  'vertexColors',
  'vertexAlphas',
  'vertexUvs',
  'uvsVertexOnly',
  'supportsVertexTextures',
  'instancing',
  'instancingColor',
  'side',
  'flatShading',
  'skinning',
  'doubleSided',
  'flipSided',
];

export const copyNecessaryProps = (originalMaterial: any, newMaterial: any) => {
  for (const props of materialProps) newMaterial[props] = originalMaterial[props];
};
