import { Cloth, resources } from '@in3d/core';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import './edit.scss';
import { AssetSettings } from './settings';

export interface EditProps {
  item: string;
  onSave: (item: Cloth) => Promise<any>;
  onDelete: () => void;
  onCancel: () => void;
}
const Edit: FC<EditProps> = ({ item, onSave, onCancel, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const editedItem = resources.clothById[item];

  const handleSave = () => {
    setIsLoading(true);
    if (!editedItem) return onCancel();
    onSave(editedItem).finally(() => setIsLoading(false));
  };
  return (
    <div className="edit-asset">
      {editedItem && (
        <>
          <div className="edit-asset__item">
            <span className="edit-asset__text">Category</span>
            <div className="edit-asset__list">
              <AssetSettings
                settings={editedItem.settings_json}
                onUpdate={(s) => {
                  resources.updateAsset(item, 'settings_json', s);
                }}
              />
            </div>
          </div>
          <div className="edit-asset__actions">
            <button onClick={onDelete} className="edit-asset__delete">
              Delete
            </button>
            <div className="edit-asset__group">
              <button onClick={onCancel} className="edit-asset__cancel">
                Cancel
              </button>
              <button onClick={handleSave} disabled={isLoading} className="edit-asset__save">
                {isLoading ? 'Loading...' : 'Save'}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const _Edit = observer(Edit);
export { _Edit as Edit };
