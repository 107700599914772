import { ScreenshotButton } from '../screenshot-btn';

export const ArtisantActions = () => {
  return (
    <div className="customizer__actions">
      <div className="controls">
        <ScreenshotButton />
      </div>
    </div>
  );
};
