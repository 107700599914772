import { avatarClient } from '@in3d/api';
import { auth } from '@in3d/store';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import { UpgradeButton } from '../upgrade-button';
import { UpgradePopup } from '../upgrade-popup';

import { when } from 'mobx';

type UpgradeProps = {
  portalOpened: boolean;
  setPortalOpened: (value: boolean) => void;
  isMobile: boolean;
};

export const Upgrade = ({ portalOpened, setPortalOpened, isMobile }: UpgradeProps) => {
  const [mode, setMode] = useState<'upgrade' | 'open' | 'hide'>('hide');
  const [upgradedUrl, setUpgradedUrl] = useState<string | null>(null);

  const [urlParams] = useSearchParams();

  useEffect(() => {
    const checkUpgrade = async () => {
      const id = urlParams.get('customization_id');
      if (!id) return;
      const uId = await avatarClient.getAvatarUpgrade(auth.token, id);
      if (uId === null) {
        setMode('upgrade');
      } else if ((uId as { id: string; scan_id: string }).id === id) {
        setMode('hide');
      } else {
        setMode('open');
        setUpgradedUrl((uId as { id: string; scan_id: string }).id as string);
      }
    };
    const disposer = when(
      () => auth.loaded,
      () => {
        if (auth.isAuth) checkUpgrade();
      }
    );

    // Clean up the when reaction when component is unmounted
    return () => disposer();
  }, [urlParams]);

  const handleClick = async () => {
    if (mode === 'upgrade') {
      // Put your logic for upgrade mode here
      const currentId = urlParams.get('customization_id');
      if (!currentId) return;
      const { id } = (await avatarClient.upgradeAvatar(auth.token, currentId)) as { id: string; scan_id: string };
      window.location.href = `/editor?customization_id=${id}`;
    } else if (mode === 'open') {
      // Put your logic for open mode here
      console.log('open clicked');
      window.location.href = `/editor?customization_id=${upgradedUrl}`;
    }
  };

  return (
    <>
      <UpgradeButton setPortalOpened={setPortalOpened} upgradedUrl={upgradedUrl} isMobile={isMobile} mode={mode} />
      {portalOpened
        ? createPortal(
            <UpgradePopup isMobile={isMobile} handleClick={handleClick} setPortalOpened={setPortalOpened} />,
            document.querySelector('#portal') as Element
          )
        : null}
    </>
  );
};
