import { auth } from '@in3d/store';
import { when } from 'mobx';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { AUTH_TOKEN_SEARCH_PARAM, localData } from '@in3d/common';
import { authClient } from '@in3d/api';

export const useAuth = (config?: { onAuth: () => void }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const loadHub = () => {
    when(
      () => auth.isAuth,
      () => config?.onAuth()
    );
  };

  useEffect(() => {
    when(
      () => auth.isAuth,
      () => {
        window.hideLoader();
        const interval = setInterval(() => auth.updateToken(), 60 * 1000);
        when(
          () => !auth.isAuth,
          () => clearInterval(interval)
        );
      }
    );

    onAuthStateChanged(authClient.auth, (authData) => {
      if (window.location.search) {
        const params = new URLSearchParams(window.location.search);
        const raw_token = params.get(AUTH_TOKEN_SEARCH_PARAM) || '';
        const token = raw_token.startsWith("b'") ? raw_token.substring(2, raw_token.length - 1) : raw_token;

        if (token) {
          return authClient.tokenAuth(token).then((data) => {
            auth.updateAuthData(data);
            loadHub();
          });
        }
      }

      if (!authData) {
        auth.setLoadedStatus(true);
        return auth.removeAuthData();
      } else {
        return authData.getIdToken().then((token) => {
          if (!localData.available && !searchParams.has(AUTH_TOKEN_SEARCH_PARAM)) {
            authClient.generateCustomToken(token).then((custom_token) => {
              setSearchParams((prev) => {
                prev.set(AUTH_TOKEN_SEARCH_PARAM, custom_token);
                return prev;
              });
            });
          }

          auth.updateAuthData({
            isGuest: authData.isAnonymous,
            verified: authData.emailVerified,
            loaded: true,
            isAuth: !!authData,
            name: authData?.displayName,
            email: authData?.email,
            token,
            id: authData?.uid,
          });

          loadHub();
        });
      }
    });
  }, [navigate, searchParams, setSearchParams]);
};
