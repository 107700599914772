import { changeCloth } from '@in3d/core';

export const EikonikosActions = () => {
  return (
    <div className="customizer__actions">
      <div className="controls">
        <button className="control-btn eikonikos-mask" onClick={() => changeCloth('eikonikos_mask')}>
          <img src={`/assets/scene/eikonikos_mask.png`} alt="music" />
        </button>
      </div>
    </div>
  );
};
