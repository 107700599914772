import { Modal } from './popup';
import SVG from 'react-inlinesvg';
import { downloadModel } from '@in3d/core';
import './avatar-download.scss';

export const AvatarDownload = ({ onClose }: { onClose: () => void }) => {
  const tutorial = (url: string, title: string) => {
    return (
      <a href={url} target="_blank" className="download-docs__link">
        <div className="download-docs__icon">
          <SVG src="/assets/landing/line.svg" />
        </div>
        {title}
      </a>
    );
  };
  return (
    <Modal onClose={onClose} title="Download 3D model">
      <div className="download-avatar">
        {/* <div className="download-avatar__desc">text goes here</div> */}
        <div className="download-avatar__list">
          <div className="download-avatar__option" onClick={() => downloadModel()}>
            <SVG src="/assets/download.svg" />
            Customized <br /> Avatar (T-Pose)
          </div>
          <div className="download-avatar__option" onClick={() => downloadModel({ justBody: true })}>
            <SVG src="/assets/download.svg" />
            Body only <br /> (T-Pose)
          </div>
          <div className="download-avatar__option" onClick={() => downloadModel({ withAnimation: true })}>
            <SVG src="/assets/download.svg" />
            Customized Avatar <br /> with animation
          </div>
        </div>
        <div className="download-avatar__divider" />
        <div className="download-avatar__docs download-docs">
          <div className="download-docs__title">Tutorials</div>
          <div className="download-docs__subtitle">Learn how to use avatars in different engines</div>

          <div className="download-docs__list">
            {tutorial('https://docs.avaturn.me/docs/importing/blender/', 'Import into Blender')}
            {tutorial('https://docs.avaturn.me/docs/importing/unreal/', 'Import into Unreal Engine')}
            {tutorial('https://docs.avaturn.me/docs/importing/unity/', 'Import into Unity')}
            {tutorial('https://docs.avaturn.me/docs/importing/mixamo/', 'Use with Mixamo animations')}
            {tutorial('https://docs.avaturn.me/docs/importing/glb-to-fbx/', 'Convert to FBX')}
            {/* {tutorial('https://docs.avaturn.me/docs/importing/blender/', 'Integrate Avaturn in your product as plugin')} */}

            <a
              style={{ color: '#55dbff' }}
              href="https://docs.avaturn.me/docs/integration/web/"
              className="download-docs__link"
            >
              <div className="download-docs__icon">
                <SVG src="/assets/landing/line.svg" />
              </div>
              Integrate Avaturn into your product as plugin
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};
