import { cn } from '@in3d/common';
import SVG from 'react-inlinesvg';
import ReactSlider from 'react-slider';

export const CustomSlider = ({
  value,
  onChange,
  valueTitle,
  title,
  classNames,
  max = 100,
  min = 0,
  isMobile = false,
}: {
  value: number;
  title?: string;
  onChange: (value: number) => void;
  valueTitle?: string;
  classNames?: string;
  max?: number;
  min?: number;
  isMobile?: boolean;
}) => {
  return (
    <div className={cn(['custom-slider', classNames])}>
      <div className="custom-slider__info">
        <span className="custom-slider__title">{title}</span>
        <div className="custom-slider__info-value">
          <span className="custom-slider__title">{valueTitle || value}</span>
          {isMobile ? (
            <button className="custom-slider__reset" onClick={() => onChange(50)}>
              <SVG src="assets/update.svg" />
            </button>
          ) : null}
        </div>
      </div>
      <ReactSlider
        max={max}
        min={min}
        value={value}
        onChange={(v) => onChange(v)}
        className="custom-slider__input"
        thumbClassName="custom-slider__thumb"
        trackClassName="custom-slider__track"
      />
    </div>
  );
};
