export function remove_nulls_from_dict(d: any) {
  Object.keys(d).forEach((key) => {
    if (d[key] === null) {
      delete d[key];
      return;
    }
    if (d[key].constructor == Object) {
      remove_nulls_from_dict(d[key]);
    }
  });
}
