import { authClient } from '../index';
import { logEvent } from 'firebase/analytics';
import env from '@in3d/environment';

/*
export const gaInit = () => {
  initialize(env.firebaseConfig.measurementId);
  pageview(window.parent.location.hostname);
};
*/

export enum GaEvent {
  AuthEmail = 'auth_email',
  AuthGoogle = 'auth_google',
  CreateScan = 'create_scan',
  NewAvatar = 'new_avatar',
  OpenEditor = 'open_editor',
  GuestAuth = 'auth_guest',
}
enum GaCategory {
  User = 'User',
}

class GaHandler {
  private events: { [key in GaEvent]: any } = {
    [GaEvent.AuthEmail]: {
      category: GaCategory.User,
      action: 'Auth email',
    },
    [GaEvent.AuthGoogle]: {
      category: GaCategory.User,
      action: 'Auth Google',
    },
    [GaEvent.GuestAuth]: {
      category: GaCategory.User,
      action: 'Auth Guest',
    },
    [GaEvent.CreateScan]: {
      category: GaCategory.User,
      action: 'Scan creation initiated',
    },
    [GaEvent.NewAvatar]: {
      category: GaCategory.User,
      action: 'New avatar created',
    },
    [GaEvent.OpenEditor]: {
      category: GaCategory.User,
      action: 'Open editor',
    },
  };
  onAppInit() {
    if (env.isLocalRun) {
      return;
    }

    logEvent(authClient.analytics, 'app_loaded');
  }
  viewPage() {
    if (env.isLocalRun) {
      return;
    }

    logEvent(authClient.analytics, 'page_view', {
      page_path: window.location.pathname,
    });
  }
  event(name: GaEvent) {
    if (env.isLocalRun) {
      return;
    }

    logEvent(authClient.analytics, name, this.events[name]);
  }
}

export const ga = new GaHandler();
