import { PerspectiveCamera } from 'three';
import { AvatarView } from './AvatarView';
import { currentState } from './core/CurrentState';
import { loadScene } from './editor_api';
import { takeScreenshotAndSave, downloadModel } from './export_api';
import { notifySuccess } from '@in3d/common';

let keyboardEnabled = false;

async function eventListner(event: KeyboardEvent) {
  if (!document.location.pathname.includes('/editor')) {
    return;
  }

  const name = event.key;
  const code = event.code;

  if (name == '8') {
    keyboardEnabled = true;
    notifySuccess("keyboard enabled")
  }

  if (!keyboardEnabled) return;


  if (code == 'Space') {
    // generateThumbnail();
    // iframe = document.createElement('iframe');
    // iframe.setAttribute('src', `http://127.0.0.1:1553/`);
    // iframe.style.width = '640px';
    // iframe.style.height = '480px';
    // document.body.appendChild(iframe);
    // // subscribers.push(iframe);
    // document.getElementById('root').style.display = 'none';
    // downloadModel();
    // array.splice(index, 1);
    // Alert the key name and key code on keydown
    // alert(`Key pressed ${name} \r\n Key code value: ${code}`);
    //AvatarView.controls.autoRotate = !AvatarView.controls.autoRotate;

    // AvatarView.render(0.1);

    // document.body.removeChild(link);
    // alert(1)
    // keyboardEnabled = true;
    // currentState.changeHaircap('bald_soft');
  } else if (name == 'o') {
    currentState.changeHaircap('bald_jeoffrey');
    // setHeadlessCamera()
    // AvatarView.recorder.start();
    // if (!currentState.animation) {
    //   await currentState.changeAnimation('idle_male');
    // }
    // AvatarView.scene_manager.switch('sceneDefault');
  } else if (name == 'p') {
    // if (!currentState.animation) {
    //   await currentState.changeAnimation('idle_male');
    // }
    AvatarView.scene_manager.switch('infosys');
  } else if (name == '[') {
    await loadScene('sceneBright12c');
  } else if (name == ']') {
    await loadScene('sceneBright12');
  } else if (name == 'c') {
    console.log(
      JSON.stringify({
        target: AvatarView.controls.target,
        position: AvatarView.controls.object.position,
        zoom: (AvatarView.controls.object as PerspectiveCamera).zoom,
      })
    );
  } else if (name == 's') {
    takeScreenshotAndSave('download');
  } else if (name == 'q') {
    const d = {
      target: {x: -0.07104778961190421, y: 1.0613777403605178, z: -0.09092505838821047},
      position: {x: 1.6791902883713352, y: 1.8292592095458189, z: 4.861440599660449},
      zoom: 1,
    };
    AvatarView.camera.position.set(d.position.x, d.position.y, d.position.z);

    AvatarView.controls.target.set(d.target.x, d.target.y, d.target.z);
    AvatarView.controls.update();

    // AvatarView.camera.position.set(-0.964898770869591, 1.7976356898521266, 2.5547282183816744);
    // AvatarView.controls.target.set(0.03986388843420672, 1.3960116539169554, -0.10178828080199137);

    // {"target":{"x":0.03986388843420672,"y":1.3960116539169554,"z":-0.10178828080199137},"position":{"x":-0.964898770869591,"y":1.7976356898521266,"z":2.5547282183816744},"zoom":1}
    // AvatarView.camera.position.set(0.9065386019087697, 1.834346437902703, 2.435226320717076);
    // AvatarView.controls.reset();
  } else if (name == 'w') {
    AvatarView.camera.position.set(-0.964898770869591, 1.7976356898521266, 2.5547282183816744);
    AvatarView.controls.target.set(0.03986388843420672, 1.3960116539169554, -0.10178828080199137);

    let d = {
      target: {x: 0.06353332580294209, y: 1.2607020819660835, z: -0.062028476270025584},
      position: {x: -1.6621553354827636, y: 1.5304205533255542, z: 2.9959997064155734},
      zoom: 1,
    };
    // d = {"target":{"x":0.07310680556840063,"y":1.2827081589589882,"z":-0.06004198550468426},"position":{"x":-1.3910729746606636,"y":1.5730214484756717,"z":2.564594268139298},"zoom":1};
    d = {
      target: {x: 0.04028245315442831, y: 1.3692852407150145, z: -0.09532199442194403},
      position: {x: -0.46887098750312634, y: 1.5937281716753346, z: 2.3002306769256187},
      zoom: 1,
    };
    AvatarView.camera.position.set(d.position.x, d.position.y, d.position.z);
    AvatarView.controls.target.set(d.target.x, d.target.y, d.target.z);

    // {"target":{"x":0.03986388843420672,"y":1.3960116539169554,"z":-0.10178828080199137},"position":{"x":-0.964898770869591,"y":1.7976356898521266,"z":2.5547282183816744},"zoom":1}
    // AvatarView.camera.position.set(0.9065386019087697, 1.834346437902703, 2.435226320717076);
    // AvatarView.controls.reset();
  } else if (name == 'm') {
    AvatarView.scene_manager.current_scene.setQuality('highest');
    // AvatarView.render(0.1);
  } else if (name == 'y') {
    // let d = {
    //   scan_glb_url: 'http://localhost:4200/assets/local/default_female.glb',
    //   scan_metadata: { gender: 'female' },
    //   customization: { assets: {}, avatar: { body: 'model_T_4' } },
    // };
    // // await window.load_from_data(d);
    // await window.export_glb();
    downloadModel({justBody: false, withAnimation: true});
    // await window.load_from_data(d);
    // let dataURL = await window.render();
    // // takeScreenshotAndSave("download", "png");
    // save_file(dataURL, `screenshot.png`);
    // // controls.target.set(0, 1.0, 0);
  } else if (name == 'u') {
    // let d = {
    //   scan_glb_url: 'http://localhost:4200/assets/local/default_avatar.glb',
    //   scan_metadata: { gender: 'male' },
    //   customization: { assets: {}, avatar: { body: 'model_T_4' } },
    // };
    // let d = {
    //   scan_glb_url: 'http://localhost:4200/assets/local/animatable/default_avatar_animatable.glb',
    //   scan_metadata: { gender: 'male', animatable: true },
    //   customization: { assets: {}, avatar: { body: 'model_T_0' } },
    // };
    // d['customization']['assets'] = {
    //   Alma_male_39: {
    //     color: { r_color1: [0, 0, 0], r_color2: [0, 0, 0], r_color3: [0, 0, 0] },
    //     local: false,
    //     placement: 'head',
    //   },
    //   height_boots: { local: false, placement: 'shoes' },
    //   DG05: { placement: 'look' },
    // };

    // const d = {
    //   customization: {
    //     assets: {},
    //     avatar: {body: ''},
    //   },
    //   scan_glb_url: 'https://storage.googleapis.com/avatars-staging-s3-results/5bfef863-d07d-487f-a14f-aecc9be1ebff/export/model_T.glb?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=GOOG1EZX4UJVIIIDQG7AKDXNR5VDOKJVN3EBGBHPCIAK6V56Q5AZE7LE6PLAI%2F20230615%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230615T180700Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=e14675824feae6ec28382d7d6397c2811afc6ad481233b61b2dfd7565710d475',
    //   scan_metadata: {
    //     gender: 'male',
    //     animatable: true
    //   },
    // } as AvatarInfo

    // await window.load_from_data(d);
    // console.log("162")
    // // await window.export_glb();
    // const dataURL = await window.render();
    // console.log("165")
    // takeScreenshotAndSave('download');
    // console.log("167")
    // save_file(dataURL, `screenshot.png`);
    // downloadModel({justBody: true});
    // await exportAvatar();
  } else if (name == 'l') {
    // const d = {
    //   scan_glb_url: 'http://localhost:4200/assets/local/animatable/default_avatar_animatable.glb',
    //   scan_metadata: {gender: 'male', animatable: true},
    //   customization: {assets: {}, avatar: {body: 'model_T_0'}},
    // } as AvatarResponseData;
    // d['customization']['assets'] = {"Artisant_red_female": {"placement": "look"}, "avitor_sunglasses": {"placement": "eyes"}, "male_shoes": {"placement": "shoes"}, "pu_bun": {"placement": "head"}};
    // let d = {"scan_glb_url":"http://localhost:4200/assets/local/default_female.glb","scan_metadata":{"gender":"female"},"customization":{"assets":{"Alma_male_39":{"color":{"r_color1":[0,0,0],"r_color2":[0,0,0],"r_color3":[0,0,0]},"local":false,"placement":"head"},"height_boots":{"local":false,"placement":"shoes"}, "sharp_hoodie":{"placement": "look"}},"avatar":{"body":"model_T_4"}}};

    // for (let i = 0; i < 10; i++) {
    //   console.log(i);

    //   await window.load_from_data(d);
    //   const dataURL = await window.render2();
    //   // takeScreenshotAndSave("download", "png");
    //   // save_file(dataURL, `screenshot.png`);
    //   // export_model('post');
    //   // await exportAvatar();
    // }
  } else if (name == 'i') {
    // if (currentState.tracking_is_recording) {
    //   console.log('stop keyframe animation');
    //   stopFaceTrackingRecording();
    // } else {
    //   console.log('start keyframe animation');
    //   startFaceTrackingRecording();
    // }
  }
}

export function addKeyDownListner() {
  if (!IS_DEBUG) return;

  document.addEventListener('keydown', eventListner, false);
}
