import { cn } from '@in3d/common';
import { createRef, useEffect, memo } from 'react';
import './scene.scss';
interface SceneProps {
  init: (ref: HTMLDivElement) => Promise<any>;
  config?: { sceneBg: string | null };
  fullScreen?: boolean;
  children?: React.ReactElement | boolean;
}
export const Scene: React.FC<SceneProps> = ({ init, fullScreen, children }) => {
  return (
    <div className={cn(['scene', fullScreen && 'full'])}>
      {children}
      <WebGLScene init={init} />
    </div>
  );
};

export const WebGLScene: React.FC<SceneProps> = memo(({ init, config }) => {
  const ref = createRef<HTMLDivElement>();
  useEffect(() => {
    if (ref.current) {
      init(ref.current);
    }
  }, [ref, init]);
  return (
    <div
      className="scene__content"
      // style={{
      //   background: config?.sceneBg
      //     ? `linear-gradient(44deg, rgb(55, 32, 76) 0%, ${config.sceneBg} 100%)!important`
      //     : '',
      // }}
      ref={ref}
    />
  );
});
