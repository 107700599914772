import {
  ApiEventType,
  AppEvent,
  AvatarData,
  ExportStatus,
  GaEvent,
  ScanData,
  ScanStatus,
  ScansAndAvatars,
  avatarClient,
  ga,
} from '@in3d/api';
import { AUTH_TOKEN_SEARCH_PARAM, ConfigType, get_page_params, sendMessageWithHttpUrl } from '@in3d/common';
import env from '@in3d/environment';
import { action, computed, makeObservable, observable } from 'mobx';

import { auth } from './auth.store';

export enum PreviewStatus {
  Pending = 'status://pending',
  Initial = 'status://initial',
}

class Avatars {
  list: AvatarData[] = [];
  scans: ScanData[] = [];

  scansTotal = 0;
  avatarsTotal = 0;
  loaded = false;
  scansModalOpen = false;
  isCreatingScan = false;
  isCreatingAvatar = false;
  isWaitingFirstScan = false;
  isScansLoading = false;
  isAvatarListLoading = false;
  isAvatarCurrentlyExporting = false;
  isExportBtnEnabled = false;

  updateAvatarHandlers: { [key in ScanStatus]?: Array<() => void> } = {};
  updateExportHandler: () => Promise<void> = Promise.resolve;

  setExportHandler(cb: () => Promise<any>) {
    this.updateExportHandler = cb;
  }
  constructor() {
    makeObservable(this, {
      list: observable,
      scans: observable,
      loaded: observable,
      scansTotal: observable,
      scansModalOpen: observable,
      isCreatingScan: observable,
      isCreatingAvatar: observable,
      isWaitingFirstScan: observable,
      isScansLoading: observable,
      isAvatarListLoading: observable,
      readyScansList: computed,
      isExportBtnEnabled: observable,
      updateAvatarData: action.bound,
      getScanList: action,
      setAvatarExportBtnActivity: action.bound,
      cancelAvatarCreation: action,
      createScan: action,
      createCustomization: action,
      deleteAvatar: action,
      getAvatars: action,
      initAvatarList: action,
    });
  }

  get processingScansList() {
    return this.scans.filter((scan) => scan.status === ScanStatus.PROCESSING);
  }

  get readyScansList() {
    return this.scans.filter((scan) => scan.status === ScanStatus.READY);
  }

  async scanById(id: string): Promise<ScanData> {
    const current = this.scans.find((e) => e.id === id);
    if (current) return current;
    const result = await avatarClient.getAvatarInfo(auth.token, id);
    return result.scan;
  }

  clearStore() {
    this.list = [];
    this.scans = [];
    this.loaded = false;
  }

  updateAvatarData(updates: AppEvent[]) {
    // console.log('Updates: ', updates);
    updates.forEach((update) => {
      if (update.type === ApiEventType.AvatarExportCompleted) {
        if (update.data.status === ExportStatus.Ready) {
          const avatarData = avatars.list.find((x)=> {return x.id == get_page_params().customization_id})
          sendMessageWithHttpUrl(`${env.baseUrl}avatars/exports/${update.data.export_id}/model`, avatarData?.scan_config == 'animatable');
          this.isExportBtnEnabled = true;
        } else if (update.data.status === ExportStatus.Failed) {
          // Fall back to upload from frontend
          this.updateExportHandler().then(() => {
            this.isExportBtnEnabled = true;
          });
        }
      }
      if (update.type === ApiEventType.AvatarPreviewUpdate) {
        const avatar = this.list.find((e) => e.id === update.data.avatar_id);
        if (avatar) {
          avatar.preview_url = update.data.url;
        }
      }
      if (update.type === ApiEventType.ScanStatusChanged) {
        switch (update.data.status) {
          case ScanStatus.READY: {
            setTimeout(() => {
              this.getAvatars({ limit: this.list.length + 1, offset: 0 }).then((data) => {
                const currentOpenedAvatar = new URLSearchParams(window.location.search).get('customization_id');
                const currentScanId = this.list.find((v) =>  v.id == currentOpenedAvatar)?.scan_id;

                if (this.list.length === 1 || currentScanId == update.data.id) this.redirectToEditor(currentOpenedAvatar || this.list[0].id, true);
                this.scans = this.scans.filter((e) => e.id !== update.data.id);
              });
            }, 1000);
            break;
          }
          case ScanStatus.PROCESSING: {
            this.scans = this.scans.filter((e) => e.id !== 'placeholder');
            const idx = this.scans.findIndex((el) => el.id === update.data.id);
            if (idx !== -1) {
              this.scans[idx] = update.data;
            } else {
              this.scans.push(update.data);
            }
            if (this.list.length === 0) {
              this.isWaitingFirstScan = true;
            }
            this.isCreatingScan = false;

            break;
          }
          default:
            break;
        }
        this.updateAvatarHandlers[update.data.status]?.forEach((cb) => cb());
      }
    });
  }

  addPlaceholderAvatar() {
    this.scans.push({
      id: 'placeholder',
      status: ScanStatus.PROCESSING,
      created_at: '',
      preview_url: '',
      queued_at: '',
      scan_metadata: {},
      config: ConfigType.v1
    });
  }

  addUpdateAvatarHandler(key: ScanStatus, cb: () => void) {
    if (Array.isArray(this.updateAvatarHandlers[key])) {
      this.updateAvatarHandlers[key]?.push(cb);
    } else this.updateAvatarHandlers[key] = [cb];
  }

  removeUpdateAvatarHandler(key: ScanStatus, cb: () => void) {
    if (Array.isArray(this.updateAvatarHandlers[key])) {
      this.updateAvatarHandlers[key] = this.updateAvatarHandlers[key]?.filter((e) => e !== cb);
    }
  }

  setTimerStatus(status: boolean) {
    this.isWaitingFirstScan = status;
  }

  async getScanList() {
    if (this.readyScansList.length === 0) {
      this.isScansLoading = true;
    }
    await avatarClient
      .listScans(auth.token, { limit: this.readyScansList.length === 0 ? 5 : this.readyScansList.length, offset: 0 })
      .then(
        action('listScansSuccess', (data) => {
          this.scans = data.items;
          this.scansTotal = data.total;
          this.isScansLoading = false;
        })
      );
  }
  loadMoreScans() {
    avatarClient.listScans(auth.token, { limit: 6, offset: this.scans.length }).then(
      action('updateScansList', (data) => {
        this.scans = [...this.scans, ...data.items];
      })
    );
  }

  cancelAvatarCreation() {
    this.isCreatingAvatar = false;
  }

  deleteAvatar(id: string) {
    if (auth.data?.token)
      avatarClient.deleteAvatar(auth.data.token, id).then(
        action('deleteAvatarSuccess', () => {
          this.list = this.list.filter((avatar) => avatar.id !== id);
        })
      );
  }
  deleteScan(token: string, id: string) {
    this.scans = this.scans.filter((e) => e.id !== id);
    avatarClient.deleteScan(token, id);
  }

  setAvatarExportBtnActivity(status: boolean) {
    this.isExportBtnEnabled = status;
  }

  async initAvatarList() {
    this.loaded = false;
    await this.getAvatars({ limit: 7, offset: 0 }).then(
      action('initScanCreation', ({ avatars, scans }) => {
        // console.log('Init avatar list: ', avatars, scans);
        if (this.scans.length === 0) {
          this.scans = scans;
        }
        if (avatars.items.length === 0 && scans.length === 0) {
          this.isCreatingScan = true;
        }
        if (
          avatars.items.length === 0 &&
          scans.length > 0 &&
          scans.some((scan) => scan.status === ScanStatus.PROCESSING)
        ) {
          this.isWaitingFirstScan = true;
        }
        this.loaded = true;
      })
    );
    return { avatars: this.list, scans: this.scans };
  }
  loadMoreAvatars() {
    return avatarClient.listAll(auth.token, { limit: 8, offset: this.list.length }).then(
      action('loadMoreAvatars', (data) => {
        this.list = [...this.list, ...data.avatars.items];
      })
    );
  }

  setAvatarListLoading(status: boolean) {
    this.isAvatarListLoading = status;
  }
  checkScanOutdate(scan: ScanData, animatable: boolean): boolean {
    // console.log(scan)

    const processing_time = new Date(scan.queued_at || scan.created_at);
    // console.log(processing_time);
    // console.log(scan.status);
    // DEBUG
    //const outdate_time = 2 * 60 * 1000; //30 * 24 * 60 * 60 * 1000; // 30 days
    //return true; //Date.now() - processing_time.getTime() > outdate_time;

    if (animatable) {
      return processing_time < new Date('2023-06-22');
    }
    return processing_time < new Date('2023-04-01');
  }

  async createScan() {
    ga.event(GaEvent.CreateScan);
    return await avatarClient.createAvatar(auth.data?.token).then(
      action('createScanSuccess', (data) => {
        this.scans.push(data.scan);
        return data;
      })
    );
  }
  async restartScan(scan_id: string) {
    this.list.forEach((avatar) => {
      if (avatar.scan_id === scan_id) {
        avatar.preview_url = PreviewStatus.Initial;
      }
    });

    return await avatarClient.restartAvatar(auth.token, scan_id);
  }

  initScanCreation() {
    this.isCreatingAvatar = false;
    this.isCreatingScan = true;
  }

  cancelScanCreation() {
    this.isCreatingScan = false;
  }

  createCustomization(scan_id: string) {
    if (auth.data?.token) {
      ga.event(GaEvent.NewAvatar);
      avatarClient.newCustomization(auth.data.token, scan_id).then(({ id }) => {
        this.isCreatingAvatar = false;
        this.isAvatarListLoading = true;
        this.redirectToEditor(id);
      });
    }
  }

  getAvatars(config?: { limit: number; offset: number }): Promise<ScansAndAvatars> {
    return avatarClient.listAll(auth.token, config || { limit: 3, offset: 0 }).then(
      action('avatarsLoadSuccess', (data) => {
        // console.log('Data: ', data);
        this.loaded = true;
        this.list = data.avatars.items;
        this.avatarsTotal = data.avatars.total;

        if (this.scans.length === 0) {
          this.scans = data.scans;
        }

        /* --- Just for testing --- */
        /*
        this.scans.push({ status: ScanStatus.PROCESSING, id: '123', created_at: '123' });
        this.list[0].preview_url = 'status://pending';
        */
        /* --- Just for testing --- */
        return data;
      })
    );
  }

  redirectToEditor(id: string, after_timer = false) {
    const params = new URL(window.location.href).searchParams;
    const token = params.get(AUTH_TOKEN_SEARCH_PARAM);

    const searchParams = new URLSearchParams();
    searchParams.append('customization_id', id);
    if (after_timer) {
      searchParams.append('after_timer', String(after_timer));
    }
    if (auth.userSessionKey) {
      searchParams.append('session_id', auth.userSessionKey);
    }
    if (token) {
      searchParams.append(AUTH_TOKEN_SEARCH_PARAM, token);
    }
    window.location.href = `/editor?${searchParams.toString()}`;
  }
}

export const avatars = new Avatars();
