import { Color, Shader, Texture } from 'three';
import { Uniform } from './_common';

export interface HairCapParams {
  hair_cap_map: Uniform<Texture | null>;
  hair_cap_multiplier: Uniform<number>;
  hair_cap_color: Uniform<Color>;
}

const default_hair_cap_params: HairCapParams = {
  hair_cap_map: new Uniform(null),
  hair_cap_multiplier: new Uniform(1.3),
  hair_cap_color: new Uniform(new Color(0, 0, 0)),
};

export const hair_cap_pars = `
uniform sampler2D hair_cap_map;
uniform float hair_cap_multiplier;
uniform vec3 hair_cap_color;

vec3 applyHairCap(vec3 diffuseColor, vec2 vUv) {
  float map_val = texture2D(hair_cap_map, vec2(vUv.x, 1.0-vUv.y)).g;

  float fac = clamp(((1.0 - map_val) * hair_cap_multiplier), 0.0,1.0);
  return mix( hair_cap_color, diffuseColor.rgb,  fac);
}

float applyHairCapRoughness(float roughness, vec2 vUv) {
  vec4 map_val = texture2D(hair_cap_map, vec2(vUv.x, 1.0-vUv.y));

  float fac_bald = clamp(map_val.r * 1.0, 0.0,1.0);
  float fac_hair = clamp(map_val.g * 1.0, 0.0,1.0);

  return mix ( mix( roughness, 0.25, fac_bald) , 0.759, fac_hair);
}
`;

export function hair_cap(shader: Shader, hair_cap_params: HairCapParams = default_hair_cap_params) {
  Object.entries(hair_cap_params).forEach(([k, v]) => {
    shader.uniforms[k] = v;
  });

  shader.fragmentShader = hair_cap_pars + shader.fragmentShader;

  shader.fragmentShader = shader.fragmentShader.replace(
    '#include <emissivemap_fragment>',
    `
    #include <emissivemap_fragment>

    diffuseColor.rgb  = applyHairCap(diffuseColor.rgb, vUv);
    roughnessFactor =  applyHairCapRoughness( roughnessFactor, vUv );

    `
  );

}
