import { Modal } from './modal';
import './export-glb-discord.scss';

export const ExportGlbDiscord = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal onClose={onClose}>
      <div className="exportglb">
        <h2 className="exportglb__title">How to export avatar as 3D model</h2>
        <ol className="exportglb__list">
          <li className="exportglb__item">Join our discord.</li>
          <li className="exportglb__item">
            Use our bot to unlock downloads. In channel{' '}
            <span className="exportglb__command" style={{ color: 'white' }}>
              #bot
            </span>{' '}
            start typing <span className="exportglb__command">/</span> and then select
            <span className="exportglb__command">/avaturn</span> in the list. Click{' '}
            <span className="exportglb__highlight">Export GLB</span>.
          </li>
          <li className="exportglb__item">
            Use the provided link to choose an avatar and download. See channel{' '}
            <span className="exportglb__command" style={{ color: 'white' }}>
              #how-to-use-bot
            </span>{' '}
            for more info.
          </li>
        </ol>
        <a
          className="exportglb__discord_button"
          href="https://discord.com/invite/2xmxXv2SQk"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/landing/social/discord.svg" alt="Discord logo" />
          Join us
        </a>
        <h3 className="exportglb__itsfree">IT'S FREE</h3>
      </div>
    </Modal>
  );
};
