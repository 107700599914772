import { get_page_params } from '@in3d/common';
// import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import GUI from 'lil-gui';
import {
  AnimationMixer,
  AnimationObjectGroup,
  Clock,
  Group,
  MeshStandardMaterial,
  PerspectiveCamera,
  Scene,
  TextureLoader,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { AdaptiveDPR, AdaptiveSceneFeatures } from './addons/fps_optimizer';
import { CanvasRecorder } from './canvas_recorder';
import { CurrentState } from './core/CurrentState';
import { DecalMaker } from './core/resources/decal_maker';
import { maybePredictBlendshapes } from './core/resources/tracking';
import { SceneManager } from './core/scenes/scene_manager';
import { resources } from './resource_manager';
import { Screenshoter } from './screenshoter';

const gltf_loader = new GLTFLoader();
const texture_loader = new TextureLoader();

class GUIWrapper extends GUI {
  private _folders: Record<string, GUI> = {};

  getFolder(name: string) {
    this._folders[name] ??= this.addFolder(name).close();
    return this._folders[name];
  }

  removeFolder(name: string) {
    this._folders[name]?.destroy();
    delete this._folders[name];
    // console.log(this._folders);
  }
}

class AvatarView {
  static recorder: CanvasRecorder;
  static parent_element: HTMLElement;
  static inited = false;
  static mixer: AnimationMixer;
  static animationGroup: AnimationObjectGroup;
  static scene_objects_group: Group;
  static controls: OrbitControls;
  static camera: PerspectiveCamera;
  static clock: Clock;
  static scene: Scene;
  static gui_: GUIWrapper;
  static scene_manager: SceneManager;
  static renderer: WebGLRenderer;
  static adaptiveDPR: AdaptiveDPR;
  static adaptiveSceneFeatures: AdaptiveSceneFeatures;
  static is_rendering_active = false;
  static isHeadlessMode = false;
  static currentState: CurrentState;
  static stats: Stats;
  private static _is_animatable = false;
  static screenshoter: Screenshoter;
  static decalMaker: DecalMaker;
  static get is_animatable() {
    return this._is_animatable;
  }
  static set is_animatable(x) {
    resources.setIsAnimatable(x);
    this._is_animatable = x;
    if (x) {
      AvatarView.scene_manager.switch(AvatarView.isHeadlessMode ? 'sceneHeadless' : 'sceneDefaultAnimatable');
    }
  }

  static set_current_state(c: CurrentState) {
    AvatarView.currentState = c;
  }

  static get gui() {
    if (IS_DEBUG && AvatarView.debug) {
      if (!AvatarView.gui_) {
        AvatarView.gui_ = new GUIWrapper({ autoPlace: false });
      }
      return AvatarView.gui_;
    }
    return undefined;
  }

  static debug_ = (get_page_params().debug || 'false') == 'true';
  static get debug() {
    return AvatarView.debug_;
  }

  static set debug(x) {
    AvatarView.debug_ = x;
  }

  static get page_params() {
    return get_page_params();
  }
  //
  static avatar_envMapIntensity_ = 1.0;
  static cloth_envMapIntensity_ = 1.0;

  static get avatar_envMapIntensity() {
    return this.avatar_envMapIntensity_;
  }

  static set avatar_envMapIntensity(x) {
    this.avatar_envMapIntensity_ = x;
    if (AvatarView.currentState.avatar) {
      AvatarView.currentState.avatar.group.traverse((obj: any) => {
        if (obj.isMesh && obj.material) {
          (obj.material as MeshStandardMaterial).envMapIntensity = x;
        }
      });
    }
  }

  static get cloth_envMapIntensity() {
    return this.cloth_envMapIntensity_;
  }
  static set cloth_envMapIntensity(x) {
    this.cloth_envMapIntensity_ = x;
    for (const s of AvatarView.currentState.all_assets) {
      if (!s.is_downloaded) continue;
      s.group.traverse((node: any) => {
        if (
          node.material &&
          s.settings_json.use_envlight !== true &&
          node.material.userData['type'] != '__lensMaterial__'
        ) {
          node.material.envMapIntensity = x;
        }
      });
    }
  }
  //

  static init_loaders() {
    gltf_loader.setMeshoptDecoder(MeshoptDecoder);
  }

  static set_camera(camera_dict: any) {
    if (camera_dict.target) {
      AvatarView.controls.target.set(camera_dict.target.x, camera_dict.target.y, camera_dict.target.z);
    }
    if (camera_dict.position) {
      AvatarView.controls.object.position.set(camera_dict.position.x, camera_dict.position.y, camera_dict.position.z);
    }
    if (camera_dict.zoom) {
      (AvatarView.controls.object as PerspectiveCamera).zoom = camera_dict.zoom;
    }
    this.camera.updateProjectionMatrix();
    this.controls.update();
  }

  static onWindowResize() {
    const parent = AvatarView.parent_element;

    let width = parent.clientWidth;
    let height = parent.clientHeight;

    if (AvatarView.isHeadlessMode) {
      width = 480;
      height = 640;
    }

    // width = 720;
    // height = 1024;
    AvatarView.scene_manager.onWindowResize(width, height);

    setTimeout(() => {
      let width = parent.clientWidth;
      let height = parent.clientHeight;

      if (AvatarView.isHeadlessMode) {
        width = 480;
        height = 640;
      }
      // width = 720;
      // height = 1024;
      AvatarView.scene_manager.onWindowResize(width, height);
    }, 500);
  }

  static render(mixerUpdateDelta: number) {
    if (AvatarView.inited == false) {
      return;
    }

    if (AvatarView.debug) {
      AvatarView.stats.update();
    }

    AvatarView.screenshoter.onBeforeRendering();
    this.scene_manager.render(mixerUpdateDelta);
    AvatarView.screenshoter.onAfterRendering();
  }

  static animate() {
    requestAnimationFrame(AvatarView.animate);

    if (!AvatarView.is_rendering_active || !AvatarView.inited) {
      return;
    }

    maybePredictBlendshapes();
    AvatarView.controls.update();

    const mixerUpdateDelta = AvatarView.clock.getDelta();
    AvatarView.mixer?.update(mixerUpdateDelta);

    AvatarView.adaptiveDPR?.update(mixerUpdateDelta, AvatarView.scene_manager.renderer);
    AvatarView.adaptiveSceneFeatures?.update(mixerUpdateDelta, AvatarView.scene_manager);

    AvatarView.render(mixerUpdateDelta);
  }
}

// window.AvatarView = AvatarView;

export { AvatarView, gltf_loader, texture_loader };
