import {useNavigate, useSearchParams} from 'react-router-dom';
import {PopupType} from './index';

export const usePopup = <T extends Record<string, string>>(name: PopupType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  return {
    open: (data?: T) =>
      setSearchParams((prev) => {
        prev.set('popup', name);
        for (const key in data) {
          prev.set(key, data[key]);
        }
        return prev;
      }),
    close: () => navigate(-1),
    closeAll: () => {
      setSearchParams(prev => {
        prev.delete('popup');
        return prev;
      })
    }
  };
};
