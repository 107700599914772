import { startExperience } from '@in3d/core';

export const DeepmotionActions = () => {
  return <></>
  // return (
  //   <div className="customizer__actions">
  //     <div className="controls">
  //       <button className="control-btn" onClick={() => startExperience('sceneDefault')}>
  //         <img src="/assets/scene/reset.svg" alt="reset" />
  //       </button>
  //     </div>
  //     <button className="expirience-btn" onClick={() => startExperience('deepmotion_Football')}>
  //       {/* <button className="expirience-btn" onClick={() => loadScene("sceneDark4")}> */}
  //       <div className="expirience-btn__content">
  //         <img src="/assets/scene/exp_football.jpg" alt="exp_football" />
  //       </div>
  //     </button>
  //   </div>
  // );
};
