import './export-buttons.scss';

import { cn } from '@in3d/common';
import { takeScreenshotAndSave } from '@in3d/core';
import SVG from 'react-inlinesvg';
import { VideoRecordingButton } from '../video-recording-btn';
export const ExportButtons = ({ enabled, onClick }: { enabled: boolean; onClick: () => void }) => {
  return (
    <div className="export-buttons">
      <button
        className="export-buttons__button export-buttons__button_camera"
        onClick={() => takeScreenshotAndSave('download')}
      >
        <SVG src={`/assets/camera.svg`} />
      </button>
      {/* <VideoRecordingButton/> */}
      <hr />
      <button
        disabled={!enabled}
        className={cn(['export-buttons__button', 'export-buttons__button_download', !enabled && 'loading'])}
        onClick={onClick}
      >
        {!enabled && <span className="loader" />}
        <SVG src="/assets/download.svg" />
      </button>
    </div>
  );
};
