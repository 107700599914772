/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  BufferAttribute,
  BufferGeometry,
  Group,
  InterleavedBufferAttribute,
  Mesh,
  SkinnedMesh,
  Vector2,
  Vector3,
} from 'three';
// import { computeMikkTSpaceTangents } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { resources } from '../../../resource_manager/resourceManager';
import { CategoryName, placementToCategory, ResourcePlacementStr } from '@in3d/common';
import { MaterialSettings } from '../../Materials';
import { concat_offsets_ids, getImageData, unpack_mask_img } from '../../utils';
import { Avatar } from '../avatar';
import { DownloadState, SettingsJson, Slots, disposeThreeJSObject, placement_to_slots } from '../common';
// import { computeMikkTSpaceTangents } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
// @ts-ignore
// import * as MikkTSpace from 'three/examples/jsm/libs/mikktspace.module';

function computeTangents(ths: BufferGeometry, negate = true) {
  // const this = ths;
  const index = ths.index;
  const attributes = ths.attributes;

  // based on http://www.terathon.com/code/tangent.html
  // (per vertex tangents)

  if (
    index === null ||
    attributes['position'] === undefined ||
    attributes['normal'] === undefined ||
    attributes['uv'] === undefined
  ) {
    console.error(
      'THREE.BufferGeometry: .computeTangents() failed. Missing required attributes (index, position, normal or uv)'
    );
    return;
  }
  const indices = index.array;
  const positions = attributes['position'].array;
  const normals = attributes['normal'].array;
  const uvs = attributes['uv'].array;
  const nVertices = positions.length / 3;
  if (ths.hasAttribute('tangent') === false) {
    ths.setAttribute('tangent', new BufferAttribute(new Float32Array(4 * nVertices), 4));
  }
  const tangents = ths.getAttribute('tangent').array;
  const tan1: Vector3[] = [],
    tan2: Vector3[] = [];
  for (let i = 0; i < nVertices; i++) {
    tan1[i] = new Vector3();
    tan2[i] = new Vector3();
  }
  const vA = new Vector3(),
    vB = new Vector3(),
    vC = new Vector3(),
    uvA = new Vector2(),
    uvB = new Vector2(),
    uvC = new Vector2(),
    sdir = new Vector3(),
    tdir = new Vector3();
  function handleTriangle(a: number, b: number, c: number) {
    vA.fromArray(positions, a * 3);
    vB.fromArray(positions, b * 3);
    vC.fromArray(positions, c * 3);
    uvA.fromArray(uvs, a * 2);
    uvB.fromArray(uvs, b * 2);
    uvC.fromArray(uvs, c * 2);
    vB.sub(vA);
    vC.sub(vA);
    uvB.sub(uvA);
    uvC.sub(uvA);
    const r = 1.0 / (uvB.x * uvC.y - uvC.x * uvB.y);

    // silently ignore degenerate uv triangles having coincident or colinear vertices

    if (!isFinite(r)) return;
    sdir.copy(vB).multiplyScalar(uvC.y).addScaledVector(vC, -uvB.y).multiplyScalar(r);
    tdir.copy(vC).multiplyScalar(uvB.x).addScaledVector(vB, -uvC.x).multiplyScalar(r);
    tan1[a].add(sdir);
    tan1[b].add(sdir);
    tan1[c].add(sdir);
    tan2[a].add(tdir);
    tan2[b].add(tdir);
    tan2[c].add(tdir);
  }
  let groups = ths.groups;
  if (groups.length === 0) {
    groups = [
      {
        start: 0,
        count: indices.length,
      },
    ];
  }
  for (let i = 0, il = groups.length; i < il; ++i) {
    const group = groups[i];
    const start = group.start;
    const count = group.count;
    for (let j = start, jl = start + count; j < jl; j += 3) {
      handleTriangle(indices[j + 0], indices[j + 1], indices[j + 2]);
    }
  }
  const tmp = new Vector3(),
    tmp2 = new Vector3();
  const n = new Vector3(),
    n2 = new Vector3();
  function handleVertex(v: number) {
    n.fromArray(normals, v * 3);
    n2.copy(n);
    const t = tan1[v];

    // Gram-Schmidt orthogonalize

    tmp.copy(t);
    tmp.sub(n.multiplyScalar(n.dot(t))).normalize();

    // Calculate handedness

    tmp2.crossVectors(n2, t);
    const test = tmp2.dot(tan2[v]);
    const w = test < 0.0 ? -1.0 : 1.0;

    if (negate) {
      tmp.negate();
    }
    // @ts-ignore
    tangents[v * 4] = tmp.x;
    // @ts-ignore
    tangents[v * 4 + 1] = tmp.y;
    // @ts-ignore
    tangents[v * 4 + 2] = tmp.z;
    // @ts-ignore
    tangents[v * 4 + 3] = w;
  }
  for (let i = 0, il = groups.length; i < il; ++i) {
    const group = groups[i];
    const start = group.start;
    const count = group.count;
    for (let j = start, jl = start + count; j < jl; j += 3) {
      handleVertex(indices[j + 0]);
      handleVertex(indices[j + 1]);
      handleVertex(indices[j + 2]);
    }
  }
}

export class Asset {
  id = '';
  type = '';
  name = '';
  asset_dir = '';
  filename = 'model_v2.glb'; // either use asset_dir / filename
  glb_url = '';
  preview_url = '';
  gender: string;
  settings_json: SettingsJson;
  ref: Asset;
  isDraft = false;
  get placement(): Slots[] {
    return [
      ...placement_to_slots[this.type || this.placement_str],
      ...(this.settings_json.with_head ? [Slots.Head] : []),
      ...(this.settings_json.with_shoes ? [Slots.Shoes] : []),
    ];
  }
  placement_str: ResourcePlacementStr;
  get category(): CategoryName {
    /*
    const slotsByCategories: { [key in ResourcePlacementStr]: CategoryName } = {
      eyes: CategoryName.Glasses,
      head: CategoryName.Hair,
      look: CategoryName.Outfit,
      shoes: CategoryName.Shoes,
      faceMask: CategoryName.Glasses,
    };
    */
    return placementToCategory(this.placement_str); //slotsByCategories[this.placement_str];
  }

  // Material
  material_preset = '';
  material_settings: MaterialSettings;

  // Other settings
  protected settings: {
    cloth_id: string;
    embeded_retarget_info: boolean;
    is_single_mesh: boolean;
    mesh_name: string;
    retargeting_offsets_attribute: string;
    retargeting_multiplier: number;
  };
  set_settings(_settings: Asset['settings']) {
    this.settings = _settings;
  }

  /** A group with meshes that the asset consists of */
  group: Group;

  // These used when the retargeting info is not embedded in glb
  protected offsets: any;
  protected offsets_ids: any;

  /** Stores offset maps, Float32Array, 1 channel [not used now] */
  offset_map: Float32Array;
  /** Stores avatar mask for head in old topology, UInt8Array, 1 channel */
  avatar_mask?: Uint8Array;
  /** Stores avatar mask for head in animatable topology, UInt8Array, 1 channel */
  avatar_mask_uv2: Uint8Array;

  //
  mesh_download_state = DownloadState.NotLoaded;
  cur_skeleton: string; // uuid of skeleton

  //
  visibility_to_set?: boolean;

  get corresp_ids() {
    return null; //??? corresp_ids;
  }

  get is_downloaded() {
    return this.mesh_download_state === DownloadState.Loaded;
  }
  get is_loading() {
    return this.mesh_download_state === DownloadState.Loading;
  }

  get model_full_path() {
    return this.glb_url || this.asset_dir + '/' + this.filename;
  }

  setDownloadState(state: DownloadState) {
    this.mesh_download_state = state;
  }

  async load_all() {
    return;
  }

  retarget(avatar: Avatar) {
    return;
  }

  load_mask_and_offset_map(
    image: HTMLImageElement | null,
    array?: Uint8ClampedArray,
    new_packing = false,
    animatable = false
  ) {
    if (!array) {
      const mask = getImageData(image) as ImageData;

      if (image!.width !== 512 || image!.height !== 512) {
        throw new Error(`Bad texture sizes!, the sizes are ${image!.width} ${image!.height}`);
      }
      array = mask.data;
    }

    const [avatar_mask, avatar_mask_uv2, offset_map] = unpack_mask_img(array, 512, 512, new_packing, animatable);
    this.avatar_mask = avatar_mask as Uint8Array;
    this.avatar_mask_uv2 = avatar_mask_uv2 as Uint8Array;
    this.offset_map = offset_map as Float32Array;
  }

  cleanup() {
    disposeThreeJSObject(this.group);
    this.group = new Group();
    this.offsets = null;
    this.offsets_ids = null;
    this.offset_map = new Float32Array(0);
    this.avatar_mask = undefined; // UInt8Array, 1 channel
    this.avatar_mask_uv2 = new Uint8Array(0); // UInt8Array, 1 channel

    this.cur_skeleton = '';

    this.setDownloadState(DownloadState.NotLoaded);
  }

  set_visibility_to_set(value: boolean) {
    this.visibility_to_set = value;
  }

  set_visibility(value: boolean, cleanup = true) {
    if (this.is_downloaded) {
      this.group.visible = value;
      if (!value && cleanup) {
        this.cleanup();
      }
    } else {
      throw new Error('Cannot set visibility. Asset is not loaded!');
    }
  }

  get_customization() {
    return {};
  }

  set_customization(customization_dict: any) {
    return;
  }
}

export class RetargetableAsset extends Asset {
  override retarget(avatar: Avatar) {
    this.group.traverse((node: any) => {
      if (!node.isMesh) {
        return;
      }
      const mesh = node as SkinnedMesh;
      const avatar_verts = avatar.verts;

      const cloth_verts_buffer = mesh.geometry.attributes['position'];
      retarget_(
        avatar_verts,
        cloth_verts_buffer,
        mesh.userData['offsets'] || this.offsets,
        mesh.userData['offsets_ids'] || this.offsets_ids,
        resources.corresponding_ids,
        this.settings.retargeting_multiplier
      );
      // node.geometry.computeVertexNormals();
      if (this.material_settings.recompute_normals) {
        mesh.geometry.computeVertexNormals();
      }
      mesh.geometry.computeBoundingBox();

      if (this.material_settings.compute_tangent) {
        computeTangents(mesh.geometry, true);
      }

      cloth_verts_buffer.needsUpdate = true;
    });
  }
  protected loadRetargetingInfoFromMesh(mesh: Mesh) {
    if (mesh.geometry.attributes['position'] instanceof InterleavedBufferAttribute) {
      alert('Interleaved buffer.');
    }
    mesh.userData['offsets'] = new Float32Array(mesh.geometry.attributes['position'].array);
    mesh.userData['offsets_ids'] = concat_offsets_ids(
      mesh.geometry.attributes['_ids_0'].array,
      mesh.geometry.attributes['_ids_1'].array
    );
    mesh.geometry.deleteAttribute('_ids_0');
    mesh.geometry.deleteAttribute('_ids_1');
  }
}

function retarget_(
  avatar_verts: ArrayLike<number>,
  cloth_verts_buffer: BufferAttribute | InterleavedBufferAttribute,
  offsets: Float32Array,
  offsets_ids: Uint16Array,
  corresp_ids: Uint16Array,
  multiplier = 1
) {
  /* perfroms actual retargeting, `cloth_verts` is modified inplace  */
  // multiplier = 1.5;
  const w = [0.3137255, 0.24019608, 0.1764706, 0.12254902, 0.078431375, 0.04411765, 0.019607844, 0.004901961];

  // if (cloth_verts.length / 3 != offsets_ids.length / 8) {

  // }
  const num_verts =
    cloth_verts_buffer instanceof InterleavedBufferAttribute
      ? cloth_verts_buffer.data.count
      : cloth_verts_buffer.array.length / 3;
  // Recompute verts inplace
  for (let i = 0; i < num_verts; i++) {
    let sx = 0,
      sy = 0,
      sz = 0;
    for (let k = 0; k < 8; k++) {
      const v_id = corresp_ids[offsets_ids[i * 8 + k]];

      sx += w[k] * avatar_verts[v_id * 3];
      sy += w[k] * avatar_verts[v_id * 3 + 1];
      sz += w[k] * avatar_verts[v_id * 3 + 2];
    }
    // const fn = (x) => { return ((Math.abs(x) + 1) **2 -1)  * Math.sign(x) }
    const fn = (x: any) => {
      return x;
    };
    // const a = 0.9;
    cloth_verts_buffer.setX(i, sx + fn(offsets[i * 3]) * multiplier);
    cloth_verts_buffer.setY(i, sy + fn(offsets[i * 3 + 1]) * multiplier);
    cloth_verts_buffer.setZ(i, sz + fn(offsets[i * 3 + 2]) * multiplier);
  }
}
