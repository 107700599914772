export const UpgradeButton = ({
  setPortalOpened,
  mode,
  upgradedUrl,
  isMobile,
}: {
  setPortalOpened: (value: boolean) => void;
  mode: 'upgrade' | 'open' | 'hide';
  upgradedUrl: string | null;
  isMobile: boolean;
}) => {
  const handleClick = () => {
    if (mode === 'upgrade') {
      // Put your logic for upgrade mode here
      setPortalOpened(true);
    } else if (mode === 'open') {
      // Put your logic for open mode here
      console.log('open clicked');
      window.location.href = `/editor?customization_id=${upgradedUrl}`;
    }
  };

  if (mode === 'hide') return null;

  return (
    <button className={`default-controls__upgrade`} onClick={handleClick}>
      <img src={`/assets/arrow-up-shadow.svg`} alt={mode} />
      <span>{mode === 'open' ? 'Open v2' : 'UPGRADE'}</span>
    </button>
  );
};
