/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AvatarView } from './AvatarView';

export class Screenshoter {
  backup_multisapling: number;
  backup_pixelratio: number;
  screenshotCallback?: (x: string) => void;

  takeScreenshot() {
    return new Promise<string>((resolve, reject) => {
      // const strMime = `image/${extension == 'jpg' ? 'jpeg' : extension}`;
      this.screenshotCallback = (img: string) => {
        resolve(img);
      };
    });
  }

  onBeforeRendering() {
    if (!this.screenshotCallback) return;

    AvatarView.renderer.setPixelRatio(3);

    // @ts-ignore
    this.backup_multisapling ??= AvatarView.scene_manager.current_scene.composer.multisampling;
    this.backup_pixelratio ??= Math.min(window.devicePixelRatio, 2.0);

    // @ts-ignore
    AvatarView.scene_manager.current_scene.composer.multisampling = Math.min(
      8,
      AvatarView.renderer.capabilities.maxSamples
    );
  }
  onAfterRendering() {
    if (!this.screenshotCallback || this.backup_multisapling === undefined) return;

    const img = AvatarView.renderer.domElement.toDataURL('image/jpeg', 0.98);

    AvatarView.renderer.setPixelRatio(this.backup_pixelratio);

    // @ts-ignore
    AvatarView.scene_manager.current_scene.composer.multisampling = this.backup;

    this.screenshotCallback(img);
    this.screenshotCallback = undefined;
  }
}
