import { cn } from '@in3d/common';
import { useEffect, useState } from 'react';
import { ColorPanel } from './color-panel';
import { CategoryName } from '@in3d/core';

const options = ['Frame', 'Lens'];

export const Option = ({ name, active, onClick }: { name: string; active: boolean; onClick: () => void }) => {
  return (
    <div className={cn(['glasses-properties__btn', active && 'active'])} onClick={onClick}>
      {name}
    </div>
  );
};

export const GlassesOptions = ({
  handleChangeColor,
  isMobile,
  children,
}: {
  handleChangeColor: (category: CategoryName, color: string) => void;
  isMobile: boolean;
  children: React.ReactNode;
}) => {
  const [activeType, setActiveType] = useState<'frame' | 'lens'>('frame');

  useEffect(() => {
    if (!isMobile) setActiveType('frame');
  }, [isMobile]);
  return (
    <>
      <div className="glasses-properties__type">
        {options.map((option, i) => (
          <Option
            key={i}
            name={option}
            active={activeType === option.toLowerCase()}
            onClick={() => setActiveType(option.toLowerCase() as 'frame' | 'lens')}
          />
        ))}
      </div>
      {activeType === 'frame' && <div className="options-content"> {children}</div>}
      {activeType === 'lens' && (
        <ColorPanel activeCategory={CategoryName.Glasses} handleChangeColor={handleChangeColor} />
      )}
    </>
  );
};
