import axios from 'axios';

export type Body = Record<any, any> | null;
export type Params = Record<any, any> | null;
export type Headers = Record<any, any>;

interface Fetcher {
  get<T>(url: string, config: { params?: Params; headers?: Headers }): Promise<T>;
  post<T>(url: string, body: Body, headers?: Headers): Promise<T>;
  put<T>(url: string, body: Body, headers?: Headers): Promise<T>;
  delete<T>(url: string, body?: Body, config?: { headers?: Headers }): Promise<T>;
}

export interface ClientSettings {
  fetcher: Fetcher;
}

export class ClientBuilder {
  fetcher: Fetcher;
  constructor(settings: ClientSettings) {
    this.fetcher = settings.fetcher;
  }
  get<T>(url: string, params?: Params, headers?: Headers): Promise<T> {
    return this.fetcher.get(url, { params, headers });
  }
  post<T>(url: string, body: Body = null, headers?: Headers): Promise<T> {
    return this.fetcher.post(url, body, { headers });
  }
  put<T>(url: string, body: Body = null, headers?: Headers): Promise<T> {
    return this.fetcher.put(url, body, { headers });
  }
  delete<T>(url: string, body?: Body, headers?: Headers): Promise<T> {
    return this.fetcher.delete(url, { headers });
  }
}

export const createSettings = (baseURL = ''): ClientSettings => {
  const instance = axios.create({ baseURL, timeout: 25000 });
  instance.interceptors.response.use(
    (res) => res.data,
    (err) => Promise.reject(err)
  );
  return {
    fetcher: instance,
  };
};

export const AUTH_TOKEN = 'TEST_TOKEN-UID=fsZ02C1hHKdW5EfVDif6GrNTW8b2';

export const api = new ClientBuilder(createSettings());
