import env from '@in3d/environment';
import GUI from 'lil-gui';
import {
  Bone,
  BufferAttribute,
  BufferGeometry,
  Color,
  DoubleSide,
  FrontSide,
  Group,
  InterleavedBufferAttribute,
  LinearEncoding,
  Matrix4,
  MeshStandardMaterial,
  NearestFilter,
  Shader,
  SkinnedMesh,
  TextureLoader,
  Vector2,
  sRGBEncoding,
} from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { AvatarView, gltf_loader, texture_loader } from '../../AvatarView';
import { LOWPOLY } from '../../client/resources';
import { AvatarList } from '../../content';
import { resources } from '../../resource_manager';
import { Uniform } from '../Assets/shaders/_common';
import { patch_shader_for_uv1_ao } from '../Assets/shaders/ao_uv1';
import { BrightnessContrastParams, brightness_contrast } from '../Assets/shaders/brightness_contrast';
import { CurveParams, curve } from '../Assets/shaders/curve';
import { EyeShaderParams } from '../Assets/shaders/eye_shader';
import { hair_cap } from '../Assets/shaders/hair_cap';
import { adjust_skeleton } from '../animation_utils';
import { Avatar, avatars } from './avatar';
import { DownloadState, clamp } from './common';
import { changeHaircap, hairCapShaderParams } from './haircap';
import { developerConfig } from '@in3d/store';

type MySkinnedMesh = SkinnedMesh<BufferGeometry, MeshStandardMaterial>;
let debug_inited = false;

export const eyeColors = [
  'Lightgreen',
  'amber',
  'Blue',
  'Blue_Gray_yellow',
  'Brown',
  'Dark_Brown',
  'Gray',
  'Gray_Green',
  'Green',
  'hazel',
  'Light_Brown',
  'Light_Grya',
  'Yellow_Green',
];

const eye_params: EyeShaderParams = {
  ramp_color1: new Uniform(new Color(0.103, 0.075, 0.057)),
  ramp_color2: new Uniform(new Color(0.104, 0.039, 0.02)),
  ramp_color3: new Uniform(new Color(0.889, 0.432, 0.174)),

  ramp_val1: new Uniform(0.032),
  ramp_val2: new Uniform(0.545),
  ramp_val3: new Uniform(1.0),

  whitesTexture: new Uniform(null),
  irisPupilMask: new Uniform(null),
};

const params = {
  c: new Color(0.104, 0.039, 0.02),
  l3: 3,
  s3: 0.9,
  h3: 0.53,
  l1: 0.12, // 0.1
  s1: 1.2, // 3
};

async function loadCorrectiveBlendshapes() {
  return 0;
  // if (LOWPOLY) {
  //   return 0;
  // }
  // let gltf = await gltf_loader.loadAsync(`${env.editorResourcesUrl}/animatable/correctives.glb`);
  // let mesh = gltf.scene.children.find((v) => {
  //   return v.name.includes('Head');
  // })! as SkinnedMesh;

  // let a: any;
  // mesh.geometry.morphAttributes['position'].forEach((val) => {
  //   a ??= (val as BufferAttribute).array;
  // });
  // return a;
}

export class AvatarAnimatable extends Avatar {
  static transformTextureBody: any;
  static transformTextureHead: any;
  // static _gui_folder: GUI;

  head_mat: Matrix4;
  eye_texture: string | undefined = undefined;

  static get eye_params() {
    return eye_params;
  }

  get eye_mesh() {
    return this.group.getObjectByName('Eye_Mesh')! as MySkinnedMesh;
  }
  get eyelash_mesh() {
    return this.group.getObjectByName('Eyelash_Mesh')! as MySkinnedMesh;
  }
  get head_mesh() {
    return this.group.getObjectByName('Head_Mesh')! as MySkinnedMesh;
  }
  get teeth_mesh() {
    return this.group.getObjectByName('Teeth_Mesh')! as MySkinnedMesh;
  }
  get tongue_mesh() {
    return this.group.getObjectByName('Tongue_Mesh')! as MySkinnedMesh;
  }
  get eyeAO_mesh() {
    return this.group.getObjectByName('EyeAO_Mesh')! as MySkinnedMesh;
  }
  override get body_mesh() {
    return this.group.getObjectByName('Body_Mesh')! as MySkinnedMesh;
  }

  get meshes() {
    return [this.head_mesh, this.eye_mesh, this.eyelash_mesh, this.teeth_mesh, this.tongue_mesh, this.eyeAO_mesh];
  }
  constructor() {
    super();

    if (IS_DEBUG && AvatarView.gui && !debug_inited) {
      const eye_gui = AvatarView.gui.getFolder('avatar').addFolder('Eye params').close();
      eye_gui.addColor(eye_params.ramp_color1, 'value').name('ramp_color1').listen();
      eye_gui.addColor(eye_params.ramp_color2, 'value').name('ramp_color2').listen();
      eye_gui.addColor(eye_params.ramp_color3, 'value').name('ramp_color3').listen();

      eye_gui.add(eye_params.ramp_val1, 'value', 0, 1, 0.01).name('ramp_val1').listen();
      eye_gui.add(eye_params.ramp_val2, 'value', 0, 1, 0.01).name('ramp_val2').listen();
      eye_gui.add(eye_params.ramp_val3, 'value', 0, 1, 0.01).name('ramp_val3').listen();

      eye_gui
        .addColor(params, 'c')
        .name('c')
        .listen()
        .onChange(() => {
          AvatarAnimatable.set_eye_color('#' + params.c.getHexString());
        });

      Object.entries(params).forEach(([k, v]) => {
        if (k == 'c') {
          return;
        }
        eye_gui
          .add(params, k, 0, 3, 0.01)
          .listen()
          .onChange(() => {
            AvatarAnimatable.set_eye_color('#' + params.c.getHexString());
          });
      });

      debug_inited = true;
    }
  }

  static set_eye_color(color_str: string) {
    params.c.set(color_str);

    /* color_str is sRGB color*/
    const _hslA = { h: 0, s: 0, l: 0 };
    const _hslB = { h: 0, s: 0, l: 0 };

    const color = new Color(color_str).convertSRGBToLinear();
    color.getHSL(_hslA);

    //
    _hslB.h = (_hslA.h + 0.49 + 0.5) % 1.0;
    _hslB.s = clamp(_hslA.s * params.s1, 0, 1);
    _hslB.l = _hslA.l * params.l1;
    eye_params['ramp_color1'].value.setHSL(_hslB.h, _hslB.s, _hslB.l);

    // As is
    eye_params['ramp_color2'].value.setHSL(_hslA.h, _hslA.s, _hslA.l);

    //
    _hslB.h = (_hslA.h + params.h3 + 0.5) % 1.0;
    _hslB.s = _hslA.s * params.s3;
    _hslB.l = _hslA.l * params.l3;
    eye_params['ramp_color3'].value.setHSL(_hslB.h, _hslB.s, _hslB.l);
  }

  async set_eye_texture(name = 'bc') {
    const eye_mesh = this.eye_mesh;
    const material = eye_mesh.material as MeshStandardMaterial;
    this.eye_texture = name;

    const p1 = texture_loader.loadAsync(`${env.editorResourcesUrl}/eyes/${name}.jpg`).then((texture) => {
      texture.encoding = sRGBEncoding;
      material.map = texture;
      material.needsUpdate = true;
    });

    const p2 = texture_loader.loadAsync(`${env.editorResourcesUrl}/eyes/Normal.jpg`).then((texture) => {
      material.normalMap = texture;
      material.needsUpdate = true;
    });

    return Promise.allSettled([p1, p2]).catch((e) => {
      console.log(`Could not load eye texture.`);
    });
  }

  override async load_mesh() {
    this.download_state = DownloadState.Loading;

    let gui: GUI;
    if (IS_DEBUG && AvatarView.gui) {
      gui = AvatarView.gui.getFolder('avatar').addFolder(this.id).close();
    }

    let root_bone: Bone = new Bone();

    const proxy_bodies_p = load_proxy_body(this.body_id, this.gender);
    const gltf_p = gltf_loader.loadAsync(this.glb_url);

    await Promise.all([
      proxy_bodies_p,
      resources.loadHeadIds(),
      resources.loadRetargetingOffsets(),
      resources.loadRetargetingIds(),
      gltf_p,
    ]);

    this.mesh = (await proxy_bodies_p) as MySkinnedMesh;
    const gltf = await gltf_p;

    const body_idx = this.body_id[this.body_id.length - 1];
    const gltf_ = gltf.scene.children.find((v) => {
      return v.name.includes('_' + body_idx);
    })!;

    const meshes: SkinnedMesh[] = [];

    await Promise.all(
      gltf_.children.map(async (node: any) => {
        gltf.scene.updateMatrixWorld();
        if (!node.isMesh) {
          return;
        }

        const mesh = node as SkinnedMesh;
        let material = mesh.material as MeshStandardMaterial;

        if (!node.name.startsWith('Eye_')) {
          this.turnOffMipMaps(material);
        }

        mesh.receiveShadow = true;
        mesh.castShadow = true;
        material.envMapIntensity = AvatarView.avatar_envMapIntensity;

        let gui = AvatarView.gui! as GUI;
        if (AvatarView.gui && IS_DEBUG) {
          gui = AvatarView.gui.getFolder('avatar').addFolder(mesh.name).close();
        }

        // let material: MeshPhysicalMaterial;

        // if (node.name.startsWith("eyelash_T")) {
        //   (node.material as MeshPhysicalMaterial).specularIntensity = 100;
        // }

        if (IS_DEBUG && AvatarView.gui) {
          if (!material.isMeshStandardMaterial) {
            gui.add(material, 'specularIntensity', 0, 3, 0.01).listen();
          }
        }
        material.flatShading = false;

        // material.specularIntensity = 0.2;
        adjust_skeleton(mesh);
        mesh.skeleton.pose();
        this.root_bone_elevation = mesh.skeleton.bones[0].position.y;
        this.head_mat = mesh.skeleton.bones[5].matrix.clone();

        meshes.push(mesh);

        mesh.frustumCulled = false;

        if (node.name.startsWith('Eye_') || node.name.startsWith('Teeth_') || node.name.startsWith('Tongue_')) {
          if (LOWPOLY) {
            mesh.geometry.computeVertexNormals();
          }
        }

        if (node.name.startsWith('Teeth_')) {
          this.loadTeeth(gui, material);
        }
        if (node.name.startsWith('Eye_')) {
          this.loadEyes(mesh, gui, material);
        }
        if (node.name.startsWith('Eyelash_')) {
          mesh.geometry.computeVertexNormals();
          this.loadEyelash(gui, material);
        }
        if (node.name.startsWith('EyeAO_')) {
          this.loadEyeAO(gui, material);
        }
        if (node.name.startsWith('Tongue_')) {
          this.loadTongue(gui, material);
        }
        if (node.name.startsWith('Head_')) {
          this.mesh.traverse((node: any) => {
            if (node.isMesh) {
              const mesh_fs = node as SkinnedMesh;

              const verts_head_cc = mesh.geometry.attributes['position'].array;

              _retarget_body(
                resources.headIds,
                verts_head_cc,
                mesh_fs.geometry.attributes['position'] as BufferAttribute,
                resources.retargetingOffsets,
                resources.retargetingIds
              );
            }
          });

          // resources.barycentrics;
          // resources.poly_ids;
          // const res = await loadCorrectiveBlendshapes();
          // if (res) {
          //   const a1 = mesh.geometry.attributes['position'].array;
          //   const a2 = new Float32Array(a1.length);
          //   for (let index = 0; index < a1.length; index++) {
          //     a2[index] = a1[index];
          //   }

          //   // MEMORY LEAK HERE
          //   const fn = (coeff: number) => {
          //     for (let index = 0; index < a1.length; index++) {
          //       // @ts-ignore
          //       a1[index] = a2[index] + res[index] * coeff;
          //     }
          //     mesh.geometry.attributes['position'].needsUpdate = true;
          //   };
          //   fn(1);

          //   if (IS_DEBUG && AvatarView.gui) {
          //     let ss = { p: 1 };
          //     gui.add(ss, 'p', -1, 3, 0.01).onChange(fn);
          //   }
          // }
          await this.loadHead(material);
        }
        if (node.name.startsWith('Body_')) {
          ({ material, root_bone } = await this.loadBody(material, mesh, gui, gltf));
        }
      })
    );

    // Array -> Group
    const group = new Group();
    group.name = 'avaturn_body';
    meshes.forEach((v, i) => {
      // v.name = `avaturn_body_${i}`;
      v.name = v.name.split('_')[0] + '_Mesh';
      group.add(v);
    });

    group.add(root_bone);
    this.group = group;
    this.group.visible = false;

    AvatarView.scene_objects_group.add(this.group);

    // if (!AvatarView.isHeadlessMode) {
    // console.time('load_blendshapes');
    await load_blendshapes(this);
    // console.timeEnd('load_blendshapes');
    // }

    AvatarView.animationGroup.add(this.group);

    this.download_state = DownloadState.Loaded;
  }

  private loadEyelash(gui: GUI, material: MeshStandardMaterial) {
    if (LOWPOLY) return;

    material.roughness = 0.8;
  }

  private turnOffMipMaps(material: MeshStandardMaterial) {
    material.map && (material.map.generateMipmaps = false);
    material.normalMap && (material.normalMap.generateMipmaps = false);
    material.roughnessMap && (material.roughnessMap.generateMipmaps = false);
  }

  private async loadBody(material: MeshStandardMaterial, mesh: SkinnedMesh, gui: GUI, gltf: GLTF) {
    material = material.clone();
    mesh.material = material;

    const root_bone = mesh.skeleton.bones[0];

    this._skeleton = mesh.skeleton;
    // mesh.skeleton.bones[32].scale.x = 0.5;
    // mesh.skeleton.bones[32].scale.y = 0.5;
    // mesh.skeleton.bones[32].scale.z = 0.5;
    if (IS_DEBUG && AvatarView.gui) {
      const p = { scale: 1, scalex: 1, scaley: 1, scalez: 1, recompute_normals: false };
      const change_scale = (v: number) => {
        mesh.skeleton.bones[5].scale.set(v, v, v);
        // mesh.skeleton.bones[32].scale.set(v, v, v)
      };
      gui.add(p, 'scale', 0, 3, 0.01).onChange(change_scale).name('head_scale');

      // gui.add(p, 'scale', 0, 3, 0.01).onChange((v: number) => {
      //   // mesh.skeleton.bones[5].scale.x = v;
      //   // mesh.skeleton.bones[5].scale.y = v;
      //   // mesh.skeleton.bones[5].scale.z = v;
      //   // mesh.skeleton.bones[1].scale.x = v;
      //   // mesh.skeleton.bones[1].scale.y = v;
      //   // mesh.skeleton.bones[1].scale.z = v;
      //   mesh.skeleton.bones[5].scale.x = v;
      //   mesh.skeleton.bones[5].scale.y = v;
      //   mesh.skeleton.bones[5].scale.z = v;
      // });
      gui.add(mesh.skeleton.bones[5].scale, 'x', 0, 3, 0.01).listen();
      gui.add(mesh.skeleton.bones[5].scale, 'y', 0, 3, 0.01).listen();
      gui.add(mesh.skeleton.bones[5].scale, 'z', 0, 3, 0.01).listen();

      // let a = mesh.geometry.getAttribute("normal").clone();
      // gui.add(p, 'recompute_normals').onChange((v: boolean) => {
      //   if (v) {
      //     mesh.geometry.computeVertexNormals();
      //   } else {
      //     mesh.geometry.setAttribute("normal", a.clone())
      //   }
      // });
      // if (mesh.geometry.hasAttribute("normal")) {
      //   mesh.geometry.deleteAttribute("normal");
      // }
      // })
    }
    if (gltf.userData.need_recompute_normals === undefined) {
      // mesh.geometry.computeVertexNormals();
    }

    // mesh.geometry.computeVertexNormals();
    // mesh.skeleton.bones[32].scale.x = 1.03;
    // mesh.skeleton.bones[32].scale.y = 1.03;
    // mesh.skeleton.bones[32].scale.z = 1.03;
    // mesh.skeleton.bones[32].matrixWorldNeedsUpdate = true;
    // mesh.skeleton.bones[32].updateMatrixWorld();
    mesh.updateMatrixWorld();
    // mesh.skeleton.pose();
    material.alphaMap = AvatarView.currentState.alpha_map_body;
    material.alphaMap.generateMipmaps = false;
    material.alphaMap.minFilter = NearestFilter;
    material.alphaMap.magFilter = NearestFilter;

    material.alphaTest = 0.5;
    material.needsUpdate = true;
    mesh.castShadow = true;

    material.shadowSide = DoubleSide;
    // material.shadowSide = THREE.BackSide;
    // material.aoMap = new TextureLoader().load('./assets/local/Avatar_AO.png',
    //   () => {
    //     material.aoMap.flipY = false;
    //     material.aoMap.encoding = 3000;
    //     material.aoMapIntensity = 1;
    //   }
    // );
    // material.normalMap = new TextureLoader().load('./assets/local/future/M_Normal.jpg',
    // () => {
    //   material.normalMap!.flipY = false;
    //   material.normalMap!.encoding = 3000;
    //   material.normalMap!.needsUpdate = true;
    //   material.needsUpdate= true;
    //   // material.aoMapIntensity = 1;
    // });

    material.normalScale = new Vector2(1, 1);

    material.customProgramCacheKey = function () {
      // return this.material_settings.use_haircap.toString();
      return 'body';
    }.bind(this);

    if (!Avatar.avatar_material) {
      Avatar.avatar_material = material;
    } else {
      mesh.material = Avatar.avatar_material;
    }

    if (IS_DEBUG && AvatarView.gui) {
      gui.add(material, 'roughness', 0, 3, 0.01).listen();
      gui.add(material, 'aoMapIntensity', 0, 3, 0.01).listen();
      gui.add(material.normalScale, 'x', -1, 3, 0.01).listen();
      gui.add(material.normalScale, 'y', -1, 3, 0.01).listen();
    }

    if (IS_DEBUG && AvatarView.gui) {
      gui.add(material, 'aoMapIntensity', 0, 3, 0.01).listen();
      gui.add(Avatar.brightness_contrast_params.brightness, 'value', -1, 1, 0.01).listen().name('brightness');
      gui.add(Avatar.brightness_contrast_params.contrast, 'value', -1, 1, 0.01).listen().name('contrast');

      gui.add(Avatar.curve_params.curve_coeff, 'value', 0, 3, 0.01).listen().name('curve_coeff');
    }
    // material.roughness = 1.6;
    // material.morphtargets = false;
    material.aoMap = null;
    material.onBeforeCompile = (shader: any) => {
      // patch_shader_for_uv1_ao(shader);
      // brightness_contrast(shader, Avatar.brightness_contrast_params, 'albedo');
      // hair_cap(shader, Avatar.hair_cap_params);
      // patch_shader_for_uv1_ao(shader);
      // hair_cap(shader, Avatar.hair_cap_params);
      // brightness_contrast(shader, Avatar.brightness_contrast_params, 'albedo');
      // curve(shader, Avatar.curve_params, 'albedo')
      brightness_contrast(shader, Avatar.brightness_contrast_params, 'albedo');

      curve(shader, Avatar.curve_params, 'albedo');

      patch_shader_for_uv1_ao(shader);
      material.needsUpdate = true;
    };
    return { material, root_bone };
  }

  private async loadHead(material: MeshStandardMaterial) {
    if (!Avatar.haircap_promise) {
      Avatar.haircap_promise = changeHaircap(developerConfig.editor.defaultHaircap);
      Avatar.set_haircap_color('#6d4539');
    }
    // const transform_texture = await loadUvTransform(`${env.editorResourcesUrl}/animatable/uv_transform_head.png`);
    // AvatarAnimatable.transformTextureHead = transform_texture;
    material.aoMap = null;
    material.alphaMap = AvatarView.currentState.alpha_map_head;
    material.alphaMap.generateMipmaps = false;
    material.alphaMap.minFilter = NearestFilter;
    material.alphaMap.magFilter = NearestFilter;

    material.roughness = 1.3;
    material.alphaTest = 0.5;
    material.side = FrontSide;
    material.shadowSide = DoubleSide;
    // material.normalMap=null;
    material.envMapIntensity = AvatarView.avatar_envMapIntensity;
    material.onBeforeCompile = (shader: Shader) => {
      // use_r_channel_for_alphamap(shader);

      hair_cap(shader, hairCapShaderParams);
      brightness_contrast(shader, Avatar.brightness_contrast_params, 'albedo');
      curve(shader, Avatar.curve_params, 'albedo');
      // patch_shader_for_tonemapping(shader);
      // patch_shader_for_uv1_ao(shader);
    };
  }

  private loadTongue(gui: GUI, material: MeshStandardMaterial) {
    if (IS_DEBUG && AvatarView.gui) {
      gui.add(material, 'aoMapIntensity', 0, 3, 0.01).listen();
      gui.addColor(material, 'color').listen();
    }

    material.onBeforeCompile = (shader: Shader) => {
      // patch_shader_for_tonemapping(shader);
      // patch_shader_for_uv1_ao(shader);
    };
  }

  private loadEyeAO(gui: GUI, material: MeshStandardMaterial) {
    if (AvatarView.gui && IS_DEBUG) {
      gui.add(material, 'aoMapIntensity', 0, 3, 0.01).listen();
      gui.add(material, 'envMapIntensity', 0, 3, 0.01).listen();
      gui.add(material, 'roughness', 0, 3, 0.01).listen();
      gui.addColor(material, 'color').listen();
      gui.add(material, 'opacity', 0, 3, 0.01).listen();
    }
    material.envMapIntensity = 0;
    // material.opacity = 0.4;
    material.roughness = 1.0;
    // material.color = new Color("#c2c2c2");
    // material.color = new Color("#e8e8e8");
    // e8e8e8
    // console.log(material.color);
  }

  private loadEyes(mesh: SkinnedMesh, gui: GUI, material: MeshStandardMaterial) {
    // mesh.
    // mesh.geometry.computeVertexNormals();
    // eye_params.whitesTexture.value = new TextureLoader().load(
    //   `${env.editorResourcesUrl}/animatable/eyes/eye_white2.png`,
    //   (t) => {
    //     t.flipY = false;
    //     t.encoding = sRGBEncoding;
    //     t.needsUpdate = true;
    //   }
    // );

    // material.map = new TextureLoader().load(`${env.editorResourcesUrl}/animatable/eyes/iris3.png`, () => {
    //   material.map!.flipY = false;
    //   material.map!.encoding = sRGBEncoding;
    //   material.map!.needsUpdate = true;
    // });

    // eye_params.irisPupilMask.value = new TextureLoader().load(
    //   `${env.editorResourcesUrl}/animatable/eyes/irisPupilMask.png`,
    //   () => {
    //     material.map!.flipY = false;
    //     material.map!.encoding = LinearEncoding;
    //     material.map!.needsUpdate = true;
    //   }
    // );

    // material.normalMap = new TextureLoader().load(`${env.editorResourcesUrl}/animatable/eyes/NRM_Eye.png`, () => {
    //   material.normalMap!.flipY = false;
    //   material.normalMap!.encoding = LinearEncoding;
    //   material.normalMap!.needsUpdate = true;
    //   material.normalScale.y = -1;
    // });

    if (IS_DEBUG && AvatarView.gui) {
      _eyes_debug(mesh, gui);
      gui.add(material.normalScale, 'x', -1, 3, 0.01).listen();
      gui.add(material.normalScale, 'y', -1, 3, 0.01).listen();

      // add button for changing texture
      gui.add({ change_texture: () => this.set_eye_texture() }, 'change_texture').name('change texture');
    }

    // mesh.geometry.computeVertexNormals();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    material.specularIntensity = 3;
    // material.envMapIntensity = 0.4;

    material.onBeforeCompile = (shader: Shader) => {
      const bc_pararms: BrightnessContrastParams = {
        brightness: new Uniform(-0.25),
        contrast: new Uniform(-0.35),
      };

      if (IS_DEBUG && AvatarView.gui) {
        gui.add(bc_pararms.brightness, 'value', -0.5, 0.5, 0.001).name('brightness');
        gui.add(bc_pararms.contrast, 'value', -0.5, 0.5, 0.001).name('contrast');
      }

      const curve_pararms_eyes: CurveParams = {
        curve_coeff: new Uniform(1),
      };

      if (IS_DEBUG && AvatarView.gui) {
        gui.add(curve_pararms_eyes.curve_coeff, 'value', 0.5, 1.5, 0.001).name('curve');
      }

      brightness_contrast(shader, bc_pararms, 'albedo');

      curve(shader, curve_pararms_eyes, 'albedo');

      // eye_shader(shader, eye_params);
      // patch_shader_for_tonemapping(shader);
      // patch_shader_for_uv1_ao(shader);
    };

    // material.color = new Color("#c2c2c2");
    // material.color = new Color("#e8e8e8");
    // e8e8e8
    // console.log(material.color);
  }

  private loadTeeth(gui: GUI, material: MeshStandardMaterial) {
    if (!LOWPOLY) {
      material.aoMap = new TextureLoader().load(`${env.editorResourcesUrl}/animatable/teeth_AO.png`, () => {
        material.aoMap!.flipY = false;
        material.aoMap!.encoding = LinearEncoding;
        material.aoMapIntensity = 0.5;
        material.needsUpdate = true;
      });
    } else {
      console.log(material.aoMap);
    }

    if (AvatarView.gui && IS_DEBUG) {
      gui.add(material, 'aoMapIntensity', 0, 3, 0.01).listen();
      gui.addColor(material, 'color').listen();
      gui.add(material, 'roughness', 0, 3, 0.01).listen();
    }

    // if (AvatarView.gui && IS_DEBUG) {
    //   gui.add(material, 'aoMapIntensity', 0, 3, 0.01).listen();
    //   gui.add(material, 'envMapIntensity', 0, 3, 0.01).listen();
    //   gui.add(material, 'roughness', 0, 3, 0.01).listen();
    //   gui.addColor(material, 'color').listen();
    //   gui.add(material, 'opacity', 0, 3, 0.01).listen();
    // }

    // material.side = FrontSide;
    // material.shadowSide = DoubleSide;
    material.onBeforeCompile = (shader: Shader) => {
      // patch_shader_for_tonemapping(shader);
      // patch_shader_for_uv1_ao(shader);
    };
  }
}

function loadAO(material: MeshStandardMaterial, gui: GUI) {
  // material.aoMap = new TextureLoader().load('./assets/test_assets/teeth_AO.png',
  //   () => {
  //     material.aoMap!.flipY = false;
  //     material.aoMap!.encoding = LinearEncoding;
  //     material.aoMapIntensity = 1.42;
  //     if (AvatarView.gui) {
  //       // const s = AvatarView.gui.addFolder("teeth");
  //       gui.add(material, 'aoMapIntensity', 0, 3, 0.01).listen();
  //       gui.addColor(material, 'color').listen();
  //     }
  //   }
  // );
}

// -----------------------------------------
//              OTHER METHODS
// -----------------------------------------

function init_avatars_animatable(avatar_list: AvatarList) {
  for (const cur of avatar_list) {
    const avatar = Object.assign(new AvatarAnimatable(), cur);
    avatars[cur.id] = avatar;
  }
}

function _eyes_debug(mesh: SkinnedMesh, gui: GUI) {
  gui.add(mesh.material, 'roughness', 0, 3, 0.01).listen();

  const p = { scale: 1.0, offsetz: 0, offsety: 0, scalex: 1, scaley: 1, scalez: 1, recompute_normals: false };

  const change_scale = (v: number) => {
    mesh.skeleton.bones[52].scale.set(v, v, v);
    mesh.skeleton.bones[53].scale.set(v, v, v);
  };
  gui.add(p, 'scale', 0, 3, 0.01).onChange(change_scale);

  const pz52 = mesh.skeleton.bones[52].position.z + 0;
  const pz53 = mesh.skeleton.bones[53].position.z + 0;
  const change_offset_z = (v: number) => {
    mesh.skeleton.bones[52].position.z = pz52 + v;
    mesh.skeleton.bones[53].position.z = pz53 + v;
  };
  gui.add(p, 'offsetz', -0.05, 0.05, 0.001).listen().onChange(change_offset_z);

  const py52 = mesh.skeleton.bones[52].position.y + 0;
  const py53 = mesh.skeleton.bones[53].position.y + 0;
  const change_offset_y = (v: number) => {
    mesh.skeleton.bones[52].position.y = py52 + v;
    mesh.skeleton.bones[53].position.y = py53 + v;
  };
  gui.add(p, 'offsety', -0.05, 0.05, 0.001).listen().onChange(change_offset_y);

  gui.add(mesh.skeleton.bones[52].rotation, 'x', -1, 1, 0.01).listen();
  gui.add(mesh.skeleton.bones[52].rotation, 'y', -1, 1, 0.01).listen();
  gui.add(mesh.skeleton.bones[52].rotation, 'z', -1, 1, 0.01).listen();

  gui.add(mesh.skeleton.bones[53].rotation, 'x', -1, 1, 0.01).listen();
  gui.add(mesh.skeleton.bones[53].rotation, 'y', -1, 1, 0.01).listen();
  gui.add(mesh.skeleton.bones[53].rotation, 'z', -1, 1, 0.01).listen();

  setTimeout(() => {
    // mesh.skeleton.bones[53].rotation.x = 0.15;
    // mesh.skeleton.bones[52].rotation.x = 0.15;
    // change_scale(0.95);
    // change_offset_z(0.001);
    // change_offset_y(0.003);
    // p.scale = 1.9;
    // p.offsetz = -0.004;
    // p.offsety = -0.001;
    // change_scale(1.9);
    // change_offset_z(-0.004);
    // change_offset_y(-0.001);
    // change_scale(0.95);
    // change_offset_z(0.0005);
    // change_offset_y(0.001);
  }, 3000);
  // mesh.skeleton.bones[52].scale.x = 1.2;
  // mesh.skeleton.bones[52].scale.y = 1.2;
  // mesh.skeleton.bones[52].scale.z = 1.2;

  // mesh.skeleton.bones[53].scale.x = 1.2;
  // mesh.skeleton.bones[53].scale.y = 1.2;
  // mesh.skeleton.bones[53].scale.z = 1.2;

  // mesh.skeleton.bones[53].position.z = -0.004;
  // mesh.skeleton.bones[52].position.z = -0.004;
  // // -0.004
}

async function load_proxy_body(body_name: string, gender: string) {
  const gltf = await gltf_loader.loadAsync(`${env.editorResourcesUrl}/animatable/bodies2_${gender}.glb`);
  const mesh = gltf.scene.children.find((v) => {
    return v.name.includes(body_name);
  })! as SkinnedMesh;

  return mesh;
}

async function load_blendshapes(avatar: Avatar) {
  let path = LOWPOLY
    ? `${env.editorResourcesUrl}/animatable/lowpoly/BlendShapesLowPoly_RetopHead_q.glb`
    : `${env.editorResourcesUrl}/animatable/BlendShapesLowPoly_C_HeadNew_EyeLashes_q.glb`;

  // if (getHostname() == 'deepmotion.avaturn.dev') {
  path = `${env.editorResourcesUrl}/animatable/BlendShapesLowPoly_C_HeadNew_EyeLashes.glb`;
  // }

  // Stanis
  if (document.location.search.includes('Stanis_Export')) {
    path = `${env.editorResourcesUrl}/animatable/Stanis_BlendShapes.glb`;
  }

  const blendshapes_scene = (await gltf_loader.loadAsync(path)).scene;

  blendshapes_scene.traverse(function (child: any) {
    if (!child.isMesh) {
      return;
    }

    const mesh = avatar.group.children.find((v) => {
      return v.name == child.name + '_Mesh';
    }) as SkinnedMesh;
    if (!mesh) {
      console.log(`Not found for ${child.name}`);
      return;
    }

    mesh.morphTargetDictionary = child.morphTargetDictionary;
    mesh.morphTargetInfluences = child.morphTargetInfluences;
    mesh.geometry.morphTargetsRelative = child.geometry.morphTargetsRelative;
    mesh.geometry.morphAttributes = child.geometry.morphAttributes;

    try {
      if (mesh.name != 'Tongue') {
        return;
      }
      if (IS_DEBUG && AvatarView.gui) {
        // let blendshapesFolder = AvatarView.gui.addFolder('Blendshapes' + mesh.name);
        // let names = Object.keys(child.morphTargetDictionary);
        // for (let i = 0; i < names.length; i++) {
        //   blendshapesFolder.add(mesh.morphTargetInfluences!, `${i}`, 0, 1, 0.01).name(names[i]);
        // }
      }
    } catch {
      console.log('error');
    }
  });
}

function _retarget_body(
  head_ids_to_set: ArrayLike<number>,
  avatar_verts: ArrayLike<number>,
  cloth_verts_buffer: BufferAttribute | InterleavedBufferAttribute,
  offsets: Float32Array,
  offsets_ids: Uint16Array,
  multiplier = 1
) {
  const w = [0.3137255, 0.24019608, 0.1764706, 0.12254902, 0.078431375, 0.04411765, 0.019607844, 0.004901961];

  const num_verts = head_ids_to_set.length;

  for (let id = 0; id < num_verts; id++) {
    const i = head_ids_to_set[id];

    let sx = 0,
      sy = 0,
      sz = 0;
    for (let k = 0; k < 8; k++) {
      const v_id = offsets_ids[i * 8 + k];

      sx += w[k] * avatar_verts[v_id * 3];
      sy += w[k] * avatar_verts[v_id * 3 + 1];
      sz += w[k] * avatar_verts[v_id * 3 + 2];
    }
    // const fn = (x) => { return ((Math.abs(x) + 1) **2 -1)  * Math.sign(x) }
    const fn = (x: any) => {
      return x;
    };
    // const a = 0.9;
    cloth_verts_buffer.setX(i, sx + fn(offsets[i * 3]) * multiplier);
    cloth_verts_buffer.setY(i, sy + fn(offsets[i * 3 + 1]) * multiplier);
    cloth_verts_buffer.setZ(i, sz + fn(offsets[i * 3 + 2]) * multiplier);
  }
}

export { init_avatars_animatable };
