import { developerConfig } from '@in3d/store';
import { observer } from 'mobx-react-lite';

import { ArtisantActions } from './artisant';
import { ArtisantStaticActions } from './artisant-static';
import { CinecomSceneAction } from './cinecom';
import { CreepyfriendsSceneAction } from './creepyfriends';
import { DeepmotionActions } from './deepmotion';
import { DefaultControlsActions } from './default-controls';
import { EikonikosActions } from './eikonikos';
import { InfosysActions } from './infosys';
import { TetonridgeActions } from './tetonridge';
import { Gn3raActions } from './gn3ra';

export const DeveloperActions = observer(() => {
  const actions: { [key: string]: JSX.Element } = {
    deepmotion: <DeepmotionActions />,
    infosys: <InfosysActions />,
    artisant: <ArtisantActions />,
    'artisant-static': <ArtisantStaticActions />,
    tetonridge: <TetonridgeActions />,
    eikonikos: <EikonikosActions />,
    gn3ra: <Gn3raActions />,
  };

  return actions[developerConfig.name] || <DefaultControlsActions isCustomDev={!!developerConfig.name}/>;
});

export const DeveloperSceneActions = observer(() => {
  const actions: { [key: string]: JSX.Element } = {
    creepyfriends: <CreepyfriendsSceneAction />,
    cinecom: <CinecomSceneAction />,
  };
  return actions[developerConfig.name] || null;
});
