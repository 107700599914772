import { Mesh, Scene } from 'three';

export function disposeScene(scene: Scene) {
  scene.traverse((node) => {
    if (node instanceof Mesh) {
      // Dispose geometry
      if (node.geometry) {
        node.geometry.dispose();
      }

      // Dispose material
      if (node.material) {
        if (Array.isArray(node.material)) {
          node.material.forEach((material) => disposeMaterial(material));
        } else {
          disposeMaterial(node.material);
        }
      }
    }
  });
}

export function disposeMaterial(material: any) {
  // Dispose texture
  if (material.map) material.map.dispose();
  if (material.lightMap) material.lightMap.dispose();
  if (material.bumpMap) material.bumpMap.dispose();
  if (material.normalMap) material.normalMap.dispose();
  if (material.specularMap) material.specularMap.dispose();
  if (material.envMap) material.envMap.dispose();

  // Dispose material
  material.dispose();
}
