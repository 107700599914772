import { useEffect } from 'react';

declare global {
  interface Window {
    showLoader: () => void;
    hideLoader: () => void;
  }
}

export const useAppLoader = () => {
  useEffect(() => {
    window.showLoader();
    return () => {
      setTimeout(window.hideLoader);
    };
  }, []);
};
