import { DecalMaker } from '.';
import { AvatarView } from '../../../AvatarView';

export function prepareRenderer(this: DecalMaker) {
  const { renderer } = this;
  const currentRenderTarget = renderer.getRenderTarget();

  const currentXrEnabled = renderer.xr.enabled;
  const currentShadowAutoUpdate = renderer.shadowMap.autoUpdate;
  const currentOutputColorSpace = renderer.outputEncoding;
  const currentToneMapping = renderer.toneMapping;

  AvatarView.is_rendering_active = false;

  // set values
  renderer.xr.enabled = false; // Avoid camera modification
  renderer.shadowMap.autoUpdate = false; // Avoid re-computing shadows

  if (this === undefined) return;

  this.rendererProps = {
    currentRenderTarget,
    currentXrEnabled,
    currentShadowAutoUpdate,
    currentOutputColorSpace,
    currentToneMapping,
  };
}

export function restoreRenderer(this: DecalMaker) {
  const { renderer, rendererProps } = this;
  if (!rendererProps) {
    throw new Error('Renderer props not set. Please call prepareRenderer first.');
  }

  const {
    currentRenderTarget,
    currentXrEnabled,
    currentShadowAutoUpdate,
    currentOutputColorSpace,
    currentToneMapping,
  } = rendererProps;

  AvatarView.is_rendering_active = true;

  renderer.setRenderTarget(currentRenderTarget);
  renderer.xr.enabled = currentXrEnabled;
  renderer.shadowMap.autoUpdate = currentShadowAutoUpdate;
  renderer.outputEncoding = currentOutputColorSpace;
  renderer.toneMapping = currentToneMapping;
}
