class LocalData {
  constructor() {
    try {
      localStorage.setItem('ls_check', 'true');
      if (!localStorage.getItem('ls_check')) {
        this.available = false;
      }
    } catch (e) {
      this.available = false;
    }
  }
  available = true;
  setItem(key: string, value: string) {
    if (this.available) return localStorage.setItem(key, value);
    return null;
  }
  getItem(key: string) {
    if (this.available) return localStorage.getItem(key);
    return null;
  }
  removeItem(key: string) {
    if (this.available) return localStorage.removeItem(key);
    return null;
  }
  async clear() {
    if (this.available) return localStorage.clear();
  }
}

export const localData = new LocalData();
