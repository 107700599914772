import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '@in3d/store';
import { observer } from 'mobx-react-lite';
import { AppLoader } from '@in3d/ui';
import { when } from 'mobx';
import { BaseRoutes } from '@in3d/common';

const RequireAuth = ({ children }: { children?: JSX.Element }) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    when(
      () => auth.loaded,
      () => {
        if (!auth.isAuth && location.pathname !== BaseRoutes.Login)
          navigate(BaseRoutes.Login, { state: { from: location } });
      }
    );
  }, [navigate, location]);

  if (auth.loaded && !auth.isAuth) return <Navigate to="/login" state={{ from: location }} replace />;
  return auth.loaded ? <Outlet /> : <AppLoader />; //auth.loaded ? children : <AppLoader />;
};

const _RequireAuth = observer(RequireAuth);

export { _RequireAuth as RequireAuth };
