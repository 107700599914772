import { Mesh, MeshStandardMaterial, SkinnedMesh } from 'three';
import { AvatarView, gltf_loader } from './AvatarView';
import { init_threejs } from './init_threejs';

const params: any = {
  normal_strength: 1,
  normal_flip_Y: true,
  envMapIntensity: 1.0,
  aoMapIntensity: 1.0
};

export function initScene(avatar_dom: HTMLElement) {
  init_threejs(avatar_dom);
  AvatarView.controls.autoRotate = false;
  AvatarView.is_rendering_active=true;
  if (IS_DEBUG && AvatarView.gui) {
    const SettingsGUI = AvatarView.gui.addFolder('MaterialSettings');

    SettingsGUI.add(params, 'normal_flip_Y').onChange(onMaterialUpdate);

    SettingsGUI.add(params, 'normal_strength', 0, 3, 0.1).onChange(
      onMaterialUpdate
    );
    SettingsGUI.add(params, 'aoMapIntensity', 0, 1, 0.01).onChange(
      onMaterialUpdate
    );
    SettingsGUI.add(params, 'envMapIntensity', 0, 1, 0.01).onChange(
      onMaterialUpdate
    );
  }
}

export function loadFile(blob: Blob) {
  const url = URL.createObjectURL(blob);
  for (let i = AvatarView.scene.children.length - 1; i >= 0; i--) {
    const obj = AvatarView.scene.children[i];

    if (obj.name != 'scene_group') {
      AvatarView.scene.remove(obj);
    }
  }

  gltf_loader.load(
    url,
    (gltf) => {
      URL.revokeObjectURL(url);

      gltf.scene.traverse((node: any) => {
        if (! (node instanceof Mesh)) {
          return;
        }
        const mesh = node as Mesh | SkinnedMesh;
        const material = mesh.material as MeshStandardMaterial;

        node.frustumCulled = false;
        node.castShadow = true;
        // node.receiveShadow = true;
        material.envMapIntensity = AvatarView.cloth_envMapIntensity;

        // node.material.aoMapIntensity = 0;
      });
      AvatarView.scene.add(gltf.scene);
      // window.mesh = gltf.scene;
      // window.AvatarView = AvatarView;
    },
    undefined,
    (err) => console.error('Error while loading material: ', err)
  );
}

function onMaterialUpdate() {
  AvatarView.scene.traverse(function (child: any) {
    if (child.material && child.material.normalScale) {
      child.material.normalScale.x = params.normal_strength;
      child.material.normalScale.y =
        params.normal_strength * (params.normal_flip_Y ? -1 : 1);
    }

    if (child.material) {
      child.material.aoMapIntensity = params.aoMapIntensity;
      child.material.envMapIntensity = params.envMapIntensity;
    }
  });
}
