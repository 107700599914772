export const createPseudoProgress = (cb: (num: number) => void) => {
  let step = 0.3;
  let progress = 0;
  let current_progress = 0;
  let interval: ReturnType<typeof setInterval> | null = null;

  const start = () => {
    interval = setInterval(() => {
      current_progress += step;
      progress = Math.round(Math.floor((Math.atan(current_progress) / (Math.PI / 2)) * 100 * 1100) / 1000);
      if (progress >= 100) {
        if (interval) {
          clearInterval(interval);
        }
      } else if (progress >= 99) {
        step = 0.2;
      }
      cb(progress);
    }, 500);
  };
  const clear = () => {
    cb(100);
    if (interval) clearInterval(interval);
  };
  return {
    start,
    clear,
  };
};
