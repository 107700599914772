import './tshirt-generator.scss';

import { avatarClient } from '@in3d/api';
import {
  AvatarView,
  CategoryName,
  DecalMaker,
  allowedClothes as customizableClothes,
  dataURItoBlob,
  resources,
  upload_to_firebase,
} from '@in3d/core';
import { useIsMobile } from '@in3d/hooks';
import { auth } from '@in3d/store';
import { CustomTextArea } from '@in3d/ui';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { SparksIcon } from './sparks-icon';

type genState = 'idle' | 'loading' | 'done';

const TshirtGenerator = ({
  is_debug = false,
  setActive,
}: {
  is_debug?: boolean;
  setActive?: (active: boolean) => void;
}) => {
  const [prompt, setPrompt] = useState('');
  const [state, setState] = useState<genState>('idle');
  const [availabe, setAvailable] = useState(false);
  const isMobile = useIsMobile();

  const decalMakerRef = useRef<DecalMaker | null>(null); // Add this line

  useEffect(() => {
    // wait 1 sec

    setTimeout(() => {
      const { scene, scene_objects_group, camera, scene_manager } = AvatarView;
      decalMakerRef.current = new DecalMaker(scene, scene_objects_group, camera, scene_manager.renderer);
      AvatarView.decalMaker = decalMakerRef.current;
      window.addEventListener('pointermove', decalMakerRef.current.check);
      window.addEventListener('pointerdown', decalMakerRef.current.shoot);

      // TODO: GUI for decal maker
      if (AvatarView.gui) {
        const AvatarViewGui = AvatarView.gui.addFolder('Decal Maker');
        AvatarViewGui.add(decalMakerRef.current, 'scale', 0.1, 10).step(0.01);
        AvatarViewGui.add(decalMakerRef.current, 'initEditingMode').name('Start Editing');
        AvatarViewGui.add(decalMakerRef.current, 'exitEditingMode').name('End Editing');
        AvatarViewGui.add(decalMakerRef.current, 'exportDecalData').name('exportDecalData');
        AvatarViewGui.add(decalMakerRef.current, 'clearDecals').name('clearDecals');
        AvatarViewGui.add(decalMakerRef.current, 'loadDecalData').name('loadDecalData');
      }
    }, 1000);

    return () => {
      if (decalMakerRef.current) {
        window.removeEventListener('pointermove', decalMakerRef.current.check);
        window.removeEventListener('pointerdown', decalMakerRef.current.shoot);
      }

      if (AvatarView.gui) AvatarView.gui.removeFolder('Decal Maker');
    };
  }, []);

  const outfit = resources.active[CategoryName.Outfit];
  const cancelRef = useRef(false);
  type Unsub = () => void;
  const unsubRef = useRef<Unsub | null>(null);

  useEffect(() => {
    return () => {
      cancelRef.current = true;
      setState('done');
      if (unsubRef.current) {
        unsubRef.current();
      }
    };
  }, [outfit]);

  useEffect(() => {
    const { active } = resources;
    const { outfit } = active;
    // console.log('clothes: ', outfit);
    // if outfit is in the list of customizableClothes clothes, set available to true
    setAvailable(customizableClothes.includes(outfit));
  }, [outfit]);

  const empty = prompt.length === 0;

  // or keyboard event
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    cancelRef.current = false;
    if (empty) return;
    e.preventDefault();
    setState('loading');

    await AvatarView.decalMaker.loadPreparedDecals();

    const dataURL = AvatarView.decalMaker.piece;
    if (!dataURL) {
      setState('idle');
      return;
    }

    // send it
    const uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
    const strMime = 'image/jpeg';
    const upload = upload_to_firebase(dataURItoBlob(dataURL), strMime, `images/${uniqueId}.jpg`);
    const url = await upload;
    AvatarView.decalMaker.pieceRemoteURL = url;
    // console.log('url: ', url);

    // mask
    const mask = AvatarView.decalMaker.maskRemoteURL;

    // pass it as parameter
    const unsub = await avatarClient.generateTexture(
      {
        user_id: auth.data?.id || '',
        prompt,
        mask,
        cloth_id: 'polo_jeans',
        image: url,
      },
      (data) => {
        if (cancelRef.current) {
          return;
        }
        // console.log('Data: ', data);
        if (data.status === 'DONE') {
          setState('done');
          AvatarView.decalMaker.applyInpaintedPiece(data.image_url).then(() => {
            unsub();
          });
        } else {
          // console.log('Status: ', data.status);
        }
      }
    );

    unsubRef.current = unsub;
  };

  return availabe ? (
    <div className="t-generator">
      <form className="" onSubmit={handleSubmit}>
        <CustomTextArea
          submit={handleSubmit}
          isMobile={isMobile}
          label=""
          className={cn([
            't-generator__input',
            {
              loading: state === 'loading',
              inactive: empty,
            },
          ])}
          loading={state === 'loading'}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder={`Describe your print...`}
        >
          {setActive ? (
            <button className="t-generator__clear-btn" onClick={() => setActive(false)}>
              <img src="/assets/close.svg" alt="logo" className="t-generator__x" />
            </button>
          ) : null}
        </CustomTextArea>
      </form>
      <motion.button
        className={cn(['t-generator__generate-btn', { loading: state === 'loading', inactive: empty }])}
        onClick={handleSubmit}
        whileHover={state === 'loading' || empty ? undefined : 'hover'}
        animate={state === 'loading' ? 'loading' : 'initial'}
      >
        <SparksIcon />
        <div>Generate</div>
      </motion.button>
    </div>
  ) : null;
};

const _TshirtGenerator = observer(TshirtGenerator);
export { _TshirtGenerator as TshirtGenerator };
