import { action, makeObservable, observable } from 'mobx';
import { Color, Group, Material, MeshPhysicalMaterial, Shader, SkinnedMesh, Vector2 } from 'three';
import { AvatarView, gltf_loader } from '../../../AvatarView';
import { resources } from '../../../resource_manager/resourceManager';
import { glasses_shader } from '../../Assets/shaders/glasses_transparent_material';
import { adjust_skeleton } from '../../animation_utils';
import { DownloadState, fixMime } from '../common';
import { Asset } from './asset';

export const glasses_properties = {
  color: new Color(1.0, 0.0, 1.0),
  transparent_or_not: new Vector2(1, 0),
};

export class Glasses extends Asset {
  static transparent_material: MeshPhysicalMaterial;

  static change_color(color: string, is_transparent: boolean) {
    if (!Glasses.transparent_material) {
      return;
    }

    glasses_properties.color.copy(new Color(color).convertSRGBToLinear());
    glasses_properties.transparent_or_not.x = Number(is_transparent);

    const m = Glasses.transparent_material;
    if (is_transparent) {
      m.opacity = 0.1;
      m.ior = 1.09;
      m.depthTest = true;
      m.depthWrite = false;
      m.specularIntensity = 4.0;
      m.color = new Color(1.0, 1.0, 1.0);
    } else {
      m.ior = 1.27;
      m.opacity = 1.0;
      m.specularIntensity = 4.0;
      m.depthTest = true;
      m.depthWrite = true;
      m.color.copy(glasses_properties.color);
    }
    m.needsUpdate = true;
  }

  constructor() {
    super();
    makeObservable(this, {
      mesh_download_state: observable,
      setDownloadState: action.bound,
    });
  }
  glasses_annotation: string;

  async load_mesh() {
    this.setDownloadState(DownloadState.Loading);

    const gltf_url = this.glb_url || this.asset_dir + '/' + this.filename;
    const gltf = await gltf_loader.loadAsync(gltf_url);

    const meshes: any[] = [];

    gltf.scene.traverse((node: any) => {
      if (!node.isMesh) {
        return;
      }
      const mesh = node as SkinnedMesh;
      const material = mesh.material as MeshPhysicalMaterial;

      /*
           -- -- Material -- --
      */

      material.transmission = 0.0;
      material.envMapIntensity = AvatarView.cloth_envMapIntensity;
      fixMime(material);

      // Override lens material
      const lens_mat_name = this.material_settings.lens_material_name;
      if (lens_mat_name && material.name.startsWith(lens_mat_name)) {
        if (Glasses.transparent_material) {
          material.dispose();
          mesh.material = Glasses.transparent_material;
          Glasses.transparent_material.envMapIntensity = 0.5;
        } else {
          Glasses.transparent_material = material;
          material.userData['type'] = '__lensMaterial__';
          material.envMapIntensity = 0.5;
          material.defines['IOR'] = '';

          material.metalness = 0;
          material.transparent = true;
          material.specularColor = new Color(1, 1, 1);
          material.color = new Color(0 / 255, 0 / 255, 0 / 255);

          Glasses.transparent_material.userData['name'] = 'no_dispose';

          Glasses.change_color('#000000', true);

          material.onBeforeCompile = (shader: Shader) => {
            glasses_shader(shader, glasses_properties);
          };

          // DEBUG
          if (IS_DEBUG && AvatarView.gui) {
            this.addDebugInfo(material);
          }
        }
      }

      if (material.userData.glasses_annotation) {
        this.glasses_annotation = material.userData.glasses_annotation;
      }

      /*
           -- -- Mesh -- --
      */

      mesh.frustumCulled = false;
      // mesh.castShadow = true;
      // mesh.receiveShadow = false;

      adjust_skeleton(mesh);
      mesh.updateMatrixWorld();

      // Save for retargeting
      mesh.userData['glasses_verts'] = new Float32Array(mesh.geometry.attributes['position'].array);

      meshes.push(mesh);
    });

    // Array -> Group
    const group = new Group();
    meshes.forEach((v, i) => {
      v.name = `avaturn_glasses_${i}`;
      (v.material as Material).name = `avaturn_glasses_${i}_material`;
      group.add(v);
    });

    this.group = group;
    this.group.visible = false;

    this.setDownloadState(DownloadState.Loaded);

    gltf.scene.updateMatrixWorld();
    AvatarView.scene_objects_group.add(this.group);
  }

  private addDebugInfo(material: MeshPhysicalMaterial) {
    const SceneStatsGUI = AvatarView.gui!.getFolder('glasses')
      .addFolder(this.id + '_' + material.name)
      .close();

    SceneStatsGUI.add(material, 'aoMapIntensity', 0, 1, 0.01).listen();
    SceneStatsGUI.add(material.normalScale, 'x', 0, 1, 0.01).listen();
    SceneStatsGUI.add(material.normalScale, 'y', -1, 1, 0.01).listen();
    SceneStatsGUI.add(material, 'roughness', 0, 1, 0.01).listen();
    SceneStatsGUI.add(material, 'metalness', 0, 1, 0.01).listen();
    SceneStatsGUI.add(material, 'opacity', 0, 1, 0.01).listen();
    SceneStatsGUI.add(material, 'transmission', 0, 1, 0.01).listen();
    SceneStatsGUI.add(material, 'ior', 0, 2, 0.01).listen();
    SceneStatsGUI.add(material, 'reflectivity', 0, 1, 0.01)
      .listen()
      .onChange(() => {
        material.needsUpdate = true;
      });

    SceneStatsGUI.add(material, 'specularIntensity', 0, 4, 0.01);

    SceneStatsGUI.addColor(material, 'color');
    SceneStatsGUI.addColor(glasses_properties, 'color');
    SceneStatsGUI.add(glasses_properties.transparent_or_not, 'x', 0, 1, 0.01);
  }

  override async load_all() {
    await this.load_mesh();
  }

  override get_customization() {
    return {
      color: glasses_properties.color.toArray(),
      alpha: Number(glasses_properties.transparent_or_not.x < 0.5),
    };
  }

  override set_customization(customization_dict: any) {
    if (customization_dict.color) {
      glasses_properties.color.fromArray(customization_dict.color);
    }
    if (customization_dict.alpha) {
      glasses_properties.transparent_or_not.x = 1 - customization_dict.alpha;
    }
  }

  override retarget(avatar: any) {
    this.group.traverse((node: any) => {
      if (!node.isMesh) {
        return;
      }

      const avatar_verts = avatar.verts;

      const cloth_verts_buffer = node.geometry.attributes.position;
      const cloth_verts = cloth_verts_buffer.array;

      retarget_glasses(
        avatar_verts,
        cloth_verts,
        node.userData['glasses_verts'],
        this.glasses_annotation,
        resources.corresponding_ids
      );

      if (this.material_settings.recompute_normals) {
        node.geometry.computeVertexNormals();
      }
      node.geometry.computeBoundingBox();
      cloth_verts_buffer.needsUpdate = true;
    });
  }
}

function retarget_glasses(
  avatar_verts: any,
  glasses_verts: any,
  glasses_verts_initial: any,
  glasses_annotation: any,
  corresp_ids: any
) {
  const SMPLX_HEAD_WIDTH = glasses_annotation.head_width;
  const SMPLX_RIGHT_EAR_ID = corresp_ids[844];
  const SMPLX_LEFT_EAR_ID = corresp_ids[165];

  const dx = avatar_verts[SMPLX_RIGHT_EAR_ID * 3 + 0] - avatar_verts[SMPLX_LEFT_EAR_ID * 3 + 0];
  const dy = avatar_verts[SMPLX_RIGHT_EAR_ID * 3 + 1] - avatar_verts[SMPLX_LEFT_EAR_ID * 3 + 1];
  const dz = avatar_verts[SMPLX_RIGHT_EAR_ID * 3 + 2] - avatar_verts[SMPLX_LEFT_EAR_ID * 3 + 2];
  const head_width = Math.hypot(dx, dy, dz);
  const scale = head_width / SMPLX_HEAD_WIDTH;

  //

  const nose_support_point = glasses_annotation.nose_support_point;
  const nose_idx = glasses_annotation.nose_idx;
  // let nose_idx = corresp_ids[glasses_annotation.nose_ids[0]];

  // Scale
  const coordinates_scale = glasses_annotation.coordinates_scale;
  const scale_x = scale * coordinates_scale[0];
  const scale_y = scale * coordinates_scale[1];
  const scale_z = scale * coordinates_scale[2];

  // Retarget
  for (let i = 0; i < glasses_verts.length / 3; i++) {
    glasses_verts[i * 3 + 0] = glasses_verts_initial[i * 3 + 0] * scale_x;
    glasses_verts[i * 3 + 1] = glasses_verts_initial[i * 3 + 1] * scale_y;
    glasses_verts[i * 3 + 2] = glasses_verts_initial[i * 3 + 2] * scale_z;
  }

  const shift_x = avatar_verts[nose_idx * 3 + 0] - nose_support_point[0] * scale;
  const shift_y = avatar_verts[nose_idx * 3 + 1] - nose_support_point[1] * scale;
  const shift_z = avatar_verts[nose_idx * 3 + 2] - nose_support_point[2] * scale;

  for (let i = 0; i < glasses_verts.length / 3; i++) {
    glasses_verts[i * 3 + 0] += shift_x;
    glasses_verts[i * 3 + 1] += shift_y;
    glasses_verts[i * 3 + 2] += shift_z;
  }
}
