import { ExportStatus, ExportType, GaEvent, ScanData, ScanStatus, avatarClient, ga } from '@in3d/api';
import {
  AUTH_TOKEN_SEARCH_PARAM,
  BaseRoutes,
  getDeveloperName,
  getHostname,
  getRecomputeStartTime,
  localData,
  notifyError,
  notifySuccess,
  removeRecomputeStartTime,
  saveRecomputeStartTime,
} from '@in3d/common';
import { ExportGlbDiscord, TshirtGenerator } from '@in3d/components';
import { changeFaceAnimation, exportAvatar, init_editor, resources, stopFaceAnimation } from '@in3d/core';
import env from '@in3d/environment';
import { auth, avatars, developerConfig } from '@in3d/store';
import { HubTimer } from '@in3d/ui';
import axios from 'axios';
import { reaction, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AvatarDownload } from './avatar-download';
import { DeveloperActions } from './developers-overlay';
import { Editor } from './editor';
import { ExportButtons } from './export-buttons';
import { FaceTracking } from './face-tracking';
import { Scene } from './scene';
import { SceneLoader } from './scene-loader/scene-loader';
import { SceneLogo } from './scene-logo';
import { TshirtGeneratorButton } from './tshirt-generator/tshirt-generator-button';
import './customizer.scss';
import { SaveButton } from './customizer/save-button-dev';
import { isLoadedAsApi } from '@in3d/hooks';

const Customizer = () => {
  const { needSaveButton, needHomeButton } = useCustomizerControls();
  const [loading, setLoading] = useState(true);
  const [withEditor, setWithEditor] = useState(false);
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const [isDiscordModal, setIsDiscordModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateStart, setUpdateStart] = useState('');
  const [currentScan, setCurrentScan] = useState<ScanData | null>(null);

  const [faceTrackingState, setFaceTrackingState] = useState<'video' | 'webcam' | 'none'>('none');
  const [uploadedVideoRef, setUploadedVideoRef] = useState<string>('');
  const [isFirstTracking, setIsFirstTracking] = useState(true);
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();
  const withUi = !urlParams.has('noui');

  useEffect(() => {
    when(
      () => auth.isAuth,
      () => {
        avatarClient.getAvatarInfo(auth.token, urlParams.get('customization_id') || '').then((info) => {
          if (!info.scan || !info.scan.id) return;
          const scan = info.scan;
          setCurrentScan(scan);
          if (
            scan.status !== ScanStatus.READY ||
            avatars.checkScanOutdate(scan, scan.scan_metadata.animatable ?? false)
          ) {
            initAvatarUpdate(scan);
          } else {
            removeRecomputeStartTime(scan.id);
          }
        });
      }
    );
  }, []);

  useEffect(() => {
    ga.event(GaEvent.OpenEditor);
    when(
      () => auth.loaded,
      () => {
        if (!auth.isAuth) {
          const avatar_link = urlParams.get('avatar_link');
          const headless = urlParams.get('headless');
          // FIXME remove preview check
          if (avatar_link || headless || getHostname().includes('preview')) {
            window.hideLoader();
          } else navigate(BaseRoutes.Login);
        }
        setWithEditor(true);
      }
    );
  }, []);

  const initAvatarUpdate = (scan: ScanData) => {
    setIsUpdating(true);
    const reload_time = getRecomputeStartTime(scan.id);
    if (!reload_time) {
      saveRecomputeStartTime(scan.id);
    }
    // it still can be null if localstorage is off
    setUpdateStart(getRecomputeStartTime(scan.id) || new Date().toUTCString());
    when(
      () => resources.isSceneLoaded,
      () => {
        resources.setSceneLoadStatus(false);
      }
    );

    if (scan.status !== ScanStatus.READY) {
      return;
    }

    avatars.restartScan(scan.id).catch(() => {
      notifyError('Avatar is outdated. But there were problems when trying to update. Please refresh the page', {
        autoClose: false,
      });
    });
  };

  const initSceneCb = useCallback(async (ref: HTMLDivElement) => {
    avatars.setAvatarExportBtnActivity(false);

    await resources.load();
    await init_editor(ref, { bgColor: sceneBg || null });
    avatars.setAvatarExportBtnActivity(true);
    return setLoading(false);
  }, []);

  const handleDownloadButton = async () => {
    const isAvailable = await checkIfDownloadPermitted();
    if (isAvailable) {
      setIsDownloadModal(true);
    } else {
      setIsDiscordModal(true);
    }
  };

  const handleSaveBtnClick = async () => {
    // Export in iframe
    if (!avatars.isExportBtnEnabled) {
      return;
    }
    avatars.setAvatarExportBtnActivity(false);
    setTimeout(() => {
      exportAvatar().then((data) => {
        if (
          data.type === ExportType.DataURL ||
          (data.type === ExportType.HttpURL && data.data.status === ExportStatus.Ready)
        ) {
          avatars.setAvatarExportBtnActivity(true);
        }
      });
    }, 200);
  };

  const handleHomeBtnClick = () => {
    const token = urlParams.get(AUTH_TOKEN_SEARCH_PARAM);
    const session_id = urlParams.get('session_id');
    window.location.href = `${BaseRoutes.Hub}${token ? `?${AUTH_TOKEN_SEARCH_PARAM}=${token}` : ''}${session_id ? '&session_id=' + session_id : ''
      }`;
  };

  const handleVideoSelect = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setUploadedVideoRef(URL.createObjectURL(file));

    maybeNotify();
    setFaceTrackingState('video');
  };

  const maybeNotify = () => {
    stopFaceAnimation();

    if (isFirstTracking) {
      notifySuccess('First run might take a while to initialize, be patient.', { autoClose: 5000 });
      setIsFirstTracking(false);
    }
  };
  const handleFBXSelect = (event: any) => {
    const file = event.target.files[0];

    // Read the file as a data URL
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageDataUrl = reader.result as string;
      changeFaceAnimation(imageDataUrl);
    };
  };

  const [isAnimatable, setAnimatable] = useState(false);
  useEffect(() => {
    const dispose = reaction(
      () => resources.isAnimatable,
      (isAnimatable) => setAnimatable(isAnimatable)
    );

    return () => dispose();
  }, []);

  const isRootHost = env.rootHosts.includes(getHostname());

  const { sceneBg } = developerConfig.editor;

  const [generatorActive, setGeneratorActive] = useState(true);

  return (
    <div className="customizer">
      {!isUpdating && loading && <SceneLoader />}
      <div className="customizer__content">
        <Scene init={initSceneCb} config={{ sceneBg: sceneBg || null }} fullScreen={!withUi}>
          {withUi && (
            <>
              {isUpdating && currentScan && (
                <div className="scene__updating">
                  <HubTimer
                    eta={90}
                    text="Upgrading your avatar for new features"
                    scan={{ ...currentScan, queued_at: updateStart }}
                  />
                </div>
              )}
              <div className="customizer__buttons">
                {isRootHost && <TshirtGeneratorButton active={generatorActive} setActive={setGeneratorActive} />}
                {isAnimatable && isRootHost && (
                  <FaceTracking
                    onClick={maybeNotify}
                    faceTrackingState={faceTrackingState}
                    setFaceTrackingState={setFaceTrackingState}
                  />
                )}
              </div>
              {isRootHost && generatorActive && <TshirtGenerator setActive={setGeneratorActive} />}
              {/* {isAnimatable && (
                <div>
                  {faceTrackingState === 'video' && (
                    <FileVideoStream fn={trackFace} src={uploadedVideoRef} disposeFn={disposeVideo} />
                  )}
                  <label className="scene__download">
                    Face tracking from video
                    <input type="file" id="change_animation" accept="video/*" onChange={handleVideoSelect} />
                  </label>
                </div>
              )} */}
              {/* {isAnimatable && (
                <label className="scene__download">
                  Upload FBX
                  <input type="file" id="change_animation" accept="*.fbx" onChange={handleFBXSelect} />
                </label>
              )} */}
              <div className="scene__overlay">
                <div className="scene__holders">
                  <SceneLogo />
                </div>
                <div className="scene__actions">
                  {/* Save button for developers */}
                  {needSaveButton && <SaveButton avatars={avatars} developerConfig={developerConfig} />}
                  {/* Save button for hub */}
                  {isRootHost && <ExportButtons enabled={avatars.isExportBtnEnabled} onClick={handleDownloadButton} />}
                </div>
              </div>
              <div className="scene__top-panel">
                {withUi && needHomeButton && (
                  <button className="scene__back" onClick={handleHomeBtnClick}>
                    <img src="assets/home-arrow.svg" alt="" />
                  </button>
                )}
                <DeveloperActions />
              </div>
            </>
          )}
        </Scene>

        {withUi && withEditor && <Editor />}
      </div>
      {isDownloadModal && <AvatarDownload onClose={() => setIsDownloadModal(false)} />}
      {isDiscordModal && <ExportGlbDiscord onClose={() => setIsDiscordModal(false)} />}
    </div>
  );
};

interface CustomizerControlsOptions {
  isEmbed: boolean;
  isIframe: boolean;
  needSaveButton: boolean;
  needHomeButton: boolean;
}

const useCustomizerControls = (): CustomizerControlsOptions => ({
  isEmbed: false,
  isIframe: window !== window.parent,
  needSaveButton: !!developerConfig.editor.withSaveBtn,
  needHomeButton: !!developerConfig.editor.withHomeBtn,
});

async function checkIfDownloadPermitted() {
  const exportCode = localData.getItem('export_code');
  if (!exportCode) return Promise.resolve(false);

  return axios.get('https://discord_worker.avaturn.workers.dev/time').then((response) => {
    const current_time = response.data;

    let is_ok = true;
    let err = '';

    try {
      const decoded = Number(atob(decodeURIComponent(exportCode).split('').reverse().join(''))) / 3 + 3485;

      const diff_min = (Number(current_time) - decoded) / 1000 / 60;

      if (diff_min > 30) {
        is_ok = false;
        err = 'The code has expired';
        notifyError(err);
        localData.removeItem('export_code');
      }
    } catch {
      is_ok = false;
      err = 'The code is incorrect';
      notifyError(err);
      localData.removeItem('export_code');
    }
    return is_ok;
  });
}

const _Customizer = observer(Customizer);
export { _Customizer as Customizer };
