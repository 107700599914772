import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import './custom-options.scss';

interface OptionProps extends React.HTMLAttributes<HTMLElement> {
  className: string;
}
interface RenderOptionsConfig {
  getOptionProps: () => OptionProps;
  setValue: (value: any) => void;
}

interface CustomOptionsProps {
  value: any;
  label?: string;
  onChange?: (value: any) => void;
  renderOptions?: (config: RenderOptionsConfig) => JSX.Element;
}
export const CustomOptions: React.FC<CustomOptionsProps> = ({ label, renderOptions, value, onChange }) => {
  const [inputElement, setInputElement] = useState<HTMLDivElement | null>(null);
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);
  const [currentValue, setCurrentValue] = useState<typeof value>(value);
  const [isOpened, setIsOpened] = useState(false);
  const { styles, attributes } = usePopper(inputElement, containerElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
    ],
  });

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      console.log('Includes: ', inputElement && inputElement.contains(e.target as Node));
      if (isOpened && inputElement && !inputElement.contains(e.target as Node)) {
        setIsOpened(false);
      }
    };
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Space') setIsOpened((prev) => !prev);
    };
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [inputElement]);

  useEffect(() => {
    if (onChange) onChange(currentValue);
    console.log('New value: ', currentValue);
  }, [currentValue]);

  const getOptionProps = (): OptionProps => ({
    className: 'custom-options__item',
  });

  return (
    <div className="custom-options" ref={setInputElement}>
      <input
        readOnly
        onFocus={() => setIsOpened(true)}
        onBlur={() => setIsOpened(false)}
        className="custom-options__label"
        value={value || label}
      />
      {isOpened &&
        createPortal(
          <div
            className="custom-options__list"
            ref={setContainerElement}
            style={{ ...styles['popper'], minWidth: 110 }}
            {...attributes['popper']}
          >
            {renderOptions && renderOptions({ getOptionProps, setValue: (value) => setCurrentValue(value) })}
          </div>,
          document.getElementById('portal') as Element
        )}
    </div>
  );
};
