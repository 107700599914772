import cn from 'classnames';
import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';

// Define the type for camera id. This can be any type according to your data.
type CameraInfo = {
  id: string;
  label: string;
};

interface DropdownProps {
  onCameraSelect: (cameraId: string) => void;
  currentCamera?: string;
  isMobile?: boolean;
  handleSwapSource: () => void;
}

const getVideoDevices = async () => {
  return navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
    /*
    alert(
      JSON.stringify(
        mediaDevices.reduce((pv, cv) => [...pv, `${cv.label}: ${cv.kind}`], [] as string[]),
        null,
        2
      )
    );
    */

    return mediaDevices
      .filter((e) => e.kind === 'videoinput')
      .reduce((pv, cv, idx) => [...pv, { id: cv.deviceId, label: cv.label || `Camera ${idx}` }], [] as CameraInfo[]);
  });
};

const CameraDropdown: React.FC<DropdownProps> = ({ onCameraSelect, currentCamera, isMobile, handleSwapSource }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cameraIds, setCameraIds] = useState<CameraInfo[]>([]);

  useEffect(() => {
    getVideoDevices().then(setCameraIds);
  }, [isOpen]);

  const handleSelect = (cameraInfo: CameraInfo) => {
    onCameraSelect(cameraInfo.id);
    setIsOpen(false);
  };

  const handleClick = () => {
    if (!isMobile) setIsOpen(!isOpen);
    else handleSwapSource();
  };

  return cameraIds.length > 1 ? (
    <>
      <button className="camera-list__btn" onClick={handleClick}>
        <SVG src="/assets/scan/swap.svg" />
      </button>
      {isOpen && (
        <ul className="camera-list__list">
          {cameraIds.map((cameraInfo) => (
            <li
              className={cn([
                'camera-list__item',
                {
                  'camera-list__item_selected': cameraInfo.id === currentCamera,
                },
              ])}
              key={cameraInfo.id}
              onClick={() => handleSelect(cameraInfo)}
            >
              {cameraInfo.label}
            </li>
          ))}
        </ul>
      )}
    </>
  ) : null;
};

export default CameraDropdown;
