import GUI from 'lil-gui';
import { action, makeObservable, observable } from 'mobx';
import {
  BackSide,
  Color,
  DoubleSide,
  FrontSide,
  Group,
  Material,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Shader,
  SkinnedMesh,
  Uniform,
  Vector2,
} from 'three';
import { AvatarView, gltf_loader } from '../../../AvatarView';
import { BrightnessContrastParams } from '../../Assets/shaders/brightness_contrast';
import { ColorRampParams, color_ramp } from '../../Assets/shaders/color_ramp';
import { FresnelParams, fresnel } from '../../Assets/shaders/fresnel';
import { adjust_skeleton } from '../../animation_utils';
import { concat_offsets_ids } from '../../utils';
import { DownloadState, clamp, fixMime } from '../common';
import { changeHaircap } from '../haircap';
import { RetargetableAsset } from './asset';

export const color_ramp_params: ColorRampParams = {
  // colors stored in linear space
  ramp_color1: new Uniform(new Color(0.005, 0.003, 0.003)),
  ramp_color2: new Uniform(new Color(0.153, 0.06, 0.042)),
  ramp_color3: new Uniform(new Color(0.337, 0.159, 0.09)),

  // new
  ramp_val1: new Uniform(0.0),
  ramp_val2: new Uniform(0.414),
  ramp_val3: new Uniform(0.9),
};

const fresnel_params: FresnelParams = {
  // hair
  fresnel_dir: new Uniform(new Color(0.5, 0, 0.5)),
  // fresnel_ior: 1.1,
  // fresnel_strength: 0.10,
  // fresnel_ior: 1.43,
  // fresnel_strength: 0.06,
  fresnel_ior: new Uniform(1.17),
  fresnel_strength: new Uniform(0.04),
  fresnel_use_custom_direction: new Uniform(true),
};

// console.log(`default`);
// console.log(new Color().copy(color_ramp_params.ramp_color2.value).convertLinearToSRGB().getHexString())

// for (const [key, value] of Object.entries(variations)) {
//   console.log(`${key}`);
//   console.log(new Color().copy(value.ramp_color2).convertLinearToSRGB().getHexString())
//   // console.log((value.ramp_color2 as Color).getHex())
// }

const bc_pararms: BrightnessContrastParams = {
  brightness: { value: 0.0 },
  contrast: { value: 0.0 },
};

// let params = {
//   c: '',
//   l3: 1.3,
//   s3: 1.1,
//   h3: 0.8,
//   l1: 0.15, // 0.1
//   s1: 1.5   // 3
// }

const params = {
  c: '',
  l3: 1.95,
  s3: 0.58,
  h3: 0.95,
  l1: 0.03, // 0.1
  s1: 1.32, // 3
};

let debug_inited = false;

export class Hair extends RetargetableAsset {
  shader: Shader;

  constructor() {
    super();
    makeObservable(this, {
      mesh_download_state: observable,
      setDownloadState: action.bound,
    });

    if (IS_DEBUG && AvatarView.gui && !debug_inited) {
      const hair_params = AvatarView.gui.addFolder('Hair params').close();
      hair_params.addColor(color_ramp_params.ramp_color1, 'value').name('ramp_color1').listen();
      hair_params.addColor(color_ramp_params.ramp_color2, 'value').name('ramp_color2').listen();
      hair_params.addColor(color_ramp_params.ramp_color3, 'value').name('ramp_color3').listen();

      hair_params.add(color_ramp_params.ramp_val1, 'value', 0, 1, 0.01).name('ramp_val1').listen();
      hair_params.add(color_ramp_params.ramp_val2, 'value', 0, 1, 0.01).name('ramp_val2').listen();
      hair_params.add(color_ramp_params.ramp_val3, 'value', 0, 1, 0.01).name('ramp_val3').listen();

      Object.entries(params).forEach(([k, v]) => {
        hair_params
          .add(params, k, 0, 3, 0.01)
          .listen()
          .onChange(() => {
            Hair.set_color(params.c);
          });
      });

      hair_params.add(fresnel_params.fresnel_strength, 'value', 0, 1, 0.01).name('fresnel_strength');
      hair_params.add(fresnel_params.fresnel_ior, 'value', 0, 2, 0.01).name('fresnel_ior');

      debug_inited = true;
    }
  }
  override get_customization() {
    return {
      color: {
        r_color1: color_ramp_params.ramp_color1.value.toArray(),
        r_color2: color_ramp_params.ramp_color2.value.toArray(),
        r_color3: color_ramp_params.ramp_color3.value.toArray(),
      },
    };
  }

  override set_customization(customization_dict: any) {
    if (customization_dict.color) {
      color_ramp_params.ramp_color1.value.fromArray(customization_dict.color['r_color1']);
      color_ramp_params.ramp_color2.value.fromArray(customization_dict.color['r_color2']);
      color_ramp_params.ramp_color3.value.fromArray(customization_dict.color['r_color3']);
    }
  }

  static set_color(color_str: string) {
    params.c = color_str;

    /* color_str is sRGB color*/
    const _hslA = { h: 0, s: 0, l: 0 };
    const _hslB = { h: 0, s: 0, l: 0 };

    const color = new Color(color_str).convertSRGBToLinear();
    color.getHSL(_hslA);

    _hslB.h = (_hslA.h + 0.49 + 0.5) % 1.0;
    _hslB.s = clamp(_hslA.s * params.s1, 0, 1);
    _hslB.l = _hslA.l * params.l1;
    color_ramp_params['ramp_color1'].value.setHSL(_hslB.h, _hslB.s, _hslB.l);

    color_ramp_params['ramp_color2'].value.setHSL(_hslA.h, _hslA.s, _hslA.l);

    _hslB.h = (_hslA.h + 0.51 + 0.5) % 1.0;
    _hslB.s = _hslA.s * params.s3;
    _hslB.l = clamp(_hslA.l * params.l3, 0, 0.6);

    color_ramp_params['ramp_color3'].value.setHSL(_hslB.h, _hslB.s, _hslB.l);
  }

  async load_mesh() {
    this.setDownloadState(DownloadState.Loading);

    const meshes: SkinnedMesh[] = [];

    const gltf = await gltf_loader.loadAsync(this.model_full_path);

    gltf.scene.traverse((node: any) => {
      if (!node.isMesh) {
        return;
      }

      const mesh = node as SkinnedMesh;
      let material = mesh.material as MeshStandardMaterial;

      let gui: GUI | null = null;
      if (IS_DEBUG && AvatarView.gui) {
        gui = AvatarView.gui.getFolder('hair').addFolder(`${this.id}_${material.name}`).close();
      }
      meshes.push(mesh);

      if (this.settings.mesh_name.length > 2 && mesh.name != this.settings.mesh_name) {
        return;
      }

      if (material.type != 'MeshStandardMaterial') {
        const cur_mat = material;
        mesh.material = new MeshStandardMaterial();
        mesh.material.copy(cur_mat);
        material = mesh.material as MeshStandardMaterial;
        cur_mat.dispose();
      }

      mesh.frustumCulled = false;
      mesh.castShadow = material.transparent ? false : this.material_settings.cast_shadow;
      // material.side = material.transparent ? FrontSide : DoubleSide;
      // material.shadowSide = DoubleSide;
      mesh.receiveShadow = this.material_settings.receive_shadow;
      mesh.castShadow = true;

      material.envMapIntensity = AvatarView.avatar_envMapIntensity;

      // Skeleton
      adjust_skeleton(mesh);
      mesh.updateMatrixWorld();

      // load retargeting info
      if (this.settings.embeded_retarget_info) {
        this.loadRetargetingInfoFromMesh(mesh);
      }

      // material.normalMap = null;
      // if (!mesh.geometry.hasAttribute("normal")) {
      //   alert(false);
      // }
      if (this.material_preset == 'hat' && !this.avatar_mask) {
        this.load_mask_and_offset_map(material.aoMap!.image);
      }

      material.needsUpdate = true;
      material.flatShading = false;

      material.roughness = Math.min(material.roughness + (1 - material.roughness) / 2, 0.75);
      material.roughness = 0.9;

      // material.roughness = Math.min(material.roughness + (1 - material.roughness) / 2, 0.75);
      // material.metalnessMap = null;
      // material.metalness = 0;

      if (IS_DEBUG && gui) {
        gui.add(material, 'side', { FrontSide, BackSide, DoubleSide }).onChange((v: any) => {
          material.needsUpdate = true;
        });
        gui.add(material, 'shadowSide', { FrontSide, BackSide, DoubleSide }).onChange((v: any) => {
          material.needsUpdate = true;
        });

        gui.add(material, 'metalness', 0, 1, 0.01).listen();
        gui.add(material, 'opacity', 0, 2, 0.01).listen();
        gui.add(material, 'roughness', 0, 2, 0.01).listen();
        gui.add(material, 'aoMapIntensity', 0, 2, 0.01).listen();
        gui.add(material.normalScale, 'x', -3, 3, 0.01).listen();
        gui.add(material.normalScale, 'y', -3, 3, 0.01).listen();
        if ((material as MeshPhysicalMaterial).specularIntensity) {
          gui.add(material, 'specularIntensity', -3, 3, 0.01).listen();
        }
        gui
          .add(material, 'alphaToCoverage')
          .listen()
          .onChange(() => {
            material.needsUpdate = true;
          })
          .name(material.name + ' alphaToCoverage');

        gui
          .add(this.settings, 'retargeting_multiplier', 0, 3, 0.01)
          .listen()
          .onChange(() => {
            this.retarget(AvatarView.currentState.avatar);
          });
        // AvatarView.gui.add(material, 'alphaToCoverage').listen().onChange(() => {material.needsUpdate=true});
      }
      // material.normalMap = null;
      mesh.geometry.deleteAttribute('tangent');
      // material.map!.generateMipmaps = false;
      // material.normalMap!.generateMipmaps = false;
      // material.roughnessMap!.generateMipmaps = false;

      // mesh.material_for_depth_testing = new MeshBasicMaterial(
      //   { map: material.map, alphaTest: 0.5 }
      // )

      fixMime(material);

      if (this.material_preset == 'hair_african') {
        material.roughness = 0.85;
        material.metalness = 0.15;
        // this.material_settings.recompute_normals = true;
      }

      if (this.material_settings.compute_tangent) {
        material.normalScale = new Vector2(1, 1);
      }
      // material.side = DoubleSide;
      const is_hair = !material.name.includes('no_ramp');

      material.customProgramCacheKey = () => {
        return (
          this.material_settings.use_fresnel.toString() + this.material_settings.use_color_ramp.toString() + is_hair
        );
      };

      // FIXME: HARDCODE
      this.material_settings.recompute_normals = true;

      material.onBeforeCompile = (shader: any) => {
        // 1. The vertex colors contain other info, so not using it
        // patch_shader_for_tonemapping(shader);
        // ShaderChunk.normal_fragment_begin = ShaderChunk.normal_fragment_begin.replace(
        //   'float faceDirection = gl_FrontFacing ? 1.0 : - 1.0;',
        //   'float faceDirection = ( dot(vViewPosition, vNormal)) > 0.0 ? 1.0 : -1.0;'
        //   // 'float faceDirection = 1.0;'
        // )
        // console.log(ShaderChunk.normal_fragment_begin);
        // shader.fragmentShader = shader.fragmentShader.replace(
        //   '#include <normal_fragment_begin>',
        //   // '\n vec3 normal = normalize( vNormal );\n vec3 geometryNormal = normal;\n'
        //   '#include <normal_fragment_begin>\n normal = normal*0.0; geometryNormal = normal;faceDirection=-1.0;  '
        // );
        if (is_hair && this.material_settings.use_fresnel) {
          const c = fresnel(shader, fresnel_params);

          // shader.uniforms[`fresnel_ior_${counter}`]
          // const fresnel_params = {
          //   // hair
          //   // fresnel_dir: new Color(0.5, 0, 0.5),
          //   // fresnel_ior: 1.05,
          //   // fresnel_strength: 0.10,
          //   // fresnel_use_custom_direction: true

          //   // other
          //   fresnel_dir: new Color(1.0, 1.0, 1.0),
          //   fresnel_ior: 1.10,
          //   fresnel_strength: 0.10 * 3,
          //   fresnel_use_custom_direction: false
          // };

          // fresnel(shader, fresnel_params, false);
        }

        if (is_hair && this.material_settings.use_color_ramp) {
          color_ramp(shader, color_ramp_params);
        }
        if (IS_DEBUG && gui) {
          gui.add(bc_pararms.brightness, 'value', -0.5, 0.5, 0.001).name('brightness');
          gui.add(bc_pararms.contrast, 'value', -0.5, 0.5, 0.001).name('contrast');
        }
        // brightness_contrast(shader, bc_pararms, 'post');
        // brightness_contrast(shader, bc_pararms, 'albedo');
      };
    });
    // Array -> Group
    const group = new Group();
    group.name = 'avaturn_hair';
    meshes.forEach((v, i) => {
      v.name = `avaturn_hair_${i}`;
      v.name = v.name.includes('no_ramp') ? `avaturn_hair_${i}_accessory` : `avaturn_hair_${i}`;
      const m = v.material as Material;
      m.userData['name'] = m.name;
      m.name = v.name + '_material';
      group.add(v);
    });

    this.group = group;

    if (this.material_settings.color && this.material_settings.color.length > 2) {
      // length 2 is when ''
      Hair.set_color(this.material_settings.color);
    }

    this.group.visible = false;
    AvatarView.scene_objects_group.add(this.group);

    this.setDownloadState(DownloadState.Loaded);
  }

  override async load_all() {
    if (this.settings_json.only_haircap) {
      this.group = new Group();
      await changeHaircap(this.settings_json.haircap);
      this.setDownloadState(DownloadState.Loaded);
      return;
    }
    await this.load_mesh();
    await changeHaircap(this.settings_json.haircap);
  }
}
