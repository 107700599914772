import { CategoryName, BodyType, animations, cn, getHostname } from '@in3d/common';
import { editorParams, resources } from '@in3d/core';
import env from '@in3d/environment';
import { useIsMobile } from '@in3d/hooks';
import { developerConfig } from '@in3d/store';
import { CustomSlider } from '@in3d/ui';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { EyesColors } from './editor/eyes-options';

type activeType = 'body' | 'options' | 'eyes' | 'head' | 'chest' | 'arms';

const BodyOptions = ({
  className,
  list,
  onChangeBody,
  loading,
}: {
  list: typeof resources.avatars;
  onChangeBody: (type: 'male' | 'female', id: string) => void;
  loading: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const [activeType, setActiveType] = useState<activeType>('body');

  const bodyTypes = [BodyType.Male];

  const isMobile = useIsMobile();

  const getBoneScaleSlider = (name: keyof typeof editorParams._boneScale, title: string) => {
    return (
      <CustomSlider
        classNames="body-options__field"
        title={title}
        onChange={(v) => {
          editorParams.setScale(name, v);
        }}
        max={85}
        min={15}
        isMobile={isMobile}
        value={editorParams._boneScale[name]}
        valueTitle={((editorParams._boneScale[name] - 50) / 100).toFixed(2)}
      />
    );
  };
  const need_eye_color = resources.isAnimatable;

  const bodyOptions = env.rootHosts.includes(getHostname());

  return (
    <motion.div
      {...animations.slide}
      className={cn(['editor-options__list body-properties', loading && 'body-properties--loading', className])}
    >
      <div className="body-properties__type">
        <div
          className={cn(['body-properties__btn', activeType === 'body' && 'active'])}
          onClick={() => setActiveType('body')}
        >
          Body
        </div>
        <div
          className={cn(['body-properties__btn', activeType === 'options' && 'active'])}
          onClick={() => setActiveType('options')}
        >
          Skin
        </div>
        {need_eye_color && (
          <div
            className={cn(['body-properties__btn', activeType === 'eyes' && 'active'])}
            onClick={() => setActiveType('eyes')}
          >
            Eyes
          </div>
        )}
        {bodyOptions ? (
          <>
            <div
              className={cn(['body-properties__btn', activeType === 'head' && 'active'])}
              onClick={() => setActiveType('head')}
            >
              Head
            </div>
            <div
              className={cn(['body-properties__btn', activeType === 'chest' && 'active'])}
              onClick={() => setActiveType('chest')}
            >
              Chest
            </div>
            <div
              className={cn(['body-properties__btn', activeType === 'arms' && 'active'])}
              onClick={() => setActiveType('arms')}
            >
              Arms
            </div>
          </>
        ) : null}
      </div>

      <div className={cn(['options-content', activeType === 'body' && 'active'])}>
        {bodyTypes.map((bodyType) => (
          <div className="body-list" key={bodyType}>
            <div className="body-list__title">
              <SVG src="/assets/editor/body_properties.svg" />
              Body Type
            </div>
            <div className="body-list__content">
              {list.map((avatar, i) => (
                <div
                  className={cn([
                    'body-list__item',
                    developerConfig.name,
                    resources.active[CategoryName.Body] === avatar.id && 'active',
                  ])}
                  key={i}
                  onClick={() => onChangeBody(bodyType, avatar.id)}
                >
                  {developerConfig.editor.customBodies ? (
                    <img
                      src={`/assets/editor/${developerConfig.name}/${avatar.gender}/body_${i + 1}.svg`}
                      alt={avatar.id}
                    />
                  ) : (
                    <img src={avatar.preview_url} alt="" />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className={cn(['body-options', activeType === 'options' && 'active'])}>
        <div className="body-options__title">
          <SVG src="/assets/editor/skin.svg" />
          Skin
        </div>
        <CustomSlider
          classNames="body-options__field"
          title="Tone correction"
          onChange={(v) => {
            editorParams.skinBrightness = v;
          }}
          max={85}
          min={15}
          value={editorParams.skinBrightness}
          valueTitle={((editorParams.skinBrightness - 50) / 100).toFixed(2)}
        />
      </div>

      {bodyOptions ? (
        isMobile ? (
          <>
            <div className={cn(['body-options', activeType === 'head' && 'active'])}>
              {getBoneScaleSlider('head', 'Head')}
            </div>
            <div className={cn(['body-options', activeType === 'chest' && 'active'])}>
              {getBoneScaleSlider('chest', 'Chest')}
            </div>
            <div className={cn(['body-options', activeType === 'arms' && 'active'])}>
              {getBoneScaleSlider('arms', 'Arms')}
            </div>
          </>
        ) : (
          <div className={cn(['body-options', activeType === 'options' && 'active'])}>
            <div className="body-options__head">
              <div className="body-options__title">
                <SVG src="/assets/editor/head_properties.svg" />
                Body proportions
              </div>
              <button
                className="body-options__reset"
                onClick={() => {
                  editorParams.setScale('head', 50);
                  editorParams.setScale('chest', 50);
                  editorParams.setScale('arms', 50);
                }}
              >
                <SVG src="assets/update.svg" />
              </button>
            </div>
            {getBoneScaleSlider('head', 'Head')}
            {getBoneScaleSlider('chest', 'Chest')}
            {getBoneScaleSlider('arms', 'Arms')}
          </div>
        )
      ) : null}

      {need_eye_color && <EyesColors activeType={activeType} />}
      {/*

        Example
        <CustomSlider name="skin_curve" onChange={changeAvatar} value={resources.getAvatarById(customization_id).curve_params.curve_coeff.value} />

        */}
    </motion.div>
  );
};

const _BodyOptions = observer(BodyOptions);
export { _BodyOptions as BodyOptions };
