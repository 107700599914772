import { Messaging, SdkMessage } from './base_sdk';
import { MessageType } from './message_types';

export enum ExportStatus {
  Processing = 'processing',
  Ready = 'ready',
  Failed = 'failed',
}

export interface NewExportInfo {
  id: string;
  status: ExportStatus;
  url: string;
}

export enum UrlType {
  DataURL = 'dataURL',
  HttpURL = 'httpURL',
}

export type AvatarInfo = { body: string; curve_coeff?: number };
export type AssetItem = { id: string; category: 'eyes' | 'head' | 'look' | 'shoes' | 'faceMask'; preview: string };
export type BodyItem = { id: string; preview: string; gender: 'male' | 'female' };
export type ExportAvatarResult = {
  url: string;
  urlType: UrlType.HttpURL | UrlType.DataURL;
  avatarId: string;
  sessionId: string;
  thumbnail?: string;
};

interface SdkEvent {
  load: AvaturnSDK;
  export: ExportAvatarResult;
  assetSet: AssetItem['id'];
  bodySet: string;
}

type SdkEventKey = keyof SdkEvent;

export interface SdkMessageResponse {
  [MessageType.SDKHandshake]: string;
  [MessageType.SDKHandshakeConfirmation]: null;
  // [MessageType.SetDefaults]: null;
  [MessageType.Ping]: null;
  [MessageType.GetAssetsList]: AssetItem[];
  [MessageType.GetActiveAssets]: Record<string, string>;
  [MessageType.SetActiveAsset]: string;
  [MessageType.ExportAvatar]: ExportAvatarResult;
  [MessageType.GenerateThumbnail]: string;
  [MessageType.GetAvatarInfo]: AvatarInfo;
  [MessageType.GetBodiesList]: BodyItem[];
  [MessageType.SetActiveBody]: null;
  [MessageType.SetAssetsList]: null;
}

export interface SdkIncomingMessages {
  [MessageType.AskForDefaults]: any;
  [MessageType.CallbackEvent]: null;
}

const DEFAULT_URL =
  'https://preview.avaturn.dev/editor?avatar_link=https%3A%2F%2Fassets.avaturn.me%2Feditor_resources%2Fdefault_avatar.glb'; //'https://hub.avaturn.me'
// const DEFAULT_URL = 'http://localhost:4200/editor?avatar_link=https%3A%2F%2Fassets.avaturn.me%2Feditor_resources%2Fdefault_avatar.glb'; //'https://hub.avaturn.me'

interface InitParams {
  url?: string;
  iframeClassName?: string;
  disableUi?: boolean;
  editorDefaults?: EditorDefaults;
  env?: { platform?: string; version?: string; [key: string]: any };
}
interface EditorDefaults {
  assets?: Record<string, any>;
  avatar?: Record<string, any>;
}

export class AvaturnSDK extends Messaging<SdkMessageResponse, SdkIncomingMessages> {
  private _version = '0.4.0';
  editorDefaults: EditorDefaults | undefined;
  constructor() {
    super();
  }

  ready = false;

  private sceneRef: HTMLIFrameElement | null = null;
  private callbacks: { [key in SdkEventKey]?: (data?: any) => void } = {};

  private suppressCallbacks?: boolean;

  private applyStyles(target: HTMLIFrameElement) {
    target.style.width = '100%';
    target.style.height = '100%';
  }

  private setupDOM(container: HTMLElement | Element, url: string, iframeClassName?: string) {
    const iframe = document.createElement('iframe');

    iframe.setAttribute('src', url);
    iframe.setAttribute('id', 'avaturn-sdk-iframe');
    iframe.setAttribute('allow', 'camera *; clipboard-write');
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('frameborder', '0');

    if (iframeClassName) {
      iframe.setAttribute('class', iframeClassName);
    } else {
      this.applyStyles(iframe);
    }

    container.appendChild(iframe);
    this.sceneRef = document.querySelector('#avaturn-sdk-iframe');
    this.recipient = this.sceneRef?.contentWindow;
  }

  override handleMessage(message: SdkMessage<any, keyof SdkIncomingMessages>) {
    // console.log('Message: ', message);
    switch (message.eventName) {
      case MessageType.AskForDefaults:
        this.sendResponse(MessageType.AskForDefaults, message.key, {
          isOk: true,
          data: JSON.stringify(this.editorDefaults),
        });
        break;

      case MessageType.CallbackEvent:
        this.callbacks[message.key as SdkEventKey]?.(JSON.parse(message.data));
        break;
      default:
        break;
    }
  }

  async init(
    container: HTMLElement | Element | null,
    { url, iframeClassName, disableUi, editorDefaults = {}, env = {} }: InitParams
  ) {
    this.suppressCallbacks = disableUi;

    const src = new URL(url || DEFAULT_URL);
    if (disableUi) src.searchParams.append('noui', 'true');

    if (container) {
      this.setupDOM(container, src.toString(), iframeClassName);
    } else {
      this.recipient = window;
    }

    this.setupMessaging();

    if (this.callbacks.load) this.callbacks.load();
    this.ready = true;

    this.editorDefaults = editorDefaults;

    const env_ = {
      url: document.location.href,
      environment: env,
      version: this._version,
    };

    // Send message to notify Avaturn that SDK is used
    return this.sendMessage(MessageType.SDKHandshake, env_, 'sdk_handshake')
      .then(async () => {
        await this.sendMessage(MessageType.SDKHandshakeConfirmation, env_, undefined, false);
        return this;
      })
      .catch((reason) => {
        throw new Error(reason);
      });
    // return Promise.resolve(this);
  }

  on<T extends SdkEventKey>(type: T, cb: (data: SdkEvent[T]) => void) {
    if (this.suppressCallbacks && type != 'load') {
      console.log(`Can't use callbacks for ${type} in disableUi mode. Use <action>.then(...) instead.`);
      return this;
    }
    this.callbacks[type] = cb;
    return this;
  }

  ping() {
    this.sendMessage(MessageType.Ping);
  }

  async getAssetList() {
    return this.sendMessage(MessageType.GetAssetsList);
  }
  async setAvailableAssetsList(cb: (list: AssetItem[]) => Array<AssetItem['id']>) {
    const list = await this.sendMessage(MessageType.GetAssetsList);
    return this.sendMessage(MessageType.SetAssetsList, cb(list));
  }

  async getActiveAssets() {
    return this.sendMessage(MessageType.GetActiveAssets);
  }
  async setActiveAsset(id: string) {
    return this.sendMessage(MessageType.SetActiveAsset, id);
  }

  async setActiveBody(id: string) {
    return this.sendMessage(MessageType.SetActiveBody, id);
  }
  async getBodyList() {
    return this.sendMessage(MessageType.GetBodiesList);
  }
  async getAvatarInfo(): Promise<AvatarInfo> {
    return this.sendMessage(MessageType.GetAvatarInfo);
  }

  async exportAvatar() {
    return this.sendMessage(MessageType.ExportAvatar, undefined, 'export_avatar');
  }
  async generateThumbnail() {
    return this.sendMessage(MessageType.GenerateThumbnail, undefined, 'export_avatar');
  }
}
