import env from '@in3d/environment';
import { SkinnedMesh, KeyframeTrack, NumberKeyframeTrack, InterpolateLinear, Group, Bone } from 'three';
import { AvatarView, gltf_loader } from '../../AvatarView';
import { filter_animation, make_animation_relative_to_rest_pose } from '../animation_utils';
import { Avatar } from './avatar';
import { AvatarAnimatable } from './avatar_animatable';

export const processMorphTargetTrack = (track: KeyframeTrack, ref_group: Group, obj_group: Group) => {
  const additional_tracks: NumberKeyframeTrack[] = [];

  // We have a single mesh containing all morph targets and tracks
  const ref_mesh_name = track.name.split('.')[0];
  const ref_mesh = ref_group.getObjectByName(ref_mesh_name) as SkinnedMesh;

  // For each mesh in the group add a track
  obj_group.traverse((child: any) => {
    if (!child.isMesh) {
      return;
    }

    const mesh = child as SkinnedMesh;
    if (!mesh.morphTargetDictionary) {
      return;
    }

    // return if child name is Eye_Mesh
    // if (mesh.name === 'Eye_Mesh') {
    //   return;
    // }
    // Transfer
    const new_array = transfer_anim(mesh, track, ref_mesh);

    // Add to additional tracks
    const t1 = track.clone();
    t1.name = `${mesh.name}.morphTargetInfluences`;
    t1.values = new_array;
    additional_tracks.push(t1);
  });

  return additional_tracks;
};

// `${env.editorResourcesUrl}/animatable/sergei_anim.fbx`
export async function load_animation_simple_fbx(avatar: AvatarAnimatable, file: string) {
  /*
    First 3 tracks are bone tracks
    then there are shape tracks, but there is a track per shape, so we need to merge them
  */
  const group = avatar.group;

  const { FBXLoader } = await import('three/examples/jsm/loaders/FBXLoader.js');
  const anim_fbx = await new FBXLoader().loadAsync(file);

  let a = anim_fbx.animations[0];

  anim_fbx.getObjectByName('eye_grp')!.name = 'Head';
  anim_fbx.getObjectByName('eyeBall_L')!.name = 'LeftEye';
  anim_fbx.getObjectByName('eyeBall_R')!.name = 'RightEye';

  a.tracks[0].name = 'Head.quaternion';
  a.tracks[1].name = 'LeftEye.quaternion';
  a.tracks[2].name = 'RightEye.quaternion';

  a = make_animation_relative_to_rest_pose(
    avatar.head_mesh,
    anim_fbx.getObjectByName('Head')! as Bone,
    a
  );

  const shape_tracks = a.tracks.slice(3);

  const mesh = anim_fbx.children[1] as SkinnedMesh;
  const morph_dict = mesh.morphTargetDictionary!;

  // Change keys in morph_dict removing prefix "blendShape1"
  for (const key in morph_dict) {
    morph_dict[key.replace('blendShape1.', '')] = morph_dict[key];
    delete morph_dict[key];
  }

  // Merge tracks into a single track
  const track_len = a.tracks[3].times.length;
  const num_blendshapes = Object.keys(morph_dict).length;
  const new_values = new Float32Array(num_blendshapes * a.tracks[3].times.length);

  for (let track_id = 0; track_id < shape_tracks.length; track_id++) {
    for (let t = 0; t < track_len; t++) {
      new_values[t * num_blendshapes + track_id] = shape_tracks[track_id].values[t];
    }
  }

  const new_track = new NumberKeyframeTrack(
    'Neutral.morphTargetInfluences',
    a.tracks[2].times as any,
    new_values as any,
    InterpolateLinear
  );

  const additional_tracks = processMorphTargetTrack(new_track, anim_fbx, group);

  a.tracks = [
    a.tracks[0],
    // a.tracks[1],
    // a.tracks[2],
  ];
  // add additional tracks
  additional_tracks.forEach((t) => {
    a.tracks.push(t);
  });

  const action = AvatarView.mixer.clipAction(a);
  return action;
}

export async function load_animation(avatar: Avatar) {
  const rpm_mapping: Record<string, string> = {
    Wolf3D_Teeth: 'Teeth_Mesh',
    Wolf3D_Tongue: 'Tongue_Mesh',
    Wolf3D_Head: 'Head_Mesh',
    EyeRight: 'Eye_Mesh',
    EyeLeft: 'Eye_Mesh',
    model: 'Head_Mesh',
    Head: 'Head_Mesh',
    Teeth: 'Teeth_Mesh',
    Tongue: 'Tongue_Mesh',
    // 'Wolf3D_Eyebrows': 'Eyebrows',
    // 'Wolf
  };
  const group = avatar.group;

  const anim_glb = await gltf_loader.loadAsync(`${env.editorResourcesUrl}/animatable/anim_full.glb`);

  anim_glb.animations.forEach((a) => {
    filter_animation(a);

    const additional_tracks: any[] = [];
    const processMorphTargetTrack = (track: KeyframeTrack) => {
      let ref_mesh_name = track.name.split('.')[0];
      const ref_mesh = anim_glb.scene.getObjectByName(ref_mesh_name) as SkinnedMesh;

      if (rpm_mapping[ref_mesh_name]) {
        track.name = track.name.replace(ref_mesh_name, rpm_mapping[ref_mesh_name]);
        ref_mesh_name = rpm_mapping[ref_mesh_name];
      }

      const add_filtered_track = (mesh_name: string) => {
        const new_array = transfer_anim(group.getObjectByName(mesh_name) as SkinnedMesh, track, ref_mesh);

        const t1 = track.clone();
        t1.name = `${mesh_name}.morphTargetInfluences`;
        t1.values = new_array;
        additional_tracks.push(t1);
      };

      //
      if (ref_mesh_name == 'Teeth_Mesh') {
        add_filtered_track('Tongue_Mesh');
      }
      if (ref_mesh_name == 'Head_Mesh') {
        if (group.getObjectByName('EyeAO_Mesh')) {
          add_filtered_track('EyeAO_Mesh');
        }

        add_filtered_track('Eyelash_Mesh');
      }
      // console.log(ref_mesh_name);
      const new_array = transfer_anim(group.getObjectByName(ref_mesh_name) as SkinnedMesh, track, ref_mesh);
      track.values = new_array;
    };

    // process morph targets
    a.tracks.forEach((track) => {
      const is_morph = track.name.endsWith('morphTargetInfluences');

      if (is_morph) {
        processMorphTargetTrack(track);
      }
    });

    // add additional tracks
    additional_tracks.forEach((t) => {
      a.tracks.push(t);
    });

    const action = AvatarView.mixer.clipAction(a);
    action.play();
  });

  // object.scale.x = 0.01;
  // object.scale.y = 0.01;
  // object.scale.z = 0.01;

  // scene.add(object);
}

export function load_animation_clip(clip: THREE.AnimationClip) {
  const action = AvatarView.mixer.clipAction(clip);
  return action;
}

export async function load_animation_simple(avatar: Avatar) {
  const group = avatar.group;

  const anim_glb = await gltf_loader.loadAsync(`${env.editorResourcesUrl}/animatable/animation.glb`);

  anim_glb.animations.forEach((a) => {
    filter_animation(a);

    const additional_tracks: any[] = [];

    const processMorphTargetTrack = (track: KeyframeTrack) => {
      // we have a single mesh containing all morph targets and tracks
      const ref_mesh_name = track.name.split('.')[0];
      const ref_mesh = anim_glb.scene.getObjectByName(ref_mesh_name) as SkinnedMesh;

      const add_filtered_track = (mesh: SkinnedMesh) => {
        const new_array = transfer_anim(mesh, track, ref_mesh);

        const t1 = track.clone();
        t1.name = `${mesh.name}.morphTargetInfluences`;
        t1.values = new_array;
        additional_tracks.push(t1);
      };

      // For ech mesh in the group add a track
      group.traverse((child: any) => {
        if (!child.isMesh) {
          return;
        }

        const mesh = child as SkinnedMesh;
        if (!mesh.morphTargetDictionary) {
          return;
        }
        // console.log(mesh.name);
        add_filtered_track(mesh);
      });
    };

    // Process morph targets
    a.tracks.forEach((track) => {
      const is_morph = track.name.endsWith('morphTargetInfluences');

      if (is_morph) {
        processMorphTargetTrack(track);
      }
    });

    a.tracks = [];
    // add additional tracks
    additional_tracks.forEach((t) => {
      a.tracks.push(t);
    });

    const action = AvatarView.mixer.clipAction(a);
    action.play();
  });

  // object.scale.x = 0.01;
  // object.scale.y = 0.01;
  // object.scale.z = 0.01;

  // scene.add(object);
}

function transfer_anim(model_to: SkinnedMesh, track: KeyframeTrack, anim_obj: SkinnedMesh) {
  const anim_dict = anim_obj.morphTargetDictionary!;
  const out_model_dict = model_to.morphTargetDictionary!;

  if (!out_model_dict) {
    alert();
  }
  const map: { [key: number]: number } = {};

  Object.entries(anim_dict!).forEach(([k, v]) => {
    const v2 = out_model_dict[k];
    if (v2 !== undefined) {
      map[v] = v2;
    } else {
      // console.log('not found corresp for ' + k);
    }
  });

  const num_frames = track.times.length;
  const anim_num_blendshapes = track.values.length / num_frames;
  const obj_num_blendshapes = Object.keys(out_model_dict).length;

  const new_array = new Float32Array(Object.keys(out_model_dict).length * num_frames);
  for (let i = 0; i < num_frames; ++i) {
    for (let j = 0; j < anim_num_blendshapes; ++j) {
      if (map[j] !== undefined) {
        new_array[i * obj_num_blendshapes + map[j]] = track.values[i * anim_num_blendshapes + j];
      }
    }
  }
  return new_array;
}
