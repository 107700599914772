/* eslint-disable @typescript-eslint/ban-ts-comment */
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { shadow_plane } from './ground';
// import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { AvatarView } from '../../AvatarView';

import { addDirectionalLightDebug, addSpotLightDebug } from './debug_gui';
import {
  ACESFilmicToneMapping,
  AmbientLight,
  Color,
  DirectionalLight,
  EquirectangularReflectionMapping,
  Group,
  PCFSoftShadowMap,
  PerspectiveCamera,
  Scene,
  SpotLight,
  SpotLightHelper,
  WebGLRenderer,
} from 'three';
import { SceneBase } from './scene_base';
import GUI from 'lil-gui';
import env from '@in3d/environment';

function build_scene(renderer: WebGLRenderer, camera: PerspectiveCamera, scene: Scene, scene_group: Group, gui?: GUI) {
  AvatarView.avatar_envMapIntensity = 2.0;
  AvatarView.cloth_envMapIntensity = 5.6;

  if (gui) {
    const f = gui.addFolder('Envmap').close();

    f.add(AvatarView, 'cloth_envMapIntensity', 0, 40, 0.1).listen();
    f.add(AvatarView, 'avatar_envMapIntensity', 0, 40, 0.1).listen();
  }

  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.1;

  scene.background = new Color('#333333'); // dark : #383838

  if (gui) {
    gui.addColor(scene, 'background');
  }
  // @ts-ignore
  renderer.useLegacyLights = true; // for rendering env

  new RGBELoader().setPath(`${env.editorResourcesUrl}/textures/`).load(
    // 'brown_photostudio_01_1k.hdr',
    // 'brown_photostudio_01_0.5k.hdr',
    // "brown_photostudio_01_128.hdr",
    'brown_photostudio_01_256.hdr',
    (texture) => {
      texture.mapping = EquirectangularReflectionMapping;

      // scene.background = texture;
      scene.environment = texture;
    },
    (texture) => {
      return;
    },
    (texture) => {
      return;
    }
  );

  // @ts-ignore
  renderer.useLegacyLights = false;

  const g = rim_light1(gui);
  camera.add(g);
  // scene_group.add(g);
  scene_group.add(camera);

  // scene.fog = new Fog(0x181818, 4, 10.12);
  scene.fog = null;
  const light = new AmbientLight(0x777777, 1.5); // soft white light
  light.name = 'ambient_light';
  scene_group.add(light);

  if (gui) {
    const f = gui.addFolder('Ambient light').close();

    f.addColor(light, 'color');
    f.add(light, 'intensity', 0, 40, 0.1).listen();
  }

  const dirLight = new DirectionalLight(0xffffff, 22);
  dirLight.name = 'directional_light';
  // dirLight.position.set(3.1, 2.00, 1.00);
  dirLight.position.set(2, 0.0, 1.0);
  dirLight.castShadow = true;
  // dirLight.shadow.bias = 0; -0.00001 ;-0.000472;
  dirLight.shadow.bias = -0.00001;

  dirLight.shadow.camera.top = 2.2;
  dirLight.shadow.camera.bottom = -1.0;
  dirLight.shadow.camera.left = -1.2;
  dirLight.shadow.camera.right = 1.2;

  dirLight.shadow.mapSize.width = 512;
  dirLight.shadow.mapSize.height = 512;

  AvatarView.camera.add(dirLight);
  // scene_group.add(dirLight);

  if (IS_DEBUG && gui) {
    const f = gui.addFolder('Directional light').close();
    addDirectionalLightDebug(dirLight, f);
  }

  // const dirLight2 = new DirectionalLight(0xffffff, 22);
  // dirLight2.name = 'directional_light2';
  // // dirLight2.position.set(3.1, 2.00, 1.00);
  // dirLight2.position.set(2, 0.0, 1.0);
  // dirLight2.castShadow = true;
  // // dirLight2.shadow.bias = 0; -0.00001 ;-0.000472;
  // dirLight2.shadow.bias = -0.00001;

  // dirLight2.shadow.camera.top = 1.8;
  // dirLight2.shadow.camera.bottom = -1.0;
  // dirLight2.shadow.camera.left = -1.2;
  // dirLight2.shadow.camera.right = 1.2;

  // dirLight2.shadow.mapSize.width = 512;
  // dirLight2.shadow.mapSize.height = 512;
  // dirLight2.intensity = 0;
  // dirLight2.visible = false;
  // // AvatarView.camera.add(dirLight2);
  // scene_group.add(dirLight2);

  // if (IS_DEBUG && gui) {
  //   const f = gui.addFolder('Directional light2').close();
  //   addDirectionalLightDebug(dirLight2, f);
  // }

  const ground = shadow_plane(new Color('#000000'), 20);
  scene_group.add(ground);
}
function rim_light1(gui?: GUI, mult = 1) {
  const g = new Group();
  let spotLight: SpotLight;
  // Spot light 1
  // let spotLight = new SpotLight(new Color(1, 0.25, 0.7), 33.5 * mult);
  spotLight = new SpotLight(new Color(1, 0.7, 0.7), 110 * mult);
  spotLight.name = 'spotlight1';
  spotLight.position.set(2.2, 1.7, -8.8);
  spotLight.angle = 0.5;
  spotLight.penumbra = 0.5;
  spotLight.castShadow = true;
  spotLight.distance = 15;
  spotLight.shadow.mapSize.width = 256;
  spotLight.shadow.mapSize.height = 256;

  g.add(spotLight);

  if (gui) {
    const h1 = new SpotLightHelper(spotLight);
    g.add(h1);
    const f = gui.addFolder('Spotlight1').close();
    addSpotLightDebug(spotLight, f);
  }

  // Spot light 2
  spotLight = new SpotLight(new Color(1, 0.7, 0.7), 80 * mult);
  spotLight.name = 'spotlight2';
  spotLight.position.set(-3, 2, -9);
  spotLight.angle = 0.9;
  spotLight.castShadow = true;
  spotLight.penumbra = 0.5;
  spotLight.shadow.mapSize.width = 256;
  spotLight.shadow.mapSize.height = 256;
  spotLight.shadow.camera.near = 0.5; // default
  spotLight.shadow.camera.far = 10; // default

  g.add(spotLight);

  // spotLight.shadow.blurSamples = 0;
  // const helper = new CameraHelper(spotLight.shadow.camera);
  // g.add(helper)

  if (gui) {
    const h1 = new SpotLightHelper(spotLight);
    g.add(h1);
    // let h1 = new SpotLightHelper(spotLight);
    // g.add(h1);
    const f = gui.addFolder('Spotlight2').close();
    addSpotLightDebug(spotLight, f);
  }

  return g;
}

export class SceneDark extends SceneBase {
  override build() {
    const scene_group = this.scene.getObjectByName('scene_group') as Group;

    this.renderer.shadowMap.type = PCFSoftShadowMap;

    build_scene(this.renderer, this.camera, this.scene, scene_group, this.gui);

    this.renderer.autoClear = true;
  }
  override clean_up() {
    this.scene.environment = null;
    const scene_group = this.scene.getObjectByName('scene_group') as Group;
    scene_group.clear();
  }
  override render() {
    this.renderer.render(this.scene, this.camera);
  }
}
