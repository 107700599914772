import { ClothesList, resourcesClient } from '../client';
import { Animation } from '../core/resources/animation';
import { FaceAnimation } from '../core/resources/animation_face';

import { makeObservable, observable, action, computed, makeAutoObservable } from 'mobx';
import { placement_to_slots, Settings, SettingsJson } from '../core/resources/common';
import { AvatarData } from '@in3d/api';
import {
  AssetData,
  CategoryName,
  getHostname,
  NewAssetConfig,
  ResourcePlacementStr,
  ResourcesPlacement,
  Slots,
} from '@in3d/common';
import { addCloth, changeAsset, changeCloth, updateClothRender } from '../editor_api';
import { auth } from '@in3d/store';
import env from '@in3d/environment';
import { remove_nulls_from_dict } from '../client/util';
import { MaterialSettings, material_presets } from '../core/Materials';
import { plainToInstance } from 'class-transformer';
import { Asset, Cloth, Glasses, Hair } from '../core/resources/assets';

export type AssetItem = { id: string; category: 'eyes' | 'head' | 'look' | 'shoes' | 'faceMask'; preview: string };
export type AvatarItem = { id: string; preview: string; gender: 'male' | 'female' };

type ProcessedAssetsList = { faceAnimations: FaceAnimation[]; animations: Animation[]; clothes: ClothesList };

class ResourceManager {
  isAnimatable = false;
  expirienceSoundEnabled = false;
  loaded = false;
  active = {
    [CategoryName.Body]: '',
    [CategoryName.Outfit]: '',
    [CategoryName.Hair]: '',
    [CategoryName.Glasses]: '',
    [CategoryName.Shoes]: '',
    [CategoryName.Animations]: '',
    [CategoryName.FaceAnimations]: '',
    // [CategoryName.Decals]: '',
  };
  animations: Animation[] = [];
  faceAnimations: FaceAnimation[] = [];
  avatars: AvatarData[] = [];
  isSceneLoaded = false;
  clothes: ClothesList = {
    [ResourcesPlacement.Eyes]: [],
    [ResourcesPlacement.Head]: [],
    [ResourcesPlacement.Look]: [],
    [ResourcesPlacement.Shoes]: [],
    [ResourcesPlacement.FaceMask]: [],
    [ResourcesPlacement.Animations]: [],
  };
  filter: { [key: AssetItem['id']]: boolean } = {};

  constructor() {
    makeAutoObservable(this);
    /*
    makeObservable(this, {
      expirienceSoundEnabled: observable,
      active: observable,
      animations: observable,
      faceAnimations: observable,
      addFaceAnimation: action.bound,
      avatars: observable,
      clothes: observable,
      isSceneLoaded: observable,
      clothById: computed,
      updateAsset: action,
      getAssetList: action,
      setActive: action,
      setAvatarList: action,
      clearAvatarList: action,
      setExpirienceSoundStatus: action,
      setSceneLoadStatus: action,
      sortByGender: action,
      filterByGender: action,
      load: action,
      setList: action,
      isAnimatable: observable,
      setIsAnimatable: action,
    });
    */
  }
  get list() {
    return {
      [CategoryName.Body]: this.avatars,
      [CategoryName.Outfit]: this.clothes.look,
      [CategoryName.Hair]: this.clothes.head,
      [CategoryName.Glasses]: this.clothes.eyes,
      [CategoryName.Shoes]: this.clothes.shoes,
      [CategoryName.Animations]: this.animations,
      [CategoryName.FaceAnimations]: this.faceAnimations,
      // [CategoryName.Decals]: this.clothes.faceMask,
    };
  }
  async isLoaded() {
    return this.loaded;
  }
  addFaceAnimation(anim: FaceAnimation) {
    this.faceAnimations.push(anim);
  }
  setIsAnimatable(x: boolean) {
    this.isAnimatable = x;
  }
  getAssetList(): Array<AssetItem> {
    return Object.values(this.clothes)
      .flat()
      .map((el) => ({ id: el.id, category: el.placement_str, preview: el.preview_url }))
      .filter((e) => (e.id in this.filter ? this.filter[e.id] : true));
  }
  getBodyList(): Array<AvatarItem> {
    return this.avatars.map((el) => ({
      id: el.id,
      preview: new URL(el.preview_url, 'https://hub.avaturn.me').href,
      gender: el.gender,
    }));
  }
  setClothesList(available: Array<AssetItem['id']>) {
    this.filter = Object.values(this.clothes).reduce((pv, cv) => {
      cv.forEach((el: any) => {
        pv[el.id] = available.includes(el.id);
      });
      return pv;
    }, {});
  }
  setSceneLoadStatus(value: boolean) {
    this.isSceneLoaded = value;
  }
  setExpirienceSoundStatus(value: boolean) {
    this.expirienceSoundEnabled = value;
  }
  setActiveAvatar(id: string) {
    this.avatars = this.avatars.map((avatar) => ({ ...avatar, is_active: id === avatar.id }));
  }
  setActive(placement: CategoryName, id: string) {
    this.active[placement] = id;
  }

  getAnimationById(id: string): Animation | null {
    return this.clothes.animations.find((e) => e.id === id) || null;
  }
  getFaceAnimationById(id: string): FaceAnimation | null {
    return this.faceAnimations.find((e) => e.id === id) || null;
  }
  setAvatarList(list: any[]) {
    this.avatars = list;
  }
  clearAvatarList() {
    this.avatars.length = 0;
  }
  sortByGender(target_gender: 'male' | 'female') {
    function compare(a: any, b: any) {
      if (a.gender < b.gender) return -1;
      if (a.gender > b.gender) return 1;
      return 0;
    }

    this.clothes.head = this.clothes.head.sort(compare);
    this.clothes.look = this.clothes.look.sort(compare);

    if (target_gender == 'male') {
      this.clothes.head.reverse();
      this.clothes.look.reverse();
    }
  }
  filterByGender(target_gender: 'male' | 'female') {
    this.clothes.look = this.clothes.look.filter((v) => {
      return v.settings_json.restrict_gender ? v.gender == target_gender : true;
    });
  }
  get clothById(): { [key: string]: Cloth } {
    return Object.values(this.clothes)
      .flat()
      .reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {} as { [key: string]: any });
  }
  updateAsset<T extends keyof Cloth>(id: string, key: T, value: Cloth[T]) {
    this.clothById[id][key] = value;
    console.log(this.clothById[id]);
    updateClothRender(id);
  }

  setList(list: AssetData[]) {
    const { faceAnimations, animations, clothes } = this.prepareAssets(list);
    if (animations) this.animations = animations;
    if (clothes) this.clothes = clothes;
    if (faceAnimations) this.faceAnimations = faceAnimations;
    this.loaded = true;
  }

  async load() {
    return resourcesClient
      .loadAll()
      .then(
        action('loadSuccess', (list: AssetData[]) => {
          const { faceAnimations, animations, clothes } = this.prepareAssets(list);
          this.animations = animations;
          this.clothes = clothes;
          this.faceAnimations = faceAnimations;
          this.loaded = true;
        })
      )
      .catch((err) => {
        console.log('Error while loading resources: ', err);
      });
  }
  corresponding_ids: Uint16Array;
  async loadCorrespondingIds() {
    if (this.corresponding_ids) {
      return;
    }

    this.corresponding_ids = await resourcesClient.loadCorrespondingIds();
  }

  // for animatable head retarget
  headIds: Uint16Array;
  async loadHeadIds() {
    if (this.headIds) {
      return;
    }

    this.headIds = await resourcesClient.head_ids();
  }

  retargetingIds: Uint16Array;
  async loadRetargetingIds() {
    if (this.retargetingIds) {
      return;
    }

    this.retargetingIds = await resourcesClient.loadBodyRetargetingIds();
  }

  retargetingOffsets: Float32Array;
  async loadRetargetingOffsets() {
    if (this.retargetingOffsets) {
      return;
    }

    this.retargetingOffsets = await resourcesClient.loadBodyRetargetingOffsets();
  }
  prepareAssets(data: AssetData[]): ProcessedAssetsList {
    const list: { [key in ResourcesPlacement]: AssetData[] } = {
      eyes: [],
      head: [],
      look: [],
      shoes: [],
      animations: [],
      faceMask: [],
    };

    data.forEach((el) => {
      //el.placement = el.type;
      list[el.type].push(el);
    });

    // eslint-disable-next-line prefer-const
    let { eyes, head, look, shoes } = list;

    const body_animations = list.animations.filter((data: any) => {
      return !data.id.startsWith('face__') || (IS_DEBUG && data.id.startsWith('__'));
    });
    const face_animations = list.animations.filter((data: any) => {
      return data.id.startsWith('face__') || (IS_DEBUG && data.id.startsWith('__face__'));
    });

    const animations = body_animations
      .map((data) => {
        if (env.localResources) {
          data.glb_url = `assets/test_assets/animations/${data.path}`;
          data.preview_url = `assets/test_assets/animations/${(data.path as string).slice(0, -4)}.png`;
        }
        //data.settings_json = Object.assign(new AnimationSettingsJson(), data.settings_json);
        //data.preview_url = data.preview_webp_url || data.preview_url;
        return plainToInstance(Animation, data);
      })
      .reverse();

    const faceAnimations = face_animations.map((data) => {
      if (env.localResources) {
        data.glb_url = `assets/test_assets/animations/${data.path}`;
        data.preview_url = data.path ? `assets/test_assets/animations/${data.path.slice(0, -4)}.png` : '';
      }
      //data.preview_url = data.preview_webp_url || data.preview_url;
      return plainToInstance(FaceAnimation, data);
    });

    const masks = look.filter((data) => {
      return data.id == 'eikonikos_mask';
    });
    look = look.filter((data) => {
      return data.id != 'eikonikos_mask';
    });

    const clothes = {
      [ResourcesPlacement.FaceMask]: masks.map((data) => {
        data.placement = ResourcesPlacement.FaceMask;
        init_data(data, ResourcesPlacement.FaceMask);
        return plainToInstance(Cloth, data);
      }),
      [ResourcesPlacement.Eyes]: eyes.map((data) => {
        init_data(data, 'eyes');
        return plainToInstance(Glasses, data);
      }),
      [ResourcesPlacement.Head]: head
        .map((data) => {
          init_data(data, 'head');

          if (data.filenames) {
            const add_looks: Hair[] = [];
            data.filenames.forEach((name) => {
              const a = plainToInstance(Hair, data);
              a.id = data.id + '_' + name;
              a.filename = name;
              a.glb_url = '';
              add_looks.push(a);
            });
            return add_looks;
          }

          return plainToInstance(Hair, data);
        })
        .flat(),
      [ResourcesPlacement.Look]: look
        .map((data) => {
          init_data(data, 'look');

          if (data.maps && Object.keys(data.maps).length > 0) {
            const base = plainToInstance(Cloth, data);

            const add_looks: Cloth[] = [];
            Object.entries(data.maps).forEach(([k, m]) => {
              const a = plainToInstance(Cloth, data);
              a.id = base.id + '_' + k;
              a.ref = base;
              a.map_url = m;
              a.preview_url = data.maps_preview?.[k] || m;
              add_looks.push(a);
            });
            return add_looks;
          }

          if (data.filenames) {
            const add_looks: Cloth[] = [];
            data.filenames.forEach((name) => {
              const a = plainToInstance(Cloth, data);
              a.id = data.id + '_' + name;
              a.filename = name;
              a.glb_url = '';
              add_looks.push(a);
            });
            return add_looks;
          }

          return plainToInstance(Cloth, data);
        })
        .flat(),
      [ResourcesPlacement.Shoes]: shoes.map((data) => {
        init_data(data, 'shoes');
        return plainToInstance(Cloth, data);
      }),
      [ResourcesPlacement.Animations]: animations,
    };
    return { faceAnimations, animations, clothes };
  }
}

function init_data(data: any, placement?: string) {
  remove_nulls_from_dict(data);
  if (env.localResources) {
    data.placement = data.placement || placement;

    data.asset_dir = `assets/test_assets/assets/${placement}/${data.id}`;
    data.filename = 'model_v2.glb';

    data.preview_url = `${data.asset_dir}/preview.png`;
  }

  data.preview_url = data.preview_webp_url || data.preview_url;
  data.placement_str = data.placement;
  /*
  data.settings_json = data.settings;
  */

  if (data.material_preset) {
    data.material_settings = material_presets[data.material_preset];
  } else {
    data.material_settings = Object.assign(new MaterialSettings(), data.material_settings);
  }
  data.settings = Object.assign(new Settings(), data.settings);
  data.settings_json = Object.assign(new SettingsJson(), data.settings);

  /*
data.placement = [...placement_to_slots[data.placement]];
if ((data.settings_json as SettingsJson).with_head) {
  data.placement.push(Slots.Head);
}
if ((data.settings_json as SettingsJson).with_shoes) {
  data.placement.push(Slots.Shoes);
}
*/
}

export const resources = new ResourceManager();
