import { BufferGeometry, DoubleSide, LinearEncoding, ShaderMaterial, Texture } from 'three';

import { prepareScene } from './prepareScene';
import { DecalMaker } from '.';
import { disposeScene } from './dispose_helpers';


const vertexShader = `
    attribute vec2 uv_outer;
    varying vec2 vUv;
    varying vec2 vUv_outer;

    void main() {
        vUv = uv;
        vUv_outer = uv_outer;
        gl_Position = vec4(vUv * 2.0 - 1.0, 0.0, 1.0);
    }
`;

const fragmentShader = `
    uniform sampler2D originalTexture;
    varying vec2 vUv;
    varying vec2 vUv_outer;

    void main() {
      vec2 decalUV = vUv_outer;
      if (decalUV.x < 0.0 || decalUV.x > 1.0 || decalUV.y < 0.0 || decalUV.y > 1.0) {
          gl_FragColor = vec4(0.0);
      } else {
          vec4 originalColor = texture2D(originalTexture, decalUV);
          gl_FragColor = originalColor;
        }
    }
`;

export const saveImage = (dataURL: string) => {
  // download
  const a = document.createElement('a');
  a.href = dataURL;
  a.download = 'decal.png';
  a.click();
};

// map → cut a piece of image
export function unprojectDecal(this: DecalMaker, originalTexture: Texture, decalGeometry: BufferGeometry) {
  const { renderer, prepareRenderer, restoreRenderer } = this;

  prepareRenderer();

  // TODO: calculate
  const picSize = {
    width: 1000,
    height: 1000,
  };

  const clonedTexture = originalTexture.clone();
  clonedTexture.encoding = LinearEncoding;

  const material = new ShaderMaterial({
    uniforms: {
      originalTexture: { value: clonedTexture },
    },
    side: DoubleSide,
    transparent: true,
    vertexShader,
    fragmentShader,
  });

  const { scene, camera } = prepareScene(picSize, material, decalGeometry);

  // renderer.outputEncoding = sRGBEncoding
  renderer.render(scene, camera);
  const dataURL = renderer.domElement.toDataURL();

  // dispose
  disposeScene(scene);

  restoreRenderer();

  return dataURL;
}
