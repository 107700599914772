import { Shader } from 'three';
import { Uniform } from './_common';

export interface BrightnessContrastParams {
  brightness: Uniform<number>;
  contrast: Uniform<number>;
}

export const brightness_contrast_pars = `
uniform float brightness;
uniform float contrast;

vec3 brightness_contrast(const in vec3 inputColor) {

  vec3 color = inputColor.rgb + vec3(brightness - 0.5);

  if(contrast > 0.0) {

    color /= vec3(1.0 - contrast);

  } else {

    color *= vec3(1.0 + contrast);

  }

  return color + vec3(0.5);
}
`;

export function brightness_contrast(
  shader: Shader,
  params: BrightnessContrastParams,
  mode: 'post' | 'before_lighting' | 'albedo'
) {
  Object.entries(params).forEach(([k, v]) => {
    shader.uniforms[k] = v;
  });

  shader.fragmentShader = brightness_contrast_pars + shader.fragmentShader;

  if (mode == 'post') {
    shader.fragmentShader = shader.fragmentShader.replace(
      '#include <output_fragment>',
      `
      #include <output_fragment>
      gl_FragColor.rgb = brightness_contrast(gl_FragColor.rgb);
      `
    );
  } else if (mode == 'before_lighting') {
    shader.fragmentShader = shader.fragmentShader.replace(
      '#include <emissivemap_fragment>',
      `
      #include <emissivemap_fragment>
      diffuseColor.rgb = brightness_contrast(diffuseColor.rgb);
      `
    );
  } else if (mode == 'albedo') {
    shader.fragmentShader = shader.fragmentShader.replace(
      '#include <map_fragment>',
      `
      #include <map_fragment>
      diffuseColor.rgb = brightness_contrast(diffuseColor.rgb);
      `
    );
  }
}
