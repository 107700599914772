import env from '@in3d/environment';
import { RenderPass } from 'postprocessing';
import { OrthographicCamera, Scene, TextureLoader, SpriteMaterial, Sprite, Color, Vector2, Texture, DirectionalLight, sRGBEncoding } from 'three';
import { AvatarView } from '../../../AvatarView';
import { SceneBright12c, SceneDark3 } from '../scene_dark2';

export class SceneInfosys extends SceneBright12c {
  sceneBG: Scene
  sceneFG: Scene
  cameraOrtho: OrthographicCamera

  override async build() {
    super.build();

    AvatarView.currentState.setDefaultAnimation();

    let gender=AvatarView.currentState.avatar.gender;

    let d;
    if (gender == 'male') {
      // d = {"target":{"x":-0.2922317271862731,"y":1.018309478488853,"z":0.012529894208424375},"position":{"x":1.8674038782201876,"y":0.8707243056766311,"z":5.163971590570672},"zoom":1};
      // d ={"target":{"x":-0.4148551969426347,"y":1.4034220439072682,"z":-0.4016318215894029},"position":{"x":0.8990572620652875,"y":1.3571986510322467,"z":2.316514080833574},"zoom":1}
      d = {"target":{"x":-0.6051928262198414,"y":1.6124841556580578,"z":-0.3060701586115076},"position":{"x":1.0910033406818491,"y":1.2821619445914219,"z":2.76249490927378},"zoom":1}
      this.camera.fov = 45;
      // d = {"target":{"x":-0.41685840643261163,"y":1.5378653258385144,"z":-0.4030308103888937},"position":{"x":1.6451971710870472,"y":1.6094422168086706,"z":4.487951139614301},"zoom":1}
    } else {
      // d = {"target":{"x":-0.31853904431956337,"y":1.101282919845815,"z":-0.03323375777047559},"position":{"x":0.7238183789164042,"y":0.7559921072029936,"z":5.44558218541665},"zoom":1}
      // d = {"target":{"x":-0.32946303904816,"y":1.5213815425368513,"z":-0.3990383080969974},"position":{"x":0.37772939667112426,"y":1.5550997154830792,"z":2.091030400309453},"zoom":1}
      // d = {"target":{"x":-0.19412381076818055,"y":1.549877045987693,"z":-0.41375650520815166},"position":{"x":0.3416651945463919,"y":1.3654110914563313,"z":5.145205748287935},"zoom":1}
      this.camera.fov = 45;
      d = {"target":{"x":-0.49181081152088146,"y":1.5237056202259083,"z":-0.3829590967212089},"position":{"x":0.013470853636829982,"y":1.6000905673235069,"z":3.1014549938102847},"zoom":1};
    }

    this.lut_manager
    .get('png/filmic2')
    .then(() => this.lut_manager.set_lut_to_effect(this.lutEffect, 'png/filmic2', this.renderer, true));
    // d ={"target":{"x":-0.4148551969426347,"y":1.4034220439072682,"z":-0.4016318215894029},"position":{"x":0.8990572620652875,"y":1.3571986510322467,"z":2.316514080833574},"zoom":1}

    // this.camera.fov = 45;
    this.camera.position.set(d.position.x, d.position.y, d.position.z);

    AvatarView.controls.target.set(d.target.x, d.target.y, d.target.z);
    AvatarView.controls.update();

    AvatarView.controls.enabled = false;

    this.camera.updateProjectionMatrix();
    this.renderer.toneMappingExposure = 0.8;

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    // d1.intensity = 1.6;
    d1.intensity = 0.6;

    d1.position.x = -1.2
    d1.position.y = 5.95
    d1.position.z = 6.35

    const a = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    a.intensity = 0.2;

    AvatarView.cloth_envMapIntensity = 0.2
    AvatarView.avatar_envMapIntensity = 0.2

    this.brightnessContrastEffect.brightness = 0.038;
    this.brightnessContrastEffect.contrast = 0.158;

    // const dir = `${env.editorResourcesUrl}/scenes/tetonridge_fanmag`
    const dir = 'assets/local/scenes/infosys/'

    const tex = await new TextureLoader().loadAsync(`https://assets.avaturn.me/Background_image_4ab4ca3cd6.webp`);
    tex.matrixAutoUpdate = false;
    tex.encoding = sRGBEncoding;
    this.scene.background = tex;

    this.onWindowResize();
  }

  override clean_up() {
    AvatarView.controls.enabled = true;
    AvatarView.camera.fov = 25;

    AvatarView.controls.target.set(
      0.04270815632886327, 0.9224574103730636, -0.05087591212150736,
    );
    AvatarView.camera.position.set(
      0.6869758505862068, 1.670105241713705, 4.392098698473549
    );
    AvatarView.controls.update();
    AvatarView.camera.updateProjectionMatrix();
  }

  override onWindowResize(width?: number, height?: number) {
    if (!width || !height) {
      [width, height] = this.get_canvas_size()
    }
    super.onWindowResize(width, height)
    var aspect = width / height;

    if ( this.scene.background) {
      cover( this.scene.background as Texture, aspect );
    }
  }

}


function cover( texture: Texture, aspect: number ) {
	var imageAspect = texture.image.width / texture.image.height;

	if ( aspect < imageAspect ) {
			texture.matrix.setUvTransform( 0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5 );
	} else {
			texture.matrix.setUvTransform( 0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5 );
	}

}
