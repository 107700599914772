import { cn } from '@in3d/common';
import { InputHTMLAttributes } from 'react';
import './custom-input.scss';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  'data-error'?: string | boolean;
}
export const CustomInput: React.FC<CustomInputProps> = (props) => {
  const { label, className, children } = props;
  const dataError = props['data-error'];

  return (
    <div className={cn(['custom-input', className])}>
      {label ? <div className="custom-input__label">{label}</div> : null}
      <input autoComplete="off" {...props} className={cn(['custom-input__field', dataError && 'error'])} />
      <div className="custom-input__bot">
        {dataError && typeof dataError === 'string' && <div className="custom-input__error">{dataError}</div>}
      </div>
    </div>
  );
};
