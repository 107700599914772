import { EvnSetup } from './environment';

export const environment: EvnSetup = {
  resourceUrl: 'https://api.createavatar.me/', // FOR TESTING // 'https://api.createavatar.me/',
  baseUrl: localStorage.getItem('custom_api') || 'https://api.createavatar.me/',
  editorResourcesUrl: 'https://assets.avaturn.me/editor_resources',
  // editorResourcesUrl: 'assets/local',
  localResources: false,
  production: false as const,
  isLocalRun: false,
  isAssetsAdmin: true,
  simulateAPIsession: false,
  scanSizeMultiplier: 4,
  hostname_override: '',
  debugShowIdsOnCards: false,
  //isMobile: true,
  rootHosts: ['hub.createavatar.me', 'developer.createavatar.me', 'face.createavatar.me', 'hub.avaturn.me'], // createavatar.io for integration
  hostsAnimatable: ['future-avaturn.createavatar.io', 'face.createavatar.me', 'hub.avaturn.me'],
  hostsWithPriveleges: [],
  externalScanHost: 'scan.createavatar.me',
  scanApiHost: 'https://p.app.gsize.io',
  firebaseConfig: {
    apiKey: 'AIzaSyDcxVccRrV45uYmQrkiB_J9ek1w9yf3zOQ',
    authDomain: 'auth.in3d.io',
    projectId: 'in3d-web-avatar-staging',
    storageBucket: 'in3d-web-avatar-staging.appspot.com',
    messagingSenderId: '654179331590',
    appId: '1:654179331590:web:0eafa71d874cf65bfcdd87',
    measurementId: 'G-6JYL3D0XKB',
  },
  sentryConfig: {
    dsn: 'https://068c1656aa3940c295af0b73fbfe9f18@o1343556.ingest.sentry.io/4504395074699264',
    environment: 'staging',
    tracesSampleRate: 1.0,
  },
  // sentryScanConfig: {
  //   dsn: 'https://068c1656aa3940c295af0b73fbfe9f18@o1343556.ingest.sentry.io/4504395074699264',
  //   environment: 'staging',
  //   tracesSampleRate: 1.0,
  // },
} as const;

export default environment;
