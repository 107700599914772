import GUI from 'lil-gui';
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Bone,
  BufferAttribute,
  BufferGeometry,
  Color,
  DoubleSide,
  Group,
  Material,
  MeshStandardMaterial,
  Skeleton,
  SkinnedMesh,
  Vector2,
} from 'three';

import { centerObject } from '../../../helpers';
import { AvatarView, gltf_loader } from '../../AvatarView';
import { AvatarList } from '../../content';
import { adjust_skeleton } from '../animation_utils';
import { Uniform } from '../Assets/shaders/_common';
import { patch_shader_for_uv1_ao } from '../Assets/shaders/ao_uv1';
import { brightness_contrast, BrightnessContrastParams } from '../Assets/shaders/brightness_contrast';
import { curve, CurveParams } from '../Assets/shaders/curve';
import { hair_cap } from '../Assets/shaders/hair_cap';
import { clamp, disposeThreeJSObject, DownloadState, traverse } from './common';
import { changeHaircap, hairCapShaderParams } from './haircap';
import { developerConfig } from '@in3d/store';

const avatars: { [key: string]: Avatar } = {};

export type MySkinnedMesh = SkinnedMesh<BufferGeometry, MeshStandardMaterial>;

export class Avatar {
  static avatar_material?: MeshStandardMaterial;
  static brightness_contrast_params: BrightnessContrastParams = {
    brightness: new Uniform(0),
    contrast: new Uniform(0),
  };
  static curve_params: CurveParams = {
    curve_coeff: new Uniform(1),
  };
  static boneScale = {
    head: 1,
    arms: 1,
    // legs: 1,
    chest: 1,
  };

  static bone_scale: Record<string, number> = {};
  static armature = new Group();
  root_bone_elevation: number;

  glb_url: string;

  // Paths-related
  id: string;
  body_id: string;
  gender: 'male' | 'female';
  avatar_dir: string;
  file_name: string;

  mesh: MySkinnedMesh;
  group: Group;
  protected _skeleton: Skeleton;
  download_state = DownloadState.NotLoaded;

  mesh_name: string;
  static haircap_promise: Promise<void>;

  get body_mesh() {
    return this.group.getObjectByName('avaturn_body')! as MySkinnedMesh;
  }

  cleanup() {
    disposeThreeJSObject(this.mesh);

    if (this.mesh) {
      AvatarView.animationGroup.uncache(this.mesh);
      AvatarView.scene_objects_group.remove(this.mesh.skeleton.bones[0]);
      AvatarView.scene_objects_group.remove(this.mesh);

      // (this.mesh.material as Material).onBeforeCompile = function () {};
      // this.mesh.material = new MeshStandardMaterial();

      this.mesh.skeleton?.dispose();
    }

    // @ts-ignore
    this.mesh = undefined;
    this.group = new Group();

    // @ts-ignore
    delete this._skeleton;

    this.download_state = DownloadState.NotLoaded;
  }

  get is_downloaded() {
    return this.download_state == DownloadState.Loaded;
  }

  get verts() {
    const p = this.mesh.geometry.attributes['position'];
    if (p instanceof BufferAttribute) {
      return p.array;
    }

    const array = new Float32Array(p.data.count * 3);
    for (let i = 0; i < p.data.count; i++) {
      array[i * 3] = p.getX(i);
      array[i * 3 + 1] = p.getY(i);
      array[i * 3 + 2] = p.getZ(i);
    }

    return array;
  }

  get skeleton() {
    return this._skeleton;
  }

  set_visibility(value: boolean) {
    if (this.is_downloaded) {
      // this.mesh.visible = value;
      this._skeleton.bones[0].visible = value;
      if (this.group) {
        this.group.visible = value;
      }
    }
  }

  private getBone(name: string) {
    return this.skeleton.bones.find((x) => {
      return x.name == name;
    });
  }
  private setScale(bones: string[], scale: number) {
    bones.forEach((name) => {
      this.getBone(name)?.scale.set(scale, scale, scale);
    });
  }
  setArmsScale() {
    const scale = Avatar.boneScale.arms;
    this.setScale(['LeftArm', 'RightArm'], scale);

    const t = scale * 0.4 + 0.6;
    this.setScale(['LeftShoulder', 'RightShoulder'], t);
  }

  // setLegsScale() {
  //   this.setScale(['LeftUpLeg', 'RightUpLeg'], Avatar.boneScale.legs);
  // }

  setChestScale() {
    this.setScale(['Spine2'], Avatar.boneScale.chest);
  }

  setHeadScale() {
    // scale head
    const scale = Avatar.boneScale.head;
    this.getBone('Head')?.scale.set(scale, scale, scale);

    // also scale neck a bit
    const t = scale * 0.4 + 0.6;
    this.getBone('Neck')?.scale.set(t, t, t);
  }
  setScaleAll() {
    this.setHeadScale();
    this.setChestScale();
    // this.setLegsScale();
    this.setArmsScale();
  }
  toRestPose() {
    this.skeleton.pose();
    this.setScaleAll();
  }
  async load_mesh() {
    this.download_state = DownloadState.Loading;

    let gui: GUI;
    if (IS_DEBUG && AvatarView.gui) {
      gui = AvatarView.gui.addFolder(this.id).close();
    }

    let root_bone: Bone = new Bone();

    const gltf = await gltf_loader.loadAsync(this.glb_url);

    await traverse(gltf.scene, async (node: any) => {
      gltf.scene.updateMatrixWorld();
      if (!node.isMesh) {
        return;
      }

      if (this.mesh_name && node.name != this.mesh_name) {
        return;
      }

      const mesh = node as SkinnedMesh;
      const material = mesh.material as MeshStandardMaterial;

      //\\ -- Mesh and skeleton -- //\\
      adjust_skeleton(mesh);
      mesh.skeleton.pose();

      root_bone = mesh.skeleton.bones[0];
      this.root_bone_elevation = root_bone.position.y;
      // add skeletonHelper
      // let skeletonHelper = getHelperFromSkeleton(mesh.skeleton);
      // // skeletonHelper.material as .linewidth = 5;
      // skeletonHelper.visible = true;
      // AvatarView.scene.add(skeletonHelper);

      this._skeleton = mesh.skeleton;
      this.mesh = mesh as MySkinnedMesh;

      mesh.frustumCulled = false;
      if (gltf.userData.need_recompute_normals === undefined) {
        mesh.geometry.computeVertexNormals();
      }
      mesh.updateMatrixWorld();
      mesh.castShadow = true;
      mesh.receiveShadow = true;

      //\\ -- Material -- //\\
      if (!Avatar.avatar_material) {
        Avatar.avatar_material = material;
      } else {
        mesh.material = Avatar.avatar_material;
        return;
      }

      material.flatShading = false;

      // material.specularIntensity = 0.2;

      if (IS_DEBUG && AvatarView.gui) {
        if (!material.isMeshStandardMaterial) {
          gui.add(material, 'specularIntensity', 0, 3, 0.01).listen();
        }
      }

      // IMPORTANT: MEMORY LEAK HERE

      if (IS_DEBUG && AvatarView.gui) {
        const p = { scale: 1, scalex: 1, scaley: 1, scalez: 1, recompute_normals: false };
        gui.add(p, 'scale', 0, 3, 0.01).onChange((v: number) => {
          mesh.skeleton.bones[5].scale.set(v, v, v);
        });

        // let a = mesh.geometry.getAttribute("normal").clone();
        // gui.add(p, 'recompute_normals').onChange((v: boolean) => {
        //   if (v) {
        //     mesh.geometry.computeVertexNormals();
        //   } else {
        //     mesh.geometry.setAttribute("normal", a.clone())
        //   }
        //   // if (mesh.geometry.hasAttribute("normal")) {
        //   //   mesh.geometry.deleteAttribute("normal");
        //   // }

        // })
      }
      if (IS_DEBUG && AvatarView.gui) {
        gui.add(material, 'roughness', 0, 3, 0.01).listen();
        gui.add(material, 'aoMapIntensity', 0, 3, 0.01).listen();

        gui.add(Avatar.brightness_contrast_params.brightness, 'value', -1, 1, 0.01).listen().name('brightness');
        gui.add(Avatar.brightness_contrast_params.contrast, 'value', -1, 1, 0.01).listen().name('contrast');

        gui.add(Avatar.curve_params.curve_coeff, 'value', 0, 3, 0.01).listen().name('curve_coeff');
      }

      // if (mesh.geometry.hasAttribute("normal")) {
      //   mesh.geometry.deleteAttribute("normal");
      // }

      material.alphaMap = AvatarView.currentState.alpha_map_body;
      material.alphaTest = 0.5;
      material.needsUpdate = true;

      material.shadowSide = DoubleSide;

      // Becomes less blurry
      material.map && (material.map.generateMipmaps = false);
      material.normalMap && (material.normalMap.generateMipmaps = false);
      material.roughnessMap && (material.roughnessMap.generateMipmaps = false);

      material.envMapIntensity = AvatarView.avatar_envMapIntensity;
      material.normalScale = new Vector2(1, 1);

      if (!Avatar.haircap_promise) {
        Avatar.haircap_promise = changeHaircap(developerConfig.editor.defaultHaircap);
        Avatar.set_haircap_color('#6d4539');
      }
      // material.customProgramCacheKey = function () {
      //   return '';
      //   // return this.material_settings.use_haircap.toString();
      // }.bind(this);

      material.roughness = 1.3;

      material.onBeforeCompile = (shader: any) => {
        hair_cap(shader, hairCapShaderParams);

        brightness_contrast(shader, Avatar.brightness_contrast_params, 'albedo');
        curve(shader, Avatar.curve_params, 'albedo');

        patch_shader_for_uv1_ao(shader);
        material.needsUpdate = true;
      };
      // console.log(AvatarView.renderer.info)
    });
    this.mesh.name = 'avaturn_body';
    (this.mesh.material as Material).name = 'avaturn_body_material';

    this.group = new Group();
    this.group.name = 'avaturn_body_group';
    this.group.add(this.mesh, root_bone);

    this.group.visible = false;

    AvatarView.scene_objects_group.add(this.group);
    AvatarView.animationGroup.add(this.group);

    centerObject(AvatarView.scene_objects_group);

    await Avatar.haircap_promise;
    this.download_state = DownloadState.Loaded;
  }

  static set_haircap_color(color_str: string) {
    /* color_str sRGB. We do not convert into linear just because it looks better without it.*/
    const _hslA = { h: 0, s: 0, l: 0 };
    const _hslB = { h: 0, s: 0, l: 0 };

    let color = new Color(color_str);
    color = color.convertSRGBToLinear();
    color.getHSL(_hslA);

    _hslB.h = (_hslA.h + 0.5 + 0.5) % 1.0;
    _hslB.s = clamp(_hslA.s * 1.2, 0, 1);
    _hslB.l = _hslA.l * 0.3;

    hairCapShaderParams.hair_cap_color.value.setHSL(_hslB.h, _hslB.s, _hslB.l);
  }
}

// -----------------------------------------
//              OTHER METHODS
// -----------------------------------------

function init_avatars(avatar_list: AvatarList) {
  for (const cur of avatar_list) {
    const avatar = Object.assign(new Avatar(), cur);
    avatars[cur.id] = avatar;
  }
}

async function load_avatar(avatar_id: string) {
  if (!(avatar_id in avatars)) {
    return;
  }

  await AvatarView.currentState.changeAvatar(avatars[avatar_id]);
}

export function dispose_avatars(remove = true) {
  for (const avatar of Object.values(avatars)) {
    avatar.cleanup();
  }
  Avatar.avatar_material?.dispose();
  Avatar.avatar_material = undefined;

  if (remove) {
    for (const prop in avatars) {
      if (Object.prototype.hasOwnProperty.call(avatars, prop)) {
        delete avatars[prop];
      }
    }
  }
}

export function set_new_url(url: string, gender: 'male' | 'female') {
  for (const avatar of Object.values(avatars)) {
    avatar.glb_url = url;
    // avatar.gender
  }
}

export { load_avatar, init_avatars, avatars };
