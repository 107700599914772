import './tshirt-generator.scss';

import { allowedClothes as customizableClothes, resources } from '@in3d/core';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { SparksIcon } from './sparks-icon';
import { useRightPopper } from './use-right-popper.hook';

const TshirtGeneratorButton = ({ active, setActive }: { active: boolean; setActive: (active: boolean) => void }) => {
  const { setReferenceElement, setPopperElement, setArrowElement, styles, attributes, popper, setPopper } =
    useRightPopper(resources.isSceneLoaded);

  const [available, setAvailable] = useState(false);

  const checkAvailable = () => {
    // if outfit is in the list of customizableClothes clothes, set available to true
    setAvailable(customizableClothes.includes(resources.active.outfit));
  };

  useEffect(() => {
    checkAvailable();
    reaction(
      () => resources.active.outfit,
      (outfit, previousOutfit) => {
        if (outfit !== previousOutfit) checkAvailable();
      }
    );
  }, []);

  return (
    <>
      <motion.button
        disabled={!available}
        className={cn([
          't-generator__btn',
          {
            active,
          },
        ])}
        onClick={() => {
          if (!available) return;
          setActive(!active);
        }}
        onPointerEnter={() => {
          if (!active) setPopper(true);
        }}
        onPointerLeave={() => setPopper(false)}
        ref={setReferenceElement}
      >
        <SparksIcon />
      </motion.button>
      {popper && (
        <div
          ref={setPopperElement}
          style={styles['popper']}
          {...attributes['popper']}
          className="face-tracking__tooltip"
        >
          Generate&nbsp;Print
          <div ref={setArrowElement} style={styles['arrow']} />
        </div>
      )}
    </>
  );
};

const _TshirtGeneratorButton = observer(TshirtGeneratorButton);
export { _TshirtGeneratorButton as TshirtGeneratorButton };
