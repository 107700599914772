import { useEffect, useState } from 'react';

// TODO: make this isMobile state global

export const useIsMobile = (threshold = 960): boolean => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < threshold);
    };

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, [threshold]);

  return isMobile;
};
