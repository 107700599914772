import { avatarClient, ExportStatus, ExportType, NewExportInfo } from '@in3d/api';
import { page_params, sendMessageWithHttpUrl, send_message_with_avatar } from '@in3d/common';
import { auth, avatars, developerConfig } from '@in3d/store';
import { GenerateModelParams, generate_model } from './addons/model_export';
import { AvatarView } from './AvatarView';
import { takeScreenshot } from './editor_api';
import { getCamera, render, setCamera, setHeadlessCamera } from './headless_api';
import { captureException } from '@sentry/react';

export function downloadFileToDisk(strData: string, filename: string) {
  const link = document.createElement('a');

  document.body.appendChild(link); //Firefox requires the link to be in the body
  link.download = filename;
  link.href = strData;
  link.click();
  document.body.removeChild(link); //remove the link when done
}
export async function shareOrDownloadToDisk(blob: Blob, filename: string, mime?: string) {
  // alert(blob!.type )
  const data = {
    files: [
      new File([blob as BlobPart], filename, {
        type: mime || blob!.type,
      }),
    ],
    text: 'Created at https://avaturn.me',
  };

  if (navigator.share && navigator.canShare(data)) {
    return navigator.share(data).catch((err) => {
      captureException(err);
      downloadFileToDisk(window.URL.createObjectURL(blob), filename);
    });
  } else {
    return downloadFileToDisk(window.URL.createObjectURL(blob), filename);
  }
}

export async function upload_to_firebase(blob: Blob | ArrayBuffer | Uint8Array, contentType: string, filename: string) {
  const { getStorage, ref, uploadBytes, getDownloadURL } = await import('firebase/storage');

  const storage = getStorage();
  const storageRef = ref(storage, filename);

  const metadata = {
    contentType: contentType, // 'image/jpeg',
  };

  // Upload the file and metadata
  await uploadBytes(storageRef, blob, metadata);

  const link = await getDownloadURL(storageRef);
  return link;
}

export function dataURItoBlob(dataURI: string) {
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {
    type: mime,
  });
}

export type ExportAvatarResult =
  | { type: ExportType.DataURL; data: null | Blob; thumbnail?: string }
  | { type: ExportType.HttpURL; data: NewExportInfo; thumbnail?: string };

declare global {
  interface Window {
    avaturnForceExportHttpUrl?: boolean;
  }
}

async function _getHttpUrlLocalUpload(): Promise<ExportAvatarResult> {
  const blob = await generateModel();
  const uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
  const url = await upload_to_firebase(blob!, 'application/octet-stream', `glb/${uniqueId}.glb`);

  sendMessageWithHttpUrl(url, AvatarView.is_animatable);
  return { type: ExportType.HttpURL, data: { id: '', url: '', status: ExportStatus.Ready } };
}

export async function generateThumbnail() {
  const c = getCamera();

  const r = AvatarView.renderer.getPixelRatio();
  AvatarView.scene_manager.onWindowResize(720 / r, 1024 / r);
  // setHeadlessCamera();

  const d = {
    target: { x: -0.07104778961190421, y: 1.0613777403605178, z: -0.09092505838821047 },
    position: { x: 1.6791902883713352, y: 1.8292592095458189, z: 4.861440599660449 },
    zoom: 1,
  };
  AvatarView.camera.position.set(d.position.x, d.position.y, d.position.z);

  AvatarView.controls.target.set(d.target.x, d.target.y, d.target.z);
  AvatarView.controls.update();

  AvatarView.renderer.domElement.style.display = 'none';
  const file = await render();
  AvatarView.is_rendering_active = true;

  // Restore
  AvatarView.onWindowResize();
  setCamera(c);
  AvatarView.renderer.domElement.style.display = 'initial';

  // save_file(file, '122.png');
  return file;
}

export async function exportAvatar(): Promise<ExportAvatarResult> {
  if (window.avaturnForceExportHttpUrl) {
    developerConfig.export_mode = ExportType.HttpURL;
  }

  avatars.setExportHandler(_getHttpUrlLocalUpload);

  switch (developerConfig.export_mode) {
    case ExportType.DataURL: {
      const blob = await generateModel();
      await send_message_with_avatar(blob!, AvatarView.is_animatable);
      return { type: ExportType.DataURL, data: null };
    }
    case ExportType.HttpURL: {
      return avatarClient.createUrlExport(auth.data?.token || null, page_params.customization_id).then(async (data) => {
        // API returns status, if the avatar has already been exported, then status is ready
        // if it was exported but failed, then 'failed'

        if (data.status === ExportStatus.Failed) {
          // Fall back to upload from frontend
          return await _getHttpUrlLocalUpload();
        }

        if (data.status === ExportStatus.Ready) {
          sendMessageWithHttpUrl(data.url, AvatarView.is_animatable);
        }

        return { type: ExportType.HttpURL, data };
      });
    }
    default: {
      return Promise.reject(new Error('Unknown export type'));
    }
  }
}

export async function generateModel(deactivate_rendering = true, generateModelParams: GenerateModelParams = {}) {
  if (deactivate_rendering) {
    AvatarView.is_rendering_active = false;
  }
  const blob = await generate_model(generateModelParams);

  if (deactivate_rendering) {
    AvatarView.is_rendering_active = true;
  }
  return blob;
}

export async function downloadModel(generateModelParams: GenerateModelParams = {}) {
  const blob = await generateModel(true, generateModelParams);
  shareOrDownloadToDisk(blob, 'model.glb');
}

export async function takeScreenshotAndSave(mode: 'upload' | 'download') {
  const strMime = 'image/jpeg';
  const dataURL = await takeScreenshot();

  try {
    if (mode === 'upload') {
      const uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
      upload_to_firebase(dataURItoBlob(dataURL), strMime, `images/${uniqueId}.jpg`);

      downloadFileToDisk(dataURL.replace(strMime, 'image/octet-stream'), 'screenshot.jpg');
    } else {
      shareOrDownloadToDisk(dataURItoBlob(dataURL), 'avaturn_screenshot.jpg');
    }
  } catch (e) {
    console.log(e);
    return;
  }
}
