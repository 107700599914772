import { Modal, BodyOptions } from '@in3d/components';
import { adminClient, NewAssetModel } from '@in3d/api';
import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { changeAnimation, changeBody, changeCloth, init_editor, resources } from '@in3d/core';
import {
  CategoryName,
  animations,
  cn,
  vibrate,
  AssetData,
  ResourcesPlacement,
  PROJECT_PARAM,
  categoryToType,
} from '@in3d/common';
import { AnimatePresence, motion } from 'framer-motion';
import SVG from 'react-inlinesvg';
import { Upload } from './upload';

import './admin.scss';
import { Cloth } from 'libs/core/src/lib/core/resources/assets';
import { auth } from '@in3d/store';
import { AdminCard } from './admin-card';
import { Edit } from './edit';
import { useSearchParams } from 'react-router-dom';
import { AssetListType, useProjcetAssets } from '../../hooks';
import { useCategories } from '../../hooks/use-categories.hook';
import { AssetsContext } from '../../context/assets';

const Admin = () => {
  const [params] = useSearchParams();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [uploading, setUploading] = useState<Record<string, boolean>>({});
  const [editedItem, setEditedItem] = useState<string | null>(null);
  const [activeCategory, setActiveCategory] = useState<CategoryName>(CategoryName.Outfit);
  const [listType, setListType] = useState<AssetListType>(AssetListType.Project);
  const [isImporting, setIsImporting] = useState(false);

  const project = useMemo(() => params.get(PROJECT_PARAM) || 'demo', [params]);
  const categories = useCategories();
  const assets = useProjcetAssets({ project, type: listType });

  const sceneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!assets.loaded) {
      assets.load().then((list) => {
        if (sceneRef.current && list.length > 0) {
          init_editor(sceneRef.current);
        }
      });
    }
  }, [sceneRef, assets]);

  const isCategoryEmpty = (category: CategoryName) => {
    return assets.listByCategory[category].length < 1;
  };
  const isCategoryEditable = (category: CategoryName) => {
    return (
      listType === AssetListType.Project &&
      [CategoryName.Hair, CategoryName.Outfit, CategoryName.Glasses, CategoryName.Animations].includes(category)
    );
  };
  const handleCategoryClick = (category: (typeof categories)[0]) => {
    vibrate(50);
    setActiveCategory(category.name);
  };
  const handleSaveAsset = async (id: string) => {
    const asset = Object.values(resources.clothes)
      .flat()
      .find((e) => e.id === id) as Cloth;

    setUploading((prev) => ({ ...prev, [id]: true }));

    console.log('Saving new asset: ', asset.placement, asset.type);
    const newAsset: NewAssetModel = {
      preview_url: '',
      alias: '',
      gender: asset.type === 'animations' ? 'unisex' : 'male',
      glb_url: asset.glb_url,
      glb_female_url: asset.glb_url,
      material_preset: asset.material_preset,
      settings: asset.settings_json || {},
      type: asset.type as NewAssetModel['type'],
    };
    const blob = await fetch(asset.glb_url).then((res) => res.blob());
    adminClient
      .uploadGlb(auth.token, blob, { name: asset.name, slug: params.get('project') || 'elijah', type: 'assets' })
      .then((new_url) => {
        adminClient.saveNewAsset(auth.token, { ...newAsset, glb_url: new_url, glb_female_url: new_url });
        assets.load();
      });
  };

  const handleOpenEdit = (id: string) => {
    setIsEditOpen(true);
    setEditedItem(id);
  };

  const handleUpdateAsset = (asset: Cloth) => {
    const assetUpdate: NewAssetModel = {
      preview_url: asset.preview_url,
      alias: asset.id,
      gender: 'male',
      glb_url: asset.glb_url,
      glb_female_url: asset.glb_url,
      material_preset: asset.material_preset,
      settings: asset.settings_json,
      type: asset.type as NewAssetModel['type'],
    };
    return adminClient.updateAsset(auth.token, asset.id, assetUpdate).then(() => {
      return; //updateAssets();
    });
  };
  const handleImportDefaults = (category: CategoryName) => {
    adminClient
      .importDefaults(auth.token, categoryToType(category), project)
      .then(() => {
        assets.load();
      })
      .catch((err) => {
        console.log('Import error: ', err);
      });
  };
  const changeAsset = (item: AssetData) => {
    console.log('Change item: ', item);
    if (item.type === ResourcesPlacement.Animations) {
      changeAnimation(item.id);
    } else {
      changeCloth(item.id);
    }
  };
  const handleDelete = (id: string) => {
    assets.delete(id).then(() => {
      setIsEditOpen(false);
    });
  };
  const handleClickAsset = (item: AssetData) => {
    if (listType === 'market') {
      assets.shareToProject(item.id);
    } else changeAsset(item);
  };
  return (
    <AssetsContext.Provider value={assets}>
      <div className="admin">
        <div className="admin__scene scene">
          <div className="scene__content" ref={sceneRef} />
        </div>
        <div className="admin__panel admin-panel">
          <div className="editor-category">
            <div className="editor-category__main editor-category__main--small admin-panel__toggle">
              <button
                onClick={() => setListType(AssetListType.Project)}
                className={cn(['editor-category__toggle', listType === 'project' && 'active'])}
              >
                {project}
              </button>
              <button
                onClick={() => setListType(AssetListType.Market)}
                className={cn(['editor-category__toggle', listType === 'market' && 'active'])}
              >
                Market
              </button>
            </div>
            <div className="editor-category__main">
              {categories.map((category, i) => (
                <button
                  key={i}
                  onClick={() => handleCategoryClick(category)}
                  className={cn(['editor-category__variant', activeCategory === category.name && 'active'])}
                >
                  {category.icon}
                </button>
              ))}
            </div>
          </div>
          {!assets.loaded && <div className="admin-panel__loader">Loading...</div>}
          <div className="admin-panel__content">
            {assets.loaded &&
              categories.map((category) => (
                <>
                  <AnimatePresence>
                    {activeCategory === CategoryName.Body && (
                      <BodyOptions
                        className="admin-panel__body"
                        list={resources.avatars}
                        onChangeBody={(type, id) => changeBody(id, type)}
                        key={CategoryName.Body}
                        loading={false}
                      />
                    )}
                  </AnimatePresence>
                  <AnimatePresence key={category.name}>
                    {category.name !== CategoryName.Body && category.name === activeCategory && (
                      <motion.div {...animations.slide} className={cn(['admin-panel__list'])} key={category.name}>
                        {isCategoryEmpty(category.name) && listType !== 'market' && (
                          <div className="admin-defaults">
                            <span className="admin-defaults__text">There is no assets in this category.</span>
                            <div className="admin-defaults__actions">
                              <button
                                disabled={isImporting}
                                className="admin-defaults__btn"
                                onClick={() => handleImportDefaults(category.name)}
                              >
                                {!isImporting ? 'Import defaults' : 'Importing...'}
                              </button>
                              {isCategoryEditable(category.name) && (
                                <button className="admin-defaults__btn" onClick={() => setIsCreateOpen(true)}>
                                  Add new asset
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                        {!isCategoryEmpty(activeCategory) && (
                          <>
                            {isCategoryEditable(activeCategory) && (
                              <div className="admin-card active add-card" onClick={() => setIsCreateOpen(true)}>
                                <div className="admin-card__content">
                                  <SVG src="/assets/scan/plus.svg" />
                                </div>
                              </div>
                            )}
                            {assets.listByCategory[activeCategory].map((item) => (
                              <AdminCard
                                editable={(!!item?.isDraft && item.type !== 'animations') || listType === 'project'}
                                key={item.id}
                                showSaveButton={!!item?.isDraft}
                                id={item.id}
                                active={listType === AssetListType.Project || !!assets.projectListById[item.id]}
                                loading={uploading[item.id]}
                                preview={item.preview_url}
                                onUpdate={() => handleOpenEdit(item.id)}
                                onClick={() => handleClickAsset(item as AssetData)}
                                onSave={() => handleSaveAsset(item.id)}
                              />
                            ))}
                          </>
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </>
              ))}
          </div>
        </div>
        {isCreateOpen && (
          <Modal title={`Upload new ${activeCategory}`} onClose={() => setIsCreateOpen(false)}>
            <Upload category={activeCategory} onDone={() => setIsCreateOpen(false)} />
          </Modal>
        )}
        {isEditOpen && editedItem && (
          <Modal title="Update asset" onClose={() => setIsEditOpen(false)}>
            <Edit
              item={editedItem}
              onDelete={() => handleDelete(editedItem)}
              onSave={handleUpdateAsset}
              onCancel={() => setIsEditOpen(false)}
            />
          </Modal>
        )}
      </div>
    </AssetsContext.Provider>
  );
};
const _Admin = observer(Admin);
export { _Admin as Admin };
