export class CanvasRecorder {
  private stream: MediaStream;
  private recordedBlobs: Blob[] = [];
  private supportedType: string;
  private  mediaRecorder: MediaRecorder;
  private  canvas: HTMLCanvasElement;

  setCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
  }

  // captureMediaStream = (mediaStream)  {
  //   stream = mediaStream
  // }

  // const recordScreen = async () => {
  //   stream = await navigator.mediaDevices.getDisplayMedia({
  //     video: { mediaSource: 'screen' }
  //   })
  // }

  start(bps = 80000000, fps = 60) {
    this.recordedBlobs = [];
    this.stream = this.canvas.captureStream(fps);

    const types = ['video/webm;codecs=h264', 'video/webm;codecs=vp9',  'video/webm', 'video/mp4'];
    for (const i in types) {
      if (MediaRecorder.isTypeSupported(types[i])) {
        this.supportedType = types[i];
        break;
      } else {
        console.log('Not supported' + types[i]);
      }
    }
    if (this.supportedType == null) {
      console.log('No supported type found for MediaRecorder');
    }
    const options = {
      mimeType: this.supportedType,
      videoBitsPerSecond: bps, // 0.05Mbps
    };

    try {
      this.mediaRecorder = new MediaRecorder(this.stream, options);
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      alert('MediaRecorder is not supported by this browser.');
      return;
    }
    this.mediaRecorder.onstop = this.handleStop.bind(this);
    this.mediaRecorder.ondataavailable = this.handleDataAvailable.bind(this);
    this.mediaRecorder.start(100); // collect 100ms of data blobs
  }
  handleDataAvailable(event: BlobEvent) {
    if (event.data && event.data.size > 0) {
      this.recordedBlobs.push(event.data);
    }
  }
  handleStop(event: Event) {
    const superBuffer = new Blob(this.recordedBlobs, { type: this.supportedType });
  }

  stop() {
    this.mediaRecorder.stop();
  }

  save() {
    return new Blob(this.recordedBlobs, { type: this.supportedType });
  }
}
export const canvasRecorder = new CanvasRecorder();
