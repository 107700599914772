import { ClientSettings } from '@in3d/api';
import axios from 'axios';
import env from '@in3d/environment';

export const createSettings = (baseURL = env.baseUrl): ClientSettings => {
  const instance = axios.create({ baseURL });
  instance.interceptors.response.use(
    (res) => res.data,
    (err) => Promise.reject(err)
  );
  return {
    fetcher: instance,
  };
};
