import {
  BufferGeometry,
  Euler,
  Intersection,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  PerspectiveCamera,
  Raycaster,
  Scene,
  SkinnedMesh,
  Texture,
  TextureLoader,
  Vector2,
  Vector3,
  WebGLRenderer,
} from 'three';

import { customizationUpdater } from '../../../addons/customization_updater';
import { currentState } from '../../CurrentState';
import { Cloth } from '../assets';
import { Slots } from '../common';
import { exportDecalData, loadDecalData, loadDecalPresetFromFile } from './decal-maker_files';
import { DecalGeometry } from './DecalGeometry';
import { projectDecal } from './project_decal';
import { prepareRenderer, restoreRenderer } from './renderer';
import { Decal, Piece, RendererProps } from './types';
import { unprojectDecal } from './unprojectDecal';

export const allowedClothes = [
  'basketball',
  'cowboy_cloth2',
  'tshirt_new',
  'eikonikos_bronze_hoodie',
  'polo_jeans',
  'teenage_2',
  'summer_outfit',
  'shirt',
  'T-Shirt+Denim_Outfit',
  // 'high_waist_pants',
  // "christmas_panda",
  // "military_woman",
  'F_Streetwear_Outfit'
]

export class DecalMaker {
  raycaster: Raycaster;
  mouseHelper: Object3D;
  scene: Scene;
  avatar: Object3D;
  intersection: {
    intersects: boolean;
    point: Vector3;
    normal: Vector3;
    object: Object3D | undefined;
  };
  rect: DOMRect;
  camera: PerspectiveCamera;
  mouse: Vector2 = new Vector2();
  decals: Decal[] = [];
  active = false;
  positionsBackup: Map<Object3D, Float32Array>;
  decalMap: Texture | undefined;
  renderer: WebGLRenderer;
  intersects: Intersection[] = [];
  decalHelper: Mesh | undefined;
  piece: string | undefined;
  pieceRemoteURL: string | undefined;
  mask: string | undefined;
  maskRemoteURL =
    'https://firebasestorage.googleapis.com/v0/b/in3d-web-avatar-staging.appspot.com/o/images%2Flisjxvebvknp4hdv47i_mask.jpg?alt=media&token=35d705ba-8629-47d3-8835-fabbc82058cf';
  rendererProps: RendererProps | undefined;
  scale = 1.3;
  prepareRenderer: () => void;
  restoreRenderer: () => void;
  unprojectDecal: typeof unprojectDecal;
  projectDecal: typeof projectDecal;
  loader: TextureLoader;
  canvas: HTMLCanvasElement;
  exportDecalData: () => void;
  loadDecalData: () => void;
  loadDecalPresetFromFile: () => Promise<void>;
  pieces: Piece[] = [];

  constructor(scene: Scene, avatar: Object3D, camera: PerspectiveCamera, renderer: WebGLRenderer) {
    this.scene = scene;
    this.camera = camera;
    this.avatar = avatar;
    this.renderer = renderer;

    this.positionsBackup = new Map();

    const mouseHelper = new Object3D();
    mouseHelper.visible = false;
    this.mouseHelper = mouseHelper;

    const raycaster = new Raycaster();
    this.raycaster = raycaster;

    this.intersection = {
      intersects: false,
      point: new Vector3(),
      normal: new Vector3(),
      object: undefined,
    };

    const canvas = renderer.domElement;
    this.canvas = canvas;

    this.prepareRenderer = prepareRenderer.bind(this);
    this.restoreRenderer = restoreRenderer.bind(this);
    this.unprojectDecal = unprojectDecal.bind(this);
    this.projectDecal = projectDecal.bind(this);
    this.loadDecalData = loadDecalData.bind(this);
    this.exportDecalData = exportDecalData.bind(this);
    this.loadDecalPresetFromFile = loadDecalPresetFromFile.bind(this);

    scene.add(mouseHelper);

    this.checkCustomization();
  }

  loadDecalMap = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*'; // Optionally, restrict the user to select only image files
    input.onchange = (e: Event) => {
      if (!e.target) return;
      const fileInput = e.target as HTMLInputElement;
      if (!fileInput.files) return;
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result === 'string') {
          const img = new Image();
          img.onload = () => {
            const texture = new Texture(img);
            texture.needsUpdate = true;
            this.decalMap = texture;
          };
          img.src = result;
        }
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    };
    input.click();
  };

  checkCustomization = () => {
    const decals = customizationUpdater.get_decals_customization();
    if (decals.length !== 0 && this.pieces.length < decals.length) {
      // copy array
      this.pieces = [...decals];
    }
    if (!currentState.cloth[Slots.Top]) {
      // console.log('no top cloth');
      return;
    }
    if (this.pieces.length === 0) return;
    const id = currentState.cloth[Slots.Top]?.id;

    // check if pieces contain element with cloth_id === id
    // we probably should check from the end of the array

    for (let i = this.pieces.length - 1; i >= 0; i--) {
      if (this.pieces[i].cloth_id === id) {
        const piece = this.pieces[i];
        this.piece = piece.cloth_id;
        this.pieceRemoteURL = piece.url;
        this.loadDecal(piece.url);
        break;
      }
    }
  };

  loadDecal = async (url: string) => {
    // new_cloth.placement;
    await this.loadPreparedDecals();


    this.applyInpaintedPiece(url);
  };

  backupPositions = () => {
    // 0. Clear previous decals
    this.positionsBackup.clear();
    this.clearDecals();
    this.rect = this.canvas.getBoundingClientRect();

    // 1. Backup original vertex positions
    // Assuming 'group' is your Group object
    const { avatar } = this;
    const { positionsBackup } = this;

    avatar.traverse((object) => {
      if (object instanceof SkinnedMesh) {
        const { geometry } = object;
        if (geometry instanceof BufferGeometry && geometry.attributes['position']) {
          positionsBackup.set(object, new Float32Array(geometry.attributes['position'].array));
        }
      }
    });

    // 2. считаешь эти позишены функцией
    // и записываешь их в геометрию
    const helper = new Vector3();
    positionsBackup.forEach((_, mesh) => {
      const { geometry } = mesh as SkinnedMesh;
      const positionAttribute = geometry.attributes['position'];

      if (geometry instanceof BufferGeometry && positionAttribute) {
        for (let i = 0; i < positionAttribute.count; i++) {
          (mesh as Mesh).getVertexPosition(i, helper);
          positionAttribute.setXYZ(i, helper.x, helper.y, helper.z);
        }
        positionAttribute.needsUpdate = true;
        geometry.computeVertexNormals();
      }
    });

    // 3. делаешь mesh.pose()
    // анимацию выключать только лучше не через .pose() а currentState.resetAnimation()
    currentState.resetAnimation();
  };

  initEditingMode = () => {
    this.backupPositions();
    this.active = true;
  };

  bakeDecal = () => {
    // Assuming 'decal' is an object with 'mesh' and 'parent' properties
    if (this.decals.length === 0) return;
    const { mesh, parent } = this.decals[0];

    // Check if 'mesh' and 'parent' are defined
    if (!mesh || !parent) return;

    // Initialize a variable to hold the 'cloth'
    let cloth: Object3D;

    // Traverse the avatar's object tree to find the parent
    this.avatar.traverse((child) => {
      if (child.name === parent.name) {
        cloth = child as Mesh

        // Check if 'cloth' is a Mesh with a MeshStandardMaterial or MeshBasicMaterial
        if (cloth instanceof Mesh && cloth.material && cloth.material.isMaterial) {
          // Get its material.map
          if (!cloth.material.map || !this.decalMap) return;
          const clothObject = currentState.cloth[Slots.Top] as Cloth;

          const initialTexture = clothObject.origDiffuseMap
          this.bakeTexture(initialTexture, mesh, clothObject, cloth as Mesh);

        }
      }
    });
  };

  bakeTexture = (initialTexture: Texture, mesh: Mesh, clothObject: Cloth, cloth: Mesh) => {
    if (!this.decalMap) return;
    const decalsGeometry = mesh.geometry;

    const texture = this.projectDecal(initialTexture, decalsGeometry, this.decalMap);

    const material = (cloth as Mesh).material as MeshStandardMaterial;
    material.map = texture;
    material.needsUpdate = true;

    texture.userData["decal"] = true

    if (clothObject && this.pieceRemoteURL) {
      clothObject.decalMapUrl = this.pieceRemoteURL;

      // this way we will save all generated pieces in chronological order
      // if there is no piece with this url, we add it
      if (!this.pieces.find((p) => p.url === this.pieceRemoteURL)) {
        this.pieces.push({
          cloth_id: clothObject.id,
          url: this.pieceRemoteURL,
        });
        customizationUpdater.update_customization();
      }
    }

  }

  getDecals = () => {
    return this.pieces;
  };

  carveMaskedPiece = () => {
    if (!this.active) return;

    // Find the group with name "avaturn_look" in the avatar's children
    if (this.decals.length === 0) return;
    const { mesh, parent } = this.decals[0];

    // Check if 'mesh' and 'parent' are defined
    if (!mesh || !parent) return;

    // Initialize a variable to hold the 'cloth'
    let cloth: Object3D;

    // Traverse the avatar's object tree to find the parent
    this.avatar.traverse((child) => {
      if (child.name === parent.name) {
        cloth = child;

        // Check if the first child is a Mesh with a MeshStandardMaterial or MeshBasicMaterial
        if (cloth instanceof Mesh && cloth.material && cloth.material.isMaterial) {
          // Get its material.map
          const clothObject = currentState.cloth[Slots.Top] as Cloth;
          if (!clothObject.origDiffuseMap) return;
          const initialTexture = clothObject.origDiffuseMap

          // all decals
          const { decals } = this;
          if (decals.length === 0) return;
          const { mesh, parent } = decals[0];

          if (!mesh) return;
          // if parent name doesn't contain 'avaturn_look' - skip
          if (!parent.name.includes('avaturn_look')) return;
          const decalsGeometry = mesh.geometry;

          // здесь нужно
          // вырезать маску
          const piece = this.unprojectDecal(initialTexture, decalsGeometry);
          this.piece = piece;
        }
      }
    });
  };

  check = (e: PointerEvent) => {
    if (!this.active) return;
    const { clientX, clientY } = e;

    if (this.avatar === undefined) return;

    const { avatar, raycaster, mouseHelper, camera, intersection, rect, mouse, intersects } = this;

    if (!rect) return;
    const x = clientX - rect.left;
    const y = clientY - rect.top;

    mouse.x = (x / rect.width) * 2 - 1;
    mouse.y = -(y / rect.height) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);
    raycaster.intersectObject(avatar, true, intersects);

    if (intersects.length > 0) {
      let intersect;
      const helper = intersects[0].object as Mesh;
      if (helper.name === 'decalHelper') {
        intersect = intersects[1];
      } else {
        intersect = intersects[0];
      }

      const p = intersect.point;
      mouseHelper.position.copy(p);
      intersection.point.copy(p);

      if (!intersect?.face?.normal) return;
      const n = intersect.face.normal.clone();
      n.transformDirection(avatar.matrixWorld);
      n.multiplyScalar(10);
      n.add(intersect.point);

      intersection.normal.copy(intersect.face.normal);
      mouseHelper.lookAt(n);

      intersection.object = intersect.object;

      intersection.intersects = true;
      intersects.length = 0;
      this.updateHelper();
    } else {
      intersection.intersects = false;
      if (!this.decalHelper) return;
      this.decalHelper.visible = false;
    }
  };

  updateHelper = () => {
    const { scene, intersection, mouseHelper, scale } = this;
    if (!intersection.intersects || !mouseHelper) return;

    const position = new Vector3();
    const orientation = new Euler();

    position.copy(intersection.point);
    orientation.copy(mouseHelper.rotation);


    const size = new Vector3(scale, scale, scale).divideScalar(5);
    const object = intersection.object as Mesh;
    const geometry = new DecalGeometry(object, position, orientation, size);

    const material = new MeshBasicMaterial({
      polygonOffset: true,
      polygonOffsetFactor: -4,
      color: 'red',
    });

    if (this.decalHelper) {
      // dispose old decal helper
      this.decalHelper.geometry.dispose();
      const material = this.decalHelper.material as MeshBasicMaterial;
      material.dispose();
      scene.remove(this.decalHelper);
    }

    const m = new Mesh(geometry, material);
    scene.add(m);
    this.decalHelper = m;
    m.name = 'decalHelper';
  };

  shoot = () => {
    if (!this.active) return;
    const { decals, scene, intersection, scale } = this;
    if (!intersection.intersects) return;

    const position = new Vector3();
    const orientation = new Euler();

    position.copy(intersection.point);

    if (!this.mouseHelper) return;
    orientation.copy(this.mouseHelper.rotation);

    const size = new Vector3(scale, scale, scale).divideScalar(5);
    // const scale = new Vector3(decalWidth, decalHeight, decalDepth).divideScalar(10);

    const object = intersection.object as Mesh;

    const material = new MeshBasicMaterial({
      polygonOffset: true,
      polygonOffsetFactor: -4,
      color: 'red',
      transparent: true,
    });

    const m = new Mesh(new DecalGeometry(object, position, orientation, size), material);

    decals.push({
      mesh: m,
      parent: object,
    });
    scene.add(m);

    this.carveMaskedPiece();
    this.exitEditingMode();
  };

  exitEditingMode = () => {
    this.active = false;
    if (!this.decalHelper) return;
    this.decalHelper.visible = false;
  };

  clearDecals = () => {
    // удаляем все декали из сцены
    this.decals.forEach((decal) => this.scene.remove(decal.mesh));
    this.decals = [];
  };

  async loadPreparedDecals() {
    if (this.decals.length !== 0) {
      const { clothName } = this.decals[0];
      if (clothName !== undefined && clothName === currentState.cloth[Slots.Top]?.id) return;
    }

    this.clearDecals();

    const clothes = currentState.cloth[Slots.Top]?.id;
    if (!clothes || !allowedClothes.includes(clothes)) return; // if no decal is present, load it from file

    // if decal.parent name is not equal to clothes name — load it from file
    await this.loadDecalPresetFromFile();

    // then carve masked piece
    this.active = true;
    this.carveMaskedPiece();
    this.exitEditingMode(); // на всякий случай
  }

  applyInpaintedPiece = async (imageUrl: string) => {
    this.pieceRemoteURL = imageUrl;

    // take image
    // load to texture
    if (!this.loader) this.loader = new TextureLoader();

    this.loader.load(imageUrl, (tex) => {
      tex.needsUpdate = true;

      this.decalMap = tex;

      // project to initial texture
      // using bakeDecal function
      this.bakeDecal();

      // this.clearDecals();
      // console.log(this.avatar)
    });
  };
}
