import { ClientBuilder, createSettings } from './api';
import env from '@in3d/environment';
import { DeveloperSettings, ExportType } from './settings';

export interface DeveloperProject {
  slug: string;
  settings: DeveloperSettings;
}

export const PAID_FUTURES: Array<keyof DeveloperSettings> = ['disable_loader'];

const get_headers = (token?: string) => ({
  Authorization: `Bearer ${token}`,
});

class DevPortalClient {
  constructor(api: ClientBuilder) {
    this.api = api;
  }

  createSubscription(token: string, options: { slug: string; success_url: string; cancel_url: string }): Promise<any> {
    const params = new URLSearchParams();
    params.set('success_url', options.success_url);
    params.set('cancel_url', options.cancel_url);
    return this.api.get(
      `/projects/${options.slug}/subscription-checkout-url?${params.toString()}`,
      null,
      get_headers(token)
    );
  }
  checkSubscriptionStatus(token: string, slug: string): Promise<any> {
    return this.api.get(`/projects/${slug}/check-subscription`, null, get_headers(token));
  }
  getWebHookPortalURL(token: string, slug: string): Promise<any> {
    return this.api.get(`/projects/${slug}/svix-portal-url`, null, get_headers(token));
  }
  getProjectsList(token: string): Promise<DeveloperProject[]> {
    return this.api.get('/projects', null, get_headers(token));
  }
  createProject(token: string, id: DeveloperProject['slug']): Promise<any> {
    return this.api.post('/projects/' + id, { export_mode: ExportType.DataURL }, get_headers(token));
  }
  updateProject(token: string, id: DeveloperProject['slug'], settings: DeveloperSettings): Promise<string> {
    return this.api.put('/projects/' + id, settings, get_headers(token));
  }
  deleteProject(token: string, id: DeveloperProject['slug']): Promise<any> {
    return this.api.delete('/projects/' + id, null, get_headers(token));
  }

  generateProjectToken(token: string, id: DeveloperProject['slug']): Promise<string> {
    return this.api.post('/projects/' + id + '/generate-token', null, get_headers(token));
  }

  api: ClientBuilder;
}

export const devPortalClient = new DevPortalClient(new ClientBuilder(createSettings(env.baseUrl)));
