import './option-card.scss';

import { cn, getDeveloperName, getHostname } from '@in3d/common';
import { CategoryName, allowedClothes as customizableClothes, resources } from '@in3d/core';
import env from '@in3d/environment';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import sparksIcon from './sparks.svg';
import faceAnim from './face_animation.svg';
import { reaction } from 'mobx';

interface OptionCardProps {
  loading?: boolean;
  loaded?: boolean;
  activeList?: { [key in CategoryName]: string };
  id: string;
  category: CategoryName;
  resource: any;
  onClick: (id: string, category: string) => void;
  onChangeColor?: (category: CategoryName, color: string) => void;
  onBuy: (id: string) => void;
}

const OptionCard: React.FC<OptionCardProps> = ({ category, id, loading, activeList, loaded, resource, onClick }) => {
  const isLoaded = loaded || resource.is_downloaded;
  const isLoading = loading || resource.is_loading;
  const isActive = activeList && activeList[category] === id;

  const isRootHost = env.rootHosts.includes(getHostname());

  const isCustomizable = useMemo(() => {
    if (category === CategoryName.Outfit) {
      // if id is in customizableClothes, then it's a customizable outfit
      if (customizableClothes.includes(id)) return true;
    }
    return false;
  }, [id, category]);

  const [isAnimatable, setAnimatable] = useState(false);
  useEffect(() => {
    const dispose = reaction(
      () => resources.isAnimatable,
      (isAnimatable) => setAnimatable(isAnimatable)
    );

    return () => dispose();
  }, []);

  return (
    <div
      onClick={() => onClick(id, category)}
      className={cn([
        'option-card',
        category && category,
        isActive && 'active',
        isLoading && 'loading',
        !isLoaded && 'not-loaded',
      ])}
    >
      {isLoading && (
        <div className="option-card__loader">
          <span />
        </div>
      )}
      {(env.debugShowIdsOnCards || getDeveloperName() == 'old-assets') && (
        <div className="option-card__debug">{resource.id}</div>
      )}
      {resource.id.startsWith('kinetix_') && (
        <img src="https://assets.avaturn.me/kinetix_1d5cf456c5.png" alt="" className="option-card__kinetix" />
      )}
      {isRootHost && isCustomizable && (
        <div className="option-card__indicator">
          <img src={sparksIcon} alt="" />
        </div>
      )}
      <div className={cn(['option-card__wrapper', category])}>
        <img className={'option-card__img'} src={resource.preview_url || faceAnim} alt={category} />
      </div>

      {/* {getHostname() == 'creepyfriends.avaturn.dev' && category == 'outfit' && handleBuyButton && (
        <button
          // ref={setColorBtnElement}
          onClick={handleBuyButton}
          className="buy-button"
        >
          Buy
        </button>
      )} */}
    </div>
  );
};

const _OptionCard = observer(OptionCard);
export { _OptionCard as OptionCard };
