import './editor.scss';

import { animations, cn, getHostname, vibrate } from '@in3d/common';
import { changeAsset, changeBody, editorParams } from '@in3d/core';
import { resources } from '@in3d/core';
import { useIsMobile } from '@in3d/hooks';
import { developerConfig } from '@in3d/store';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { ReactComponent as Animations } from './assets/animations.svg';
import { ReactComponent as Body } from './assets/body.svg';
import { ReactComponent as FaceAnimations } from './assets/face_animations.svg';
import { ReactComponent as Glasses } from './assets/glasses.svg';
import { ReactComponent as Hair } from './assets/hair.svg';
import { ReactComponent as Outfit } from './assets/outfit.svg';
import { ReactComponent as Shoes } from './assets/shoes.svg';
import { BodyOptions } from './body-options';
import { BodyAnimationsPanel } from './editor/body-animations-panel';
import { EditorPanel } from './editor/editor-panel';
import { FaceAnimationsPanel } from './editor/face-animations-panel';
import { GlassesOptions } from './editor/glasses-options';
import { HairOptions } from './editor/hair-options';
import { OptionCard } from './option-card/option-card';
import { reaction } from 'mobx';
import env from '@in3d/environment';

enum CategoryName {
  Body = 'body',
  Outfit = 'outfit',
  Hair = 'hair',
  Glasses = 'glasses',
  Shoes = 'shoes',
  Animations = 'animations',
  FaceAnimations = 'face_animations',
  // Decals = 'decals',
}

function insertIf(condition: boolean, ...elements: any) {
  // (A)
  return condition ? elements : [];
}

const Editor: React.FC<{ children?: React.ReactElement | boolean }> = ({ children }) => {
  const needBodyTab = getHostname() !== 'artisant-static.avaturn.dev';

  const [activeCategory, setActiveCategory] = useState<CategoryName>(
    needBodyTab ? CategoryName.Body : CategoryName.Outfit
  );

  const [isAnimatable, setAnimatable] = useState(false);
  useEffect(() => {
    const dispose = reaction(
      () => resources.isAnimatable,
      (isAnimatable) => setAnimatable(isAnimatable)
    );

    return () => dispose();
  }, []);

  const isRootHost = env.rootHosts.includes(getHostname());

  const categories = [
    { name: CategoryName.Hair, icon: <Hair /> },
    { name: CategoryName.Outfit, icon: <Outfit /> },
    { name: CategoryName.Glasses, icon: <Glasses /> },
    { name: CategoryName.Shoes, icon: <Shoes /> },
    ...insertIf(!!developerConfig.editor.withAnimationTab, { name: CategoryName.Animations, icon: <Animations /> }),
    ...insertIf(isAnimatable && isRootHost, {
      name: CategoryName.FaceAnimations,
      icon: <FaceAnimations />,
    }),
  ];

  const handleChangeColor = (category: CategoryName, color: string) => {
    if (category === CategoryName.Hair) {
      editorParams.hairColor = color;
    }
    if (category === CategoryName.Glasses) {
      editorParams.glassesColor = color;
    }
  };

  const sendMessageForBuy = (id: string) => {
    const message = JSON.stringify({
      source: 'avaturn',
      eventName: 'v2.asset.buy',
      date: Date.now(),
      data: {
        id: id,
      },
    });
    window.parent.postMessage(message, '*');
  };

  const showColors = [CategoryName.Glasses, CategoryName.Hair].includes(activeCategory);

  const optionCards = resources.list[activeCategory].map((item) => (
    <OptionCard
      key={item.id}
      id={item.id}
      onClick={() => changeAsset(activeCategory, item.id)}
      onChangeColor={handleChangeColor}
      onBuy={sendMessageForBuy}
      category={activeCategory}
      resource={item}
      activeList={resources.active}
    />
  ));

  const isMobile = useIsMobile();

  return (
    <div className="editor">
      {showColors && <EditorPanel activeCategory={activeCategory} handleChangeColor={handleChangeColor} />}
      <div className="editor-category">
        <div className="editor-category__main">
          {needBodyTab && (
            <button
              disabled={!resources.isSceneLoaded}
              onClick={() => {
                setActiveCategory(CategoryName.Body);
                vibrate(50);
              }}
              className={cn(['editor-category__variant', activeCategory === CategoryName.Body && 'active'])}
            >
              <Body />
            </button>
          )}
          {categories.map((category, i) => (
            <button
              key={i}
              disabled={!resources.isSceneLoaded}
              onClick={() => {
                vibrate(50);
                setActiveCategory(category.name);
              }}
              className={cn([
                category.name === CategoryName.Animations ? 'editor-animations' : 'editor-category__variant',
                activeCategory === category.name && 'active',
              ])}
            >
              {category.icon}
            </button>
          ))}
        </div>
      </div>
      <div className="editor-options">
        <AnimatePresence>
          {activeCategory === CategoryName.Body && (
            <BodyOptions
              list={resources.avatars}
              onChangeBody={(type, id) => changeBody(id, type)}
              key={CategoryName.Body}
              loading={!resources.isSceneLoaded}
            />
          )}
        </AnimatePresence>
        {categories.map((category) => (
          <AnimatePresence key={category.name}>
            {category.name === activeCategory && (
              <motion.div
                {...animations.slide}
                className={cn([
                  'editor-options__list',
                  !resources.isSceneLoaded && 'editor-options__list--loading',
                  `editor-options__list-${category.name}`,
                ])}
              >
                {activeCategory === CategoryName.Hair ? (
                  <HairOptions
                    isMobile={isMobile}
                    handleChangeColor={handleChangeColor}
                    key={CategoryName.Hair}
                    children={optionCards}
                  />
                ) : activeCategory === CategoryName.Glasses ? (
                  <GlassesOptions
                    isMobile={isMobile}
                    handleChangeColor={handleChangeColor}
                    key={CategoryName.Glasses}
                    children={optionCards}
                  />
                ) : activeCategory === CategoryName.FaceAnimations ? (
                  <FaceAnimationsPanel optionCards={optionCards} />
                ) : activeCategory === CategoryName.Animations ? (
                  <BodyAnimationsPanel optionCards={optionCards} />
                ) : (
                  optionCards
                )}
              </motion.div>
            )}
          </AnimatePresence>
        ))}
      </div>
      {children}
    </div>
  );
};

const _Editor = observer(Editor);
export { _Editor as Editor };
