import { MessageType } from '..';
export interface SdkMessage<DataType, EventType> {
  type: string;
  source: string;
  eventName: EventType;
  key: string;
  date: number;
  data?: DataType;
  isOk: boolean;
}

class ResponseQueue {
  listeners: { [key: string | symbol]: (isOk: boolean, data: any) => void } = {};
  watch(key: string | symbol, cb: (isOk: boolean, data: any) => void) {
    this.listeners[key] = cb;
  }
  unwatch(key: string | symbol) {
    delete this.listeners[key];
  }
}

export class Messaging<MessageResponseTypes, IncomingMessageTypes> {
  recipient?: Window | null;
  thisSourceName = 'v1.avaturn-sdk';
  expectedIncomingSourceName = 'v1.avaturn-sdk-server';

  constructor(sourceName?: string, incomingSourceName?: string) {
    sourceName && (this.thisSourceName = sourceName);
    incomingSourceName && (this.expectedIncomingSourceName = incomingSourceName);
    return;
  }

  private queue = new ResponseQueue();

  /**
   * Sends a message to partner. If `waitForResponse=true` resolves or rejects only when response is received.
   */
  protected sendMessage<DataType, EventNameType extends keyof MessageResponseTypes>(
    type: EventNameType,
    data?: DataType,
    messageKey?: string,
    waitForResponse = true
  ): Promise<MessageResponseTypes[EventNameType]> {
    // console.log(`Send message from ${this.thisSourceName}: `, data);
    return new Promise((resolve, reject) => {
      const date = Date.now();
      const key = messageKey || (type as string) + date;

      if (waitForResponse) {
        this.queue.watch(key, (isOk, data) => {
          const value = data ? JSON.parse(data) : null;
          isOk ? resolve(value) : reject(value);
          this.queue.unwatch(key);
        });
      }

      const message: SdkMessage<DataType, EventNameType> = {
        type: 'MESSAGE',
        source: this.thisSourceName,
        eventName: type,
        key,
        date,
        data,
        isOk: true,
      };
      this.recipient?.postMessage(message, '*');

      if (!waitForResponse) {
        resolve(null as any);
      }
    });
  }

  protected setupMessaging() {
    // console.log('setup')
    window.addEventListener('message', ({ data, source }) => {
      const data_ = data as SdkMessage<any, any>;

      if (this.expectedIncomingSourceName != data_.source) return;

      // console.log('Received Message: ', data, source);

      if (data_.type === 'RESPONSE') {
        if (data_.key === 'sdk_handshake') {
          this.sendMessage(MessageType.SDKHandshakeConfirmation as keyof MessageResponseTypes, {}, undefined, false);
        }
        if (this.queue.listeners[data_.key]) {
          this.queue.listeners[data_.key](data_.isOk, data_.data);
        }
      }

      if (data.type === 'MESSAGE') {
        this.handleMessage(data);
      }
    });
  }

  protected sendResponse(
    eventName: keyof IncomingMessageTypes,
    key: string,
    { isOk, data }: { isOk: boolean; data?: any } = { isOk: true }
  ) {
    // console.log(`Send RESPONSE from ${this.thisSourceName}: `, data);
    if (!this.recipient) {
      console.log('Recipient is not set.');
      return;
    }
    const msg: SdkMessage<typeof data, keyof IncomingMessageTypes> = {
      type: 'RESPONSE',
      source: this.thisSourceName,
      eventName: eventName,
      key,
      date: Date.now(),
      data,
      isOk,
    };
    this.recipient?.postMessage(msg, '*');
  }

  handleMessage(message: any) {
    return;
  }
}
