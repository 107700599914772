import { localData } from '@in3d/common';
import { AvatarCustomization } from '@in3d/common';

import { AvatarView } from '../AvatarView';
import { commonClient } from '../client';

const COOLDOWN = 5; // seconds between updates

class CustomizationUpdater {
  is_active = false;
  private customization_id = '';
  private time_of_last_update: number;
  // private customizatoin_to_send: any
  private previous_customization: AvatarCustomization;
  private scheduled = false;

  set_customization_id(customization_id: string) {
    this.customization_id = customization_id;
  }

  set_previous_customization(customization: AvatarCustomization) {
    this.previous_customization = customization;
  }

  get_decals_customization() {
    const c = this.previous_customization;
    if (c && c.avatar && c.avatar.decals) {
      return c.avatar.decals;
    }
    return [];
  }

  get_customization_for_cloth(name: string) {
    const c = this.previous_customization;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (c.assets[name] && c.assets[name].decalMapUrl) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const url = c.assets[name].decalMapUrl;
      return url;
    }
    return null;
  }

  async update_customization(forced = false) {
    if (!this.is_active) {
      return;
    }

    const customization_data = AvatarView.currentState.get_current_customization();

    const time_since_last_update = (new Date().getTime() - this.time_of_last_update) / 1000;
    if (time_since_last_update < COOLDOWN) {
      // this.customizatoin_to_send = customization_data;
      if (!this.scheduled) {
        setTimeout(() => {
          this.scheduled = false;
          this.update_customization();
        }, COOLDOWN - time_since_last_update);
        this.scheduled = true;
      }
      return;
    }

    if (!forced && this.previous_customization && _object_equals(this.previous_customization, customization_data)) {
      return;
    }
    this.previous_customization = JSON.parse(JSON.stringify(customization_data)); // kind of clone data
    this.time_of_last_update = new Date().getTime();

    if (this.customization_id) {
      commonClient.updateCustomization(this.customization_id, customization_data).catch((err) => {
        console.log('Error reading/parsing avatar customizations list: ', err);
      });
    } else {
      localData.setItem(AvatarView.currentState.avatar.glb_url, JSON.stringify(customization_data));
    }
  }
}

function _object_equals(x: any, y: any) {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (const p in x) {
    if (!Object.prototype.hasOwnProperty.call(x, p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!(p in y)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== 'object') return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!_object_equals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (const p in y)
    if (Object.prototype.hasOwnProperty.call(y, p) && !Object.prototype.hasOwnProperty.call(x, p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
}

export const customizationUpdater = new CustomizationUpdater();
