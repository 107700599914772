import { getHostname, ResourcesPlacement } from '@in3d/common';
import { action, makeObservable, observable } from 'mobx';
import { AnimationAction, AnimationClip, Bone, Group, KeyframeTrack, Scene, VectorKeyframeTrack } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { AvatarView, gltf_loader } from '../../AvatarView';
import { filter_animation, make_animation_relative_to_rest_pose } from '../animation_utils';
import { AnimationSettingsJson, DownloadState } from './common';
import { AssetData } from '@in3d/common';

export class Animation implements AssetData {
  settings_json: AnimationSettingsJson;
  constructor() {
    makeObservable(this, {
      download_state: observable,
      load_animation: action,
      setDownloadState: action.bound,
    });
  }
  user_id: string;
  gender: 'male' | 'female';
  material_preset: string;
  placement: ResourcesPlacement | symbol[];
  path?: string | undefined;
  settings: {
    cloth_id: string;
    embeded_retarget_info: boolean;
    is_single_mesh: boolean;
    mesh_name: string;
    retargeting_offsets_attribute: string;
  };
  created_at: string;
  updated_at: string;
  type: ResourcesPlacement;
  alias: string;
  id: string;
  glb_url: string;
  preview_url: string;
  camera: {
    position: { x: number; y: number; z: number };
    target: { x: number; y: number; z: number };
    zoom: number;
  };

  protected _clip: AnimationClip;
  protected _action: AnimationAction;
  private _relative_to_rest_pose = false;
  private hipsTrackOrigValues: Float32Array | undefined;
  private hipsTrack: KeyframeTrack | undefined;
  private rootElevation: number;

  // private animation_skeleton: Skeleton;
  is_pose: boolean;
  download_state: DownloadState = DownloadState.NotLoaded;

  correctHips(newRootElevation: number) {
    if (this.hipsTrack && this.hipsTrackOrigValues) {
      makeAnimationRelativeToRootBoneElevation(
        newRootElevation,
        this.rootElevation,
        this.hipsTrack,
        this.hipsTrackOrigValues
      );
    }
  }

  setDownloadState(state: DownloadState) {
    this.download_state = state;
  }

  getClip(): AnimationClip {
    return this._clip;
  }
  setClip(x: AnimationClip) {
    this._clip = x;
  }

  get is_downloaded() {
    return this.download_state === DownloadState.Loaded;
  }
  play() {
    if (this.is_downloaded) {
      const res_clip = this._clip;

      if (!this._action) {
        this._action = AvatarView.mixer.clipAction(res_clip);
      }
      this._action.play();
    } else {
      throw new Error('Animation not loaded. Cannot start palying');
    }
  }
  stop() {
    this._action.stop();
    // this.action.reset();
    // this.action.enabled = false;
    // AvatarView.mixer.uncacheAction(this.action);
    // AvatarView.mixer.uncacheAction(this.action);
    // AvatarView.mixer.uncacheAction(this.animation);
  }
  reset() {
    this._action.reset();
  }
  async load_animation(): Promise<void> {
    this.setDownloadState(DownloadState.Loading);

    let loader;
    let scene, animations;
    if (this.glb_url.endsWith('fbx')) {
      const { FBXLoader } = await import('three/examples/jsm/loaders/FBXLoader.js');
      const res = await new FBXLoader().loadAsync(this.glb_url);
      animations = res.animations;
      scene = res;
    } else {
      loader = gltf_loader;
      const gltf = (await loader.loadAsync(this.glb_url)) as GLTF;
      animations = gltf.animations;
      scene = gltf.scene;

      // console.log(gltf)
    }
    this.rootElevation = getHipsBone(scene).position.y;
    scene.updateMatrixWorld();
    // console.log(this.id);
    // this._relative_to_rest_pose = true;
    // scene.updateMatrixWorld();
    // for (const track of gltf.animations[0].tracks) {
    //     if (track.name == 'mixamorigHead.scale') {
    //         for (let i = 0; i < track.values.length; i++) {
    //             track.values[i] = 2;
    //         }
    //     }
    // }

    this._clip = animations[0];
    this.hipsTrack = getHipsTrack(this._clip.tracks);
    this.hipsTrackOrigValues = this.hipsTrack?.values.slice();
    const max_len = this._clip.tracks.reduce((pv: number, cv: any) => Math.max(pv, cv.times.length), -1);

    this.is_pose = max_len <= 2;

    // if (['idle_male_dec19', 'idle_female_dec19', 'idle_awe_pose'].includes(this.id)) {
    //   this.settings_json.use_hips_position = false;
    // }
    // let use_hips_position = this.settings_json.use_hips_position;
    // if (getHostname().includes('infosys') || getHostname().includes('bonjourlab')) {
    //   use_hips_position = !this.is_pose;
    // }

    filter_animation(this._clip, true, false);

    this._relative_to_rest_pose = this.id.startsWith('kinetix') || this.id.startsWith('__idle_anim');
    if (this._relative_to_rest_pose) {
      // convert animations with old names
      scene.getObjectByName('mixamorigHips')?.traverse((node: any) => {
        if (node.isBone) {
          node.name = node.name.replace('mixamorig', '');
        }
      });

      AvatarView.currentState.avatar.toRestPose();

      this._clip = make_animation_relative_to_rest_pose(
        AvatarView.currentState.avatar.body_mesh,
        scene.getObjectByName('Hips')! as Bone,
        this._clip
      );
    }
    this.setDownloadState(DownloadState.Loaded);
  }
}

function getHipsBone(scene: Group) {
  scene.getObjectByName('mixamorigHips')?.traverse((node: any) => {
    if (node.isBone) {
      node.name = node.name.replace('mixamorig', '');
    }
  });

  return scene.getObjectByName('Hips') as Bone;
}

function getHipsTrack(tracks: KeyframeTrack[]) {
  return tracks.find((x) => {
    return x.name.endsWith('Hips.position');
  });
}

function makeAnimationRelativeToRootBoneElevation(
  avatarRootElevation: number,
  animRootElevation: number,
  hipsTrack: VectorKeyframeTrack,
  hipsTrackValuesOrig: Float32Array
) {
  const delta = avatarRootElevation - animRootElevation;

  for (let i = 0; i < hipsTrack.times.length; i++) {
    hipsTrack.values[i * 3 + 1] = hipsTrackValuesOrig[i * 3 + 1] + delta;
  }
}
