import GUI from "lil-gui";
import { PerspectiveCamera, Scene, Vector2, WebGLRenderer } from "three";

export class SceneBase {
  renderer: WebGLRenderer
  scene: Scene
  camera: PerspectiveCamera
  gui: GUI | undefined

  constructor(renderer: WebGLRenderer, scene: Scene, camera: PerspectiveCamera, gui?: GUI) {
    this.renderer = renderer;
    this.scene = scene;
    this.camera = camera;
    this.gui = gui;
  }

  build() { }
  clean_up() { }
  render(mixerUpdateDelta: number) { }
  onWindowResize(width?: number, height?: number) {
    if (!width || !height ) {
      [width, height] = this.get_canvas_size()
    }
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  get_canvas_size(){
    const canvas_size = new Vector2();
    this.renderer.getSize(canvas_size);
    return [canvas_size.x, canvas_size.y];
  }
  setQuality(x: 'low' | 'mid' | 'high' | 'highest') {}
}
