export const blockScroll = (status: boolean) => {
  const doc = document.body;
  if (status) {
    //doc.style.setProperty('max-height', `${window.innerHeight}px`);
    doc.style.setProperty('overflow', 'hidden');
    doc.style.setProperty('max-height', 'var(--app-height)');
  } else {
    //doc.style.setProperty('max-height', 'initial');
    doc.style.setProperty('overflow', 'initial');
    doc.style.setProperty('max-height', 'initial');
  }
};
