import { AvatarView, gltf_loader } from '../../AvatarView';
import {
  ACESFilmicToneMapping,
  sRGBEncoding,
  AmbientLight,
  CineonToneMapping, Color, DirectionalLight, Group, LinearToneMapping, Object3D,
  RepeatWrapping,
  SpotLight,
  TextureLoader
} from 'three';
import { SceneDark } from './scene_dark';
import {
  EffectComposer,
  EffectPass,
  RenderPass,
  BrightnessContrastEffect, BloomEffect, LookupTexture, LUT3DEffect,
  BlendFunction
} from 'postprocessing';
import {
  addBrightnessContrastPassDebugInfo, LUTManager,
  LUTManagerSimple
} from './debug_gui';

import { Avatar } from '../resources/avatar';
import { Uniform } from '../Assets/shaders/_common';
import { developerConfig } from '@in3d/store';
import { GroundProjectedSkybox } from './groundProjectedSkybox';
import { VelocityDepthNormalPass } from './effects/velocityDepthNormalPass';
import { MotionBlurEffect } from './effects/motionBlurEffect';
import { localData } from '@in3d/common';
export class SceneDark2 extends SceneDark {
  override build() {
    super.build();
    const sp1 = this.scene.getObjectByName('spotlight1') as SpotLight;
    sp1.castShadow = false;

    const sp2 = this.scene.getObjectByName('spotlight2') as SpotLight;
    sp2.castShadow = false;

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.shadow.bias = -0.00003;

    // gltf_loader.load("./assets/local/sky_Sphere_33_36_BC0.glb", (gltf) => {
    //   gltf.scene.scale.set(3, 3, 3);

    //   const m = gltf.scene.children[0] as Mesh;
    //   const mat = m.material as MeshStandardMaterial;

    //   m.material = new MeshBasicMaterial({ map: mat.emissiveMap })
    //   m.material.toneMapped = false;

    //   mat.dispose();

    //   let scene_group = this.scene.getObjectByName('scene_group') as Group;
    //   // scene_group.add(gltf.scene);
    // });

    // this.scene.background = new Color(236 / 255, 236 / 255, 240 / 255); // dark : #383838
    // this
    // this.scene.background = new Color(0x2d2d2f);
    // this.scene.background = null;
  }
}

export class SceneDark3 extends SceneDark2 {
  override build() {
    super.build();
    // const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    // d1.position.set(5, -5, 5);
    this.scene.background = new Color(0x454450);

    const d1 = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    d1.intensity *= 3;
  }
}

export class SceneDark4 extends SceneDark2 {
  override build() {
    super.build();
    // const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    // d1.position.set(-2.4, 0.45, 3.9);

    // this.scene.background = new Color(0x454450);

    // AvatarView.avatar_envMapIntensity = 0.0;
    // AvatarView.cloth_envMapIntensity = 0.3;

    // this.renderer.toneMappingExposure = 1.0;

    // const sp1 = this.scene.getObjectByName('spotlight1') as SpotLight;
    // sp1.intensity = 2.4;

    // const sp2 = this.scene.getObjectByName('spotlight2') as SpotLight;
    // sp2.intensity = 9.4;

    // const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    // d1.intensity = 3.1;
    // d1.shadow.bias = -0.00001;

    AvatarView.avatar_envMapIntensity = 1.5;
    AvatarView.cloth_envMapIntensity = 1.6;

    const d1 = this.scene.getObjectByName('ambient_light') as AmbientLight;
    d1.intensity = 12.5;

    // this.renderer.toneMappingExposure = 1.0;

    // const sp1 = this.scene.getObjectByName('spotlight1') as SpotLight;
    // sp1.intensity = 2.4;

    // const sp2 = this.scene.getObjectByName('spotlight2') as SpotLight;
    // sp2.intensity = 9.4;

    const d2 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d2.intensity = 33.1;
    // d1.shadow.bias = -0.00001;

    this.scene.background = new Color(0x454450);
  }
}

export class SceneLinear extends SceneDark3 {
  override build() {
    super.build();
    // const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    // d1.position.set(-5, -5, 5);

    this.renderer.toneMapping = LinearToneMapping;
    // this.scene.background = new Color(0x454450);
    AvatarView.avatar_envMapIntensity = 2.9;
    AvatarView.cloth_envMapIntensity = 1.0;

    const d1 = this.scene.getObjectByName('ambient_light') as AmbientLight;
    d1.intensity = 14;
  }
}

export class SceneDark5 extends SceneLinear {
  override build() {
    super.build();
    // const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    // d1.position.set(-5, -5, 5);

    AvatarView.avatar_envMapIntensity = 2.9;
    AvatarView.cloth_envMapIntensity = 3.3;

    const d1 = this.scene.getObjectByName('ambient_light') as AmbientLight;
    d1.intensity = 21.4;

    const d2 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d2.intensity = 14.7;
  }
}

// export class SceneLinear extends SceneDark3 {
//   override build() {
//     super.build();
//     // const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
//     // d1.position.set(-5, -5, 5);

//     this.renderer.toneMapping = LinearToneMapping;
//     // this.scene.background = new Color(0x454450);
//     AvatarView.avatar_envMapIntensity = 2.9;
//     AvatarView.cloth_envMapIntensity = 1.0;

//     const d1 = this.scene.getObjectByName('ambient_light') as AmbientLight;
//     d1.intensity = 14;
//   }
// }

export class SceneBright extends SceneDark3 {
  luts: any;
  composer: EffectComposer;
  composers: { low?: EffectComposer; high?: EffectComposer; highest?: EffectComposer } = {};
  lut_manager: LUTManager | LUTManagerSimple;
  lutEffect: LUT3DEffect;
  brightnessContrastEffect: BrightnessContrastEffect;

  override build() {
    super.build();

    const d1 = this.scene.getObjectByName('ambient_light') as AmbientLight;
    d1.intensity = 6;

    const d2 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d2.intensity = 8.8;

    const s2 = this.scene.getObjectByName('spotlight2') as DirectionalLight;
    s2.intensity = 48.3;

    s2.position.x = -3;
    s2.position.y = 2;
    s2.position.z = -4.6;

    // Effects and composer
    const lutEffect = new LUT3DEffect(LookupTexture.createNeutral(1));
    const brightnessContrastEffect = new BrightnessContrastEffect();
    const effect_pass = new EffectPass(this.camera, brightnessContrastEffect, lutEffect);

    // const vingette = new VignetteEffect({offset:-100,darkness:-0.8});

    this.brightnessContrastEffect = brightnessContrastEffect;

    let multisampling = window.devicePixelRatio < 2 ? Math.min(8, this.renderer.capabilities.maxSamples) : 0;

    if (AvatarView.isHeadlessMode) {
      multisampling = Math.min(16, this.renderer.capabilities.maxSamples);
    }
    // if (AvatarView.gui) {
    //   this.gui?.add(composer, 'multisampling').min(0).max(8).step(1).name('Multisampling');
    // }
    const composer = new EffectComposer(this.renderer, { stencilBuffer: true, multisampling: multisampling });
    composer.setRenderer(this.renderer);
    composer.addPass(new RenderPass(this.scene, this.camera));
    composer.addPass(effect_pass);

    this.composer = composer;
    this.composers['low'] = composer;

    // HQ
    const USE_POSTPROCESSING = true;
    if (USE_POSTPROCESSING) {
      const velocityDepthNormalPass = new VelocityDepthNormalPass(this.scene, this.camera);
      const motionBlurOptions = { intensity: 0.1, jitter: 0.1, samples: 8 };
      const motionBlurEffect = new MotionBlurEffect(velocityDepthNormalPass, motionBlurOptions);
      const bloom_effect = new BloomEffect({ mipmapBlur: false, intensity: 0.2 });
      bloom_effect.blurPass.blurMaterial.scale = this.renderer.getPixelRatio() / 2;
      bloom_effect.luminancePass.enabled = false;
      const effect_pass = new EffectPass(
        this.camera,
        motionBlurEffect,
        bloom_effect,
        brightnessContrastEffect,
        lutEffect
      );

      const composer = new EffectComposer(this.renderer, { stencilBuffer: true, multisampling: multisampling });
      composer.setRenderer(this.renderer);
      composer.addPass(new RenderPass(this.scene, this.camera));
      composer.addPass(effect_pass);

      this.composers['high'] = composer;

      // Highest
      const effect_pass_highest = new EffectPass(
        this.camera,
        motionBlurEffect,
        bloom_effect,
        brightnessContrastEffect,
        lutEffect
      );

      const composer2 = new EffectComposer(this.renderer, { stencilBuffer: true, multisampling: multisampling });
      composer2.setRenderer(this.renderer);
      composer2.addPass(new RenderPass(this.scene, this.camera));
      composer2.addPass(velocityDepthNormalPass);
      composer2.addPass(effect_pass_highest);

      this.composers['highest'] = composer2;
    }

    let lut_manager: LUTManager | LUTManagerSimple;
    if (IS_DEBUG) {
      lut_manager = new LUTManager();
    } else {
      lut_manager = new LUTManagerSimple();
    }
    // lut_manager.get("png/filmic1").then(() => lut_manager.set_lut_to_effect(lutEffect, "png/filmic1", this.renderer, true));

    if (IS_DEBUG && AvatarView.gui) {
      const menu = this.gui!.addFolder('POSTPROCESSING');
      // addBloomEffectDebugInfo(menu, effect_pass, bloom_effect);
      addBrightnessContrastPassDebugInfo(menu, brightnessContrastEffect);
      lut_manager.add_gui(menu, lutEffect, this.renderer);
      menu.close();
    }

    lut_manager.get('png/fall').then(() => lut_manager.set_lut_to_effect(lutEffect, 'png/fall', this.renderer, true));
    lutEffect.blendMode.opacity.value = 1.0;
    lutEffect.blendMode.blendFunction = BlendFunction.SCREEN;

    AvatarView.avatar_envMapIntensity = 1.0;
    AvatarView.cloth_envMapIntensity = 0.7;

    this.lut_manager = lut_manager;
    this.lutEffect = lutEffect;
    this.renderer.toneMapping = CineonToneMapping;
  }
  override render() {
    this.composer?.render();
  }

  override onWindowResize(width?: number, height?: number) {
    if (!width || !height) {
      [width, height] = this.get_canvas_size();
    }
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.composer.setSize(width, height);
  }
}

// export class SceneBright2 extends SceneBright {

//   luts: any
//   composer: EffectComposer

//   override build() {
//     super.build();

//     this.renderer.toneMappingExposure = 0.08;
//     this.scene.background = new Color("#030303")
//     lut_manager.get("png/fall").then(() => lut_manager.set_lut_to_effect(lutEffect, "png/fall", this.renderer, true));
//     lutEffect.blendMode.opacity.value = 1.0;
//     lutEffect.blendMode.blendFunction = BlendFunction.SCREEN;
//   }
// }

export class SceneBright3 extends SceneBright {
  override build() {
    super.build();

    this.renderer.toneMappingExposure = 0.08;
    this.scene.background = new Color('#2e2e2e');
    this.lut_manager
      .get('png/filmic2')
      .then(() => this.lut_manager.set_lut_to_effect(this.lutEffect, 'png/filmic2', this.renderer, true));
    this.lutEffect.blendMode.opacity.value = 1.0;
    this.lutEffect.blendMode.blendFunction = BlendFunction.SCREEN;

    AvatarView.avatar_envMapIntensity = 1.0;
    AvatarView.cloth_envMapIntensity = 2.0;
  }
}

export class SceneBright4 extends SceneBright {
  override build() {
    super.build();

    this.renderer.toneMappingExposure = 0.1;
    this.scene.background = new Color('#212121');
    // this.lut_manager.get("png/filmic2").then(() => this.lut_manager.set_lut_to_effect(this.lutEffect, "png/filmic2", this.renderer, true));
    // this.lutEffect.blendMode.opacity.value = 1.0;
    // this.lutEffect.blendMode.blendFunction = BlendFunction.SCREEN;

    AvatarView.avatar_envMapIntensity = 0.7;
    AvatarView.cloth_envMapIntensity = 0.7;

    const s2 = this.scene.getObjectByName('spotlight2') as DirectionalLight;
    s2.intensity = 31.8;

    s2.position.x = -3;
    s2.position.y = 2;
    s2.position.z = -8;

    this.renderer.toneMapping = ACESFilmicToneMapping;
  }
}

export class SceneBright5 extends SceneBright3 {
  override build() {
    super.build();

    // this.renderer.toneMappingExposure = 0.1;
    // this.scene.background = new Color("#212121")
    this.lut_manager
      .get('3dl/cinematic')
      .then(() => this.lut_manager.set_lut_to_effect(this.lutEffect, '3dl/cinematic', this.renderer, true));
    this.lutEffect.blendMode.opacity.value = 1.0;
    this.lutEffect.blendMode.blendFunction = BlendFunction.COLOR;

    // AvatarView.avatar_envMapIntensity = 0.7;
    // AvatarView.cloth_envMapIntensity = 0.7;

    this.brightnessContrastEffect.brightness = 0.147;
    this.brightnessContrastEffect.contrast = 0.199;
    // const s2 = this.scene.getObjectByName('spotlight2') as DirectionalLight;
    // s2.intensity = 31.8;

    // s2.position.x = -3;
    // s2.position.y = 2;
    // s2.position.z = -8;

    // this.renderer.toneMapping = ACESFilmicToneMapping;
  }
}

export class SceneBright6 extends SceneBright4 {
  override build() {
    super.build();

    // this.renderer.toneMappingExposure = 0.1;
    // this.scene.background = new Color("#212121")
    // this.lut_manager.get("3dl/cinematic").then(() => this.lut_manager.set_lut_to_effect(this.lutEffect, "3dl/cinematic", this.renderer, true));
    // this.lutEffect.blendMode.opacity.value = 1.0;
    // this.lutEffect.blendMode.blendFunction = BlendFunction.COLOR;

    AvatarView.avatar_envMapIntensity = 1.0;
    AvatarView.cloth_envMapIntensity = 1.0;

    const s2 = this.scene.getObjectByName('spotlight2') as DirectionalLight;
    s2.intensity = 8.3;

    s2.position.x = -3;
    s2.position.y = 2;
    s2.position.z = -8;

    const s1 = this.scene.getObjectByName('spotlight1') as DirectionalLight;
    s1.intensity = 0;

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.intensity = 7.7;

    d1.position.x = 5.2;
    d1.position.y = 0.5;
    d1.position.z = 1;

    this.brightnessContrastEffect.brightness = 0.048;
    this.brightnessContrastEffect.contrast = 0.068;
  }
}

export class SceneBright7 extends SceneBright6 {
  override build() {
    super.build();

    this.brightnessContrastEffect.contrast = 0.121;
    this.scene.background = new Color('#08080c');
  }
}

export class SceneBright8 extends SceneBright7 {
  override build() {
    super.build();
    // this.scene.background = new Color("#2e2e2e")

    const s2 = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    s2.intensity = 3;

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;

    d1.position.x = 2.2;
  }
}

export class SceneBright9 extends SceneBright8 {
  override build() {
    super.build();

    this.scene.background = null;
    AvatarView.parent_element.style.backgroundImage =
      'linear-gradient(44deg, rgb(55, 32, 76) 0%, rgb(17, 15, 17) 100%)';

    // const s2 = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    // s2.color = new Color ("#28115f")

    if (!Avatar.brightness_contrast_params) {
      Avatar.brightness_contrast_params = {
        brightness: new Uniform(-0.0),
        contrast: new Uniform(-0.0),
      };
    } else {
      Avatar.brightness_contrast_params.brightness.value = -0.0;
      Avatar.brightness_contrast_params.contrast.value = -0.0;
    }
  }
}

export class SceneBright10 extends SceneBright9 {
  override build() {
    super.build();

    this.scene.background = null;
    AvatarView.parent_element.style.backgroundImage =
      'linear-gradient(44deg, rgb(55, 32, 76) 0%, rgb(17, 15, 17) 100%)';

    this.brightnessContrastEffect.contrast = 0.246;
    this.renderer.toneMapping = LinearToneMapping;
    // const s2 = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    // s2.color = new Color ("#28115f")
  }
}

export class SceneBright11 extends SceneBright9 {
  override build() {
    super.build();

    this.scene.background = null;
    AvatarView.parent_element.style.backgroundImage =
      'linear-gradient(44deg, rgb(55, 32, 76) 0%, rgb(17, 15, 17) 100%)';

    // this.brightnessContrastEffect.contrast = 0.173;
    // this.brightnessContrastEffect.brightness = 0.068;
    this.renderer.toneMapping = LinearToneMapping;

    Avatar.brightness_contrast_params.brightness.value = -0.12;
    Avatar.brightness_contrast_params.contrast.value = -0.15;

    // const s2 = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    // s2.color = new Color ("#28115f")
  }
}

export class SceneBright10e extends SceneBright10 {
  override build() {
    super.build();

    this.scene.background = null;
    AvatarView.parent_element.style.backgroundImage =
      'linear-gradient(44deg, rgb(55, 32, 76) 0%, rgb(17, 15, 17) 100%)';

    // this.brightnessContrastEffect.contrast = 0.246;
    this.renderer.toneMapping = LinearToneMapping;
    const s2 = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    s2.intensity = 4.6;

    AvatarView.avatar_envMapIntensity = 0.0;

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.intensity = 11.3;

    const s0 = this.scene.getObjectByName('spotlight1') as DirectionalLight;
    s0.intensity = 0;

    const s1 = this.scene.getObjectByName('spotlight2') as DirectionalLight;
    s1.intensity = 0;

    // image.png
  }
}

export class SceneBright12 extends SceneBright9 {
  override build() {
    super.build();

    this.renderer.toneMapping = LinearToneMapping;

    // postprocessing
    this.brightnessContrastEffect.contrast = 0.14;

    const s2 = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    s2.intensity = 0;

    AvatarView.avatar_envMapIntensity = 1.0;
    AvatarView.cloth_envMapIntensity = 1.0;

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.intensity = 11.3;

    d1.position.x = 3.4;
    d1.position.y = 15;
    d1.position.z = 6.35;

    const s0 = this.scene.getObjectByName('spotlight1') as DirectionalLight;
    s0.intensity = 0;

    const s1 = this.scene.getObjectByName('spotlight2') as DirectionalLight;
    s1.intensity = 125.6;
    s1.position.x = -2.8;
    s1.position.y = 2;
    s1.position.z = -3.9;
    // image.png

    Avatar.brightness_contrast_params.brightness.value = -0.15;
    Avatar.brightness_contrast_params.contrast.value = -0.17;
  }
}

export class SceneBright12b extends SceneBright9 {
  override build() {
    super.build();

    this.renderer.toneMapping = LinearToneMapping;

    // postprocessing
    // postprocessing
    this.brightnessContrastEffect.brightness = 0;
    this.brightnessContrastEffect.contrast = 0.14;

    const s2 = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    s2.intensity = 2;

    AvatarView.avatar_envMapIntensity = 1.0;
    AvatarView.cloth_envMapIntensity = 1.0;
    AvatarView.renderer.toneMappingExposure = 0.15;

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.intensity = 12.5;

    d1.position.x = 3.4;
    d1.position.y = 15;
    d1.position.z = 6.35;

    const s0 = this.scene.getObjectByName('spotlight1') as DirectionalLight;
    s0.intensity = 0;

    const s1 = this.scene.getObjectByName('spotlight2') as DirectionalLight;
    s1.intensity = 12.2;
    s1.position.x = -2.8;
    s1.position.y = 2;
    s1.position.z = -3;
    // image.png

    Avatar.brightness_contrast_params.brightness.value = -0.15;
    Avatar.brightness_contrast_params.contrast.value = -0.19;
  }
}

export class SceneBright12c extends SceneBright12b {
  loadSkybox({ url = '', height = 2.7, radius = 10.0, scale = 20 }) {
    _loadSkybox(url, this.scene.getObjectByName('scene_group') as Group, height, radius, scale);
  }
  loadGLB({ url = '' }) {
    gltf_loader.loadAsync(url).then((x) => {
      this.scene.add(x.scene);
    });
  }
  override build() {
    super.build();

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.position.y = 6.25;

    if (developerConfig.editor.sceneBg) {
      AvatarView.parent_element.style.backgroundImage = developerConfig.editor.sceneBg;
    }
  }
}

export class SceneBright12cFilmicLut extends SceneBright12c {
  override build() {
    super.build();
    this.lut_manager
      .get('png/filmic2')
      .then(() => this.lut_manager.set_lut_to_effect(this.lutEffect, 'png/filmic2', this.renderer, true));
  }
}

export class SceneBright12cArtisant extends SceneBright12c {
  override build() {
    super.build();

    this.scene.background = new Color(0x300808);
  }
}

export class SceneBright12cBonjourlab extends SceneBright12c {
  override build() {
    super.build();

    AvatarView.parent_element.style.backgroundImage = 'linear-gradient(1deg, #a33419 0%, #301010 100%)';
  }
}

export class SceneBright12Animatable extends SceneBright12c {
  override build() {
    super.build();

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.intensity = 9.2;
    // d1.position.x = -15;
    // d1.position.y = 6.25;
    // d1.position.z = 15;

    d1.position.set(-15, 2.45, 15);
    d1.shadow.camera.top = 2.2;

    const s2 = this.scene.getObjectByName('ambient_light') as DirectionalLight;
    s2.intensity = 6.6;

    AvatarView.avatar_envMapIntensity = 0.0;
    AvatarView.cloth_envMapIntensity = 0.7;

    // this.scene.environment =null;
    // this.scene.environment = new TextureLoader().load('assets/test_assets/Untitled.png');
    // const scene = new THREE.Scene();
    // this.scene.environment = new CubeTextureLoader()
    //   .setPath( 'assets/test_assets/' )
    //   .load( [
    //     'Untitled.png',
    //     'Untitled.png',
    //     'Untitled.png',
    //     'Untitled.png',
    //     'Untitled.png',
    //     'Untitled.png'
    //   ] );
  }
}

export class SceneBright12AnimatableB extends SceneBright12Animatable {
  override build() {
    super.build();

    const sp = this.scene.getObjectByName('spotlight2') as DirectionalLight;
    sp.castShadow = true;

    sp.position.set(2.6, 2, -5.8);
    sp.intensity = 180;

    AvatarView.avatar_envMapIntensity = 0.0;
    AvatarView.cloth_envMapIntensity = 0.0;
  }
}

export class SceneBright12AnimatableC extends SceneBright12AnimatableB {
  override build() {
    super.build();

    AvatarView.avatar_envMapIntensity = 0.0;
    AvatarView.cloth_envMapIntensity = 0.0;

    AvatarView.renderer.toneMappingExposure = 0.2;

    const sp = this.scene.getObjectByName('spotlight2') as SpotLight;
    sp.castShadow = true;

    sp.position.set(-3.6, 0.1, -4.7);
    sp.intensity = 180;

    const sp1 = this.scene.getObjectByName('spotlight1') as SpotLight;
    sp1.castShadow = true;
    sp1.position.set(2.2, 1.7, -8.9);
    sp1.intensity = 180;

    this.loadSkybox(skyboxes[3]);

    const q = localData.getItem('avaturn-quality-settings') || 'high';
    this.setQuality(q as any);
    // const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    // d1.position.set(-15, 2.45, 15)

    // this.scene.environment
  }

  override setQuality(x: 'low' | 'mid' | 'high' | 'highest') {
    if (x == 'low') {
      AvatarView.avatar_envMapIntensity = 0.6;
      AvatarView.cloth_envMapIntensity = 0.6;

      AvatarView.renderer.toneMappingExposure = 0.7;

      this.spotlight1.visible = false;
      this.spotlight2.visible = false;
      this.directional_light.visible = false;
      this.ambient_light.visible = false;
    } else if (x == 'mid') {
      AvatarView.avatar_envMapIntensity = 0.0;
      AvatarView.cloth_envMapIntensity = 0.0;

      AvatarView.renderer.toneMappingExposure = 0.2;

      this.spotlight1.castShadow = false;
      this.spotlight2.visible = true;
      this.directional_light.visible = true;
      this.ambient_light.visible = true;
    } else if (x == 'high') {
      AvatarView.avatar_envMapIntensity = 0.0;
      AvatarView.cloth_envMapIntensity = 0.0;

      AvatarView.renderer.toneMappingExposure = 0.2;

      this.spotlight2.visible = true;
      this.spotlight1.visible = false;
      this.directional_light.visible = true;
      this.ambient_light.visible = true;
      this.composer = this.composers['high']!;
    } else if (x == 'highest') {
      AvatarView.avatar_envMapIntensity = 0.0;
      AvatarView.cloth_envMapIntensity = 0.0;

      AvatarView.renderer.toneMappingExposure = 0.2;

      this.spotlight2.visible = true;
      this.spotlight1.visible = true;
      this.directional_light.visible = true;
      this.ambient_light.visible = true;
      this.composer = this.composers['highest']!;
    }
  }

  get spotlight2() {
    return this.scene.getObjectByName('spotlight2') as SpotLight;
  }
  get spotlight1() {
    return this.scene.getObjectByName('spotlight1') as SpotLight;
  }
  get directional_light() {
    return this.scene.getObjectByName('directional_light') as DirectionalLight;
  }
  get ambient_light() {
    return this.scene.getObjectByName('ambient_light') as AmbientLight;
  }
}

export class SceneBright12AnimatableC15 extends SceneBright12AnimatableC {
  override build() {
    super.build();

    this.camera.fov = 15;
    const c = 25 / 15;
    const t = AvatarView.controls.target;
    const p = AvatarView.camera.position;
    const d = {
      target: { x: t.x, y: t.y, z: t.z },
      position: { x: p.x * c, y: p.y, z: p.z * c },
      zoom: 1,
    };
    AvatarView.set_camera(d);

    const p2 = this.spotlight2.position;
    this.spotlight2.position.set(p2.x, p2.y, p2.z * c);

    const p1 = this.spotlight1.position;
    this.spotlight1.position.set(p1.x, p1.y, p1.z * c);

    const d1 = this.directional_light.position;
    this.directional_light.position.set(d1.x, d1.y, d1.z * c);
  }
}
export class SceneBright12AnimatableD extends SceneBright12AnimatableB {
  override build() {
    super.build();

    AvatarView.avatar_envMapIntensity = 0.0;
    AvatarView.cloth_envMapIntensity = 0.0;

    AvatarView.renderer.toneMappingExposure = 1;

    const sp = this.scene.getObjectByName('spotlight2') as SpotLight;
    sp.removeFromParent();

    const sp1 = this.scene.getObjectByName('spotlight1') as SpotLight;
    sp1.removeFromParent();

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.intensity = 1;
    d1.position.set(3.8, 1.65, 9.5);

    const d2 = this.scene.getObjectByName('directional_light2') as DirectionalLight;
    d2.intensity = 1;
    d2.position.set(15, 0.6, -0.55);

    const a = this.scene.getObjectByName('ambient_light') as AmbientLight;
    a.intensity = 1;
  }
}

export class SceneBright12AnimatableEnv extends SceneBright12AnimatableB {
  override build() {
    super.build();

    AvatarView.avatar_envMapIntensity = 0.6;
    AvatarView.cloth_envMapIntensity = 0.6;

    AvatarView.renderer.toneMappingExposure = 0.7;

    const sp = this.scene.getObjectByName('spotlight2') as SpotLight;
    sp.removeFromParent();

    const sp1 = this.scene.getObjectByName('spotlight1') as SpotLight;
    sp1.removeFromParent();

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.removeFromParent();

    const d2 = this.scene.getObjectByName('directional_light2') as DirectionalLight;
    d2.removeFromParent();

    const a = this.scene.getObjectByName('ambient_light') as AmbientLight;
    a.removeFromParent();
  }
}

export class SceneBright12AnimatableE extends SceneBright12AnimatableB {
  override build() {
    super.build();

    AvatarView.avatar_envMapIntensity = 0.0;
    AvatarView.cloth_envMapIntensity = 0.0;

    AvatarView.renderer.toneMappingExposure = 1;

    const sp = this.scene.getObjectByName('spotlight2') as SpotLight;
    sp.removeFromParent();

    const sp1 = this.scene.getObjectByName('spotlight1') as SpotLight;
    sp.removeFromParent();

    const d1 = this.scene.getObjectByName('directional_light') as DirectionalLight;
    d1.intensity = 1;
    d1.position.set(-12.35, 1.65, 9.5);

    const d2 = this.scene.getObjectByName('directional_light2') as DirectionalLight;
    d2.intensity = 1;
    d2.position.set(-1.85, 0.6, -0.55);

    const a = this.scene.getObjectByName('ambient_light') as AmbientLight;
    a.intensity = 1;
  }
}

// (this.scene.getObjectByName('scene_group') as Group)
function _loadSkybox(url: string, parent: Object3D, height = 2.7, radius = 10.0, scale = 20) {
  // const texture_url = `assets/test_assets/scifi_avatar_creation_podium.jpg`;
  // console.log(url);
  new TextureLoader().load(url, (texture) => {
    // new RGBELoader().setPath(`assets/test_assets/`).load('scifi_avatar_creation_podium.jpg', (texture) => {
    // texture.mapping = EquirectangularReflectionMapping;
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.generateMipmaps = false;
    texture.encoding = sRGBEncoding;

    // texture.flipY=fals;
    // scene.environment = texture;
    const env = new GroundProjectedSkybox(texture);
    env.name = 'ground_projected';

    env.frustumCulled = false;
    env.scale.setScalar(scale);

    if (parent.getObjectByName('ground_projected')) {
      parent.remove(parent.getObjectByName('ground_projected')!);
    }
    parent.add(env);

    env.height = height;
    env.radius = radius;

    if (IS_DEBUG && AvatarView.gui) {
      const skyboxGui = AvatarView.gui.addFolder('skybox').close();
      skyboxGui.add(env, 'height', 0, 250, 0.1);
      skyboxGui.add(env, 'radius', 1, 600, 0.1);
      const s = { scale: 20 };
      skyboxGui.add(s, 'scale', 10, 100, 1).onChange((p: number) => {
        env.scale.setScalar(p);
      });
    }
  });
}

export const skyboxes = [
  {
    url: 'https://assets.avaturn.me/height_7_radius_10_scale_11_73a89e3297.jpg?ver=1',
    // url: 'assets/test_assets/Map6.png',
    height: 61.5,
    radius: 450.6,
    scale: 18,
  },
  { url: 'https://assets.avaturn.me/Map4_da652e5325.jpg?ver=1', height: 16.3, radius: 19.9, scale: 20 },
  {
    url: 'https://assets.hub.in3d.io/BG_2_03da89d2aa.jpg',
    // url: 'assets/test_assets/Map7.png',
    height: 4,
    radius: 600,
    scale: 20,
  },
  {
    url: 'https://assets.hub.in3d.io/Map6_3_401cc37b94.png?ver=1',
    // url: 'assets/test_assets/Map6_.webp',
    height: 41.4,
    radius: 84.9,
    scale: 20,
  },
  {
    url: 'https://assets.avaturn.me/00595_1636582125_a_360_e71551f80c.jpg?ver=1',
    // url: 'assets/test_assets/skyboxes/height_7_radius_10_scale_11 1.png',
    // url: 'assets/test_assets/Map7.png',
    height: 92.3,
    radius: 130.6,
    scale: 20,
  },
];
