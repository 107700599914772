import { CategoryName } from '@in3d/core';

import { ColorPanel } from './color-panel';

type EditorPanelProps = {
  handleChangeColor?: (category: CategoryName, color: string) => void;
  activeCategory: CategoryName;
  name?: string;
  children?: React.ReactNode;
};

export const EditorPanel = ({ activeCategory, handleChangeColor, name, children }: EditorPanelProps) => {
  return (
    <div className="editor-panel">
      {handleChangeColor ? (
        <ColorPanel activeCategory={activeCategory} handleChangeColor={handleChangeColor} name={name} />
      ) : null}
      {children}
    </div>
  );
};
