import { memo, useEffect, useRef, useState } from 'react';
import './webcam-video.scss';
import CameraDropdown from './face-tracking/camera-dropdown';
import { isMobile } from '@in3d/common';

export const requestMediaStream = (direction?: 'user' | 'environment', deviceId?: string): Promise<MediaStream> => {
  if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
    return navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          deviceId,
          width: { ideal: 640 * 2 },
          height: { ideal: 480 * 2 },
          ...(!deviceId && { facingMode: direction || 'user' }),
        },
      })
      .catch((err) => {
        throw err;
      });
  }
  return Promise.reject('No media devices!');
};

export const WebcamVideoStream: React.FC<{ fn: CallableFunction; disposeFn: CallableFunction }> = ({
  fn,
  disposeFn,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const initedRef = useRef(false);

  const [isStreamAvailable, setIsStreamAvailable] = useState(false);

  const updateStream = (stream: MediaStream) => {
    setIsStreamAvailable(false);
    streamRef.current = stream;
    setTimeout(() => {
      setIsStreamAvailable(true);
      fn(videoRef.current!);
    }, 200);
  };
  const closeStream = () => {
    streamRef.current?.getTracks().forEach((track) => track.stop());
    if (videoRef.current) videoRef.current.srcObject = null;
  };

  const [currentCamera, setCurrentCamera] = useState<string | undefined>(undefined);

  const changeCamera = (deviceId?: string) => {
    initedRef.current = true;
    setCurrentCamera(deviceId);
    requestMediaStream(undefined, deviceId)
      .then(updateStream)
      .catch(() => {
        alert("Can't start web camera. Did you give permission?");
      });
  };
  useEffect(() => {
    changeCamera();

    return () => {
      disposeFn();
      closeStream();
    };
  }, []);

  useEffect(() => {
    if (isStreamAvailable && videoRef.current && streamRef.current) {
      const video = videoRef.current;
      // window.videoRef = videoRef.current;
      const stream = streamRef.current;
      if (video && stream) {
        video.srcObject = stream;
      }
    }
  }, [isStreamAvailable]);

  const [isFrontCamera, setIsFrontCamera] = useState(true);

  const handleSwapSource = () => {
    setIsStreamAvailable(false);
    closeStream();
    requestMediaStream(isFrontCamera ? 'environment' : 'user')
      .then((stream) => {
        setIsStreamAvailable(true);
        setIsFrontCamera((prev) => !prev);
        updateStream(stream);
      })
      .catch(console.log);
  };

  const isMobileDevice = isMobile();
  return (
    <>
      <CameraDropdown
        isMobile={isMobileDevice}
        handleSwapSource={handleSwapSource}
        currentCamera={currentCamera}
        onCameraSelect={changeCamera}
      />

      <div className="webcam-main__video">
        <video ref={videoRef} id="video" muted autoPlay playsInline />
      </div>
    </>
  );
};

export const FileVideoStream: React.FC<{ fn: CallableFunction; src: string; disposeFn: CallableFunction }> = ({
  fn,
  src,
  disposeFn,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    fn(videoRef.current);
    if (videoRef.current) videoRef.current.play();
    return () => {
      disposeFn();
    };
  }, []);

  return (
    <video
      className="webcam-main__video"
      ref={videoRef}
      muted={false}
      autoPlay
      playsInline
      loop
      src={src}
      id="video"
      crossOrigin="false"
    />
  );
};

export const MemoizedWebcamVideoStream = memo(WebcamVideoStream);
