import { adminClient } from '@in3d/api';
import {
  AssetData,
  CategoryName,
  cn,
  NewAssetConfig,
  notifyError,
  notifySuccess,
  ResourcePlacementStr,
  ResourcesPlacement,
} from '@in3d/common';
import { addCloth, changeCloth, getDefaultAvatar, resources, SettingsJson } from '@in3d/core';
import { auth } from '@in3d/store';
import { FC, useContext, useEffect, useState } from 'react';
import { AssetsContext } from '../../context/assets';
import { AssetSettings } from './settings';

import './upload.scss';

interface UploadProps {
  category: CategoryName;
  onDone: () => void;
}
export enum Slots {
  Eyes = 'Eyes',
  Head = 'Head',
  Shoes = 'Shoes',
  // Look = 'Look',
  Top = 'Top',
  Bottom = 'Bottom',
  Face = 'Face',
}
enum Status {
  Select = 'select',
  Uploading = 'uploading',
  Ready = 'ready',
}
type SelectedSlots = { [key in Slots]: boolean };
export type ValuesOf<T extends any[]> = T[number];

const ClothTypes = ['Look', 'Hair', 'Glasses'];

export const Upload: FC<UploadProps> = ({ category, onDone }) => {
  const assets = useContext(AssetsContext);
  const [status, setStatus] = useState<Status>(Status.Select);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [settings, setSettings] = useState(new SettingsJson());
  const [file, setFile] = useState<File | null>(null);

  const isAddingDisabled = () => {
    if (category === CategoryName.Animations) {
      return !file;
    }
    return !file || !avatar;
  };

  const handleAddAsset = async () => {
    const getType = () => {
      if (category === CategoryName.Glasses) return ClothTypes[2];
      if (category === CategoryName.Hair) return ClothTypes[1];
      if (category === CategoryName.Shoes) return 'Shoes';
      else return ClothTypes[0];
    };
    setStatus(Status.Uploading);
    if (category === CategoryName.Animations && file) {
      return assets
        .addAnimation({ file })
        .then(() => onDone())
        .finally(() => setStatus(Status.Select));
    }
    if (avatar && file) {
      assets
        .add({ placement: typeToPlacement(getType()), type: getType(), settings, avatar, cloth: file })
        .then(() => onDone())
        .finally(() => setStatus(Status.Select));
    }
  };

  const typeToPlacement = (type: ValuesOf<typeof ClothTypes>): ResourcePlacementStr => {
    if (type === 'Look') return 'look';
    if (type === 'Hair') return 'head';
    if (type === 'Glasses') return 'eyes';
    else return 'look';
  };

  return (
    <div className="upload">
      <div className="upload__files">
        {category !== CategoryName.Animations && (
          <label className="upload__input upload-input">
            <span>{avatar ? avatar.name : 'Select avatar'}</span>
            <input
              className="upload-input__field"
              type="file"
              accept=".glb"
              onChange={(e) => setAvatar(e.target.files?.[0] || null)}
            />
          </label>
        )}
        <label className="upload__input upload-input">
          {status === Status.Select && <span>{file ? file.name : 'Select new ' + category}</span>}
          {status === Status.Uploading && <span>Loading</span>}
          <input
            className="upload-input__field"
            type="file"
            accept=".glb"
            onChange={(e) => setFile(e.target.files?.[0] || null)}
          />
        </label>
      </div>
      {avatar && (
        <div className="upload__settings">
          <AssetSettings settings={settings} onUpdate={(s) => setSettings(s)} />
        </div>
      )}
      <div className="upload__actions">
        <button disabled={isAddingDisabled()} className="upload__button" onClick={handleAddAsset}>
          Add
        </button>
      </div>
    </div>
  );
};
