import { cn, isMobile } from '@in3d/common';
import { memo, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import './modal.scss';

interface ModalProps {
  title?: string | boolean;
  onClose?: () => void;
  type?: 'default' | 'light';
  noAdaptive?: boolean;
  actions?: boolean;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  title,
  onClose,
  type = 'default',
  actions = true,
  noAdaptive = false,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const handleBackClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!contentRef.current?.contains(e.target as Node)) {
      if (actions && onClose) onClose();
    }
  };
  const closeModal = () => {
    if (onClose) onClose();
  };
  return createPortal(
    <div className="modal modal--open" onClick={handleBackClick}>
      <div
        ref={contentRef}
        className={cn([
          'modal__content',
          `modal__content--${type}`,
          !noAdaptive && 'modal__content--adaptive',
          isMobile() && 'mobile',
          actions && 'modal__content--actions',
        ])}
      >
        {actions && (
          <div className="modal-actions">
            <div className="modal-actions__title">{title}</div>
            <div className="modal-actions__buttons">
              {!!onClose && (
                <button className="modal-actions__btn" onClick={closeModal}>
                  <img src="/assets/close.svg" alt="close" />
                </button>
              )}
            </div>
          </div>
        )}
        {children}
      </div>
    </div>,
    document.getElementById('portal') as Element
  );
};

const _Modal = memo(Modal);
export { _Modal as Modal };
