export const animations = {
  slide: {
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 0.2,
      },
    },
    initial: { x: '100%', opacity: 0 },
    exit: {
      x: '100%',
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  },
};
