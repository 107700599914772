import cn from 'classnames';
import { useEffect, useState } from 'react';
import { ColorPanel } from './color-panel';
import { CategoryName } from '@in3d/core';

export const Option = ({ name, active, onClick }: { name: string; active: boolean; onClick: () => void }) => {
  return (
    <div
      className={cn([
        'hair-properties__btn',
        {
          active,
        },
      ])}
      onClick={onClick}
    >
      {name}
    </div>
  );
};

export const HairOptions = ({
  handleChangeColor,
  children,
  isMobile,
}: {
  children: React.ReactNode;
  handleChangeColor: (category: CategoryName, color: string) => void;
  isMobile: boolean;
}) => {
  const [activeType, setActiveType] = useState<'haircut' | 'color'>('haircut');

  useEffect(() => {
    if (!isMobile) setActiveType('haircut');
  }, [isMobile]);

  return (
    <>
      <div className="hair-properties__type">
        <Option name="Haircut" active={activeType === 'haircut'} onClick={() => setActiveType('haircut')} />
        <Option name="Color" active={activeType === 'color'} onClick={() => setActiveType('color')} />
      </div>
      <div className={cn(['options-content', { active: activeType === 'haircut' }])}>
        {activeType === 'haircut' ? (
          children
        ) : (
          <ColorPanel activeCategory={CategoryName.Hair} handleChangeColor={handleChangeColor} />
        )}
      </div>
    </>
  );
};
