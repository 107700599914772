import { developerConfig } from '@in3d/store';
import { LogoText } from '@in3d/ui';
import { observer } from 'mobx-react-lite';

export const SceneLogo = observer(() => {
  return (
    <>
      {developerConfig.editor.logo ? (
        <>
          <div className="scene__logo">
            <img src={developerConfig.editor.logo} alt="logo" />
          </div>
          {developerConfig.editor.withPoweredBtn && (
            <div className="scene__powered">
              Powered by <span className="bold">Avaturn</span>
            </div>
          )}
        </>
      ) : (
        <div className="scene__logo">
          <LogoText height={13} />
        </div>
      )}
    </>
  );
});
