import { toast, ToastOptions } from 'react-toastify';

const getConfig = (config?: ToastOptions) => {
  const defaultConfig: ToastOptions = {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    theme: 'dark',
  };
  return { ...defaultConfig, ...config };
};

export const notifySuccess = (msg: string, config?: ToastOptions) => {
  toast.success(msg, getConfig(config));
};

export const notifyError = (msg: string, config?: ToastOptions) => {
  toast.error(msg, getConfig(config));
};
