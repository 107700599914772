import { cn } from '@in3d/common';
import { FC } from 'react';
import SVG from 'react-inlinesvg';

export type AdminCardProps =
  | {
    id: string;
    preview: string;
    loading?: never;
    editable?: never;
    showSaveButton: boolean;
    active?: boolean;
    onClick: () => void;
    onSave: () => void;
    onUpdate?: never;
  }
  | {
    id: string;
    preview: string;
    loading: boolean;
    editable: boolean;
    showSaveButton: boolean;
    active?: boolean;
    onClick: () => void;
    onSave: () => void;
    onUpdate: () => void;
  };
export const AdminCard: FC<AdminCardProps> = ({
  id,
  loading,
  preview,
  editable,
  showSaveButton,
  active = true,
  onClick,
  onSave,
  onUpdate,
}) => {
  return (
    <div className={cn(['admin-card', active && 'active'])} onClick={onClick}>
      <div className="admin-card__content">
        <div className="admin-card__overlay">
          {preview !== 'new' ? (
            <img className="admin-card__image" src={preview || '/assets/default-asset.png'} alt="" />
          ) : (
            <span className="admin-card__text">NEW</span>
          )}
          <div className="admin-card__buttons">
            {showSaveButton && (
              <button
                className="admin-card__save"
                disabled={loading}
                onClick={(e) => {
                  e.stopPropagation();
                  onSave();
                }}
              >
                {loading ? 'UPLOADING' : 'SAVE'}
              </button>
            )}
            {editable && (
              <button
                className="admin-card__settings"
                onClick={(e) => {
                  e.stopPropagation();
                  onUpdate();
                }}
              >
                <SVG src="/assets/cog.svg" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
