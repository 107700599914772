import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  signInWithRedirect,
  Auth,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  AuthErrorMap,
  browserSessionPersistence,
  browserLocalPersistence,
  getRedirectResult,
  sendPasswordResetEmail,
  inMemoryPersistence,
  signInAnonymously,
  User,
  EmailAuthProvider,
  linkWithCredential,
  linkWithPopup,
  AuthCredential,
  UserCredential,
} from 'firebase/auth';
import { FirebaseApp } from 'firebase/app';
import { Analytics, initializeAnalytics, logEvent, setDefaultEventParameters } from 'firebase/analytics';
import { getHostname, localData } from '@in3d/common';
import axios from 'axios';
import env from '@in3d/environment';

export type AuthError = AuthErrorMap;

const isLocalStorageAvailable = () => localData.available;
export interface AuthData {
  isGuest: boolean;
  verified: boolean;
  loaded: boolean;
  email: string | null;
  name: string | null;
  isAuth: boolean;
  token: string;
  id: string;
}

declare global {
  interface Window {
    avaturnFirebaseUseSignInWithRedirect?: boolean;
  }
}

export class AuthClient {
  constructor(firebase: FirebaseApp) {
    this.firebase = firebase;
    this.auth = getAuth(this.firebase);
    this.analytics = initializeAnalytics(this.firebase, {
      config: {
        cookie_flags: 'SameSite=None;Secure', // otherwise does not work in iframe
      },
    });
    const parent_url = window.location != window.parent.location ? document.referrer : window.location.host;
    setDefaultEventParameters({
      parent: parent_url,
      origin: window.location.host,
    });
  }
  auth: Auth;
  firebase: FirebaseApp;
  analytics: Analytics;

  async createAuthData(user: User): Promise<AuthData> {
    return user.getIdToken().then((token) => ({
      isGuest: user.isAnonymous,
      verified: user.emailVerified,
      loaded: true,
      email: user.email,
      name: user.displayName,
      isAuth: true,
      token,
      id: user.uid,
    }));
  }

  async generateCustomToken(token: string) {
    return axios
      .post(env.baseUrl + 'auth/custom_token', null, {
        headers: {
          Authorization: `Bearer ${token || ''}`,
        },
      })
      .then((res) => res.data['token'] || '');
  }

  signUp({ email, password }: { email: string; password: string }): Promise<void> {
    return createUserWithEmailAndPassword(this.auth, email, password).then(({ user }) => {
      sendEmailVerification(user);
    });
  }

  async getRedirectResult(): Promise<AuthData | null> {
    return getRedirectResult(this.auth).then((data) => {
      if (!data) return null;
      const { user } = data;
      return this.createAuthData(user);
    });
  }

  async sendVerification() {
    if (!this.auth.currentUser) throw 'No user found';
    if (this.auth.currentUser.emailVerified) throw 'User verified';
    return await sendEmailVerification(this.auth.currentUser);
  }

  async authWithEmail({ email, password }: { email: string; password: string }): Promise<AuthData> {
    await this.auth.setPersistence(isLocalStorageAvailable() ? browserLocalPersistence : inMemoryPersistence);
    if (!isLocalStorageAvailable()) {
      // probably in this case we need to use custom token and send via url
    }

    return signInWithEmailAndPassword(this.auth, email, password).then(async ({ user }) => this.createAuthData(user));
  }
  async linkWithEmail({ email, password }: { email: string; password: string }): Promise<AuthData> {
    const credential = EmailAuthProvider.credential(email, password);
    if (this.auth.currentUser) {
      return linkWithCredential(this.auth.currentUser, credential).then(({ user }) => {
        return this.createAuthData(user);
      });
    } else throw 'No guest user found';
  }
  async restorePassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }
  async tokenAuth(token: string): Promise<AuthData> {
    // It seems that we don't need any storages when using tokens ( and we acturally don't want to cache auth )
    await this.auth.setPersistence(inMemoryPersistence);
    return signInWithCustomToken(this.auth, token).then(({ user }) => this.createAuthData(user));
  }
  async guestAuth(): Promise<AuthData> {
    return signInAnonymously(this.auth).then(({ user }) => this.createAuthData(user));
  }
  async googleAuth(): Promise<AuthData> {
    const provider = new GoogleAuthProvider();

    await this.auth.setPersistence(isLocalStorageAvailable() ? browserLocalPersistence : inMemoryPersistence);

    const isInsideIframe = window.location !== window.parent.location;

    const useRedirect = window.avaturnFirebaseUseSignInWithRedirect; // || env.rootHosts.includes(getHostname());

    if (useRedirect) {
      // The page is not in an iframe
      logEvent(this.analytics, 'sign_up');
      return signInWithRedirect(this.auth, provider);
    } else {
      // The page is in an iframe
      return signInWithPopup(this.auth, provider).then((data) => {
        logEvent(this.analytics, 'sign_up');
        console.log('Current user: ', this.auth.currentUser);
        return this.createAuthData(data.user);
      });
    }
  }
  async linkWithGoogle() {
    if (this.auth.currentUser) {
      const provider = new GoogleAuthProvider();
      return linkWithPopup(this.auth.currentUser, provider).then((data) => {
        return this.createAuthData(data.user);
      });
    } else throw 'No guset user found';
  }
  refreshToken(force = false): Promise<string> {
    const user = this.auth.currentUser;
    if (user) {
      return user.getIdToken(force);
    }
    return Promise.reject(new Error('no user found'));
  }
  logout() {
    return this.auth.signOut();
  }
}
