import { ExportStatus, ExportType } from '@in3d/api';
import { cn } from '@in3d/common';
import { exportAvatar, resources } from '@in3d/core';
import { observer } from 'mobx-react-lite';
import { useIsMobile, useCustomPopper } from '@in3d/hooks';

const SaveButton = ({ avatars, developerConfig }: { avatars: any; developerConfig: any }) => {
  const handleSaveBtnClick = async () => {
    // Export in iframe
    if (!avatars.isExportBtnEnabled) {
      return;
    }
    avatars.setAvatarExportBtnActivity(false);
    setTimeout(() => {
      exportAvatar().then((data) => {
        if (
          data.type === ExportType.DataURL ||
          (data.type === ExportType.HttpURL && data.data.status === ExportStatus.Ready)
        ) {
          avatars.setAvatarExportBtnActivity(true);
        }
      });
    }, 200);
  };

  const mobile = useIsMobile();

  const { setReferenceElement, setPopperElement, styles, attributes, popper } = useCustomPopper(
    resources.isSceneLoaded,
    mobile ? [0, 10] : [14, 10],
    10000
  );

  return (
    <>
      <button
        disabled={!avatars.isExportBtnEnabled}
        className={cn(['scene__save', developerConfig.name, !avatars.isExportBtnEnabled && 'loading'])}
        onClick={handleSaveBtnClick}
        ref={setReferenceElement}
      >
        {!avatars.isExportBtnEnabled && <span className="loader" />}
        Next
        <img src="/assets/arrow-right.svg" alt="" />
      </button>
      {popper && (
        <div ref={setPopperElement} style={styles['popper']} {...attributes['popper']} className="save__tooltip">
          Customize your look
          <br />
          and&nbsp;then click "Next"
        </div>
      )}
    </>
  );
};

const _SaveButton = observer(SaveButton);
export { _SaveButton as SaveButton };
