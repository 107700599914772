import env from '@in3d/environment';

export const getHostname = (): string => {
  return env.hostname_override || window.location.hostname || '';
};

export function getDeveloperName() {
  const host = getHostname().split('.avaturn.dev');
  if (host.length > 1) return host[0];

  const hostStage = getHostname().split('.createavatar.io');
  if (hostStage.length > 1) return hostStage[0];

  return '';
}
