import env from "@in3d/environment";
import { BlendFunction, LUT3dlLoader, BloomEffect, BrightnessContrastEffect, EffectPass, LookupTexture, LUT3DEffect, LUTCubeLoader } from "postprocessing";
import { SpotLight, ACESFilmicToneMapping, CineonToneMapping, ClampToEdgeWrapping, DirectionalLight, LinearFilter, LinearToneMapping, NoToneMapping, ReinhardToneMapping, Renderer, Texture, TextureLoader, WebGLRenderer } from 'three';


// let flags = {
//   common: false
// }

// export function add_debug_stuff(AvatarView: AvatarView, gui: any, scene: any) {
//   let renderer = AvatarView.renderer;

//   // gui.removeFolder()

//   if (!flags.common) {
//     // envIntensity
//     gui.add(AvatarView, 'avatar_envMapIntensity', 0, 2, 0.1).listen();
//     gui.add(AvatarView, 'cloth_envMapIntensity', 0, 2, 0.1).listen();

//     flags.common = true;
//   }

//   // let light = AvatarView.scene.getObjectByName("ambient_light");
//   // if (light) {
//   //   gui.add(light, 'intensity', 0, 40, 0.1).listen();
//   //   gui.addColor(light, 'color').listen();

//   //   flags.light = true;
//   // }

//   let spotlight = AvatarView.scene.getObjectByName("spotlight");
//   if (spotlight) {
//     const sphereSize = 0.1;
//     const pointLightHelper = new PointLightHelper(spotlight, sphereSize);
//     scene.add(pointLightHelper);

//     // let control = new TransformControls(AvatarView.camera, renderer.domElement);
//     // control.attach(spotlight);
//     // control.addEventListener('dragging-changed', function (event) {
//     //   AvatarView.controls.enabled = !event.value;
//     // });
//     // scene.add(control);
//   }


// }


export function addToneMappingDebugInfo(gui: any, renderer: WebGLRenderer) {
  const toneMappingOptions: any = {
    None: NoToneMapping,
    Linear: LinearToneMapping,
    Reinhard: ReinhardToneMapping,
    Cineon: CineonToneMapping,
    ACESFilmic: ACESFilmicToneMapping,
    // Custom: CustomToneMapping,
  };

  const params = {
    exposure: 1.0,
    toneMapping: 'ACESFilmic',
  };


  gui.add(params, 'toneMapping', Object.keys(toneMappingOptions))
    .onChange(function () {
      renderer.toneMapping = toneMappingOptions[params.toneMapping];
    });
  gui.add(renderer, 'toneMappingExposure', 0, 2, 0.01).listen();
}

export class LUTManager {

  luts = new Map([
    ["neutral-2", null],
    ["neutral-4", null],
    ["neutral-8", null],
    ["png/bleach-bypass", "png/bleach-bypass.png"],
    ["png/candle-light", "png/candle-light.png"],
    ["png/cool-contrast", "png/cool-contrast.png"],
    ["png/warm-contrast", "png/warm-contrast.png"],
    ["png/desaturated-fog", "png/desaturated-fog.png"],
    ["png/evening", "png/evening.png"],
    ["png/fall", "png/fall.png"],
    ["png/filmic1", "png/filmic1.png"],
    ["png/filmic2", "png/filmic2.png"],
    ["png/matrix-green", "png/matrix-green.png"],
    ["png/strong-amber", "png/strong-amber.png"],
    ["3dl/cinematic", "3dl/presetpro-cinematic.3dl"],
    ["cube/cinematic", "cube/presetpro-cinematic.cube"],
    ["cube/django-25", "cube/django-25.cube"]
  ]);

  assets = new Map();

  async load_all_luts() {

    for (const entry of this.luts) {
      this.load_lut(entry[0])
    }



  }

  async load_lut(name: string) {

    if (name == 'neutral-2') {
      const lutNeutral2 = LookupTexture.createNeutral(2);
      lutNeutral2.name = "neutral-2";
      this.assets.set(lutNeutral2.name, lutNeutral2);
    } else if (name == 'neutral-4') {
      const lutNeutral4 = LookupTexture.createNeutral(4);
      lutNeutral4.name = "neutral-4";
      this.assets.set(lutNeutral4.name, lutNeutral4);
    } else if (name == 'neutral-8') {
      const lutNeutral8 = LookupTexture.createNeutral(8);
      lutNeutral8.name = "neutral-8";
      this.assets.set(lutNeutral8.name, lutNeutral8);
    }


    let path = this.luts.get(name)!;
    if (path === null) {
      return;
    }


    const textureLoader = new TextureLoader();
    const lut3dlLoader = new LUT3dlLoader();
    const lutCubeLoader = new LUTCubeLoader();


    if (/.3dl$/im.test(path)) {

      await lut3dlLoader.loadAsync(`${env.editorResourcesUrl}/lut/${path}`).then((t: any) => {

        t.name = name;
        this.assets.set(name, t);

      });

    } else if (/.cube$/im.test(path)) {

      await lutCubeLoader.loadAsync(`${env.editorResourcesUrl}/lut/${path}`).then((t: any) => {

        t.name = name;
        this.assets.set(name, t);

      });

    } else {

      await textureLoader.loadAsync(`${env.editorResourcesUrl}/lut/${path}`).then((t: any) => {

        t.name = name;
        t.generateMipmaps = false;
        t.minFilter = LinearFilter;
        t.magFilter = LinearFilter;
        t.wrapS = ClampToEdgeWrapping;
        t.wrapT = ClampToEdgeWrapping;
        t.flipY = false;

        this.assets.set(name, LookupTexture.from(t));

      });

    }
  }

  async get(name: string) {
    return this.load_lut(name).then(() => { return this.assets.get(name) });
  }

  set_lut_to_effect(lutEffect: LUT3DEffect, lut_name: string, renderer: WebGLRenderer, use_3d_texture: boolean = true) {
    let lut = this.assets.get(lut_name) as LookupTexture;

    lutEffect.lut.dispose();

    if (renderer.capabilities.isWebGL2) {

      if (renderer.getContext().getExtension("OES_texture_float_linear") === null) {

        console.log("Linear float filtering not supported, " +
          "converting to Uint8");

        lut.convertToUint8();

      }

      lutEffect.lut = use_3d_texture ? lut : lut.toDataTexture();

    } else {
      lutEffect.lut = lut.convertToUint8().toDataTexture();
    }
  }

  add_gui(menu: any, effect: LUT3DEffect, renderer: WebGLRenderer) {

    let lut_params = {
      "LUT": effect.lut.name,
      "base size": effect.lut.image.width,
      "3D texture": true,
      "tetrahedral filter": false,
      "scale up": false,
      "target size": 48,
      "opacity": effect.blendMode.opacity.value,
      "blend mode": effect.blendMode.blendFunction
    }

    const changeLUT = () => {
      this.load_lut(lut_params['LUT']).then(() => {
        this.set_lut_to_effect(effect, lut_params['LUT'], renderer, lut_params["3D texture"])
      })
    }

    let f = menu.addFolder("LUT").close();


    f.add(lut_params, "LUT", [...this.luts.keys()]).onChange(changeLUT);

    f.add(lut_params, "base size").listen()
    // infoOptions.push();

    if (renderer.capabilities.isWebGL2) {

      f.add(lut_params, "3D texture").onChange(changeLUT);
      f.add(lut_params, "tetrahedral filter").onChange((value: boolean) => {

        effect.tetrahedralInterpolation = value;

      });

    }

    f.add(lut_params, "scale up").onChange(changeLUT);
    f.add(lut_params, "target size", [32, 48, 64, 96, 128]).onChange(changeLUT);
    // f.add(lut_params, "show LUT").onChange(updateLUTPreview);

    f.add(lut_params, "opacity", 0.0, 1.0, 0.01).onChange((value: number) => {

      effect.blendMode.opacity.value = value;

    });

    f.add(lut_params, "blend mode", BlendFunction).onChange((value: number) => {

      effect.blendMode.setBlendFunction(Number(value));

    });

  }
}

export class LUTManagerSimple {

  luts = new Map([
    // ["neutral-2", null],
    // ["neutral-4", null],
    // ["neutral-8", null],
    // ["png/bleach-bypass", "png/bleach-bypass.png"],
    // ["png/candle-light", "png/candle-light.png"],
    // ["png/cool-contrast", "png/cool-contrast.png"],
    // ["png/warm-contrast", "png/warm-contrast.png"],
    // ["png/desaturated-fog", "png/desaturated-fog.png"],
    // ["png/evening", "png/evening.png"],
    ["png/fall", "png/fall.png"],
    // ["png/filmic1", "png/filmic1.png"],
    ["png/filmic2", "png/filmic2.png"],
    // ["png/matrix-green", "png/matrix-green.png"],
    // ["png/strong-amber", "png/strong-amber.png"],
    // ["3dl/cinematic", "3dl/presetpro-cinematic.3dl"],
    // ["cube/cinematic", "cube/presetpro-cinematic.cube"],
    // ["cube/django-25", "cube/django-25.cube"]
  ]);

  assets = new Map();


  async load_lut(name: string) {

    let path = this.luts.get(name)!;
    if (path === null) {
      return;
    }
    await new TextureLoader().loadAsync(`${env.editorResourcesUrl}/lut/${path}`).then((t: any) => {

      t.name = name;
      t.generateMipmaps = false;
      t.minFilter = LinearFilter;
      t.magFilter = LinearFilter;
      t.wrapS = ClampToEdgeWrapping;
      t.wrapT = ClampToEdgeWrapping;
      t.flipY = false;

      this.assets.set(name, t);

    });
  }

  async get(name: string) {
    return this.load_lut(name).then(() => { return this.assets.get(name) });
  }

  set_lut_to_effect(lutEffect: LUT3DEffect, lut_name: string, renderer: WebGLRenderer, use_3d_texture: boolean = true) {
    let lut = this.assets.get(lut_name) as Texture;

    lutEffect.lut.dispose();
    lutEffect.lut = lut;
  }

  add_gui(menu: any, effect: LUT3DEffect, renderer: WebGLRenderer){

  }
}



export function addBloomEffectDebugInfo(menu: any, pass: EffectPass, effect: any) {

  menu = menu.addFolder("Bloom").close();

  const blendMode = effect.blendMode;

  const params = {
    "intensity": effect.intensity,
    "radius": effect.mipmapBlurPass.radius,
    "luminance": {
      "filter": effect.luminancePass.enabled,
      "threshold": effect.luminanceMaterial.threshold,
      "smoothing": effect.luminanceMaterial.smoothing
    },
    "selection": {
      "inverted": effect.inverted,
      "ignore bg": effect.ignoreBackground
    },
    "opacity": blendMode.opacity.value,
    "blend mode": blendMode.blendFunction
  };

  menu.add(params, "intensity", 0.0, 10.0, 0.01).onChange((value: number) => {

    effect.intensity = Number(value);

  });

  menu.add(params, "radius", 0.0, 1.0, 0.001).onChange((value: number) => {

    effect.mipmapBlurPass.radius = Number(value);

  });

  let folder = menu.addFolder("Luminance");

  folder.add(params.luminance, "filter").onChange((value: number) => {

    effect.luminancePass.enabled = value;

  });

  folder.add(params.luminance, "threshold", 0.0, 1.0, 0.001)
    .onChange((value: number) => {

      effect.luminanceMaterial.threshold = Number(value);

    });

  folder.add(params.luminance, "smoothing", 0.0, 1.0, 0.001)
    .onChange((value: number) => {

      effect.luminanceMaterial.smoothing = Number(value);

    });

  folder.open();
  folder = menu.addFolder("Selection");

  // folder.add(params.selection, "inverted").onChange((value) => {

  //   effect.inverted = value;

  // });

  // folder.add(params.selection, "ignore bg").onChange((value) => {

  //   effect.ignoreBackground = value;

  // });

  folder.open();

  menu.add(params, "opacity", 0.0, 1.0, 0.01).onChange((value: number) => {

    blendMode.opacity.value = value;

  });

  menu.add(params, "blend mode", BlendFunction).onChange((value: number) => {

    blendMode.setBlendFunction(Number(value));

  });

  // menu.add(pass, "dithering").onChange((value: number) => {

  //   pass.dithering = value;

  // });


}

export function addBrightnessContrastPassDebugInfo(menu: any, brightnessContrastEffect: any) {
  let brightnessContrast_params = {
    "brightness": brightnessContrastEffect.uniforms.get("brightness").value,
    "contrast": brightnessContrastEffect.uniforms.get("contrast").value,
    "opacity": brightnessContrastEffect.blendMode.opacity.value,
    "blend mode": brightnessContrastEffect.blendMode.blendFunction
  };

  let f = menu.addFolder("Brightness & Contrast").close();

  f.add(brightnessContrast_params, "brightness", -1.0, 1.0, 0.001)
    .onChange((value: number) => {

      brightnessContrastEffect.uniforms.get("brightness").value = value;

    });

  f.add(brightnessContrast_params, "contrast", -1.0, 1.0, 0.001)
    .onChange((value: number) => {

      brightnessContrastEffect.uniforms.get("contrast").value = value;

    });

  f.add(brightnessContrast_params, "opacity", 0.0, 1.0, 0.01)
    .onChange((value: number) => {

      brightnessContrastEffect.blendMode.opacity.value = value;

    });

  f.add(brightnessContrast_params, "blend mode", BlendFunction)
    .onChange((value: number) => {

      brightnessContrastEffect.blendMode.setBlendFunction(Number(value));

    });
}


export function addDirectionalLightDebug(dirLight: DirectionalLight, gui: any, scale: number = 1) {
  const upd_camera = () => { dirLight.shadow.camera.updateProjectionMatrix(); }

  gui.addColor(dirLight, 'color').listen();
  gui.add(dirLight, 'intensity', 0, 30, 0.1).listen();
  gui.add(dirLight.shadow, 'bias', -0.01, 0.01, 0.000001).listen();

  gui.add(dirLight.shadow.camera, 'top', -3 * scale, 3 * scale, 0.05).onChange(upd_camera);
  gui.add(dirLight.shadow.camera, 'bottom', -3 * scale, 3 * scale, 0.05).onChange(upd_camera);
  gui.add(dirLight.shadow.camera, 'left', -3 * scale, 3 * scale, 0.05).onChange(upd_camera);
  gui.add(dirLight.shadow.camera, 'right', -3 * scale, 3 * scale, 0.05).onChange(upd_camera);

  gui.add(dirLight.position, 'x', -20 * scale, 20 * scale, 0.05).listen();
  gui.add(dirLight.position, 'y', -20 * scale, 20 * scale, 0.05).listen();
  gui.add(dirLight.position, 'z', -20 * scale, 20 * scale, 0.05).listen();
}

export function addSpotLightDebug(spotLight: SpotLight, gui: any, scale: number = 1) {
  const upd_camera = () => { spotLight.shadow.camera.updateProjectionMatrix(); spotLight.shadow.needsUpdate = true; }

  gui.add(spotLight, 'intensity', 0, 180, 0.1).listen();
  gui.add(spotLight, 'castShadow', [true, false]).listen();
  gui.add(spotLight, 'angle', 0, 2, 0.1).listen();
  gui.add(spotLight.shadow, 'radius', 0, 512, 1).listen();
  gui.add(spotLight.shadow, 'blurSamples', 0, 32, 1).listen();
  gui.add(spotLight.shadow, 'bias', -0.01, 0.01, 0.000001).listen();

  gui.add(spotLight.shadow.camera, 'near', 0, 40, 0.1)
    .listen()
    .onChange(upd_camera)
    .name('shadow_near');
  gui.add(spotLight.shadow.camera, 'far', 0, 40, 0.1)
    .listen()
    .onChange(upd_camera)
    .name('shadow_far');
  gui.add(spotLight.shadow, 'focus', 0, 1, 0.1)
    .listen()
    .onChange(upd_camera);

  gui.add(spotLight.position, 'x', -20, 20, 0.1).listen();
  gui.add(spotLight.position, 'y', -20, 20, 0.1).listen();
  gui.add(spotLight.position, 'z', -20, 20, 0.1).listen();
}
