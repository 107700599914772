import { HaircapIdType, base_path, haircaps } from '@in3d/common';
import { Color, TextureLoader } from 'three';

import { AvatarView } from '../../AvatarView';
import { Uniform } from '../Assets/shaders/_common';
import { HairCapParams } from '../Assets/shaders/hair_cap';

const haircaps_animatable = {
  stripes: `${base_path}/animatable/stripes.webp?q=1`,
  regular: `${base_path}/animatable/regular.webp?q=1`,
  bald_jeoffrey: `${base_path}/animatable/bald_jeoffrey.webp`,
  bald_statham: `${base_path}/animatable/bald_statham.webp`,
  bald_soft: `${base_path}/animatable/bald_soft.webp`,
  bald_zero: `${base_path}/animatable/bald_zero.webp`,
};

// getter
export let haircapId: HaircapIdType;

// fns
async function _getHairCapTexture(id: HaircapIdType, isAnimatable: boolean) {
  return new TextureLoader().loadAsync(isAnimatable ? haircaps_animatable[id] : haircaps[id]);
}

export async function changeHaircap(id: HaircapIdType = 'stripes') {
  if (haircapId == id) {
    return;
  }

  const oldTexture = hairCapShaderParams.hair_cap_map.value;
  hairCapShaderParams.hair_cap_map.value = await _getHairCapTexture(id, AvatarView.is_animatable);
  oldTexture?.dispose();
  haircapId = id;
}

// Params for shader
export const hairCapShaderParams: HairCapParams = {
  hair_cap_map: new Uniform(null),
  hair_cap_multiplier: new Uniform(1.3),
  hair_cap_color: new Uniform(new Color(0, 0, 0)),
};

setTimeout(() => {
  if (IS_DEBUG && AvatarView.gui) {
    const haircapGui = AvatarView.gui.addFolder('haircap').close();
    haircapGui.add(hairCapShaderParams.hair_cap_multiplier, 'value', 1, 3, 0.01).name('haircap_strength');

    const params = {
      exposure: 1.0,
      haircaps: 'stripes',
    };

    haircapGui.add(params, 'haircaps', Object.keys(haircaps)).onChange(function () {
      changeHaircap(params.haircaps as HaircapIdType);
    });
  }
}, 1000);
