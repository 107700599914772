import React from 'react'
import cn from 'classnames'
import { CategoryName } from '@in3d/core'

export const FaceAnimationsPanel = ({
  optionCards,
  category,
}: {
  optionCards: React.ReactNode;
  category?: CategoryName;
}) => {
  console.log(optionCards);
  return (
    <>
      {/* <div className={cn(['option-card', category && category])} onClick={() => {}}>
        <img src="/assets/plus.svg" alt="plus" />
      </div> */}
      {optionCards}
    </>
  );
};
