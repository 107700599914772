import env from '@in3d/environment';
import { auth } from '@in3d/store';

const cache: { present: boolean; current: null | boolean } = { present: false, current: null };
export const isLoadedAsApi = (): boolean => {
  if (env.simulateAPIsession) return true;
  if (cache.present) return !!cache['current'];
  else {
    if (auth.isAuth && auth.data?.email) {
      const result = auth.data.email.split('@')[1].includes('avaturn.dev');
      cache.present = true;
      cache.current = result;
      return result;
    } else return false;
  }
};
