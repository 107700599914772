import { ClientBuilder, createSettings } from '@in3d/api';
import { AvatarCustomization } from '@in3d/common';
import env from '@in3d/environment';
import { auth } from '@in3d/store';

const get_headers = (token?: string | null) => ({
  Authorization: `Bearer ${token || ''}`,
});

class CommonClient {
  constructor(api: ClientBuilder) {
    this.api = api;
  }
  api: ClientBuilder;

  loadCustomizations(customization_id: string): Promise<any> {
    return this.api.get(`${env.baseUrl}avatars/${customization_id}`, null, get_headers(auth.token));
  }
  updateCustomization(customization_id: string, customization_data: AvatarCustomization) {
    return this.api.put(
      `${env.baseUrl}avatars/${customization_id}/customization`,
      customization_data,
      get_headers(auth.token)
    );
  }
}
export const commonClient = new CommonClient(
  new ClientBuilder(createSettings(env.localResources ? '' : env.resourceUrl))
);
