import { Shader } from 'three';

export function patch_shader_for_uv1_ao(shader: Shader) {
  shader.fragmentShader = shader.fragmentShader.replace(
    '#include <aomap_fragment>',
    `
        #ifdef USE_AOMAP

            // reads channel R, compatible with a combined OcclusionRoughnessMetallic (RGB) texture

            #if defined( FLIP_UV_OCCLUSION )
            float ambientOcclusion = ( texture2D( aoMap, vec2(vUv.x, 1.0-vUv.y) ).r - 1.0 ) * aoMapIntensity + 1.0;
            #else
            float ambientOcclusion = ( texture2D( aoMap, vec2(vUv.x, vUv.y) ).r - 1.0 ) * aoMapIntensity + 1.0;
            #endif

            reflectedLight.indirectDiffuse *= ambientOcclusion;

            #if defined( USE_ENVMAP ) && defined( STANDARD )

                float dotNV = saturate( dot( geometry.normal, geometry.viewDir ) );

                reflectedLight.indirectSpecular *= computeSpecularOcclusion( dotNV, ambientOcclusion, material.roughness );

            #endif

        #endif
        `
  );
}
