import React from 'react';
import cn from 'classnames';
import { CategoryName } from '@in3d/core';
import Dropzone from 'react-dropzone';

export const BodyAnimationsPanel = ({
  optionCards,
  category,
  handleDropFile,
}: {
  optionCards: React.ReactNode;
  category?: CategoryName;
  handleDropFile?: () => void;
}) => {

  return (
    <>
      {/* <Dropzone
        multiple={false}
        // useFsAccessApi={false}
        accept={{ '': ['.glb'] }}
        onDropAccepted={(files) => handleDropFile(files)}
        // onDropRejected={(files) => handleRejectedDrop(files)}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={cn(['option-card', category && category])} {...getRootProps()}>
            <img src="/assets/plus.svg" alt="plus" />
            <input type="file" className="file-input__field" {...getInputProps()} />
          </div>
        )}
      </Dropzone> */}
      {optionCards}
    </>
  );
};
