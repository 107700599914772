import { HaircapIdType, getHostname } from '@in3d/common';
import { developerConfig } from '@in3d/store';
import { BufferGeometry, Material, MeshStandardMaterial, Object3D, SkinnedMesh } from 'three';

export enum DownloadState {
  NotLoaded = 'NOT_LOADED',
  Loading = 'LOADING',
  Loaded = 'LOADED',
}

export enum Slots {
  Eyes = 'Eyes',
  Head = 'Head',
  Shoes = 'Shoes',
  // Look = 'Look',
  Top = 'Top',
  Bottom = 'Bottom',
  Face = 'Face',
}

export class Settings {
  embeded_retarget_info = true;
  retargeting_offsets_attribute = 'position';
  mesh_name = '';
  is_single_mesh = true;

  retargeting_multiplier = 1.0;
}

export class SettingsJson {
  restrict_gender?: boolean = false;
  with_head?: boolean = false;
  with_shoes?: boolean = false;
  use_fresnel?: boolean = true;
  use_envlight?: boolean = false;

  // hair
  only_haircap?: boolean = false;
  haircap?: HaircapIdType = developerConfig.editor.defaultHaircap || 'stripes';
}

export class AnimationSettingsJson {
  use_hips_position = true;
}

export const placement_to_slots: Record<string, any> = {
  bottom: [Slots.Bottom],
  look: [Slots.Bottom, Slots.Top],
  top: [Slots.Top],
  eyes: [Slots.Eyes],
  head: [Slots.Head],
  shoes: [Slots.Shoes],
  faceMask: [Slots.Face],
};

export const clamp = (x: number, min: number, max: number) => {
  return Math.min(Math.max(x, min), max);
};

export function disposeThreeJSObject(object?: Object3D, with_geometry = true) {
  if (!object) {
    return;
  }
  object.traverse((node) => {
    if (node instanceof SkinnedMesh) {
      if (with_geometry) {
        const geometry = node.geometry as BufferGeometry;
        for (const key in geometry.attributes) {
          geometry.deleteAttribute(key);
        }
        geometry.setIndex([]);
        geometry.dispose();
        node.geometry = undefined;
      }
      if (node.material instanceof MeshStandardMaterial) {
        node.material.map?.dispose();
        node.material.normalMap?.dispose();
        node.material.aoMap?.dispose();
        node.material.roughnessMap?.dispose();
        node.material.metalnessMap?.dispose();
        node.material.alphaMap?.dispose();

        node.material.map = null;
        node.material.normalMap = null;
        node.material.aoMap = null;
        node.material.roughnessMap = null;
        node.material.metalnessMap = null;
        node.material.alphaMap = null;

        if (node.material.userData['name'] != 'no_dispose') {
          node.material.dispose();
          (node.material as Material).onBeforeCompile = noop;
        }

        node.material = undefined;
      }
    }
  });
}

function noop() {
  return;
}

function mat_to_mat() {
  //   const cur_mat = material_;
  //   let new_mat = new MeshPhysicalMaterial();
  // //   // for (const p in cur_ma)
  // //   new_mat.map = cur_mat.map;
  // //   new_mat.roughness = cur_mat.roughness;
  // //   new_mat.roughnessMap = cur_mat.roughnessMap;
  // //   new_mat.metalness = cur_mat.metalness;
  // //   new_mat.metalnessMap = cur_mat.metalnessMap;
  // //   new_mat.normalMap = cur_mat.normalMap;
  // //   new_mat.aoMapIntensity = cur_mat.aoMapIntensity;
  // //   new_mat.aoMap = cur_mat.aoMap;
  // //   mesh.material = new_mat;
  // //   material = mesh.material as MeshPhysicalMaterial;
}

function new_skinned_mesh() {
  //////////////// Create new ////////////////////////
  // const skinnedMesh = new THREE.SkinnedMesh(child.geometry, child.material);
  // const bones = THREE.getBoneList(current_rig);
  // const skeleton = new THREE.Skeleton(bones);
  // skinnedMesh.add(skeleton.bones[0].parent);
  // skinnedMesh.bind(skeleton, child.matrixWorld)
  // // swap mesh for skinned mesh
  // child.parent.add(skinnedMesh)
  // child.parent.remove(child)
}

export async function traverse(object: Object3D, callback: (object: Object3D) => Promise<void>) {
  await callback(object);
  const children = object.children;
  for (let i = 0, l = children.length; i < l; i++) {
    await traverse(children[i], callback);
  }
}

export function fixMime(material: MeshStandardMaterial) {
  [material.map, material.roughnessMap, material.normalMap, material.metalnessMap, material.aoMap].forEach((v) => {
    if (v) v.userData.mimeType = 'image/jpeg';
  });

  if (material.map && (material.transparent || material.alphaTest > 0)) {
    material.map.userData.mimeType = 'image/png';
  }
}

export function loadFileFromDisk() {
  return new Promise<string>((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    // input.accept = '*'; // Optionally, restrict the user to select only image files
    input.onchange = (e: Event) => {
      if (!e.target) return;
      const fileInput = e.target as HTMLInputElement;
      if (!fileInput.files) return;
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result === 'string') {
          resolve(result);
        } else{
          reject('error loading file')
        }
        input.remove();
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    };
    input.click();
  });
};
