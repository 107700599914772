import { CategoryName } from '@in3d/common';
import SVG from 'react-inlinesvg';

export const useCategories = () => {
  return [
    { name: CategoryName.Body, icon: <SVG src="../../assets/body.svg" /> },
    { name: CategoryName.Hair, icon: <SVG src="../../assets/hair.svg" /> },
    { name: CategoryName.Outfit, icon: <SVG src="../../assets/outfit.svg" /> },
    { name: CategoryName.Glasses, icon: <SVG src="../../assets/glasses.svg" /> },
    { name: CategoryName.Shoes, icon: <SVG src="../../assets/shoes.svg" /> },
    { name: CategoryName.Animations, icon: <SVG src="../../assets/animations.svg" /> },
  ];
};
