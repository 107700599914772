import './header.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { blockScroll } from '@in3d/common';
import { useState, useEffect, memo } from 'react';
import { BaseRoutes, cn, getHostname } from '@in3d/common';
import { auth, developerConfig } from '@in3d/store';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';
import { LogoText } from '@in3d/ui';

const isIframe = window !== window.parent;

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isMobileNav, setIsMobileNav] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const isBurgerMenu = isIframe;
  const isAuthBtn = true;

  useEffect(() => {
    const onScroll = () => {
      if (document.documentElement.scrollTop >= 10) {
        setIsScroll(true);
      }
      if (document.documentElement.scrollTop <= 10) {
        setIsScroll(false);
      }
    };
    document.addEventListener('scroll', onScroll);
    document.addEventListener('touchmove', onScroll);
  }, []);
  const withNav = developerConfig.app.withNav;

  const handleAuth = () => {
    if (auth.isAuth) {
      auth.logout();
    }
    navigate(BaseRoutes.Login);
  };

  const isMainLinkExternal = getHostname().includes('avaturn.dev');
  const changeMobileNavStatus = () => {
    setIsMobileNav((isOpen) => {
      blockScroll(!isOpen);
      return !isOpen;
    });
  };

  return (
    <div className={cn(['header', isScroll && 'scroll'])}>
      <div className="header__content">
        <div className="header__bar">
          <div className="header__nav">
            {!isMainLinkExternal ? (
              <NavLink className={cn(['header__logo', !withNav && 'single'])} to="/">
                {developerConfig.app.customLogo ? (
                  <img src={developerConfig.app.customLogo} className="header__icon" alt="header-icon" />
                ) : (
                  <LogoText className="header-logo" />
                )}
              </NavLink>
            ) : (
              <a
                className={cn(['header__logo', !withNav && 'single'])}
                href="https://avaturn.me"
                target="_blank"
                rel="noreferrer"
              >
                <LogoText className="header-logo" />
              </a>
            )}
            {withNav && (
              <div className="header__links">
                <NavLinks />
              </div>
            )}
          </div>
          <div className="header__actions">
            <div className="header__social">
              <a
                href="https://www.linkedin.com/company/28502076"
                target="_blank"
                rel="noreferrer"
                className="header__resource"
              >
                <img src="/assets/landing/social/linkedin.svg" alt="linkedin" />
              </a>
              <a href="https://twitter.com/avaturn_me" target="_blank" rel="noreferrer" className="header__resource">
                <img src="/assets/landing/social/twitter.svg" alt="twitter" />
              </a>
              <a
                href="https://discord.com/invite/2xmxXv2SQk"
                target="_blank"
                rel="noreferrer"
                className="header__resource"
              >
                <img src="/assets/landing/social/discord.svg" alt="discord" />
              </a>

              <div className="header__link header__link--mobile header__link--main">For Developers</div>
            </div>
            <div className="header__auth">
              {(isAuthBtn || !isBurgerMenu) && (
                <div
                  className={cn([
                    'header__btn auth-btn',
                    !auth.isAuth && 'header__btn--invert',
                    !isBurgerMenu && 'static',
                  ])}
                  onClick={handleAuth}
                >
                  {auth.isAuth ? 'Log Out' : 'Create avatar'}
                </div>
              )}

              {isBurgerMenu && (
                <div className={cn(['header-burger', isMobileNav && 'active'])}>
                  <div className="header-burger__btn" onClick={changeMobileNavStatus}>
                    <>
                      <span />
                      <span />
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobileNav &&
          createPortal(
            <div key="burger" className="burger-box">
              <div className="burger-box__content">
                <div className="burger-box__header">
                  <LogoText className="header-logo" />

                  <div className="burger-box__close" onClick={changeMobileNavStatus}>
                    <img src="/assets/close.svg" alt="close" />
                  </div>
                </div>
                <div className="burger-box__links">
                  <NavLinks />
                </div>
                <div className="burger-box__actions">
                  {!location.pathname.includes('hub') && (
                    <NavLink to={BaseRoutes.Hub} className="header__btn header__btn--invert">
                      My avatars
                    </NavLink>
                  )}
                  <div className="header__btn burger-box__logout" onClick={handleAuth}>
                    {!auth.isAuth ? 'Sign in' : 'Log Out'}{' '}
                  </div>
                </div>
              </div>
            </div>,
            document.querySelector('#portal') as Element
          )}
      </div>
    </div>
  );
};

const NavLinks = memo(() => (
  <>
    <a className="header__link" target="_blank" rel="noreferrer" href="https://docs.avaturn.me/">
      Docs
    </a>
    {/*
    <NavLink
      target="_blank"
      rel="noreferrer"
      className="header__link"
      to={BaseRoutes.Developers}
    >
      For Developers
    </NavLink>
    */}
    <a
      className="header__link header__link--main"
      target="_blank"
      rel="noreferrer"
      href="https://developer.avaturn.me/"
    >
      Integrate
    </a>
    <a target="_blank" rel="noreferrer" className="header__link" href="mailto:hello@avaturn.me">
      Contact Us
    </a>
  </>
));
const _Header = observer(Header);
export { _Header as Header };
