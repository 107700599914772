import env from '@in3d/environment';
import { action, makeObservable, observable, observe } from 'mobx';
import {
  Color,
  FrontSide,
  Group,
  InterleavedBufferAttribute,
  LinearEncoding,
  Mesh,
  MeshStandardMaterial,
  Scene,
  Shader,
  SkinnedMesh,
  Texture,
  TextureLoader,
  sRGBEncoding,
} from 'three';
import { AvatarView, gltf_loader } from '../../../AvatarView';
import { textureToArray } from '../../../addons/export_utils';
import { Uniform } from '../../Assets/shaders/_common';
import { patch_shader_for_uv1_ao } from '../../Assets/shaders/ao_uv1';
import { disable_vertex_color } from '../../Assets/shaders/disable_vertex_color';
import { FresnelParams, fresnel } from '../../Assets/shaders/fresnel';
import { adjust_skeleton } from '../../animation_utils';
import { concat_offsets_ids, flipY_uv1, flipY_uv2, load_offsets } from '../../utils';
import { DownloadState, Slots, fixMime } from '../common';
import { RetargetableAsset } from './asset';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

export class Cloth extends RetargetableAsset {
  map_url: string;

  origDiffuseMap: Texture;
  decalMapUrl = '';

  private _overrideMaterial: MeshStandardMaterial;
  get overrideMaterial() {
    return this._overrideMaterial;
  }
  set overrideMaterial(x: MeshStandardMaterial) {
    this._overrideMaterial = x;
    this._loadFromOverrideMaterial();
  }

  alpha_promise: Promise<void> = Promise.resolve();

  constructor() {
    super();
    makeObservable(this, {
      type: observable,
      mesh_download_state: observable,
      setDownloadState: action.bound,
      settings_json: observable,
    });
  }

  private _loadFromOverrideMaterial(material?: MeshStandardMaterial) {
    const om = this.overrideMaterial;

    const m = material || ((this.group.children[0] as Mesh).material as MeshStandardMaterial);

    // ['map', 'normalMap', 'roughness', 'metalne']
    m.map = om.map;
    m.normalMap = om.normalMap;
    m.roughness = om.roughness;
    m.metalness = om.metalness;
    m.metalnessMap = om.metalnessMap;
    m.roughnessMap = om.roughnessMap;
    m.alphaTest = om.alphaTest;
    m.transparent = om.transparent;
    m.aoMap = om.aoMap;
    m.aoMapIntensity = om.aoMapIntensity;

    m.map && (m.map.generateMipmaps = false);
  }
  private async load_from_existing() {
    // if (!this.ref.mesh) {
    // need to load every time as we clean memory and remove masks and meshes
    await this.ref.load_all();
    // }
    this.group = this.ref.group.clone();
    this.setDownloadState(DownloadState.Loaded);
    AvatarView.scene_objects_group.add(this.group);
    this.avatar_mask = this.ref.avatar_mask;
    this.avatar_mask_uv2 = this.ref.avatar_mask_uv2;
    this.offset_map = this.ref.offset_map;

    const shirt = this.group.children.find((o) => {
      return ['T-Shirt_low.002', 'T-Shirt_low'].includes(o.userData['name']);
    }) as SkinnedMesh;
    shirt.material = (shirt.material as MeshStandardMaterial).clone();

    const material = shirt.material as MeshStandardMaterial;

    const d = new TextureLoader();
    const map = await d.loadAsync(this.map_url);
    map.encoding = sRGBEncoding;
    map.flipY = false;
    map.generateMipmaps = false;
    map.needsUpdate = true;
    material.map = map;
    material.needsUpdate = true;
    // material.normalScale = new Vector2(1, 1);
    material.normalScale.y = 0.5;
    material.normalScale.x = 0.5;
    material.roughness = 1.1;
  }

  private async load_mesh() {
    const gltf = await gltf_loader.loadAsync(this.model_full_path);

    const meshes: SkinnedMesh[] = [];
    gltf.scene.traverse((node: any) => {
      if (!node.isMesh) {
        return;
      }

      const mesh = node as SkinnedMesh;
      const material = mesh.material as MeshStandardMaterial;

      meshes.push(mesh);

      mesh.frustumCulled = false;
      mesh.castShadow = this.material_settings.cast_shadow;
      mesh.receiveShadow = this.material_settings.receive_shadow;

      material.envMapIntensity = AvatarView.cloth_envMapIntensity * this.material_settings.env_mult;

      if (this.settings_json.use_envlight) {
        material.envMapIntensity = 0.4;
      }

      adjust_skeleton(mesh);
      mesh.updateMatrixWorld();

      // mesh.geometry.deleteAttribute('tangent');
      // material.normalScale.y = -1.0;
      // if there are no tangents available we can compute them with
      // computeTangents(mesh.geometry, false); after retargeting

      if (this.settings.embeded_retarget_info) {
        this.loadRetargetingInfoFromMesh(mesh);
      }

      if (!this.avatar_mask && material.aoMap && material.aoMap.name == 'occlusion') {
        this.loadAvatarAlpha(gltf, material);
      }

      // -------------
      // Material
      // --------------

      fixMime(material);

      if (this.material_settings.roughness != -1) {
        material.roughnessMap = null;
        material.roughness = this.material_settings.roughness;
      }

      if (this.material_settings.metalness != -1) {
        material.metalnessMap = null;
        material.metalness = this.material_settings.metalness;
      }
      if (this.material_settings.aoMapIntensity != -1) {
        material.aoMapIntensity = this.material_settings.aoMapIntensity;
      }
      if (this.material_settings.normalScale != -1) {
        material.normalScale.x = this.material_settings.normalScale;
        material.normalScale.y = Math.sign(material.normalScale.y) * this.material_settings.normalScale;
      }

      material.needsUpdate = true;

      if (mesh.geometry.hasAttribute('color')) {
        mesh.geometry.deleteAttribute('color');
      }

      // Becomes less blurry
      material.map && (material.map.generateMipmaps = false);
      // material.normalMap!.generateMipmaps = false;
      // material.roughnessMap!.generateMipmaps = false;

      // Backup diffuse map for the cases where we override diffuse with decals
      if (material.map) {
        this.origDiffuseMap = material.map;
      }

      material.shadowSide = FrontSide;

      this.overrideMaterial && this._loadFromOverrideMaterial(material);

      material.onBeforeCompile = (shader: Shader) => {
        // 1. The vertex colors contain other info, so not using it
        disable_vertex_color(shader);

        // 2. Use uv1 for AOmap
        patch_shader_for_uv1_ao(shader);

        if (this.settings_json.use_fresnel) {
          const fresnel_params: FresnelParams = {
            // other
            fresnel_dir: new Uniform(new Color(1.0, 0.0, 1.0)),
            fresnel_ior: new Uniform(1.1),
            fresnel_strength: new Uniform(0.1),
            fresnel_use_custom_direction: new Uniform(false),
          };
          const c = fresnel(shader, fresnel_params);
        }
        // if (AvatarView.gui) {
        //   AvatarView.gui.add(shader.uniforms[`fresnel_strength_${c-1}`], 'value', 0, 1, 0.01);
        //   // AvatarView.gui.add(shader.uniforms[`fresnel_strength_1`], 'value', 0, 1, 0.01);
        // }
        // }
      };

      if (IS_DEBUG && AvatarView.gui) {
        this.addDebugInfo(material);
      }
    });

    const group = new Group();
    group.name = this.placement[0] == Slots.Shoes ? 'avaturn_shoes' : 'avaturn_look';
    meshes.forEach((v, i) => {
      v.name = `${group.name}_${i}`;
      (v.material as MeshStandardMaterial).name = `${group.name}_${i}_material`;
      group.add(v);
    });

    this.group = group;
    this.group.visible = false;

    gltf.scene.updateMatrixWorld();
    AvatarView.scene_objects_group.add(group);

    // try {
    await this.alpha_promise;
  }

  private addDebugInfo(material: MeshStandardMaterial) {
    const SceneStatsGUI = AvatarView.gui!.getFolder('clothes').addFolder(this.id).close();

    SceneStatsGUI.add(material, 'aoMapIntensity', 0, 2, 0.01).listen();
    SceneStatsGUI.add(material.normalScale, 'x', -1, 3, 0.01).listen();
    SceneStatsGUI.add(material.normalScale, 'y', -1, 3, 0.01).listen();
    SceneStatsGUI.add(material, 'roughness', 0, 3, 0.01).listen();
    SceneStatsGUI.add(material, 'metalness', 0, 1, 0.01).listen();
    SceneStatsGUI.addColor(material, 'color').listen();
    SceneStatsGUI.add(this.settings, 'retargeting_multiplier', 0, 3, 0.01)
      .listen()
      .onChange(() => {
        this.retarget(AvatarView.currentState.avatar);
      });
  }

  private loadAvatarAlpha(gltf: GLTF, material: MeshStandardMaterial) {
    if (AvatarView.is_animatable) {
      if (gltf.userData['has_new_masks'] === true) {
        // new packing format
        this.load_mask_and_offset_map(material.aoMap!.image, undefined, true, true);
      } else {
        this.alpha_promise = new TextureLoader()
          .loadAsync(`${env.editorResourcesUrl}/animatable/alpha_masks/${this.id}.png`)
          .then((x) => {
            x.flipY = false;
            x.generateMipmaps = false;
            x.encoding = LinearEncoding;

            const mask = textureToArray(x, 512);

            this.load_mask_and_offset_map(null, mask.image.data);
          })
          .catch(() => {
            return;
          });
      }
    } else {
      this.load_mask_and_offset_map(material.aoMap!.image);
    }
  }

  override async load_all() {
    if (!this.settings.embeded_retarget_info) {
      [this.offsets, this.offsets_ids] = await load_offsets(this.asset_dir);
    }
    this.setDownloadState(DownloadState.Loading);
    if (this.ref) {
      await this.load_from_existing();
    } else {
      await this.load_mesh();
    }
    this.setDownloadState(DownloadState.Loaded);
  }
}
