const colorList = ['#9198D7', '#E4BA87', '#ED9DB5', '#9DECB8', '#CFA6E1', '#E88780', '#75CDD2', '#EBE290'];
const cache: Record<number, string> = {};

export const getRandomColor = (i: number) => {
  if (cache[i]) return cache[i];
  if (i >= colorList.length) {
    const count = Math.floor(i / colorList.length);
    const color = colorList[i - colorList.length * count];
    cache[i] = color;
    return color;
  }
  return colorList[i];
};
