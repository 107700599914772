import { BufferAttribute, BufferGeometry, DoubleSide, Mesh, MeshBasicMaterial } from 'three';

import { currentState } from '../../CurrentState';
import { Slots } from '../common';
import { loadBinDecalPreset } from './loadBinDecalPreset';
import { DecalMaker } from '.';

export async function loadDecalData(this: DecalMaker) {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = '.json';
  input.onchange = (e: Event) => {
    if (!e.target) return;
    const fileInput = e.target as HTMLInputElement;
    if (!fileInput.files) return;
    const file = fileInput.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      console.log('first step');
      const result = e.target?.result;
      if (typeof result === 'string' && e.target !== null) {
        console.log('second step');
        const data = JSON.parse(e.target.result as string);

        // Find the parent with the needed name
        let parent;
        this.avatar.traverse((node) => {
          if (node.name === data.name) {
            parent = node;
          }
        });

        // If parent is found, create a new decal and add it to the decals array
        if (parent) {
          console.log('third step');
          const geometry = new BufferGeometry();
          Object.keys(data).forEach((key) => {
            if (key !== 'name' && key !== 'clothName') {
              geometry.setAttribute(key, new BufferAttribute(new Float32Array(data[key].array), data[key].itemSize));
            }
          });

          // geometry.clone()

          const material = new MeshBasicMaterial({
            color: 'red',
            side: DoubleSide,
            polygonOffset: true,
            polygonOffsetFactor: -4,
          });
          const mesh = new Mesh(geometry, material);

          const decal = { parent, mesh };
          // this.backupPositions();
          this.decals.push(decal);
          this.scene.add(decal.mesh);
          this.decalHelper = mesh;
        }
      }
    };
    reader.readAsText(file); // Read the file as text
  };
  input.click();
}

export function exportDecalData(this: DecalMaker) {
  // export name of the mesh, name of the selected cloth,
  // position, normal, uv, uv2

  if (this.decals.length === 0) return;
  const decal = this.decals[0];
  const { attributes } = decal.mesh.geometry;

  const clothName = currentState.cloth[Slots.Top]?.id ?? 'none';
  const data = {
    name: decal.parent.name,
    clothName,
    ...attributes,
  };

  const json = JSON.stringify(data);
  const blob = new Blob([json], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'decal.json';
  link.href = url;
  link.click();
}

export async function loadDecalPresetFromFile(this: DecalMaker) {
  // Get the current cloth name
  const clothName = currentState.cloth[Slots.Top]?.id ?? 'none';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = await loadBinDecalPreset(clothName) as any;

  if(!data) return;

  // Find the parent with the needed name
  let parent;
  this.avatar.traverse((node) => {
    if (node.name === data.name) {
      parent = node;
    }
  });

  // If parent is found, create a new decal and add it to the decals array
  if (parent) {
    const geometry = new BufferGeometry();
    Object.keys(data).forEach((key) => {
      if (key !== 'name' && key !== 'clothName') {
        geometry.setAttribute(key, new BufferAttribute(new Float32Array(data[key].array), data[key].itemSize));
      }
    });

    const material = new MeshBasicMaterial({
      color: 'red',
      side: DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: -4,
    });
    const mesh = new Mesh(geometry, material);

    const decal = { parent, mesh, clothName };
    // this.backupPositions();
    this.decals.push(decal);
    this.decalHelper = mesh;
    this.scene.add(mesh);
  }
}
