import { useEffect } from 'react';

export const useAppHeight = () => {
  useEffect(() => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    let timer: ReturnType<typeof setTimeout>;
    const handler = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
      }, 100);
    };
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);
};
