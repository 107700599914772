import { EvnSetup } from './environment';

const hostname_overrides: Record<string, string> = {
  'avaturn.uvisiontech.cn': 'metaversesz.avaturn.dev',
};
export const environment: EvnSetup = {
  resourceUrl: 'https://api.avaturn.me/',
  baseUrl: 'https://api.avaturn.me/',
  editorResourcesUrl: 'https://assets.avaturn.me/editor_resources',
  localResources: false,
  production: true as const,
  isLocalRun: false,
  simulateAPIsession: false,
  scanSizeMultiplier: 4,
  debugShowIdsOnCards: false,
  hostname_override: hostname_overrides[window.location.hostname] || '',
  rootHosts: ['hub.avaturn.me', 'face.avaturn.me', 'developer.avaturn.me'],
  hostsAnimatable: ['face.avaturn.me'],
  externalScanHost: 'scan.avaturn.me',
  scanApiHost: 'https://app.gsize.io',
  hostsWithPriveleges: [],
  firebaseConfig: {
    apiKey: 'AIzaSyDy0k5N2IXOFlPd9JBVpGB-yB4ZLbVoQ7o',
    authDomain: 'auth.avaturn.me',
    projectId: 'in3d-web-avatars-prod',
    storageBucket: 'in3d-web-avatars-prod.appspot.com',
    messagingSenderId: '646095974441',
    appId: '1:646095974441:web:c9326c3abe62777b752c38',
    measurementId: 'G-GHMX28BLGC',
  },
  sentryConfig: {
    //dsn: 'https://96bbd72e20364134b8b274d002aa65f2@sentry.gsize.io/2',
    dsn: 'https://068c1656aa3940c295af0b73fbfe9f18@o1343556.ingest.sentry.io/4504395074699264',
    environment: 'production',
    tracesSampleRate: 1.0,
  },
} as const;

export default environment;
