import env from '@in3d/environment';

class Logger {
  log: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
  info: (...args: any[]) => void;
}

export const log: Logger = env.production ? new Logger() : console;
