type StringBool = 'true' | 'false';
class PageParams {
  avatar_link: string;
  gender: 'male' | 'female' = 'male';
  scene = 'scene4';

  //
  download_immediately: string;

  //
  debug: string;
  debug_scene: string;
  antialias: StringBool = 'true';
  webgl1: string;
  adaptive_dpr: StringBool = 'false';

  //
  customization_id: string;

  //
  avatar_link2: string;
  avatar_link3: string;

  //
  headless: StringBool = 'false';
  session_id: string = '';
}

export function get_page_params() {
  const _page_params: any = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (s, k, v) => {
    _page_params[k] = v;
    return '';
  });

  if (_page_params.avatar_link) {
    _page_params.avatar_link = decodeURIComponent(_page_params.avatar_link);
  }

  return Object.assign(new PageParams(), _page_params) as PageParams;
}

export const page_params = get_page_params();
