import { KeyframeTrack, Matrix4, Quaternion, QuaternionKeyframeTrack, Vector3 } from 'three';

export class TrackingRecorder {
  times: number[] = [];
  keyframeTrack: number[][] = [];
  headBoneTrack: number[] = [];
  numBlendshapes: number;
  start_time: number | undefined;
  enabled: boolean;

  constructor(numBlendshapes: number, enabled = true) {
    this.numBlendshapes = numBlendshapes;
    this.enabled = enabled;
  }

  cleanup = () => {
    // Reset keyframe storage.
    this.times.length = 0;
    this.keyframeTrack.forEach((x) => {
      x.length = 0;
    });
    this.keyframeTrack.length = 0;
    this.headBoneTrack.length = 0;
  };

  stopRecording = (meshName: string) => {
    const timesArray = new Float32Array(this.times);
    const valuesArray = new Float32Array(this.keyframeTrack.flat());

    if (timesArray.length === 0 || valuesArray.length === 0) {
      return null;
    }
    const trackName = `${meshName}.morphTargetInfluences`;
    return {
      blenshapesKeys: new KeyframeTrack(trackName, timesArray, valuesArray),
      headKeys: new QuaternionKeyframeTrack('Head.quaternion', timesArray, this.headBoneTrack),
    };
  };

  recordInfluence(idx: number, influence: number) {
    if (!this.enabled) return;
    this.keyframeTrack[this.keyframeTrack.length - 1][idx] = influence;
  }

  recordHeadTransform(matrix: Matrix4) {
    if (!this.enabled) return;

    const [t, q, s] = [new Vector3(), new Quaternion(), new Vector3()];
    matrix.decompose(t, q, s);

    this.headBoneTrack.push(...q.toArray());
  }

  newFrame(frameTime?: number) {
    if (!this.enabled) return;

    // values
    this.keyframeTrack.push(Array(this.numBlendshapes).fill(0));

    // time
    if (frameTime) {
      this.start_time ??= frameTime;
      const time = (frameTime - this.start_time) / 1000;
      this.times.push(time);
    } else {
      this.start_time ??= Date.now();
      const time = (Date.now() - this.start_time) / 1000;
      this.times.push(time);
    }
  }
}
