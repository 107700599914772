import { authClient, AuthData, avatarClient } from '@in3d/api';
import { action, makeAutoObservable } from 'mobx';
import { avatars } from './avatars.store';
import { Unsubscribe } from 'firebase/firestore';
import { localData } from '@in3d/common';

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
class Auth {
  isAuth = false;
  data?: AuthData;
  loaded = false;
  avatarsSubscription: Unsubscribe | null = null;
  iconsSubscription: Unsubscribe | null = null;
  userSessionKey = '';

  constructor() {
    makeAutoObservable(this);
  }

  get token(): string {
    return this.data ? this.data.token : '';
  }

  async logout() {
    await authClient.logout();
    this.removeAuthData();
    localData.clear();
    avatars.clearStore();
  }

  saveUserSessionToken(token: string) {
    this.userSessionKey = token;
  }

  updateToken() {
    authClient.refreshToken(true).then(
      action('successRefreshToken', (token) => {
        if (this.data) this.data.token = token;
      })
    );
  }

  unsubscribeStore() {
    if (this.avatarsSubscription) this.avatarsSubscription();
    if (this.iconsSubscription) this.iconsSubscription();
  }

  updateAuthData(data: AuthData) {
    this.unsubscribeStore();
    this.isAuth = !!data.token;
    this.data = data;
    this.loaded = true;
    this.avatarsSubscription = avatarClient.subscribeAvatars(data.id, avatars.updateAvatarData);
  }

  setLoadedStatus(status: boolean) {
    this.loaded = status;
  }

  removeAuthData() {
    this.loaded = true;
    this.isAuth = false;
    this.data = undefined;
  }
}

const auth = new Auth();

export { auth };
