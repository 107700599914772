import { PerspectiveCamera, Vector3 } from 'three';

import { loadAvatarFromURL } from './avatar_list';
import { AvatarView } from './AvatarView';
import { avatarList, customizationInfo, fixMissing } from './content';
import { currentState } from './core/CurrentState';
import { Avatar, dispose_avatars, init_avatars, load_avatar } from './core/resources/avatar';
import { init_avatars_animatable } from './core/resources/avatar_animatable';
import { takeScreenshot2 } from './editor_api';
import { generateModel } from './export_api';
import { resources } from './resource_manager';
import { AvatarCustomization, AvatarInfo } from '@in3d/common';

// async function load_from_data(customization_data: AvatarResponseData) {
//   currentState.takeOffClothes();

//   if (resources.avatars.length == 0) {
//     resources.clearAvatarList();

//     const avatar_glb_list = loadAvatarFromURL(
//       customization_data.scan_glb_url,
//       customization_data.scan_metadata['gender']
//     );

//     avatarList.remove_all();
//     avatarList.load_from_list(avatar_glb_list);
//     init_avatars(avatarList);
//   }

//   if (currentState.avatar) {
//     currentState.avatar.set_visibility(false);
//   }
//   dispose_avatars(false);

//   set_new_url(customization_data.scan_glb_url, customization_data.scan_metadata['gender']);
//   // resources.clearAvatarList();

//   // const avatar_glb_list = loadAvatarFromURL(
//   //   customization_data.scan_glb_url,
//   //   customization_data.scan_metadata['gender']
//   // );

//   // avatarList.remove_all();
//   // avatarList.load_from_list(avatar_glb_list);
//   // init_avatars(avatarList);

//   await load_avatar(customization_data.customization.avatar.body);

//   await load_from_customization_list(customization_data.customization.assets, customization_data.scan_metadata['gender']);
// }

async function load_from_data(customization_data: AvatarInfo) {
  await customizationInfo.loadFromDict(customization_data);
  fixMissing();
  AvatarView.is_animatable = customizationInfo.is_animatable;

  currentState.takeOffClothes();

  dispose_avatars();

  if (currentState.avatar) {
    currentState.avatar.set_visibility(false);
  }
  resources.clearAvatarList();

  const avatar_glb_list = loadAvatarFromURL(customizationInfo.avatar_url, customizationInfo.gender);

  avatarList.remove_all();
  avatarList.load_from_list(avatar_glb_list);

  if (AvatarView.is_animatable) {
    init_avatars_animatable(avatarList);
  } else {
    init_avatars(avatarList);
  }

  console.log(customizationInfo.data.avatar.body)
  await load_avatar(customizationInfo.data.avatar.body);

  if (customizationInfo.data.avatar.curve_coeff) {
    Avatar.curve_params.curve_coeff.value = customizationInfo.data.avatar.curve_coeff;
  }

  await load_from_customization_list(customizationInfo.data.assets, customizationInfo.gender);
}

export async function render() {
  // log timings
  // console.time('set_headless');
  // set_headless_mode();
  // console.timeEnd('set_headless');
  // log timings
  console.time('render');
  AvatarView.render(0.03);
  console.timeEnd('render');
  // await takeScreenshotAndSave('download', 'png');
  console.time('extract_screenshot');
  const res = AvatarView.renderer.domElement.toDataURL('image/png');
  console.timeEnd('extract_screenshot');

  return res;
}

async function render2() {
  // log timings
  // console.time('set_headless');
  // set_headless_mode();
  // console.timeEnd('set_headless');
  // log timings
  console.time('render');
  AvatarView.render(0.1);

  console.timeEnd('render');
  // await takeScreenshotAndSave('download', 'png');
  console.time('extract_screenshot');
  const res = await takeScreenshot2('png');
  console.timeEnd('extract_screenshot');

  AvatarView.is_rendering_active = false;

  return res;
}

function blobToBase64(blob: Blob) {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

async function export_glb() {
  AvatarView.is_rendering_active = false;
  const blob = await generateModel(true);
  AvatarView.is_rendering_active = false;
  const base64 = await blobToBase64(blob);
  return base64;
}

class DeferredPromise {
  promise: Promise<void>;
  resolve: CallableFunction;
  reject: CallableFunction;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

declare global {
  interface Window {
    // set_headless_mode: CallableFunction;
    load_from_data: typeof load_from_data;
    render: typeof render;
    render2: typeof render2;
    export_glb: typeof export_glb;
    loaded: Promise<void>;
    loaded_promise: DeferredPromise;
  }
}

export function resolve_loaded() {
  return
}
export function init_headless() {
  // window.set_headless_mode = () => {};
  window.load_from_data = load_from_data;
  window.render = render;
  window.render2 = render2;
  window.export_glb = export_glb;
  window.loaded_promise = new DeferredPromise();
  window.loaded = window.loaded_promise.promise;
}

const load_from_customization_list = async (
  customization_list: AvatarCustomization['assets'],
  gender: 'male' | 'female'
) => {
  // Assets
  const promises = Object.entries(customization_list).map(async ([id, asset_customizations]) => {
    return currentState.changeAsset(id, false).then((is_ok) => {
      if (is_ok) {
        currentState.set_customization(id, asset_customizations);
      }
    });
  });

  currentState.resetAnimation();
  const anim_promise = currentState.setDefaultAnimation().then(() => {
    AvatarView.mixer?.update(0.01);
  });
  promises.push(anim_promise);

  return Promise.allSettled(promises);
};

export const setHeadlessCamera = () => {
  let d = {
    target: { x: 0.06353332580294209, y: 1.2607020819660835, z: -0.062028476270025584 },
    position: { x: -1.6621553354827636, y: 1.5304205533255542, z: 2.9959997064155734 },
    zoom: 1,
  };

  // d = {"target":{"x":0.07310680556840063,"y":1.2827081589589882,"z":-0.06004198550468426},"position":{"x":-1.3910729746606636,"y":1.5730214484756717,"z":2.564594268139298},"zoom":1}
  d = {
    target: { x: 0.04028245315442831, y: 1.3692852407150145, z: -0.09532199442194403 },
    position: { x: -0.46887098750312634, y: 1.5937281716753346, z: 2.3002306769256187 },
    zoom: 1,
  };
  AvatarView.camera.position.set(d.position.x, d.position.y, d.position.z);
  AvatarView.controls.target.set(d.target.x, d.target.y, d.target.z);
  AvatarView.controls.update();
};

interface cameraParams {
  position: Vector3;
  target: Vector3;
  zoom: number;
}

export const getCamera = () => {
  return {
    target: AvatarView.controls.target.clone(),
    position: AvatarView.controls.object.position.clone(),
    zoom: (AvatarView.controls.object as PerspectiveCamera).zoom,
  } as cameraParams;
};

export const setCamera = (x: cameraParams) => {
  AvatarView.camera.position.copy(x.position);
  AvatarView.controls.target.copy(x.target);
  AvatarView.controls.update();
};
