import { Color, Shader } from 'three';
import { Uniform } from './_common';

export interface CurveParams {
  curve_coeff: Uniform<number>;
}

const defaultCurveParams: CurveParams = {
  curve_coeff: new Uniform(1.0),
};

export const curve_pars = `
uniform float curve_coeff;

vec3 curve(const in vec3 inputColor) {
  vec3 curve_coeff3 = vec3(curve_coeff);
  curve_coeff3.x = curve_coeff3.x * 1.1 - 1.0 * 0.1;
  return pow ( 1. - pow( abs(inputColor - 1.), curve_coeff3 ) , 1. / curve_coeff3);
}
`;

export function curve(shader: Shader, curve_params: CurveParams = defaultCurveParams, mode: 'emissive' | 'albedo') {
  Object.entries(curve_params).forEach(([k, v]) => {
    shader.uniforms[k] = v;
  });

  shader.fragmentShader = curve_pars + shader.fragmentShader;

  let to_replace = '#include <emissivemap_fragment>';
  if (mode == 'albedo') {
    to_replace = '#include <map_fragment>';
  }

  shader.fragmentShader = shader.fragmentShader.replace(
    to_replace,
    `
      ${to_replace}

      diffuseColor.xyz = curve(diffuseColor.xyz);
    `
  );
}
