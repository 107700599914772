import { Color, Shader, Texture } from 'three';
import { ColorRampParams, color_ramp_pars } from './color_ramp';
import { Uniform } from './_common';

export interface EyeShaderParams extends ColorRampParams {
  whitesTexture: Uniform<Texture | null>;
  irisPupilMask: Uniform<Texture | null>;
}

const default_eye_params: EyeShaderParams = {
  ramp_color1: new Uniform(new Color(0.103, 0.075, 0.057)),
  ramp_color2: new Uniform(new Color(0.788, 0.527, 0.266)),
  ramp_color3: new Uniform(new Color(0.889, 0.432, 0.174)),

  ramp_val1: new Uniform(0.032),
  ramp_val2: new Uniform(0.545),
  ramp_val3: new Uniform(1.0),

  whitesTexture: new Uniform(null),
  irisPupilMask: new Uniform(null),
};

export const eye_pars = `
uniform sampler2D whitesTexture;
uniform sampler2D irisPupilMask;

vec3 eye_color(const in vec3 inputColor, vec2 vUv) {
  vec3 white_rgb = texture2D(whitesTexture, vUv).rgb;
  float iris_alpha = 1.0 - texture2D(irisPupilMask, vUv).r;
  float pupil_alpha = texture2D(irisPupilMask, vUv).g;

  vec3 iris_color = color_ramp(inputColor);
  vec3 color = iris_color * iris_alpha + (1.0 - iris_alpha) * white_rgb;

  return pupil_alpha * color;
}`;

export function eye_shader(shader: Shader, color_ramp_params: EyeShaderParams = default_eye_params) {
  Object.entries(color_ramp_params).forEach(([k, v]) => {
    shader.uniforms[k] = v;
  });

  shader.fragmentShader = color_ramp_pars + eye_pars + shader.fragmentShader;

  shader.fragmentShader = shader.fragmentShader.replace(
    '#include <map_fragment>',
    `
    #include <map_fragment>
    diffuseColor.rgb = eye_color(diffuseColor.xyz, vUv);
    `
  );
}
