import { startExperience } from '@in3d/core';
import { ScreenshotButton } from '../screenshot-btn';

export const InfosysActions = () => {
  return (
    <div className="customizer__actions">
      <div className="controls">
        <button className="control-btn" onClick={() => startExperience('sceneDefault')}>
          <img src="/assets/scene/reset.svg" alt="reset" />
        </button>
        <ScreenshotButton />
      </div>
      <button className="expirience-btn" onClick={() => startExperience('infosys')}>
        {/* <button className="expirience-btn" onClick={() => loadScene("sceneDark4")}> */}
        <div className="expirience-btn__content">
          <img src="https://assets.avaturn.me/thumb3_995555713a.png" alt="scene" />
        </div>
      </button>
    </div>
  );
};
