import { AssetData, CategoryName } from '@in3d/common';

export const createDefaultAssetsList = (): { [key in CategoryName]: AssetData[] } => {
  return {
    [CategoryName.Body]: [],
    [CategoryName.Hair]: [],
    [CategoryName.Shoes]: [],
    [CategoryName.Outfit]: [],
    [CategoryName.Glasses]: [],
    [CategoryName.Animations]: [],
    [CategoryName.FaceAnimations]: [],
  };
};
