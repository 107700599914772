/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ClientBuilder } from '@in3d/api';
import { AssetData, RankedAsset, ResourcesPlacement } from '@in3d/common';
import env from '@in3d/environment';
import axios from 'axios';
import { plainToInstance } from 'class-transformer';

import { MaterialSettings, material_presets } from '../core/Materials';
import { Animation } from '../core/resources/animation';
import { FaceAnimation } from '../core/resources/animation_face';
import { Cloth, Glasses, Hair } from '../core/resources/assets';
import { SettingsJson, Slots } from '../core/resources/common';
import { Settings, placement_to_slots } from '../core/resources/common';
import { createSettings } from './api';
import { remove_nulls_from_dict } from './util';

const get_headers = (token?: string) => ({
  Authorization: `Bearer ${token}`,
});

export const LOWPOLY = false;
export interface ClothesList {
  [ResourcesPlacement.Eyes]: Glasses[];
  [ResourcesPlacement.Head]: Hair[];
  [ResourcesPlacement.Look]: Cloth[];
  [ResourcesPlacement.Shoes]: Cloth[];
  [ResourcesPlacement.FaceMask]: Cloth[];
  [ResourcesPlacement.Animations]: Animation[];
}
class ResourcesClient {
  constructor(api: ClientBuilder) {
    this.api = api;
  }
  api: ClientBuilder;
  paths = {
    assets: env.localResources ? '/assets/test_assets/assets.json' : `/content`,
    combined_content: `${env.resourceUrl}content/combined`,
  };
  async head_ids(): Promise<Uint16Array> {
    let basePath = `${env.editorResourcesUrl}/animatable`;
    if (LOWPOLY) {
      basePath += '/lowpoly';
    }
    return loadBuffer(`${basePath}/head_ids.dat`, Uint16Array) as Promise<Uint16Array>;
  }
  async loadCorrespondingIds(): Promise<Uint16Array> {
    return loadBuffer(`${env.editorResourcesUrl}/corresp_ids.dat`, Uint16Array) as Promise<Uint16Array>;
  }
  async loadBodyRetargetingIds() {
    let basePath = `${env.editorResourcesUrl}/animatable`;
    if (LOWPOLY) {
      basePath += '/lowpoly';
    }
    return loadBuffer(`${basePath}/bodyIdx.dat`, Uint16Array) as Promise<Uint16Array>;
  }
  async loadBodyRetargetingOffsets() {
    let basePath = `${env.editorResourcesUrl}/animatable`;
    if (LOWPOLY) {
      basePath += '/lowpoly';
    }
    return loadBuffer(`${basePath}/bodyOffsets.dat`, Float32Array) as Promise<Float32Array>;
  }
  async loadAll() {
    const params = new URLSearchParams(window.location.search);
    const path = params.get('headless') === 'true' ? this.paths.combined_content : this.paths.assets;
    return await this.api.get<AssetData[]>(path, null, {
      ...(env.hostname_override && { 'X-Override-Origin': env.hostname_override }),
    });
  }
  async loadProjectAssets(token: string, project: string): Promise<RankedAsset[]> {
    console.log('Token: ', token);
    return this.api.get(`/assets/project_assets/${project}`, null, get_headers(token));
  }
}

async function loadBuffer(url: string, BufferType: Uint16ArrayConstructor | Float32ArrayConstructor) {
  return axios
    .get<number[]>(url, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      return new BufferType(response.data);
    });
}

export const resourcesClient = new ResourcesClient(
  new ClientBuilder(createSettings(env.localResources ? '' : env.resourceUrl))
);
