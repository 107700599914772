import { localData } from '@in3d/common';
/* eslint-disable @typescript-eslint/ban-ts-comment */
import isMobile from 'is-mobile';

import { SceneManager } from '../core/scenes/scene_manager';

export class AdaptiveDPR {
  avg_frame_time = 0;
  dpr_delta = 0.1;

  max_frame_time = 1 / 59.0; // s, ~ 30FPS
  min_frame_time = 1 / 60; // s, ~ 50FPS

  // max_frame_time = 1 / 29; // s, ~ 30FPS
  // min_frame_time = 1 / 59; // s, ~ 50FPS

  minDpr = 1.0;
  maxDpr = window.devicePixelRatio;

  time_since_last_decrease = 0;
  time_since_last_increase = 0;

  relax_time_after_last_decrease = 0.5; //s
  current_relax_time = 0;

  cur_dpr = 0;
  enabled = false;
  constructor() {
    console.log();
  }

  update(frame_time: number, renderer: any) {
    if (!this.enabled) return;
    if (this.avg_frame_time == 0) {
      this.avg_frame_time = frame_time;
    } else {
      const alpha = 0.95;
      this.avg_frame_time = this.avg_frame_time * alpha + (1 - alpha) * frame_time;
    }

    this.time_since_last_decrease += frame_time;
    this.time_since_last_increase += frame_time;

    if (this.time_since_last_decrease < 0.5 || this.time_since_last_increase < 0.5) {
      return;
    }

    const dpr = renderer.getPixelRatio();

    if (this.avg_frame_time > this.max_frame_time && dpr > this.minDpr) {
      const dpr_delta = Math.min(this.dpr_delta, (this.avg_frame_time / this.max_frame_time - 1) * 10);
      if (dpr_delta >= 0.1) {
        renderer.setPixelRatio(dpr - dpr_delta);
        this.time_since_last_decrease = 0;
      }
    }
    if (
      this.time_since_last_increase > 1 &&
      this.time_since_last_decrease > 2 &&
      this.avg_frame_time < this.min_frame_time &&
      dpr < this.maxDpr
    ) {
      const dpr_delta = 0.1;
      // const dpr_delta = Math.min(this.dpr_delta, (this.min_frame_time / this.avg_frame_time - 1) * 10);
      // if (dpr_delta >= 0.1)
      renderer.setPixelRatio(dpr + dpr_delta);

      this.time_since_last_increase = 0;
    }
    this.cur_dpr = renderer.getPixelRatio();
  }
}
function median(values: number[]) {
  if (values.length === 0) throw new Error('No inputs');

  values.sort(function (a, b) {
    return a - b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

type Quality = 'high' | 'mid';
export class AdaptiveSceneFeatures {
  avg_frame_time = 0;

  fpsArray: number[] = [];
  frame_count = 0;

  prevQuality: string;
  enabled = true;
  constructor() {
    return;
  }

  update(frame_time: number, scene_manager: SceneManager) {
    if (!this.enabled) return;

    const existingQuality = localData.getItem('avaturn-quality-settings');

    if (existingQuality) {
      // @ts-ignore
      scene_manager.current_scene.setQuality(existingQuality);
      this.enabled = false;
      return;
    }

    if (this.frame_count == 100) {
      const fps = 1 / median(this.fpsArray);

      let quality = 'mid';
      if (fps < 15) {
        quality = 'low';
      } else if (fps < 30) {
        quality = 'mid';
      } else if (fps < 59) {
        quality = 'high';
      } else if (fps >= 59 && !isMobile()) {
        quality = 'highest';
      }

      this.prevQuality = quality;
      // alert(`1. FPS: ${fps.toFixed(2)}, quality: ${quality}`);

      // @ts-ignore
      scene_manager.current_scene.setQuality(quality);
      this.fpsArray = [];
    } else if (this.frame_count == 200) {
      const fps = 1 / median(this.fpsArray);
      const previousQuality = this.prevQuality;

      let quality = previousQuality;
      if (previousQuality == 'highest' && fps < 59) {
        quality = 'high';
      }
      if (previousQuality == 'mid' && fps < 25) {
        quality = 'low';
      }

      // alert(`2. FPS: ${fps.toFixed(2)}, quality: ${quality}`);
      // @ts-ignore
      scene_manager.current_scene.setQuality(quality);
      localData.setItem('avaturn-quality-settings', quality);

      this.enabled = false;
    } else if (this.frame_count < 200) {
      this.fpsArray.push(frame_time);
    }

    this.frame_count += 1;
  }
}
