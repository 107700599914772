import './face-tracking.scss';

import { resources, startFaceTrackingRecording, stopFaceTrackingRecording } from '@in3d/core';
import { useCallback, useState } from 'react';
import { CameraView } from './camera-view';
import { useRightPopper } from '../tshirt-generator/use-right-popper.hook';
import { observer } from 'mobx-react-lite';

type FaceTrackingProps = {
  onClick: () => void;
  faceTrackingState: 'webcam' | 'video' | 'none';
  setFaceTrackingState: (state: 'webcam' | 'video' | 'none') => void;
};

const _CameraButton = ({ onClick }: { onClick: () => void;  }) => {
  const { setReferenceElement, setPopperElement, setArrowElement, styles, attributes, popper, setPopper } =
    useRightPopper(resources.isSceneLoaded);

  return (
    <>
      <button
        ref={setReferenceElement}
        onClick={onClick}
        onPointerEnter={() => setPopper(true)}
        onPointerLeave={() => setPopper(false)}
        className="face-tracking__button"
      >
        <img src="/assets/face-tracking.svg" alt="" />
      </button>
      {popper && (
        <div
          ref={setPopperElement}
          style={styles['popper']}
          {...attributes['popper']}
          className="face-tracking__tooltip"
        >
          Face&nbsp;Tracking
          <div ref={setArrowElement} style={styles['arrow']} />
        </div>
      )}
    </>
  );
};

const CameraButton = observer(_CameraButton);

export const FaceTracking = ({ faceTrackingState, onClick, setFaceTrackingState }: FaceTrackingProps) => {
  const [isRecording, setIsRecording] = useState(false);

  const handleRecordTracking = useCallback(() => {
    if (isRecording) {
      stopFaceTrackingRecording();
      setFaceTrackingState('none');
    } else startFaceTrackingRecording();

    setIsRecording(!isRecording);
  }, [isRecording]);

  const handleClick = () => {
    onClick();
    setFaceTrackingState('webcam');
  };

  return (
    <div className="face-tracking scene__face-tracking">
      {faceTrackingState === 'webcam' ? (
        <CameraView
          close={() => {
            setFaceTrackingState('none');
          }}
          handleRecordTracking={handleRecordTracking}
          isRecording={isRecording}
        />
      ) : (
        <CameraButton onClick={handleClick} />
      )}
    </div>
  );
};
