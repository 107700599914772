
import { EffectComposer, EffectPass, RenderPass, BrightnessContrastEffect, BloomEffect } from "postprocessing";
import { AvatarView, gltf_loader } from '../../../AvatarView';
import { filter_animation } from "../../../core/animation_utils";
import { SceneBase } from "../scene_base";
import { setup_scene } from '../scenes_utils';
import { AnimationAction, AnimationClip, AnimationMixer, Group, InterpolateDiscrete, InterpolationModes, Object3D, PerspectiveCamera, Scene } from "three";


declare global {
  interface Window {
    soundEffect: HTMLAudioElement;
  }
}

export class SceneExperienceBase extends SceneBase {
  sound: HTMLAudioElement
  composer: EffectComposer | undefined

  action: AnimationAction
  mixer: AnimationMixer

  sound_loaded: boolean
  is_ready: boolean
  scene_loaded: boolean

  avatar_animation: AnimationClip
  scene_animations: AnimationClip[]
  scene_actions: AnimationAction[] = []
  scene_scene: Group

  async build_from_glbs(avatar_glb_url: string, scene_glb_url: string, audio_url: string, discrete_animation = false) {

    const scene_group = this.scene.getObjectByName('scene_group') as Group;

    const gltf_avatar = await gltf_loader.loadAsync(avatar_glb_url);
    const gltf_scene = await gltf_loader.loadAsync(scene_glb_url);

    if (discrete_animation) {
      for (let anim of gltf_scene.animations) {
        for (let track of anim.tracks) {
          track.setInterpolation(InterpolateDiscrete)
        }
      }
      for (let anim of gltf_avatar.animations) {
        for (let track of anim.tracks) {
          track.setInterpolation(InterpolateDiscrete)
        }
      }
    }

    for (let anim of gltf_avatar.animations) {
      filter_animation(anim);
    }

    // Add camera
    if (gltf_scene.cameras.length > 0) {
      this.camera = gltf_scene.cameras[0] as PerspectiveCamera;
      this.camera.aspect = AvatarView.parent_element.offsetWidth / AvatarView.parent_element.offsetHeight;
      this.camera.updateProjectionMatrix();
    }

    // Add audio
    let listener;
    // [this.sound, listener] = await setup_audio(audio_url)
    // this.camera.add(listener);

    // const soundEffect = new Audio();
    // soundEffect.autoplay = true;

    // // // onClick of first interaction on page before I need the sounds
    // // // (This is a tiny MP3 file that is silent and extremely short - retrieved from https://bigsoundbank.com and then modified)
    // soundEffect.src = './assets/local/Rodeo.mp3'
    // // // soundEffect.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
    // soundEffect.play()

    const sound = window.soundEffect;
    if (audio_url) {

      sound.autoplay = false;
      // sound.autostart = 0;
      sound.preload = "none";
      sound.loop = true;
      // onClick of first interaction on page before I need the sounds
      // (This is a tiny MP3 file that is silent and extremely short - retrieved from https://bigsoundbank.com and then modified)
      sound.src = audio_url;
      sound.addEventListener('canplaythrough', this.on_sound_loaded.bind(this), false);
      sound.load();
    } else {
      this.sound_loaded = true;
    }
    // sound.play();
    this.sound = sound;

    // Add objects to the scene
    setup_scene(gltf_scene.scene);
    scene_group.add(gltf_scene.scene);
    scene_group.add(gltf_avatar.scene);
    const avaturn_template = gltf_avatar.scene.getObjectByName('avaturn_template') as Object3D;
    avaturn_template.add(AvatarView.scene_objects_group);

    // Effects and composer
    // const bloom_effect = new BloomEffect();
    // const brightnessContrastEffect = new BrightnessContrastEffect();
    // const effect_pass = new EffectPass(this.camera, bloom_effect, brightnessContrastEffect);

    const composer = new EffectComposer(this.renderer);
    composer.addPass(new RenderPass(this.scene, this.camera));
    // composer.addPass(effect_pass);
    this.composer = composer;

    if (IS_DEBUG && AvatarView.gui) {
      let menu = AvatarView.gui.addFolder("POSTPROCESSING");
      // addBloomEffectDebugInfo(menu, effect_pass, bloom_effect);
      // addBrightnessContrastPassDebugInfo(menu, brightnessContrastEffect);
      menu.close();
    }

    this.avatar_animation = gltf_avatar.animations[0]
    this.scene_animations = gltf_scene.animations;
    this.scene_scene = gltf_scene.scene;
  }

  on_sound_loaded() {
    if (this.sound_loaded) return;
    this.sound_loaded = true;
    this.play()
  }
  play() {
    if (!(this.sound_loaded && this.scene_loaded)) return;

    // Update timestamp so that animation is better aligned
    const mixerUpdateDelta = AvatarView.clock.getDelta();
    this.mixer?.update(mixerUpdateDelta);

    // Play animations and audio
    this.action = this.mixer.clipAction(this.avatar_animation, AvatarView.animationGroup);
    this.action.play();
    // this.scene_action = this.mixer.clipAction(a, this.scene_scene)

    this.scene_actions = []
    for (let a of this.scene_animations) {
      const action = this.mixer.clipAction(a, this.scene_scene);
      this.scene_actions.push(action);
      action.play();
    }

    this.sound.currentTime = 0;
    this.sound.play();

  }
  override clean_up() {
    for (let a of this.scene_actions) {
      a.stop();
    }
    this.action?.stop();
    // this.sound.stop();

    if (this.sound) {
      this.sound.pause();
      this.sound.currentTime = 0;
    }

    // change parent for scene_objects_group
    AvatarView.scene.add(AvatarView.scene_objects_group);

    this.scene.environment = null;
    let scene_group = this.scene.getObjectByName('scene_group') as Group;
    scene_group.clear()
  }
  override render(mixerUpdateDelta: number) {
    if (this.is_ready)
      // this.composer?.render();
      this.renderer.render(this.scene, this.camera);
    if (this.mixer != AvatarView.mixer) {
      this.mixer?.update(mixerUpdateDelta);
    }
  }

}

