import { cn } from '@in3d/common';
import { getCurrentCustomization, upload_to_firebase } from '@in3d/core';
import { avatars } from '@in3d/store';

export const CinecomSceneAction = () => {
  const handleSubmitGuinessBtnClick = async () => {
    avatars.setAvatarExportBtnActivity(false);

    let customization_data = getCurrentCustomization(true);

    var blob = new Blob([JSON.stringify(customization_data)], { type: 'text/plain' });
    let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
    await upload_to_firebase(blob, 'text/plain', `cinecom/${uniqueId}.txt`);
    avatars.setAvatarExportBtnActivity(true);
    alert('Congrats, subscribe to Cinecom Youtube channel not to miss the video featuring your avatar!');
  };
  return (
    <button
      disabled={!avatars.isExportBtnEnabled}
      className="scene__save"
      style={{ fontSize: '13px', width: '149px' }}
      onClick={handleSubmitGuinessBtnClick}
    >
      Submit avatar to set Guinness record
    </button>
  );
};
