import { useEffect } from 'react';

export const UpgradePopup = ({
  setPortalOpened,
  handleClick,
  isMobile,
}: {
  setPortalOpened: (value: boolean) => void;
  handleClick: () => void;
  isMobile: boolean;
}) => {
  
  useEffect(() => {
    return () => {
      console.log('bye');
    };
  }, []);
  return (
    <>
      <div className="upgrade-popup">
        <button className="upgrade-popup__close">
          <img src="/assets/close.svg" alt="close" onClick={() => setPortalOpened(false)} />
        </button>

        <div className="upgrade-popup__text">
          <h1 className="upgrade-popup__title">
            <img src="/assets/arrow-up.svg" alt="upgrade" />
            <span>Upgrade Avatar</span>
          </h1>
          {/* <h4 className="upgrade-popup__description">Make your avatar better!</h4> */}
        </div>
        <div className="upgrade-popup__img">
          <video
            src="https://assets.hub.in3d.io/arkit_blendshapes_caaa3ba94e.mp4"
            autoPlay
            loop
            muted
            poster="/assets/upgrade/poster.png"
          />
          <div className="upgrade-popup__img-desc">
            <div>
              <img src="/assets/upgrade/sparkle.svg" alt="upgrade" />
              <p>REAL TIME FACE TRACKING</p>
            </div>
            <div>
              <img src="/assets/upgrade/sparkle.svg" alt="upgrade" />
              <p>ARKIT BLENDSHAPES</p>
            </div>
          </div>
        </div>

        <button className="upgrade-popup__button" onClick={handleClick}>
          Upgrade My Avatar <span>free</span>
        </button>
        {isMobile && <div className="upgrade-popup__wrapper" />}
      </div>
      <div className="upgrade-popup__backdrop" />
    </>
  );
};
