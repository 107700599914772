import { cn, getHostname } from '@in3d/common';
import { ImgHTMLAttributes } from 'react';
import './logo-text.scss';

interface LogoTextProps extends ImgHTMLAttributes<HTMLImageElement> {
  size?: 'lg' | 'md' | 'sm';
  variant?: 'default' | 'white';
}

interface CustomLogo {
  size: number;
  url: string;
}

export const LogoText: React.FC<LogoTextProps> = (props) => {
  const { size, variant } = props;

  const icons: Record<string, CustomLogo> = {
    'flamapp.avaturn.dev': { url: '/assets/flamapp-icon.svg', size: 22 },
    'graphity.avaturn.dev': {
      url: 'https://assets.avaturn.me/Graphity_JO_JEETA_WOHI_SIKANDER_5976916c8d.png',
      size: 70,
    },
    'zorabots.avaturn.dev': {
      url: 'https://assets.avaturn.me/zorabots_vector_white_69b1a28fc9.svg',
      size: 40,
    },
    'maayaverse.avaturn.dev': {
      url: 'https://assets.avaturn.me/Maya_Verse_Logo_White_Text_020831bab0.svg',
      size: 70,
    },
    'metaversesz.avaturn.dev': {
      url: 'https://assets.avaturn.me/uvision_181258d44a.png',
      size: 37,
    },
  };

  const defaultLogo: CustomLogo = {
    url: `/assets/${variant && variant === 'white' ? 'avaturn-white' : 'avaturn'}.svg`,
    size: props.height as number,
  };

  const d = icons[getHostname()] || defaultLogo;

  return (
    <img
      src={d.url}
      className={cn(['logo-text', size && `logo-text--${size}`])}
      alt="avaturn"
      {...props}
      style={{ height: d.size }}
    />
  );
};
