import { View } from 'structurae';

interface ItemDetails {
  positionCount: number;
  normalCount: number;
  uvCount: number;
  uv_outerCount: number;
}

interface Counts {
  [key: string]: ItemDetails;
}

const counts: Counts = {
  "basketball": {
    "positionCount": 1071,
    "normalCount": 1071,
    "uvCount": 714,
    "uv_outerCount": 714
  },
  "cowboy_cloth2": {
    "positionCount": 3429,
    "normalCount": 3429,
    "uvCount": 2286,
    "uv_outerCount": 2286
  },
  "tshirt_new": {
    "positionCount": 2493,
    "normalCount": 2493,
    "uvCount": 1662,
    "uv_outerCount": 1662
  },
  "eikonikos_bronze_hoodie": {
    "positionCount": 5949,
    "normalCount": 5949,
    "uvCount": 3966,
    "uv_outerCount": 3966
  },
  "polo_jeans": {
    "positionCount": 1134,
    "normalCount": 1134,
    "uvCount": 756,
    "uv_outerCount": 756
  },
  "teenage_2": {
    "positionCount": 3321,
    "normalCount": 3321,
    "uvCount": 2214,
    "uv_outerCount": 2214
  },
  "summer_outfit": {
    "positionCount": 3330,
    "normalCount": 3330,
    "uvCount": 2220,
    "uv_outerCount": 2220
  },
  "shirt": {
    "positionCount": 2601,
    "normalCount": 2601,
    "uvCount": 1734,
    "uv_outerCount": 1734
  },
  "T-Shirt+Denim_Outfit": {
    "positionCount": 2574,
    "normalCount": 2574,
    "uvCount": 1716,
    "uv_outerCount": 1716
  },
  "high_waist_pants": {
    "positionCount": 2790,
    "normalCount": 2790,
    "uvCount": 1860,
    "uv_outerCount": 1860
  },
  "christmas_panda": {
    "positionCount": 2304,
    "normalCount": 2304,
    "uvCount": 1536,
    "uv_outerCount": 1536
  },
  "military_woman": {
    "positionCount": 1413,
    "normalCount": 1413,
    "uvCount": 942,
    "uv_outerCount": 942
  },
  "F_Streetwear_Outfit": {
    "positionCount": 1791,
    "normalCount": 1791,
    "uvCount": 1194,
    "uv_outerCount": 1194
  }
}

interface Data {
  name: string;
  clothName: string;
  position: {
    itemSize: number;
    type: string;
    array: Array<number>;
  };
  normal: {
    itemSize: number;
    type: string;
    array: Array<number>;
  };
  uv: {
    itemSize: number;
    type: string;
    array: Array<number>;
  };
  uv_outer: {
    itemSize: number;
    type: string;
    array: Array<number>;
  };
}

export const loadBinDecalPreset = async (name = 'basketball') => {
  const { positionCount, normalCount, uvCount, uv_outerCount } = counts[name];

  // Instantiate a view protocol
  const view = new View();

  // Define interface for your data

  // Create and return a view class that handles your data
  const DataView = view.create<Data>({
    $id: 'Data',
    type: 'object',
    properties: {
      name: { type: 'string', maxLength: 50 },
      clothName: { type: 'string', maxLength: 50 },
      position: {
        $id: 'Position',
        type: 'object',
        properties: {
          itemSize: { type: 'number' },
          type: { type: 'string', maxLength: 50 },
          array: {
            type: 'array',
            items: {
              type: 'number',
              btype: 'float32',
            },
            maxItems: positionCount,
          },
        },
      },
      normal: {
        $id: 'Normal',
        type: 'object',
        properties: {
          itemSize: { type: 'number' },
          type: { type: 'string', maxLength: 50 },
          array: {
            type: 'array',
            items: {
              type: 'number',
              btype: 'float32',
            },
            maxItems: normalCount,
          },
        },
      },
      uv: {
        $id: 'UV',
        type: 'object',
        properties: {
          itemSize: { type: 'number' },
          type: { type: 'string', maxLength: 50 },
          array: {
            type: 'array',
            items: {
              type: 'number',
              btype: 'float32',
            },
            maxItems: uvCount,
          },
        },
      },
      uv_outer: {
        $id: 'UV_Outer',
        type: 'object',
        properties: {
          itemSize: { type: 'number' },
          type: { type: 'string', maxLength: 50 },
          array: {
            type: 'array',
            items: {
              type: 'number',
              btype: 'float32',
            },
            maxItems: uv_outerCount,
          },
        },
      },
    },
  });

  const url = `/assets/decals/${name}.bin`;

  // Fetch the binary data
  const response = await fetch(url);
  if (!response.ok) {
    console.error(`Failed to fetch data from ${url}`);
    return;
  }

  // Read the data as an ArrayBuffer
  const binaryData = await response.arrayBuffer();

  // Create a DataView from the binary data
  const dataView = new DataView(binaryData);

  // Decode the data
  // const data = DataView.decode(dataView);

  return dataView.toJSON()
};
