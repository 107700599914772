import { localData } from './local-storage';

export const saveRecomputeStartTime = (scan_id: string) => {
  return localData.setItem(`reload-time-${scan_id}`, new Date().toUTCString());
};
export const getRecomputeStartTime = (scan_id: string) => {
  return localData.getItem(`reload-time-${scan_id}`);
};

export const removeRecomputeStartTime = (scan_id: string) => {
  return localData.removeItem(`reload-time-${scan_id}`);
};
