import env from '@in3d/environment';
import { OrthographicCamera, Scene, TextureLoader, SpriteMaterial, Sprite, Color, Vector2 } from 'three';
import { AvatarView } from '../../../AvatarView';
import { SceneDark3 } from '../scene_dark2';

export class SceneFanMag extends SceneDark3 {
  sceneBG: Scene
  sceneFG: Scene
  cameraOrtho: OrthographicCamera

  override build() {
    super.build();

    this.sceneBG = new Scene();
    this.sceneFG = new Scene();

    let load_one = (path: string) => {
      let material = new SpriteMaterial();
      let sprite = new Sprite(material);

      sprite.center.set(0.5, 0.5);
      material.toneMapped = false;

      new TextureLoader().load(path,
        (texture) => {

          material.map = texture;
          texture.encoding = 3001;
          material.needsUpdate = true;

          this.onWindowResize();
        }
      );
      return sprite;
    }

    this.cameraOrtho = new OrthographicCamera(- 1, 1, 1, - 1, - 10000, 10000);

    const dir = `${env.editorResourcesUrl}/scenes/tetonridge_fanmag`
    this.sceneFG.add(load_one(`${dir}/Layer 02.png`));
    this.sceneFG.add(load_one(`${dir}/Layer 05.png`));
    this.sceneBG.add(load_one(`${dir}/Layer 03.png`));

    this.scene.background = null;
    // this.sceneBG.background = new Color("#221111"); // dark : #383838
    AvatarView.parent_element.style.backgroundImage = 'linear-gradient(44deg, rgb(55, 32, 76) 0%, rgb(17, 15, 17) 100%)';

    this.renderer.autoClear = false;

    this.onWindowResize();
  }

  override clean_up() {
    this.renderer.autoClear = true;
  }

  override render() {
    let renderer = this.renderer;
    renderer.clear();
    renderer.render(this.sceneBG, this.cameraOrtho);
    renderer.clearDepth();
    renderer.render(this.scene, this.camera);
    renderer.clearDepth();
    renderer.render(this.sceneFG, this.cameraOrtho);
  }

  override onWindowResize(width?: number, height?: number) {
    if (!width || !height) {
      [width, height] = this.get_canvas_size()
    }
    super.onWindowResize(width, height)

    let halfHeight = height / 2;
    let halfWidth = width / 2;

    this.cameraOrtho.left = - halfWidth;
    this.cameraOrtho.right = halfWidth;
    this.cameraOrtho.top = halfHeight;
    this.cameraOrtho.bottom = - halfHeight;
    this.cameraOrtho.updateProjectionMatrix();

    update_sprite(this.sceneFG.children[0] as Sprite, width, height)
    update_sprite(this.sceneFG.children[1] as Sprite, width, height)
    update_sprite(this.sceneBG.children[0] as Sprite, width, height)
  }

}

function update_sprite(sprite: Sprite, width: number, height: number) {
  const material = sprite.material;

  if (!material.map) {
    return;
  }
  const im_width = material.map!.image.width;
  const im_height = material.map!.image.height;
  const im_aspect = im_width / im_height;

  const canvas_aspect = width / height;

  let factor = im_aspect / canvas_aspect;

  if (width < height) {
    sprite.scale.set(width, height / Math.min(factor, 1), 1);
  } else {
    sprite.scale.set(width * Math.min(factor, 1) * 1.1, height * 1.1, 1);
  }

}
