import { AudioLoader, Group, Scene, Audio, AudioListener, MeshStandardMaterial } from 'three';

export async function setup_audio(audio_path: string) {
  const audioLoader = new AudioLoader();
  const buffer = await audioLoader.loadAsync(audio_path)

  const listener = new AudioListener();
  const sound = new Audio(listener);

  sound.setBuffer(buffer);
  sound.setLoop(true);
  sound.setVolume(0.5);

  return [sound, listener]
}

export function setup_scene(scene: Group) {
  scene.traverse(function (child: any) {
    if (child.isMesh) {
      if (child.material.type != "MeshStandardMaterial") {
        const cur_mat = child.material;
        child.material = new MeshStandardMaterial();
        // if (child.material.map) {
        //   // child.material.map.premultiplyAlpha = true;
        //   // child.material.map.generateMipmaps = false;
        // }
        child.material.copy(cur_mat);
        cur_mat.dispose();
      }

      child.frustumCulled = false;
      child.receiveShadow = true;
      child.castShadow = true;
    }
  });

}

// export function create_renderer() {
//   AvatarView.renderer.domElement.style.display = 'none'
//   let renderer = new WebGLRenderer({
//     antialias: false,
//     powerPreference: 'high-performance',
//     preserveDrawingBuffer: true,
//   });
//   renderer.setPixelRatio(window.devicePixelRatio);
//   renderer.shadowMap.enabled = true;
//   // AvatarView.renderer = renderer;
//   // renderer.toneMapping = SRGBColorSpace;
//   // renderer.toneMappingExposure = 1.0;
//   renderer.outputEncoding = sRGBEncoding;
//   renderer.setSize(AvatarView.parent_element.offsetWidth, AvatarView.parent_element.offsetHeight);
//   AvatarView.parent_element.appendChild(renderer.domElement);
//   this.renderer = renderer;
// }
