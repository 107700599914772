/* eslint-disable camelcase */

import { Effect } from 'postprocessing';
import {
  NoColorSpace,
  NearestFilter,
  RepeatWrapping,
  TextureLoader,
  Uniform,
  Vector2,
  Renderer,
  WebGLRenderer,
} from 'three';
import { VelocityDepthNormalPass } from './velocityDepthNormalPass';

const motion_blur_shader = `
uniform sampler2D inputTexture;
uniform sampler2D velocityTexture;
uniform sampler2D blueNoiseTexture;
uniform ivec2 blueNoiseSize;
uniform vec2 texSize;
uniform float intensity;
uniform float jitter;

uniform float deltaTime;
uniform int frame;

// source: https://www.shadertoy.com/view/wltcRS

// internal RNG state
uvec4 s0, s1;
ivec2 pixel;

void rng_initialize(vec2 p, int frame) {
    pixel = ivec2(p);

    // white noise seed
    s0 = uvec4(p, uint(frame), uint(p.x) + uint(p.y));

    // blue noise seed
    s1 = uvec4(frame, frame * 15843, frame * 31 + 4566, frame * 2345 + 58585);
}

// https://www.pcg-random.org/
void pcg4d(inout uvec4 v) {
    v = v * 1664525u + 1013904223u;
    v.x += v.y * v.w;
    v.y += v.z * v.x;
    v.z += v.x * v.y;
    v.w += v.y * v.z;
    v = v ^ (v >> 16u);
    v.x += v.y * v.w;
    v.y += v.z * v.x;
    v.z += v.x * v.y;
    v.w += v.y * v.z;
}

// random blue noise sampling pos
ivec2 shift2() {
    pcg4d(s1);
    return (pixel + ivec2(s1.xy % 0x0fffffffu)) % blueNoiseSize;
}

void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
    vec4 velocity = textureLod(velocityTexture, vUv, 0.0);

    if (dot(velocity.xyz, velocity.xyz) == 0.0) {
        outputColor = inputColor;
        return;
    }

    velocity.xy *= intensity;

    rng_initialize(vUv * texSize, frame);

    vec2 blueNoise = texelFetch(blueNoiseTexture, shift2(), 0).rg - 0.5;

    vec2 jitterOffset = jitter * velocity.xy * blueNoise;

    float frameSpeed = (1. / 100.) / deltaTime;

    // UVs will be centered around the target pixel (see http://john-chapman-graphics.blogspot.com/2013/01/per-object-motion-blur.html)
    vec2 startUv = vUv + (jitterOffset - velocity.xy * 0.5) * frameSpeed;
    vec2 endUv = vUv + (jitterOffset + velocity.xy * 0.5) * frameSpeed;

    startUv = max(vec2(0.), startUv);
    endUv = min(vec2(1.), endUv);

    vec3 motionBlurredColor;
    for (float i = 0.0; i <= samplesFloat; i++) {
        vec2 reprojectedUv = mix(startUv, endUv, i / samplesFloat);
        vec3 neighborColor = textureLod(inputTexture, reprojectedUv, 0.0).rgb;

        motionBlurredColor += neighborColor;
    }

    motionBlurredColor /= samplesFloat;

    outputColor = vec4(motionBlurredColor, inputColor.a);
}`;

// https://www.nvidia.com/docs/io/8230/gdc2003_openglshadertricks.pdf
// http://john-chapman-graphics.blogspot.com/2013/01/per-object-motion-blur.html
// reference code: https://github.com/gkjohnson/threejs-sandbox/blob/master/motionBlurPass/src/CompositeShader.js

const defaultOptions = { intensity: 1, jitter: 1, samples: 16 };

export class MotionBlurEffect extends Effect {
  pointsIndex = 0;

  constructor(velocityPass: VelocityDepthNormalPass, options = defaultOptions) {
    options = { ...defaultOptions, ...options };

    super('MotionBlurEffect', motion_blur_shader, {
      // type: "MotionBlurMaterial",
      uniforms: new Map([
        ['inputTexture', new Uniform(null)],
        ['velocityTexture', new Uniform(velocityPass.texture)],
        ['blueNoiseTexture', new Uniform(null)],
        ['blueNoiseSize', new Uniform(new Vector2())],
        ['texSize', new Uniform(new Vector2())],
        ['intensity', new Uniform(1)],
        ['jitter', new Uniform(1)],
        ['frame', new Uniform(0)],
        ['deltaTime', new Uniform(0)],
      ]),
      defines: new Map([
        ['samples', options.samples.toFixed(0)],
        ['samplesFloat', options.samples.toFixed(0) + '.0'],
      ]),
    });

    this.makeOptionsReactive(options)
  }

  makeOptionsReactive(options: any) {
  	for (const key of Object.keys(options)) {
  		Object.defineProperty(this, key, {
  			get() {
  				return options[key]
  			},
  			set(value) {
  				options[key] = value

  				switch (key) {
  					case "intensity":
  					case "jitter":
  						this.uniforms.get(key).value = value
  						break
  				}
  			}
  		})
      // @ts-ignore
  		this[key] = options[key]
  	}
  }

  override initialize(renderer: WebGLRenderer, ...args: any) {
    // @ts-ignore
    super.initialize(renderer, ...args);

    new TextureLoader().load('https://assets.avaturn.me/LDR_RGBA_0_2fe89d7d0f.png', (blueNoiseTexture) => {
      blueNoiseTexture.minFilter = NearestFilter;
      blueNoiseTexture.magFilter = NearestFilter;
      blueNoiseTexture.wrapS = RepeatWrapping;
      blueNoiseTexture.wrapT = RepeatWrapping;
      blueNoiseTexture.generateMipmaps = false;
      // blueNoiseTexture.colorSpace = NoColorSpace

      this.uniforms.get('blueNoiseTexture')!.value = blueNoiseTexture;
    });
  }

  override update(renderer: WebGLRenderer, inputBuffer: any, deltaTime: number) {
    this.uniforms.get('inputTexture')!.value = inputBuffer.texture;
    this.uniforms.get('deltaTime')!.value = Math.max(1 / 1000, deltaTime);

    const frame = renderer.info.render.frame % 65536;
    this.uniforms.get('frame')!.value = frame;

    this.uniforms.get('texSize')!.value.set(window.innerWidth, window.innerHeight);

    const noiseTexture = this.uniforms.get('blueNoiseTexture')!.value;
    if (noiseTexture) {
      const { width, height } = noiseTexture.source.data;

      this.uniforms.get('blueNoiseSize')!.value.set(width, height);
    }
  }
}
