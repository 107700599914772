/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AnimationClip,
  Bone,
  BufferAttribute,
  BufferGeometry,
  Group,
  Material,
  MeshStandardMaterial,
  Object3D,
  SkinnedMesh,
} from 'three';

import { GLTFExporterOptions } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { AvatarView } from '../AvatarView';
import { currentState } from '../core/CurrentState';
import {
  convert_to_opaque,
  filter_faces_by_alpha,
  set_avatar_indices,
  update_avatar_skin_texture,
  update_hair_diffuse,
  update_ORM_map,
} from './export_utils';

import { getHostname } from '@in3d/common';
import { Animation } from '../core/resources/animation';
import { MySkinnedMesh } from '../core/resources/avatar';
import { AvatarAnimatable } from '../core/resources/avatar_animatable';
import { disposeThreeJSObject } from '../core/resources/common';
import { FaceAnimation } from '../core/resources/animation_face';

interface ExtraGLTFExporterOptions {
  bonePrefix: string;
  includeUserData: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  extras: Object;
  exportTangents: boolean;
  occlusionUseUV1: boolean;
  removeUv2: boolean;
  includeCustomExtras: boolean;
}

class AvatarGroup extends Group {
  _get(name: string) {
    return this.getObjectByName(name)! as MySkinnedMesh;
  }

  get eye_mesh() {
    return this._get('Eye_Mesh');
  }
  get eyelash_mesh() {
    return this._get('Eyelash_Mesh');
  }
  get head_mesh() {
    return this._get('Head_Mesh');
  }
  get body_mesh() {
    return this._get('Body_Mesh') || this._get('avaturn_body');
  }
}

export class Transformer {
  bones: Object3D;
  bones_parent: Object3D;
  export_group: Group;
  indices_backup: { body?: BufferAttribute; head?: BufferAttribute } = {};
  current_animation: Animation | null;
  current_face_animation: FaceAnimation | null;
  justBody = false;
  // to_dispose: any[] = [];
  texture_size = 1024;
  avatar_group: AvatarGroup;

  create_export_group() {
    return;
  }

  async reset_export_transforms() {
    this._reset_avatar_transform();

    disposeThreeJSObject(this.export_group, false);

    this.export_group.clear();
    this.bones_parent.add(this.bones); // add bones back

    // Restore animation
    await currentState.changeAnimation(this.current_animation);
    await currentState.changeFaceAnimation(this.current_face_animation);
  }

  protected _transform_avatar(texture_size: number) {
    const avatar = this.avatar_group;

    if (AvatarView.is_animatable) {
      if (!this.justBody) {
        this.indices_backup.body = filter_faces_by_alpha(avatar.body_mesh, currentState.alpha_map_body, 1);
        this.indices_backup.head = filter_faces_by_alpha(avatar.head_mesh, currentState.alpha_map_head, 1);
      }

      update_avatar_skin_texture(avatar.head_mesh.material, texture_size, true);
      update_avatar_skin_texture(avatar.body_mesh.material, texture_size, false);
      // update_eye_texture(avatar.eye_mesh, texture_size);
      // update_eyelash_texture(avatar.eyelash_mesh)
    } else {
      if (!this.justBody) {
        this.indices_backup.body = filter_faces_by_alpha(avatar.body_mesh, currentState.alpha_map_body);
      }
      update_avatar_skin_texture(avatar.body_mesh.material, texture_size, true);
    }
  }

  private _reset_avatar_transform() {
    if (AvatarView.is_animatable) {
      const avatar = currentState.avatar as AvatarAnimatable;

      if (!this.justBody) {
        set_avatar_indices(avatar.body_mesh, this.indices_backup.body!);
        set_avatar_indices(avatar.head_mesh, this.indices_backup.head!);
      }
    } else {
      // Not animatable
      if (!this.justBody) {
        set_avatar_indices(currentState.avatar.body_mesh, this.indices_backup.body!);
      }
    }
  }

  apply_export_transforms(texture_size: number) {
    // Turn off animation (get T pose)
    // console.time("reset_anim");

    this.current_animation = currentState.animation;
    this.current_face_animation = currentState.faceAnimation;
    currentState.resetAnimation();
    currentState.resetFaceAnimation();

    currentState.avatar.setScaleAll();
  }
}

class TransformerSeparate extends Transformer {
  override create_export_group() {
    const export_group = new Group();
    export_group.name = 'Armature';
    this.export_group = export_group;

    // Add meshes related to avatar
    this.avatar_group = new AvatarGroup();

    currentState.avatar.group.traverseVisible((node: any) => {
      if (!(node as SkinnedMesh).isMesh) {
        return;
      }
      const mesh = node as SkinnedMesh;

      const m = mesh.clone();
      m.userData = {};
      if (m.name.startsWith('Head_T') || m.name.startsWith('Body') || m.name.startsWith('avaturn_body')) {
        m.material = convert_to_opaque(m.material as MeshStandardMaterial);
        if (getHostname() == '8agora.avaturn.dev') {
          (m.material as MeshStandardMaterial).roughnessMap = null;
        }
      } else {
        m.material = (m.material as MeshStandardMaterial).clone();
      }

      update_ORM_map(m, 512);

      this.bones_parent = mesh.skeleton.bones[0].parent!;
      this.bones = mesh.skeleton.bones[0];

      // this.to_dispose.push(m.material, m);
      this.avatar_group.children.push(m);
    });

    this._transform_avatar(this.texture_size);
    export_group.add(this.bones);
    export_group.add(...this.avatar_group.children);

    if (this.justBody) {
      return;
    }

    // Assets
    for (const asset of [...currentState.current_assets]) {
      const g = cloneGroup(asset.group);
      if (g.children.length == 0) continue;

      // Process hair
      if (asset.material_preset.startsWith('hair')) {
        update_hair_diffuse(g, this.texture_size);

        // Disable AO
        g.traverse((node: any) => {
          if (node.isMesh) {
            const material = node.material as MeshStandardMaterial;
            material.aoMap = null;
          }
        });
      } else {
        // Other assets
        g.traverse((node: any) => {
          if (node.isMesh) {
            const mesh = node as SkinnedMesh;
            const material = node.material as MeshStandardMaterial;
            // update_ORM_map(node, this.texture_size);
          }
        });
      }

      export_group.add(...g.children);
    }

    // flatten group
  }
}

function cloneGroup(group: Group) {
  const g = new Group();
  group.traverseVisible((node: any) => {
    if (!node.isMesh) return;

    const n = node.clone();
    if (n.material) {
      n.material = n.material.clone();
    }
    g.add(n);
  });
  return g;
}

function join_meshes(group: Group) {
  const geometries: BufferGeometry[] = [];
  const materials: Material[] = [];
  let bones: Bone;

  group.traverseVisible((node: any) => {
    if ((node as Bone).isBone) {
      bones = node;
      return;
    }
    if (!(node as SkinnedMesh).isMesh) {
      return;
    }

    const mesh = node as SkinnedMesh;

    // Material
    const mat = mesh.material as Material;
    materials.push(mat);

    // Geometry
    const new_geom = mesh.geometry.clone();
    for (const att of ['_ids_0', '_ids_1', 'uv2', 'tangent']) {
      new_geom.deleteAttribute(att);
    }
    geometries.push(new_geom);
  });

  const merged_geom = mergeBufferGeometries(geometries, true);

  // Export
  const export_mesh: SkinnedMesh = new SkinnedMesh(merged_geom, materials);

  export_mesh.geometry.computeBoundingBox();
  export_mesh.name = 'Armature';
  export_mesh.skeleton = currentState.avatar.skeleton;

  let export_group = new Group();
  export_group.add(currentState.avatar.skeleton.bones[0], export_mesh);
  // eslint-disable-next-line no-self-assign
  export_group = export_group;
  export_group.updateMatrixWorld();

  // Cleanup separate geometries
  geometries.forEach((element) => {
    element.dispose();
  });

  return export_group;
}

export type GenerateModelParams = { withAnimation?: boolean; justBody?: boolean; textureSize?: number };

export async function generate_model({
  withAnimation = false,
  justBody = false,
  textureSize = 1024,
}: GenerateModelParams) {
  const hostname = getHostname();

  let animations: AnimationClip[] = [];
  if ((withAnimation || hostname == 'zappar.avaturn.dev') && currentState.animation) {
    animations = [currentState.animation!.getClip()];
    if (currentState.faceAnimation) {
      animations.push(currentState.faceAnimation.getClip());

      // merge
      const clip = animations[0].clone();
      clip.tracks = [...clip.tracks, ...currentState.faceAnimation.getClip().tracks];
      animations = [clip];
      clip.name = 'avaturn_animation';
    }
  }
  // console.log(animations);
  const bonePrefix = '';
  const exporter_promise = import('three/examples/jsm/exporters/GLTFExporter.js');

  const is_eikonikos = hostname === 'eikonikos.avaturn.dev';
  const t = new TransformerSeparate();

  if (hostname === 'gn3ra.avaturn.dev') {
    textureSize = 2048;
  }
  t.texture_size = textureSize ;
  t.justBody = justBody;
  t.apply_export_transforms(textureSize);
  t.create_export_group();

  if (is_eikonikos) {
    t.export_group = join_meshes(t.export_group);
  }

  const maxTextureSize = textureSize;

  const { GLTFExporter } = await exporter_promise;
  const exporter = new GLTFExporter();
  const options: ExtraGLTFExporterOptions & GLTFExporterOptions = {
    trs: true,
    bonePrefix: bonePrefix,
    includeUserData: false,
    extras: {
      version: '1.0',
      gender: currentState.avatar.gender,
      body_index: currentState.avatar.body_id,
    },
    occlusionUseUV1: true,
    removeUv2: true,
    includeCustomExtras: false,
    exportTangents: false,
    binary: true,
    maxTextureSize,
    animations: animations,
  };
  const gltf = await exporter.parseAsync(t.export_group, options);

  const blob = new Blob([gltf as ArrayBuffer], {
    type: 'application/octet-stream',
  });

  await t.reset_export_transforms();

  return blob;
}
