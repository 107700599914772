import { DeveloperProject, devPortalClient } from '@in3d/api';
import { when } from 'mobx';
import { useEffect, useState } from 'react';
import { auth } from '../../common';

export const useProjectList = () => {
  const [list, setList] = useState<DeveloperProject[]>([]);
  const [loaded, setLoaded] = useState(false);
  const loadList = () => {
    devPortalClient
      .getProjectsList(auth.token)
      .then(setList)
      .finally(() => {
        setLoaded(true);
      });
  };
  useEffect(() => {
    when(() => auth.isAuth, loadList);
  }, []);
  return { list, loaded, loadList, updateList: setList };
};
