import { BufferAttribute, BufferGeometry } from 'three';

function concat_offsets_ids(offsets1: ArrayLike<number>, offsets2: ArrayLike<number>) {
  const offsets_ids = new Uint16Array(offsets1.length * 2);

  for (let i = 0; i < offsets1.length / 4; i++) {
    offsets_ids[i * 8] = offsets1[i * 4];
    offsets_ids[i * 8 + 1] = offsets1[i * 4 + 1];
    offsets_ids[i * 8 + 2] = offsets1[i * 4 + 2];
    offsets_ids[i * 8 + 3] = offsets1[i * 4 + 3];

    offsets_ids[i * 8 + 4] = offsets2[i * 4];
    offsets_ids[i * 8 + 5] = offsets2[i * 4 + 1];
    offsets_ids[i * 8 + 6] = offsets2[i * 4 + 2];
    offsets_ids[i * 8 + 7] = offsets2[i * 4 + 3];
  }
  return offsets_ids;
}

async function load_offsets(base_dir: string) {
  const response = await fetch(base_dir + '/offsets.dat');
  const arrayBuffer = await response.arrayBuffer();

  const offsets = new Float32Array(arrayBuffer);

  const response2 = await fetch(base_dir + '/idx.dat');
  const arrayBuffer2 = await response2.arrayBuffer();

  const offsets_ids = new Uint16Array(arrayBuffer2);

  return [offsets, offsets_ids];
}

function getImageData(image: any) {
  /* Get image array from canvas */
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;

  const context = canvas.getContext('2d');
  context?.drawImage(image, 0, 0);

  return context?.getImageData(0, 0, image.width, image.height);
}

function unpack_mask_img(mask_array: Uint8ClampedArray, width: number, height: number, new_packing = false, animatable = false) {
  /*
    mask_array is height x width x 4 array
    */

  const offset_map = new Float32Array(width * height);
  const avatar_mask_uv2 = new Uint8Array(width * height);
  const avatar_mask = new Uint8Array(width * height);
  // let s = 0;
  for (let i = 0; i < height; i++) {
    for (let j = 0; j < width; j++) {
      const position = i + width * j;

      // const c = mask_array[position * 4 + 2];
      // if (!(c == 0 || c == 1 || c == 2 || c == 3)) {
      //   // alert(c)
      // } else {
      //   // alert(c)
      // }
      // s = s + mask_array.data[position * 4 + 1];
      // offset_map[position] = (mask_array.data[position * 4 + 1] / 255.0) * 0.2;
      if (new_packing) {
        if(animatable) {
          avatar_mask[position] = Number((mask_array[position * 4 + 2] & 2) > 0) * 255;
          avatar_mask_uv2[position] = Number((mask_array[position * 4 + 2] & 4) > 0) * 255;
        } else {
          avatar_mask[position] = Number((mask_array[position * 4 + 2] & 1) > 0) * 255;
        }
      } else {
        avatar_mask[position] = Number(mask_array[position * 4 + 2] % 2 == 1) * 255;
        avatar_mask_uv2[position] = Math.floor(mask_array[position * 4 + 2] / 2) * 255;
      }
    }
  }
  return [avatar_mask, avatar_mask_uv2, offset_map];
}

function flipY_uv1(geometry: BufferGeometry) {
  const uv = geometry.attributes['uv'];

  for (let i = 0; i < uv.count; i++) {
    uv.setY(i, 1 - uv.getY(i));
  }
}
function flipY_uv2(geometry: BufferGeometry) {
  const uv2 = geometry.attributes['uv2'] as BufferAttribute;

  if (uv2) {
    for (let i = 0; i < uv2.count; i++) {
      uv2.setY(i, 1 - uv2.getY(i));
    }
  }
}

export { concat_offsets_ids, load_offsets, unpack_mask_img, getImageData, flipY_uv1, flipY_uv2 };
