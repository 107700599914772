import { changeBody } from '@in3d/core';
import { ScreenshotButton } from '../screenshot-btn';

export const ArtisantStaticActions = () => {
  return (
    <div className="customizer__actions">
      <ScreenshotButton />
      <button className="expirience-btn" onClick={() => changeBody('model_T_0')}>
        <div className="expirience-btn__content">
          <img src="https://assets.avaturn.me/Eva_7469b04555.jpg" alt="exp_1" />
        </div>
      </button>
      <button className="expirience-btn" onClick={() => changeBody('model_T_1')}>
        <div className="expirience-btn__content">
          <img src="https://assets.avaturn.me/Ave_e9dfefbe16.jpg" alt="exp_1" />
        </div>
      </button>
    </div>
  );
};
