
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { AvatarView } from '../../../AvatarView';
import { addBloomEffectDebugInfo, addBrightnessContrastPassDebugInfo, addDirectionalLightDebug } from '../debug_gui';
import { SceneExperienceBase } from "./scene_experience_base";
import { AmbientLight, Color, DirectionalLight, EquirectangularReflectionMapping, Group, PerspectiveCamera, ReinhardToneMapping, Renderer, Scene, WebGLRenderer } from 'three';
import env from '@in3d/environment';

// import { VolumetricSpotlight } from "./VolumetricSpotlight.js"


async function setup_light(renderer: WebGLRenderer, camera: PerspectiveCamera, scene: Scene, scene_group: Group) {

  AvatarView.avatar_envMapIntensity = 1.4;
  AvatarView.cloth_envMapIntensity = 1.4;

  if (IS_DEBUG && AvatarView.gui) {

    const f = AvatarView.gui.addFolder('Envmap');

    f.add(AvatarView, 'cloth_envMapIntensity', 0, 40, 0.1).listen();
    f.add(AvatarView, 'avatar_envMapIntensity', 0, 40, 0.1).listen();
  }

  renderer.toneMapping = ReinhardToneMapping;
  renderer.toneMappingExposure = 0.6;

  scene.background = new Color('#F8F8F8'); // dark : #383838

  renderer.physicallyCorrectLights = false; // for rendering env

  let texture = await new RGBELoader().loadAsync(`${env.editorResourcesUrl}/textures/brown_photostudio_01_1k.hdr`)
  texture.mapping = EquirectangularReflectionMapping;
  scene.environment = texture;

  renderer.physicallyCorrectLights = true;

  scene.fog = null;
  // const light = newAmbientLight(0x777777, 0.5); // soft white light
  // light.name = 'ambient_light';
  // scene_group.add(light);

  let dirLight = new DirectionalLight(0xffffff, 4);
  dirLight.name = 'dirlight'
  dirLight.position.set(3.1, 3.00, 3.00);
  dirLight.castShadow = false;
  dirLight.shadow.bias = -0.000472;

  dirLight.shadow.camera.top = 1.80;
  dirLight.shadow.camera.bottom = - 1.00;
  dirLight.shadow.camera.left = - 1.20;
  dirLight.shadow.camera.right = 1.20;
  // scene_group.add(dirLight);

  if (IS_DEBUG && AvatarView.gui) {
    AvatarView.gui.add(dirLight.shadow, 'bias', -0.01, 0.01, 0.000001);
    AvatarView.gui.add(dirLight, 'intensity', 0, 10, 0.01);
  }
  if (IS_DEBUG && AvatarView.gui) {
    const f = AvatarView.gui.addFolder('Directional light');
    addDirectionalLightDebug(dirLight, f);
  }

  dirLight = new DirectionalLight(0xffffff, 25);
  dirLight.name = 'dirlight'
  dirLight.position.set(0.35, 1.7, -5);
  dirLight.castShadow = true;
  dirLight.shadow.bias = -0.000472;

  dirLight.shadow.camera.top = 1.80;
  dirLight.shadow.camera.bottom = - 1.00;
  dirLight.shadow.camera.left = - 1.20;
  dirLight.shadow.camera.right = 1.20;
  scene_group.add(dirLight);

  // scene.fog = newFog(0x181818, 6, 10.12);

  const light = new AmbientLight(0x777777, 1.5); // soft white light
  light.name = 'ambient_light';
  scene_group.add(light);
}


export class SceneBull extends SceneExperienceBase {

  override async build() {

    const scene_group = this.scene.getObjectByName('scene_group') as Group;

    const dir = `${env.editorResourcesUrl}/scenes/tetonridge_rodeo`
    await this.build_from_glbs(
      `${dir}/Rodeo_scene_avatar_opt.glb`,
      `${dir}/Rodeo_scene_background_opt.glb`,
      `${dir}/Rodeo.mp3`
    )

    await setup_light(this.renderer, this.camera, this.scene, scene_group);

    // Compile materials
    this.renderer.compile(this.scene, this.camera);

    this.mixer = AvatarView.mixer;

    this.scene_loaded = true;

    this.play()

    this.is_ready = true
  }

  // render() {
  //   this.composer?.render();

  //   // this.scene.traverse((node) => {
  //   //   if (node.isVolumetricSpotlight)
  //   //     node.update();
  //   // })
  // }

}

