import { loadGn3raGlb, resources } from '@in3d/core';
import { reaction } from 'mobx';
import { useEffect, useState } from 'react';

export const Gn3raActions = () => {
  const [available, setAvailable] = useState(false);

  const checkAvailable = () => {
    setAvailable(resources.active.outfit.startsWith('gn3ra'));
  };

  useEffect(() => {
    checkAvailable();
    reaction(
      () => resources.active.outfit,
      (outfit, previousOutfit) => {
        if (outfit !== previousOutfit) checkAvailable();
      }
    );
  }, []);

  return (
    <>
      {available && (
        <div className="customizer__actions">
          <div className="controls">
            <button className="control-btn" onClick={() => loadGn3raGlb()}>
              <img src={`https://uploads-ssl.webflow.com/62e19c2ca67ba6f659c09e2a/640d9da0317316ffe4949c53_GN3RA%20Logo.png`} alt="music" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
