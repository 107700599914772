import { Color, Shader, Vector2 } from 'three';

export const glasses_properties_default = {
  color: new Color(1.0, 0.0, 1.0),
  transparent_or_not: new Vector2(1, 0),
};

export function glasses_shader(shader: Shader, glasses_properties = glasses_properties_default) {
  shader.uniforms['glasses_color'] = { value: glasses_properties.color };
  shader.uniforms['glasses_transparent_or_not'] = { value: glasses_properties.transparent_or_not };

  shader.fragmentShader =
    `
  uniform vec3 glasses_color;
  uniform vec2 glasses_transparent_or_not;
  ` + shader.fragmentShader;

  shader.fragmentShader = shader.fragmentShader.replace(
    'vec4 diffuseColor = vec4( diffuse, opacity );',
    `
    vec4 diffuseColor = vec4( diffuse, opacity );

    if (glasses_transparent_or_not.x > 0.5) {
      diffuseColor = vec4(0.0,0.0,0.0, 0.0);
    }
    `
  );

  shader.fragmentShader = shader.fragmentShader.replace(
    '#include <output_fragment>',
    `
    #include <output_fragment>
    bool is_transparent = glasses_transparent_or_not.x  > 0.5;

    if (is_transparent) {

      vec3 outgoingLight_low_opacity = clamp(log2(totalSpecular)*-1.0 + 0.5, 0.0,10.0);

      vec3 color232 =  vec3(1.0, 1.0, 1.0) + clamp(outgoingLight_low_opacity-0.0, 0.0, 100.0) ;
      float alpha = clamp((outgoingLight.x + outgoingLight.y + outgoingLight.z)/ 3.0 , 0.0, 1.0);
      gl_FragColor = vec4( color232  , alpha);

    } else {
      // vec3 outgoingLight1 = clamp(log2(clamp(totalSpecular+1.0, 0.0, 100.0) ) , 0.0,10.0);
      // vec3 outgoingLight_low_opacity = clamp(log2(totalSpecular)*-1.0 + 0.5, 0.0,10.0);
      vec3 outgoingLight_high_opacity = clamp(log2(clamp(totalSpecular+1.0, 0.0, 100.0) ) , 0.0,10.0);
      // vec3 outgoingLight1 = clamp(totalSpecular, 0.0,10.0);

      vec3 color_ =  glasses_color + clamp(outgoingLight_high_opacity, 0.0, 100.0);
      gl_FragColor = vec4( color_  , 1.0);
    }

    `
  );
}
