class MaterialSettings {
  normal_flipY = false;
  flip_uv = false;
  recompute_normals = false;

  env_mult = 1;

  metalness = -1;
  roughness = -1;
  aoMapIntensity = -1;
  reflectivity = -1;

  normalScale = -1;

  color = '';

  // Hair
  use_color_ramp = false;
  use_fresnel = false;
  use_haircap = false;

  // Shadows
  receive_shadow = true;
  cast_shadow = true;

  // Transparecny hack
  one_call = true;

  // Glasses
  lens_material_name = '';

  // ToneMapping
  tone_mapping = 'linear';
  compute_tangent = false;
  compute_tangent_regular = false;
}

const material_presets: { [key: string]: MaterialSettings } = {
  hair: Object.assign(new MaterialSettings(), {
    use_color_ramp: true,
    use_fresnel: true,
    receive_shadow: true,
    one_call: true,
    recompute_normals: false,
    compute_tangent: true,
    use_haircap: true,
  }),
  glasses: Object.assign(new MaterialSettings(), {
    one_call: true,
    lens_material_name: 'lens',
    recompute_normals: false,
  }),
  shoes: Object.assign(new MaterialSettings(), {
    normal_flipY: true,
    recompute_normals: false,
  }),
  hat: Object.assign(new MaterialSettings(), {
    use_haircap: true,
    recompute_normals: true,
    use_color_ramp: false,
    use_fresnel: false,
    receive_shadow: true,
  }),
  hat_recolorable: Object.assign(new MaterialSettings(), {
    use_haircap: true,
    recompute_normals: false,
    use_color_ramp: true,
    use_fresnel: false,
    receive_shadow: true,
  }),
  // cloth_no_fresnel: Object.assign( new MaterialSettings() {
  //   use_fresnel: false
  // })
};

material_presets['hair_african'] = Object.assign({}, material_presets['hair'], {
  use_fresnel: false,
  compute_tangent: false
});

export { MaterialSettings, material_presets };
