import { ClientBuilder, createSettings } from './api';
import { AppConfig, ConfigType, EditorConfig, getHostname, ScanConfig } from '@in3d/common';
import env from '@in3d/environment';
import axios from 'axios';
import { JSONSchemaType } from 'ajv';

export enum ExportType {
  DataURL = 'web_message_blob',
  HttpURL = 'web_message_link',
}

const get_headers = (token?: string) => ({
  Authorization: `Bearer ${token || ''}`,
});

export interface UserInfo {
  meta: Record<string, any>;
  project_id: string;
}

export interface DeveloperSettings {
  export_mode: ExportType;
  default_config: ConfigType.v1 | ConfigType.v2 | ConfigType.V3;
  disable_loader?: boolean;
  editor: Partial<EditorConfig>;
  app: Partial<AppConfig>;
  scan: Partial<ScanConfig>;
}

export const developerSettingsSchema: JSONSchemaType<DeveloperSettings> = {
  title: 'Developer settings',
  type: 'object',
  properties: {
    export_mode: {
      type: 'string',
      enum: [ExportType.DataURL, ExportType.HttpURL],
    },
    default_config: {
      type: 'string',
      enum: [ConfigType.v1, ConfigType.v2, ConfigType.V3],
    },
    disable_loader: {
      type: 'boolean',
      nullable: true,
    },
    editor: {
      type: 'object',
      properties: {
        withPoweredBtn: { type: 'boolean', nullable: true },
        withSaveBtn: { type: 'boolean', nullable: true },
        withHomeBtn: { type: 'boolean', nullable: true },
        withAnimationTab: { type: 'boolean', nullable: true },
        withFaceAnimationTab: { type: 'boolean', nullable: true },
        customCss: { type: 'boolean', nullable: true },
        customLogo: { type: 'boolean', nullable: true },
        sceneBg: { type: 'string', nullable: true },
        customBodies: { type: 'boolean', nullable: true },
        logo: { type: 'string', nullable: true },
        exportWithThumbnail: { type: 'boolean', nullable: true },
        defaultHaircap: {type: 'string', nullable: true},
        textureSize: {type: 'number', nullable: true},
      },
    },
    app: {
      type: 'object',
      properties: {
        customLogo: { type: 'string', nullable: true },
        disableSocialLinks: { type: 'boolean', nullable: true },
        withNav: { type: 'boolean', nullable: true },
        allowSelectAvatarVersion: { type: 'boolean', nullable: true }
      },
    },
    scan: {
      type: 'object',
      properties: {
        withPhotoUploader: { type: 'boolean', nullable: true },
      },
    },
  },
  required: ['export_mode', 'default_config'],
};

class AppClient {
  constructor(api: ClientBuilder) {
    this.api = api;
  }

  api: ClientBuilder;

  async getDeveloperSettings(): Promise<DeveloperSettings> {
    return await this.api.get('/settings/developer/' + getHostname());
  }

  async getUserInfo(token: string): Promise<UserInfo> {
    return await this.api.get('/users/me', null, get_headers(token));
  }

  async getShortLink(url: string): Promise<string> {
    return await axios
      .post('https://link.avaturn.me/link', { url })
      .then(({ data }) => data['shortened'])
      .catch((err) => console.log('Bad request: ', err));
  }
}

export const appClient = new AppClient(new ClientBuilder(createSettings(env.baseUrl)));
