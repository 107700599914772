import './scene-navigation.scss';

import { changeSkybox, getNumSkyboxes, loadScene } from '@in3d/core';
import cn from 'classnames';
import { useState } from 'react';
import { useIsMobile } from '@in3d/hooks';
import { Upgrade } from './upgrade';

const scenes = [
  { name: 'sceneDefault', image: '/assets/scene/exp_football.jpg' },
  { name: 'skybox1', image: '/assets/scene/exp_football.jpg' },
  { name: 'skybox2', image: '/assets/scene/exp_football.jpg' },
  { name: 'skybox3', image: '/assets/scene/exp_football.jpg' },
  { name: 'skybox4', image: '/assets/scene/exp_football.jpg' },
];

// const skyboxes = [
// {
//   name: '00545-1698924982-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), inside of a sci-fi spaceship, al.jpg',
// },
// {
//   name: '00555-381551982-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), inside of a sci-fi spaceship, al.jpg',
// },
// {
//   name: '00565-3484237171-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), inside of a sci-fi spaceship, al.jpg',
// },
// {
//   name: '00570-2351532596-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), inside of a sci-fi spaceship, al.jpg',
// },
// {
//   name: '00572-2351532597-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00575-2351532596-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00576-2351532597-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00577-2351532598-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00578-2351532599-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00579-2924690745-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00580-2924690746-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00581-2924690747-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00582-2924690748-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00584-2924690746-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00585-2924690747-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00586-2924690748-a 360 equirectangular panorama  , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lo.jpg',
// },
// {
//   name: '00587-2924690745-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), a japanese city near the sea, lof.jpg',
// },
// {
//   name: '00588-1087129617-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), floating in space, view of a inte.jpg',
// },
// {
//   name: '00590-3735484594-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), floating in space, view of a inte.jpg',
// },
// {
//   name: '00591-3735484595-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), floating in space, view of a inte.jpg',
// },
// {
//   name: '00592-3735484596-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), floating in space, view of a inte.jpg',
// },

// {
//   name: '00594-1636582124-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), floating in space, view of a inte.jpg',
// },

// {
//   name: '00600-1414336656-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), night city.jpg',
// },
// {
//   name: '00605-2552976376-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), cosmic waves in galaxy , starry n.jpg',
// },
// {
//   name: '00606-2552976377-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), cosmic waves in galaxy , starry n.jpg',
// },
// {
//   name: '00607-2552976378-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), cosmic waves in galaxy , starry n.jpg',
// },
// {
//   name: '00608-2552976379-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), cosmic waves in galaxy , starry n.jpg',
// },
// { name: 'scifi_avatar_creation_podium3.jpg' },
// {
//   name: `https://assets.avaturn.me/scifi_avatar_creation_podium_b3ca62b4cf.webp?updated_at=2023-05-15T11:46:25.202Z`,
// },
// { name: 'H_4.2_R_41.6_S_28.png' },
// { name: '01196_37328208_a_360_equirectangular_panorama_,_modelshoot_style.jpg' },
// { name: 'height_7_radius_10_scale_11.png', height: 61.5, radius: 450.6, scale: 18 },
// // { name: 'Map1.png' },
// // { name: 'Map2.png', height: 7.2, radius: 10, scale: 18 },
// // { name: 'Map3.png', height: 9.6, radius: 85, scale: 20 },
// {
//   name: '00596-1636582126-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), floating in space, view of a inte.jpg',
// },
// {
//   name: '00596-1636582126-a 360 equirectangular panorama , modelshoot style, (extremely detailed CG unity 8k wallpaper), floating in space, view of a inte.jpg',
//   height: 134,
//   radius: 137.7,
//   scale: 20,
// },

export const DefaultControlsActions = ({ isCustomDev = false }) => {
  const [activeScene, setActiveScene] = useState('sceneDefault');
  const [activeSkybox, setActiveSkybox] = useState(3);
  const [portalOpened, setPortalOpened] = useState(false);

  const handleSceneChange = (name: string) => {
    loadScene(name);
    setActiveScene(name);
  };

  const handleSkyboxChange = (idx: number) => {
    changeSkybox(idx);
    setActiveSkybox(idx);
  };

  const handlePrevScene = () => {
    const currentIndex = scenes.findIndex((scene) => scene.name === activeScene);
    const prevIndex = (currentIndex - 1 + scenes.length) % scenes.length;
    handleSceneChange(scenes[prevIndex].name);
  };

  const handleNextScene = () => {
    const currentIndex = scenes.findIndex((scene) => scene.name === activeScene);
    const nextIndex = (currentIndex + 1) % scenes.length;
    handleSceneChange(scenes[nextIndex].name);
  };

  const handlePrevSkybox = () => {
    const n = getNumSkyboxes();
    const prevIndex = (activeSkybox - 1 + n) % n;
    handleSkyboxChange(prevIndex);
  };

  const handleNextSkybox = () => {
    const n = getNumSkyboxes();
    const nextIndex = (activeSkybox + 1) % n;
    handleSkyboxChange(nextIndex);
  };

  const isMobile = useIsMobile(760);

  return (
    <div className="default-controls">
      <div
        className={cn([
          'scene-navigation',
          {
            'scene-navigation_default': activeScene === 'sceneDefault',
          },
        ])}
      >
        <button className="scene-navigation__button" onClick={handlePrevSkybox}>
          <img src="/assets/chevron-left.svg" alt="prev" />
        </button>
        <img src="/assets/image.svg" alt="prev" />
        <button className="scene-navigation__button" onClick={handleNextSkybox}>
          <img src="/assets/chevron-right.svg" alt="next" />
        </button>
      </div>
      {!isCustomDev ? <Upgrade {...{ portalOpened, setPortalOpened }} isMobile={isMobile} /> : null}
    </div>
  );
};
