import { AnimationClip, Group } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { AvatarView, gltf_loader } from '../../AvatarView';
import { Animation } from './animation';
import { load_animation_simple_fbx, processMorphTargetTrack } from './animation_loader';
import { AvatarAnimatable } from './avatar_animatable';
import { DownloadState } from './common';
export class FaceAnimation extends Animation {
  override async load_animation(): Promise<void> {
    this.setDownloadState(DownloadState.Loading);

    let loader;
    let scene, animations;
    if (this.glb_url.endsWith('fbx')) {
      this._action = await load_animation_simple_fbx(AvatarView.currentState.avatar as AvatarAnimatable, this.glb_url);
    } else {
      loader = gltf_loader;
      const gltf = (await loader.loadAsync(this.glb_url)) as GLTF;

      const avatar = AvatarView.currentState.avatar as AvatarAnimatable;
      const g = new Group();
      avatar.meshes.forEach((x) => g.children.push(x));

      const allKeyframeTracks = processMorphTargetTrack(gltf.animations[0].tracks[0], gltf.scene as any, g);

      // Now you can do something with allKeyframeTracks, e.g., create an animation clip.
      this._clip = new AnimationClip('BlendshapeAnimation', -1, allKeyframeTracks);

      // scene = gltf.scene;
    }

    this.setDownloadState(DownloadState.Loaded);
  }
}
