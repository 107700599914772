import axios from 'axios';
import { ClientBuilder, createSettings } from './api';
import env from '@in3d/environment';
import { AssetData, CategoryName, getHostname, NewAssetConfig, RankedAsset, ResourcePlacementStr } from '@in3d/common';

const get_headers = (token?: string) => ({
  Authorization: `Bearer ${token || ''}`,
});

export interface NewAssetModel {
  alias: string;
  type: 'animations' | 'eyes' | 'head' | 'look' | 'shoes';
  gender: 'male' | 'female' | 'unisex';
  glb_url: string;
  glb_female_url: string;
  preview_url: string;
  material_preset: string;
  settings: Record<string, any>;
  id?: string;
}

class AdminClient {
  constructor(api: ClientBuilder) {
    this.api = api;
  }
  api: ClientBuilder;

  async prepareGlb(
    cloth: File,
    avatar: File,
    config?: { cloth_type: string; placement: ResourcePlacementStr }
  ): Promise<{ glb_url: string; preview: string }> {
    const form = new FormData();
    form.append('avatar', avatar);
    form.append('cloth', cloth);

    const params = new URLSearchParams();
    if (config) {
      params.set('cloth_type', config.cloth_type);
    }
    try {
      const blob = await (
        await fetch('https://cloth-processor-zlhq6agkjq-uc.a.run.app/process_cloth?' + params.toString(), {
          method: 'POST',
          body: form,
        })
      ).blob();
      const preview = await this.renderPreview({ file: cloth, type: config?.placement || 'look' });
      return {
        glb_url: URL.createObjectURL(new Blob([blob], { type: 'application/octet-stream' })),
        preview: preview,
      };
    } catch (err) {
      console.log('Prepare glb error: ', err);
      throw err;
    }
  }
  async uploadGlb(
    token: string,
    file: Blob,
    config: { slug: string; type: 'assets' | 'resources'; name: string }
  ): Promise<any> {
    const params = new URLSearchParams();
    params.set('type', config.type);
    params.set('file_name', config.name);

    return this.api
      .post<{ presign_url: string; cdn_url: string }>(
        `/projects/${config.slug}/upload?${params.toString()}`,
        null,
        get_headers(token)
      )
      .then(({ presign_url, cdn_url }) => {
        return axios.put(presign_url, file, { headers: { 'Content-Type': 'model/gltf-binary' } }).then(() => cdn_url);
      });
  }
  async createUserAsset(
    file: File,
    config: NewAssetConfig
  ): Promise<{ id: string; glb_url: string; glb_female_url: string }> {
    const form = new FormData();
    form.set('config', JSON.stringify(config));
    form.set('file', file);

    return axios
      .post<{ status: string; data: { id: string; glb_url: string; glb_female_url: string } }>(
        'https://avaturn-image-store.avaturn.workers.dev/upload/' + getHostname(),
        form
      )
      .then(({ data }) => data.data);
  }

  async getAssets(token: string): Promise<AssetData[]> {
    return this.api.get('/assets', null, get_headers(token));
  }
  async getProjectAssets(token: string, slug: string): Promise<RankedAsset[]> {
    return this.api.get<RankedAsset[]>('/assets/project_assets/' + slug, null, get_headers(token));
  }
  async saveNewAsset(token: string, asset: NewAssetModel): Promise<string> {
    return this.api.post('/assets/new', asset, get_headers(token));
  }
  async updateAsset(token: string, id: string, asset: NewAssetModel) {
    return this.api.post('/assets/' + id, asset, get_headers(token));
  }
  async loadAvatar(url: string) {
    return fetch(url).then((res) => res.blob());
  }
  async addAssetToProject(token: string, project: string, id: string): Promise<any> {
    return this.api.put(
      '/assets/project_assets/' + project,
      { add: [{ asset_id: id, rank: 0, public: true }] },
      get_headers(token)
    );
  }
  async deleteAsset(token: string, ids: string[], project: string) {
    this.api.put('/assets/project_assets/' + project, { delete: ids }, get_headers(token));
  }
  async importDefaults(token: string, category: string, project: string) {
    return this.getAssets(token).then((list) => {
      const assetsToImport = list
        .filter((e) => e.type === category)
        .map((e, i) => ({ asset_id: e.id, rank: i, public: true }));

      return this.api.put(`/assets/project_assets/${project}`, assetsToImport, get_headers(token));
    });
  }
  async renderPreview({ file, type }: RenderPreviewParams): Promise<string> {
    if (type === 'eyes' || type === 'faceMask' || type === 'animations') return '';

    const scenes: { [key in RenderPreviewParams['type']]: string } = {
      look: 'look_render',
      shoes: 'shoes_render',
      head: 'head_render',
      eyes: '',
      animations: '',
      faceMask: '',
    };
    const form = new FormData();
    form.set('file', file);
    form.set('scene_name', scenes[type]);
    form.set('pose_name', 'default_pose');
    const preview = await (
      await fetch('https://cloth-processor-zlhq6agkjq-uc.a.run.app/run_render_model', { method: 'POST', body: form })
    ).blob();

    console.log('preview: ', preview);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(preview);

      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.result);
    });
  }
}

export interface RenderPreviewParams {
  file: File | Blob;
  type: ResourcePlacementStr;
}

export const adminClient = new AdminClient(new ClientBuilder(createSettings(env.baseUrl)));
