import cn from 'classnames';
import { AvatarAnimatable, eyeColors } from 'libs/core/src/lib/core/resources/avatar_animatable';
import SVG from 'react-inlinesvg';
import { currentState } from 'libs/core/src/lib/core/CurrentState';
import { useEffect, useState } from 'react';
import env from '@in3d/environment';
import { customizationUpdater } from 'libs/core/src/lib/addons/customization_updater';
import { observer } from 'mobx-react-lite';
import { resources } from '@in3d/core';

type EyesColorsProps = {
  activeType: string;
};

const EyesColors = ({ activeType }: EyesColorsProps) => {
  const [texture, setTexture] = useState<string>('');

  const whenSceneLoaded = () => {
    const text = (currentState.avatar as AvatarAnimatable)?.eye_texture;
    // console.log(text);
    text && setTexture(text);
  };

  useEffect(() => {
    if (resources.isSceneLoaded) {
      whenSceneLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources.isSceneLoaded]);

  const handleSetTexture = (eyeColorName: string) => {
    currentState.changeEyeTexture(eyeColorName).then(() => {
      customizationUpdater.update_customization();
      setTexture(eyeColorName);
    });
  };
  return (
    <div className={cn(['body-options', activeType === 'eyes' && 'active'])}>
      <div className="body-options__title">
        <SVG src="/assets/editor/eyes.svg" />
        Eye color
      </div>
      <div className="eyes-color">
        <div className="eyes-color__list">
          {eyeColors.map((eyeColor, i) => (
            <button
              key={eyeColor + i}
              className={cn(['eyes-color__btn'], {
                active: texture === eyeColor,
              })}
              style={{ backgroundImage: `url(${env.editorResourcesUrl}/eyes/thumbnails/${eyeColor}.webp)` }}
              onClick={() => handleSetTexture(eyeColor)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const _EyesColors = observer(EyesColors);
export { _EyesColors as EyesColors };
