import {
  DataTexture,
  DoubleSide, RGBAFormat, ShaderMaterial, WebGLRenderTarget,
  sRGBEncoding
} from 'three';
import { DecalMaker } from '.';

import { disposeScene } from './dispose_helpers';
import { prepareScene } from './prepareScene';

export const vertexShader = `
    precision highp float;
    attribute vec2 uv_outer;
    varying vec2 vUv;

    void main() {
        vUv = uv;
        vec2 xy = uv_outer * 2.0 - 1.0;
        gl_Position = vec4(xy, 0.0, 1.0);
    }
`;

export const fragmentShader = `
    precision highp float;
    uniform sampler2D decalMap;
    varying vec2 vUv;

    void main() {
        gl_FragColor = texture2D(decalMap, vUv);
        // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
    }
`;

// generated image → texture
export function projectDecal(
  this: DecalMaker,
  initialTexture: THREE.Texture,
  decalGeometry: THREE.BufferGeometry,
  decalMap: THREE.Texture,
) {
  // Create an initial texture from an image, canvas, or any other source
  // const clonedTexture = initialTexture.clone();

  const { mapping, magFilter, minFilter, generateMipmaps, anisotropy, wrapS, wrapT, encoding } = initialTexture;
  initialTexture.needsUpdate = true; // Make sure to update the texture

  // Create a WebGLRenderTarget with the desired dimensions
  const initWidth = initialTexture.image.width;
  const initHeight = initialTexture.image.height;

  // calculate the width and height of the texture
  // so that the minimum dimension is 2048

  const width = initWidth > initHeight ? 2048 : (initWidth / initHeight) * 2048;
  const height = initHeight > initWidth ? 2048 : (initHeight / initWidth) * 2048;

  decalMap.encoding = sRGBEncoding

  // OBJECTS
  const material = new ShaderMaterial({
    uniforms: {
      decalMap: { value: decalMap },
    },
    transparent: true,
    side: DoubleSide,
    vertexShader,
    fragmentShader,
    premultipliedAlpha: true,
  });

  // SCENE
  const { scene, camera } = prepareScene({ width, height }, material, decalGeometry);

  // BG
  scene.background = initialTexture;


  const renderTarget = new WebGLRenderTarget(width, height);
  renderTarget.texture.encoding = sRGBEncoding

  // PREPARE FOR RENDER

  // save values
  const { renderer, prepareRenderer, restoreRenderer } = this;

  prepareRenderer();

  // RENDER
  renderer.setRenderTarget(renderTarget);
  renderer.render(scene, camera);

  const pixels = new Uint8Array(width * height * 4); // 4 for RGBA
  renderer.outputEncoding = sRGBEncoding
  renderer.readRenderTargetPixels(renderTarget, 0, 0, width, height, pixels);

  const dataTexture = new DataTexture(pixels, width, height, RGBAFormat);
  dataTexture.magFilter = magFilter;
  dataTexture.minFilter = minFilter;
  dataTexture.mapping = mapping;
  dataTexture.generateMipmaps = generateMipmaps;
  dataTexture.anisotropy = anisotropy;
  dataTexture.wrapS = wrapS;
  dataTexture.wrapT = wrapT;
  dataTexture.encoding = sRGBEncoding
  dataTexture.needsUpdate = true;

  // dispose everything
  disposeScene(scene);

  // restore values
  restoreRenderer();

  return dataTexture;
};
