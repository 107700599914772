import { DeveloperSettings, ExportType, appClient } from '@in3d/api';
import { makeAutoObservable } from 'mobx';
import env from '@in3d/environment';
import { ConfigType, getDeveloperName, getHostname } from '@in3d/common';

declare global {
  interface Window {
    $avaturn: {
      app: {
        config: DeveloperSettings;
      };
    };
  }
}

class DeveloperConfig implements DeveloperSettings {
  constructor() {
    makeAutoObservable(this);
    this.applyDefaultConfig();
    // const config = window?.$avaturn?.app?.config;
    // if (config) {
    //   console.log('[DEBUG]: Remote config loaded');
    //   this.name = getDeveloperName();

    //   if (config.export_mode) this.export_mode = config.export_mode;
    //   if (config.editor) this.editor = config.editor;
    //   if (config.app) this.app = config.app;
    //   if (config.scan) this.scan = config.scan;
    //   if (config.default_config) this.default_config = config.default_config;
    //   if (config.disable_loader) this.disable_loader = config.disable_loader;
    // } else {
    //   console.log('[DEBUG]: Local config loaded');
    //   this.applyDefaultConfig();
    // }
  }

  export_mode = ExportType.DataURL;
  default_config = ConfigType.v1;
  disable_loader: DeveloperSettings['disable_loader'] = false;

  name = '';
  editor: DeveloperSettings['editor'] = {
    withPoweredBtn: true,
    withSaveBtn: false,
    withHomeBtn: true,
    withAnimationTab: true,
    withFaceAnimationTab: false,
    customCss: false,
    customLogo: false,
    sceneBg: null as null | string,
    customBodies: false,
    logo: '',
    exportWithThumbnail: false,
    defaultHaircap: 'stripes',
    textureSize: 1024,
  };
  app: DeveloperSettings['app'] = {
    withNav: false,
    customLogo: '',
    disableSocialLinks: false,
    allowSelectAvatarVersion: false,
  };
  scan: DeveloperSettings['scan'] = {
    withPhotoUploader: false,
  };
  applyDefaultConfig() {
    this.name = getDeveloperName();
    appClient
      .getDeveloperSettings()
      .then((settings) => {
        this.export_mode = settings.export_mode;
        this.default_config = settings.default_config;
        if (settings.disable_loader) this.disable_loader = settings.disable_loader;
      })
      .catch((err) => {
        if (this.name) console.warn(`Error while getting settings for ${this.name}: ${err}`);
        else console.warn(`Developer name not set, so there is no settings for it`);
      });

    this.editor.logo = getDeveloperLogo(this.name);
    this.editor.withSaveBtn = !['artisant', 'artisant-static', 'cinecom', 'creepyfriends'].includes(this.name);
    this.editor.withHomeBtn = !['artisant-static', 'eikonikos', 'scenic'].includes(this.name);

    if (env.rootHosts.includes(getHostname())) {
      this.app.allowSelectAvatarVersion = true;
    }
    if (this.name == 'moviebot') {
      this.editor.defaultHaircap = 'regular';
    }
    if (this.name == 'deepmotion') {
      this.editor.withAnimationTab = false;
    }
    if (['elijah', 'bonjourlab'].includes(this.name)) {
      this.editor.customCss = true;
      this.editor.customLogo = true;
      this.editor.sceneBg = '#a33419';
      this.editor.customBodies = true;
    }
    if (this.name === 'eikonikos') {
      this.editor.customCss = true;
    }
    if (this.name === 'gn3ra') {
      this.editor.customCss = true;
      this.editor.customLogo = true;
    }
    if (
      ['eikonikos', 'artisant', 'artisant-static', 'cinecom', 'creepyfriends', 'scenic', 'flamapp'].includes(this.name)
    ) {
      this.editor.withPoweredBtn = false;
    }
    if (this.name === 'scenic') {
      this.editor.customCss = true;
      this.app.customLogo = '/assets/scenic-icon.png';
    }
    if (env.rootHosts.includes(getHostname())) {
      this.scan.withPhotoUploader = true;
      this.app.withNav = true;
      this.editor.withSaveBtn = false;
    }
    if (this.name === 'singleview') {
      this.scan.withPhotoUploader = true;
    }
    if (this.name === 'awe-test' || this.name === 'awe-base') {
      this.editor.customCss = true;
      this.editor.sceneBg = 'linear-gradient(92.41deg, #0E0E14 -51.71%, #332148 95.88%)';
      this.editor.withHomeBtn = false;
      this.editor.exportWithThumbnail = true;
    }

    if (env.hostsAnimatable.includes(getHostname())) {
      this.editor.withFaceAnimationTab = true;
    }

    if (this.name === 'elijah') {
      this.scan.withPhotoUploader = true;
    }
    if (this.name === 'mtn') {
      this.editor.exportWithThumbnail = true;
    }
  }
}
function getDeveloperLogo(name: string) {
  const icons: { [key: string]: string } = {
    scenic: '/assets/scenic-icon.png',
    cinecom: '/assets/Cinecom_white.svg',
    bonjourlab: '/assets/developers/logo-nike.svg',
    gn3ra: 'https://uploads-ssl.webflow.com/62e19c2ca67ba6f659c09e2a/640d9da0317316ffe4949c53_GN3RA%20Logo.png',
    elijah: '/assets/developers/logo-nike.svg',
    eikonikos: 'https://assets.avaturn.me/eikonikos_091a0d5e51.png',
  };
  return icons[name] || '';
}

export const developerConfig = new DeveloperConfig();

/*
const artisant = {
  editor: {
    withSaveBtn: true,
    withPoweredBtn: true,
  },
};
const artisantStatic = {
  editor: {
    withSaveBtn: true,
    withHomeBtn: true,
    withPoweredBtn: true,
  },
};
const cinecom = {
  editor: {
    withSaveBtn: true,
    withPoweredBtn: true,
  },
};
const scenic = {
  editor: {
    withHomeBtn: true,
    withPoweredBtn: true,
    customCss: true,
  },
  app: {
    customLogo: '/assets/scanic-icon.png',
  },
};
const flamapp = {
  editor: {
    withPoweredBtn: true,
  },
};
const singleview = {
  scan: {
    withPhotoUploader: true,
  },
};
const creepyfriends = {
  editor: {
    withSaveBtn: true,
    withPoweredBtn: true,
  },
};

const bonjourlab = {
  editor: {
    customCss: true,
    customLogo: true,
    sceneBg: '#a33419',
    customBodies: true,
  },
};

const eikonikos = {
  editor: {
    withHomeBtn: true,
    customCss: true,
    withPoweredBtn: true,
  },
};
const aweTest = {
  editor: {
    customCss: true,
    sceneBg: 'linear-gradient(92.41deg, #0E0E14 -51.71%, #332148 95.88%)',
    withHomeBtn: false,
    exportWithThumbnail: true,
  },
};
*/
