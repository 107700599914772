import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

export const useRightPopper = (sceneLoaded = false) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
    placement: 'right',
  });

  const [popper, setPopper] = useState(false);

  useEffect(() => {
    if (sceneLoaded) {
      // show popper after scene loaded
      // and then hide it
      setPopper(true);
      setTimeout(() => {
        setPopper(false);
      }, 3000);
    }
  }, [sceneLoaded]);

  return {
    referenceElement,
    setReferenceElement,
    popperElement,
    setPopperElement,
    arrowElement,
    setArrowElement,
    styles,
    attributes,
    popper,
    setPopper,
  };
};
