import { trackFace } from '@in3d/core';
import cn from 'classnames';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { disposeVideo } from 'libs/core/src/lib/core/resources/tracking';

import { MemoizedWebcamVideoStream } from '../webcam-video';
import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';

type CameraViewProps = {
  handleRecordTracking: () => void;
  isRecording: boolean;
  close: () => void;
};

export const CameraView = ({ handleRecordTracking, isRecording, close }: CameraViewProps) => {
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isRecording) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else {
      setSeconds(0);
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isRecording]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="face-tracking__recording">
      {!isRecording && (
        <button className="face-tracking__close" onClick={close}>
          <SVG src="/assets/x.svg" />
        </button>
      )}
      <MemoizedWebcamVideoStream fn={trackFace} disposeFn={disposeVideo} />
      <button
        className={cn([
          'face-tracking__record',
          {
            recording: isRecording,
          },
        ])}
        onClick={handleRecordTracking}
      >
        <div>• {isRecording ? formatTime(seconds) : 'REC'}</div>
        <div className="circle-outer">
          <span />
        </div>
      </button>
    </div>
  );
};
