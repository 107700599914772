import { adminClient } from '@in3d/api';
import {
  CategoryName,
  placementToCategory,
  AssetData,
  notifySuccess,
  ResourcePlacementStr,
  notifyError,
} from '@in3d/common';
import { addCloth, changeAnimation, changeCloth, resources, SettingsJson } from '@in3d/core';
import { auth } from '@in3d/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createDefaultAssetsList } from '../common';

export interface AddAssetParams {
  cloth: File;
  avatar: File;
  type: string;
  placement: ResourcePlacementStr;
  settings: SettingsJson;
}
export enum AssetListType {
  Project = 'project',
  Market = 'market',
}

export const useProjcetAssets = ({ project, type }: { project: string; type: AssetListType }) => {
  const [list, setList] = useState<{
    [AssetListType.Market]: AssetData[];
    [AssetListType.Project]: AssetData[];
  }>({
    market: [],
    project: [],
  });
  const [loaded, setLoaded] = useState(false);

  const listByCategory: { [key in CategoryName]: AssetData[] } = useMemo(() => {
    return list[type].reduce((pv, cv) => {
      const category = placementToCategory(cv.type);
      if (pv[category]) pv[category].push(cv);
      else pv[category] = [cv];
      return pv;
    }, createDefaultAssetsList());
  }, [list, type]);

  const projectListById: { [key: string]: AssetData } = useMemo(() => {
    return list.project.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {} as { [key: string]: AssetData });
  }, [list.project]);

  const deleteAsset = (id: string) => {
    return adminClient.deleteAsset(auth.token, [id], project).then(() => {
      setList((prev) => ({ ...prev, project: prev.project.filter((e) => e.id !== id) }));
    });
  };

  const loadAssets = useCallback(() => {
    if (type === 'market') {
      return adminClient.getAssets(auth.token).then((list) => {
        setList((prev) => ({ ...prev, market: list }));
        resources.setList(list);
        setLoaded(true);
        return list;
      });
    }
    return adminClient.getProjectAssets(auth.token, project).then((list) => {
      resources.setList(list.map((e) => e.asset));
      setList((prev) => ({ ...prev, project: list.map((e) => e.asset) }));
      setLoaded(true);
      return list;
    });
  }, [project, type]);

  const addAnimation = useCallback(async ({ file }: { file: File }) => {
    const glb_url = URL.createObjectURL(file);
    const id = String(Date.now());

    const newAsset: AssetData = {
      id,
      glb_url,
      type: 'animations',
      created_at: new Date().toUTCString(),
      gender: 'male',
      isDraft: true,
    } as AssetData;
    setList((prev) => ({ ...prev, project: [...prev.project, newAsset] }));

    const animation = addCloth(glb_url, 'animations', { id, settings: new SettingsJson(), name: file.name });
    changeAnimation(animation);

    notifySuccess('Asset successfully added');
    return;
  }, []);

  const addAsset = useCallback(async ({ cloth, avatar, type, settings, placement }: AddAssetParams) => {
    try {
      const { glb_url, preview } = await adminClient.prepareGlb(cloth, avatar, { cloth_type: type, placement });
      const id = String(Date.now());
      const newAsset: AssetData = {
        id,
        glb_url,
        type: placement,
        created_at: new Date().toUTCString(),
        gender: 'male',
        preview_url: preview,
        isDraft: true,
      } as AssetData;

      setList((prev) => ({ ...prev, project: [...prev.project, newAsset] }));

      addCloth(glb_url, placement, { id, settings, name: cloth.name, preview });
      changeCloth(id);

      notifySuccess('Asset successfully added');
    } catch (err) {
      notifyError('Something went wrong');
      throw err;
    }
  }, []);

  const shareToProject = (id: string) => {
    adminClient.addAssetToProject(auth.token, project, id).then(() => {
      adminClient.getProjectAssets(auth.token, project).then((list) => {
        setList((prev) => ({ ...prev, project: list.map((e) => e.asset) }));
      });
    });
  };

  useEffect(() => {
    loadAssets();
  }, [project, type, loadAssets]);
  return {
    load: loadAssets,
    add: addAsset,
    addAnimation,
    shareToProject,
    delete: deleteAsset,
    list,
    loaded,
    listByCategory,
    projectListById,
  };
};
