import GUI from 'lil-gui';
import { Color, Mesh, Group, PlaneGeometry, ShadowMaterial, RepeatWrapping, TextureLoader, MeshStandardMaterial, SpotLight, SpotLightHelper, LinearEncoding } from 'three';

// import {VolumetricSpotlight} from "./SpotLightConeMaterial.js"

function plane_rocks() {
  // Setup plane
  const N = 20;
  const ground = new Mesh(
    new PlaneGeometry(N, N),
    new MeshStandardMaterial({ color: 0x000000, depthWrite: false })
  );
  ground.rotation.x = -Math.PI / 2;
  ground.receiveShadow = true;
  // ground.material.transparent = true;
  // ground.material.opacity = 1;

  new TextureLoader().load(
    'local/textures/terrain-roughness.jpg',
    function (t) {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(N / 4, N / 4);
      t.offset.set(0, 0);

      ground.material.roughnessMap = t;
      ground.material.needsUpdate = true;
      t.encoding = LinearEncoding;
    },
    undefined,
    function (err) {
      console.error('An error while loading floor roughness.');
    }
  );

  new TextureLoader().load(
    'local/textures/terrain-normal.jpg',
    function (t) {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(N / 4, N / 4);
      t.offset.set(0, 0);

      ground.material.normalMap = t;
      // ground.material.normalScale = new Vector2(0.15, 0.15);
      ground.material.needsUpdate = true;
      t.encoding = LinearEncoding;
    },
    undefined,
    function (err) {
      console.error('An error while loading floor normals.');
    }
  );

  return ground;
}

function shadow_plane(color: Color = new Color(0,0,0), width: number, opacity=0.6) {
  const shadowMaterial = new ShadowMaterial({
    color: color,
  });
  const plane = new Mesh(
    new PlaneGeometry(width, width),
    shadowMaterial
  );
  plane.receiveShadow = true;
  plane.rotation.x = -Math.PI / 2;
  plane.material.opacity = opacity;
  plane.name = 'shadow_plane';
  return plane;
}

function rim_light(gui: GUI, mult = 1) {
  let g = new Group();

  // Spot light 1
  let spotLight = new SpotLight(new Color(1, 0.25, 0.7), 33.5 * mult);
  spotLight.name = 'spotlight1';
  spotLight.position.set(3, 5, -5);
  spotLight.angle = 0.5;
  spotLight.penumbra = 0.5;
  spotLight.castShadow = true;
  spotLight.distance = 15;
  spotLight.shadow.mapSize.width = 512;
  spotLight.shadow.mapSize.height = 512;

  g.add(spotLight);

  if (IS_DEBUG && gui) {
    let h1 = new SpotLightHelper(spotLight);
    g.add(h1);
    const f = gui.addFolder('Spotlight1');
    const upd_camera = () => { spotLight.shadow.camera.updateProjectionMatrix(); }

    f.add(spotLight, 'castShadow', [true, false]).listen();
    f.add(spotLight, 'intensity', 0, 80, 0.1).listen();
    f.add(spotLight, 'angle', 0, 2, 0.1).listen();
    f.add(spotLight.shadow.camera, 'near', 0, 40, 0.1).listen().onChange(upd_camera);
    f.add(spotLight.shadow.camera, 'far', 0, 40, 0.1).listen().onChange(upd_camera);
    f.add(spotLight.shadow, 'focus', 0, 1, 0.1).listen().onChange(upd_camera);
  }

  // Spot light 2
  spotLight = new SpotLight(new Color(1, 0.7, 0.7), 31.5 * mult);
  spotLight.name = 'spotlight2'
  spotLight.position.set(-1.5, 2, -5);
  spotLight.angle = 0.9;
  spotLight.castShadow = true;
  spotLight.penumbra = 0.5;
  spotLight.shadow.mapSize.width = 512;
  spotLight.shadow.mapSize.height = 512;
  spotLight.shadow.camera.near = 0.5; // default
  spotLight.shadow.camera.far = 10; // default

  g.add(spotLight);

  // const helper = new CameraHelper(spotLight.shadow.camera);
  // g.add(helper)

  if (IS_DEBUG && gui) {
    let h1 = new SpotLightHelper(spotLight);
    g.add(h1);
    const f = gui.addFolder('Spotlight2');

    f.add(spotLight, 'intensity', 0, 80, 0.1).listen();
    f.add(spotLight, 'angle', 0, 2, 0.1).listen();
    f.add(spotLight.shadow.camera, 'near', 0, 40, 0.1).listen().onChange(() => { spotLight.shadow.camera.updateProjectionMatrix(); });
    f.add(spotLight.shadow.camera, 'far', 0, 40, 0.1).listen().onChange(() => { spotLight.shadow.camera.updateProjectionMatrix(); });
    f.add(spotLight.shadow, 'focus', 0, 1, 0.1).listen().onChange(() => { spotLight.shadow.camera.updateProjectionMatrix(); });
  }

  return g;
}

export { rim_light, plane_rocks, shadow_plane };

