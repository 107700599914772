import { CategoryName, resources } from '@in3d/core';
import { avatars } from '@in3d/store';
import { observer } from 'mobx-react-lite';

export const CreepyfriendsSceneAction = observer(() => {
  const sendMessageForBuy = () => {
    const message = JSON.stringify({
      source: 'avaturn',
      eventName: 'v2.asset.buy',
      date: Date.now(),
      data: {
        id: resources.active[CategoryName.Outfit],
      },
    });
    window.parent.postMessage(message, '*');
  };
  return (
    <button disabled={!avatars.isExportBtnEnabled} className="scene__save" onClick={sendMessageForBuy}>
      Buy!
    </button>
  );
});
