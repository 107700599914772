import './timer.scss';

import { ScanData } from '@in3d/api';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

const ETA = 1 * 60 * 1000;

const HubTimer = ({ scan, text, eta = ETA }: { scan: ScanData; text?: string; eta?: number }) => {
  const start_time = scan?.queued_at || '';
  useEffect(() => {
    window.hideLoader();
  }, []);

  return (
    <div className="hub-timer">
      <div className="hub-timer__loader">
        <div className="timer-animation">
          <TimerCircle start={start_time} eta={ETA} />
        </div>
      </div>
      <div className="hub-timer__text">{text ? text : 'Avatar is loading'}</div>
      <div className="hub-timer__text hub-timer__text--small">Usually takes about 30 seconds</div>
    </div>
  );
};

export const TimerCircle = ({ start, eta }: { start: string; eta: number }) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const calculatePercent = () => {
      if (start) {
        const time_passed = Math.round(eta - (new Date().getTime() - new Date(start).getTime()));
        const actual_percent = Math.max(100 - Math.round((time_passed / eta) * 100), 0);
        if (actual_percent) setPercent(actual_percent > 99 ? 99 : actual_percent);
      }
    };
    calculatePercent();
    const interval = setInterval(calculatePercent, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [start]);

  return (
    <>
      <div className="timer-animation__text">{percent}%</div>
      <svg>
        <circle className="border" cx="80" cy="80" r="60" />
        <circle
          cx="80"
          cy="80"
          r="60"
          style={{
            strokeDasharray: 500 + (375 * percent) / 100,
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
          }}
        />
      </svg>
    </>
  );
};

const _HubTimer = observer(HubTimer);
export { _HubTimer as HubTimer };
