// import { scene1 } from './scene1.js';
// import { scene2 } from './scene2.js';
// import { scene3 } from './scene3.js';
// import { Scene4 } from './scene4.js';
// import { scene5 } from './scene5.js';
// import { scene6 } from './scene6.js';
import GUI from 'lil-gui';
import { Group, PerspectiveCamera, Scene, WebGLRenderer } from 'three';
import { AvatarView } from '../../AvatarView';
import { addToneMappingDebugInfo } from './debug_gui';
import { SceneFootball } from './deepmotion/scene_football';
import { SceneInfosys } from './infosys/scene_infosys';
import { SceneBase } from './scene_base';
import { SceneDark } from './scene_dark';
import {
  SceneBright,
  SceneBright10,
  SceneBright10e,
  SceneBright11,
  SceneBright12,
  SceneBright12Animatable,
  SceneBright12AnimatableB,
  SceneBright12AnimatableC,
  SceneBright12AnimatableC15,
  SceneBright12AnimatableD,
  SceneBright12AnimatableE,
  SceneBright12AnimatableEnv,
  SceneBright12b,
  SceneBright12c,
  SceneBright12cArtisant,
  SceneBright12cBonjourlab,
  SceneBright12cFilmicLut,
  SceneBright3,
  SceneBright4,
  SceneBright5,
  SceneBright6,
  SceneBright7,
  SceneBright8,
  SceneBright9,
  SceneDark2,
  SceneDark3,
  SceneDark4,
  SceneDark5,
  SceneLinear,
} from './scene_dark2';
import { SceneAWE } from './tetonridge/scene_awe';
import { SceneBull } from './tetonridge/scene_bull';
import { SceneFanMag } from './tetonridge/scene_fanmag';
import { loadFileFromDisk } from '../resources/common';

export class SceneManager {
  renderer_: WebGLRenderer;
  scene_: Scene;
  camera_: PerspectiveCamera;
  gui_: GUI | undefined;

  current_scene: SceneBase;
  new_scene_name = 'sceneDefault';
  current_scene_name: string;

  scenes_map: Record<string, any>;

  get camera() {
    return this.current_scene ? this.current_scene.camera : this.camera_;
  }
  get renderer() {
    return this.current_scene ? this.current_scene.renderer : this.renderer_;
  }
  constructor(renderer: WebGLRenderer, scene: Scene, camera: PerspectiveCamera, gui: GUI | undefined) {
    this.renderer_ = renderer;
    this.scene_ = scene;
    this.camera_ = camera;
    this.gui_ = gui;

    const scenes = {
      sceneDefault: SceneBright12AnimatableC15,
      sceneDefaultAnimatable: SceneBright12AnimatableC15,
      sceneDefaultDev: SceneBright12c,

      sceneHeadless: SceneBright12cFilmicLut,
      //
      sceneBright12cArtisant: SceneBright12cArtisant,
      sceneBright12cBonjourlab: SceneBright12cBonjourlab,
      //
      sceneBright12Animatable: SceneBright12Animatable,
      //
      infosys: SceneInfosys,
      //
      tetonridge_FanMag: SceneFanMag,
      tetonridge_AWE: SceneAWE,
      tetonridge_Bull: SceneBull,
      //
      deepmotion_Football: SceneFootball,
    };
    if (IS_DEBUG) {
      const debug_scenes_map = {
        sceneDark: SceneDark,
        sceneBright: SceneBright,
        sceneBright3: SceneBright3,
        sceneBright4: SceneBright4,
        sceneBright5: SceneBright5,
        sceneBright6: SceneBright6,
        sceneBright7: SceneBright7,
        sceneBright8: SceneBright8,
        sceneBright9: SceneBright9,
        sceneBright10: SceneBright10,
        sceneBright11: SceneBright11,
        sceneBright10e: SceneBright10e,
        sceneBright12: SceneBright12,
        sceneBright12b: SceneBright12b,
        sceneBright12c: SceneBright12c,
        sceneBright12AnimatableC15: SceneBright12AnimatableC15,
        sceneBright12AnimatableB: SceneBright12AnimatableB,
        sceneBright12AnimatableC: SceneBright12AnimatableC,
        sceneBright12AnimatableD: SceneBright12AnimatableD,
        sceneBright12AnimatableE: SceneBright12AnimatableE,
        sceneBright12AnimatableEnv: SceneBright12AnimatableEnv,
        sceneDark2: SceneDark2,
        sceneDark3: SceneDark3,
        sceneDark4: SceneDark4,
        sceneDark5: SceneDark5,
        SceneLinear: SceneLinear,
      };
      this.scenes_map = Object.assign({}, debug_scenes_map, scenes);
    } else {
      this.scenes_map = scenes;
    }
    if (IS_DEBUG && gui) {
      // SceneSettingsGUI = AvatarView.gui.addFolder('SceneSettings');
      // add_debug_stuff(AvatarView, SceneSettingsGUI, AvatarView.scene);
      gui
        .add(this, 'new_scene_name', Object.keys(this.scenes_map))
        .listen()
        .onChange(() => {
          this.switch(this.new_scene_name);
        });

      addToneMappingDebugInfo(gui, this.renderer_);
      // this.gui_ = undefined;

      const loadSkybox = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*'; // Optionally, restrict the user to select only image files
        input.onchange = (e: Event) => {
          if (!e.target) return;
          const fileInput = e.target as HTMLInputElement;
          if (!fileInput.files) return;
          const file = fileInput.files[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            const result = e.target?.result;
            if (typeof result === 'string') {
              (this.current_scene as any).loadSkybox({ url: result });
            }
          };
          reader.readAsDataURL(file); // Read the file as a data URL
        };
        input.click();
      };
      const loadGLB = async () => {
        loadFileFromDisk().then((url) => {
          (this.current_scene as any).loadGLB({ url });
        });
      };
      const funcs = {
        loadSkybox,
        loadGLB,
      };
      gui.add(funcs, 'loadSkybox').name('Import Skybox');
      gui.add(funcs, 'loadGLB').name('Import GLB');
    }
  }

  async switch(name: keyof typeof this.scenes_map) {
    if (this.current_scene_name == name || this.scenes_map[name] === this.scenes_map[this.current_scene_name]) {
      return;
    }
    this.current_scene_name = name;

    const scene_group = this.scene_.getObjectByName('scene_group') as Group;
    scene_group.clear();

    AvatarView.camera.clear();

    this.current_scene?.clean_up();
    this.current_scene = new this.scenes_map[name](
      this.renderer_,
      this.scene_,
      this.camera_,
      this.gui_?.addFolder(name).close()
    );
    await this.current_scene.build();
  }

  render(mixerUpdateDelta: number) {
    this.current_scene?.render(mixerUpdateDelta);
  }

  onWindowResize(width: number, height: number) {
    this.current_scene?.onWindowResize(width, height);
  }
}
