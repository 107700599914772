import { authClient, ga, GaEvent } from '@in3d/api';
import { AuthError } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Navigate, NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CustomInput, LogoText } from '@in3d/ui';
import { auth } from '@in3d/store';
import { useSessionStorage } from '@in3d/hooks';
import './login.scss';
import { when } from 'mobx';
import { BaseRoutes, cn, getHostname, GOOGLE_PARAM, notifySuccess } from '@in3d/common';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react-lite';
import env from '@in3d/environment';
import { useGetParam } from '@in3d/hooks';

interface AuthForm {
  email: string;
  password: string;
  repeat_password: string;
}
type FormErrors = AuthForm;

enum LoginStep {
  Google,
  Email,
  Register,
  Restore,
}

enum AuthErrorCode {
  InvalidEmail = 'auth/invalid-email',
  WrongPassword = 'auth/wrong-password',
  PasswordsNotEqual = 'auth/passwords-equal',
}
const initialForm = {
  email: '',
  password: '',
  repeat_password: '',
};
const initialFormErrors = {
  email: '',
  password: '',
  repeat_password: '',
};
const errorMessages = {
  [AuthErrorCode.InvalidEmail]: {
    email: 'Invalid email',
  },
  [AuthErrorCode.WrongPassword]: {
    password: 'Wrong password',
  },
  [AuthErrorCode.PasswordsNotEqual]: {
    repeat_password: 'Not equal',
  },
};

const Login: React.FC<{ redirectTo?: BaseRoutes }> = ({ redirectTo }) => {
  const redirectUrl = redirectTo || BaseRoutes.Hub;
  // FIXME

  const isInfoSys = getHostname().includes('infosys');

  const initialValue = useGetParam(GOOGLE_PARAM) === 'false';
  const [googleDisabled, setGoogleDisabled] = useSessionStorage<boolean>(GOOGLE_PARAM, initialValue);

  useEffect(() => {
    if (initialValue) setGoogleDisabled(initialValue);
  }, [initialValue, setGoogleDisabled]);

  const navigate = useNavigate();
  const location = useLocation();
  const [form, setForm] = useState<AuthForm>({ ...initialForm });
  const [commonError, setCommonError] = useState<string>('');
  const [formErrors, setFormErrors] = useState<FormErrors>({
    ...initialFormErrors,
  });
  const [isAgree, setIsAgree] = useState(!isInfoSys);
  const [step, setStep] = useState<LoginStep>(LoginStep.Google);
  const [isFormValid, setIsFormValid] = useState(false);

  const isEmailAuthError = !!form.email && !formErrors.email && formErrors.password;
  const isLinkingStage = !!auth.data?.isGuest;
  const withNavButton = location.pathname !== '/login' || auth.data?.isGuest;

  useEffect(() => {
    when(
      () => auth.isAuth,
      () => {
        if (auth.data && !auth.data.isGuest) {
          navigate(location.state?.from || redirectUrl);
        }
      }
    );
  }, [location, navigate, redirectUrl]);

  useEffect(() => {
    if (location.pathname === '/login') setStep(LoginStep.Google);
    if (location.pathname === '/login/register') setStep(LoginStep.Register);
    if (location.pathname === '/login/email') setStep(LoginStep.Email);
    if (location.pathname === '/login/restore') setStep(LoginStep.Restore);
    setForm({ ...initialForm });
    setIsFormValid(false);
    setFormErrors({ ...initialFormErrors });
    setCommonError('');
  }, [location.pathname]);

  useEffect(() => {
    window.showLoader();
    authClient.getRedirectResult().then((result) => {
      window.hideLoader();
    });
  }, []);
  useEffect(() => {
    if (formErrors.password) {
      setCommonError('Invalid email or password');
    }
  }, [formErrors.password]);

  const handleDone = () => {
    ga.event(GaEvent.AuthEmail);
    navigate(location.state?.from || redirectUrl);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => {
      prev[e.target.name as keyof AuthForm] = e.target.value.trim();
      setIsFormValid(() => {
        if (step === LoginStep.Register)
          return Object.values(prev).every(Boolean) && prev.password === prev.repeat_password;
        if (step === LoginStep.Email) return !!prev.email && !!prev.password;
        if (step === LoginStep.Restore) return !!prev.email;
        return false;
      });
      return prev;
    });
  };
  const handleError = async (err: AuthError) => {
    // console.log('err: ', err.code);
    if (errorMessages[err.code as AuthErrorCode]) {
      setFormErrors((prev) => ({
        ...prev,
        ...errorMessages[err.code as AuthErrorCode],
      }));
    } else {
      const { commonFirebaseErrors } = await import('./auth-errors');
      if (commonFirebaseErrors[err.code]) {
        setCommonError(commonFirebaseErrors[err.code]);
      }
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (isAgree) {
      e.preventDefault();
      authClient.authWithEmail({ email: form.email, password: form.password }).then(handleDone).catch(handleError);
    }
  };

  const handleAuthGoogle = () => {
    ga.event(GaEvent.AuthGoogle);
    if (isAgree) {
      if (isLinkingStage) {
        authClient
          .linkWithGoogle()
          .then((user) => {
            auth.updateAuthData(user);
            handleDone();
          })
          .catch(handleError);
        return;
      }
      authClient.googleAuth().then(handleDone).catch(handleError);
    }
  };
  const handleAuthGuest = () => {
    ga.event(GaEvent.GuestAuth);
    if (isAgree) {
      authClient.guestAuth().then(handleDone).catch(handleError);
    }
  };
  const handleRestorePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authClient.restorePassword(form.email).then(() => {
      notifySuccess('We sent you a message with a link to reset your password. Please check your e-mail.', {
        autoClose: false,
      });
    });
  };
  const handleRegister = (e: React.FormEvent<HTMLFormElement>) => {
    if (isAgree) {
      e.preventDefault();
      if (form.password !== form.repeat_password) {
        setFormErrors((prev) => ({
          ...prev,
          ...errorMessages[AuthErrorCode.PasswordsNotEqual],
        }));
      } else {
        if (isLinkingStage) {
          authClient
            .linkWithEmail({ email: form.email, password: form.password })
            .then((user) => {
              auth.updateAuthData(user);
              handleDone();
            })
            .catch(handleError);
          return;
        }
        authClient.signUp({ email: form.email, password: form.password }).then(handleDone).catch(handleError);
      }
    }
  };

  const handleGoBack = () => {
    if (isLinkingStage && location.pathname === BaseRoutes.Login) {
      navigate(BaseRoutes.Hub);
    } else navigate(BaseRoutes.Login);
  };

  const isRoot = env.rootHosts.includes(getHostname());

  return (
    <div className="login">
      <div className="login__header">
        <div className="login__navigations">
          {withNavButton && (
            <div className="login__back" onClick={handleGoBack}>
              <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 2L2 9L9 16" stroke="#fff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="login__title">
        {!isInfoSys ? (
          <LogoText variant="default" size="lg" />
        ) : (
          <span className="login__brands">Metaverse Avatar powered by Infosys, Avaturn and NVIDIA</span>
        )}
      </div>
      {commonError && <div className="login-error">{commonError}</div>}
      <Routes>
        <Route
          path="/"
          element={
            <>
              {isInfoSys && (
                <label className={cn(['login-check', isAgree && 'active'])}>
                  <input
                    onChange={(e) => setIsAgree((prev) => !prev)}
                    type="checkbox"
                    checked={isAgree}
                    className="login-check__input"
                  />
                  <div className="login-check__label">
                    I agree with{' '}
                    <a
                      href="https://docs.google.com/document/d/1plQyHIuoO1LAwvevuvqd3jBW0a438tK9aombl7mB1X4/edit?usp=sharing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      data privacy
                    </a>
                  </div>
                </label>
              )}
              {googleDisabled ? null : (
                <div className="login__btn">
                  <button disabled={!isAgree} className="login-btn" onClick={handleAuthGoogle}>
                    <img src="/assets/google_logo.svg" alt="G" />
                    Continue with Google
                  </button>
                </div>
              )}
              {!isLinkingStage &&
                isRoot &&
                !['developer.avaturn.me', 'developer.createavatar.me'].includes(getHostname()) && (
                  <div className="login__btn">
                    <button className="login-btn" onClick={handleAuthGuest}>
                      <SVG src="/assets/user.svg" />
                      Continue as Guest
                    </button>
                  </div>
                )}

              <div className="login__form login-form">
                {googleDisabled ? null : <div className="login-form__text">or</div>}
                <NavLink className="login-form__link" to="register">
                  <button disabled={!isAgree} className="login-form__btn login-form__btn--round">
                    Sign up with e-mail
                  </button>
                </NavLink>
                {!isLinkingStage && (
                  <button disabled={!isAgree} className="login-form__btn">
                    Already have an account?
                    <NavLink to="email" className="colored">
                      Log in with e-mail
                    </NavLink>
                  </button>
                )}
              </div>
            </>
          }
        />
        <Route
          path="/register"
          element={
            <RequirePrivacyAgreement isAgree={isAgree}>
              <form className="register-form" onSubmit={handleRegister}>
                <CustomInput
                  className="register-form__input"
                  placeholder="email@example.com"
                  data-error={formErrors.email}
                  label="Email"
                  name="email"
                  onChange={handleChange}
                />
                <CustomInput
                  className="register-form__input"
                  label="Password"
                  data-error={formErrors.password}
                  placeholder="Your best password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                />
                <CustomInput
                  className="register-form__input"
                  label="Repeat password"
                  type="password"
                  name="repeat_password"
                  onChange={handleChange}
                />
                <div className="register-form__privacy">
                  By clicking "Create Aсcount" button below I agree to the <br />
                  <a href="https://avaturn.me/terms" rel="noreferrer" target="_blank" className="colored">
                    Terms & Conditions
                  </a>{' '}
                  and{' '}
                  <a href="https://avaturn.me/privacy" rel="noreferrer" target="_blank" className="colored">
                    Privacy Policy
                  </a>
                </div>
                <button disabled={!isFormValid} className="register-form__btn">
                  Create account
                </button>
              </form>
            </RequirePrivacyAgreement>
          }
        />
        <Route
          path="email"
          element={
            <RequirePrivacyAgreement isAgree={isAgree}>
              <form className="email-form" onSubmit={handleSubmit}>
                <CustomInput
                  className="email-form__input"
                  placeholder="email@example.com"
                  data-error={formErrors.email}
                  label="Email"
                  name="email"
                  onChange={handleChange}
                />
                <CustomInput
                  className="email-form__input email-form__input--flat"
                  onChange={handleChange}
                  data-error={!!formErrors.password}
                  label="Password"
                  type="password"
                  name="password"
                />
                <NavLink to="/login/restore" className="email-form__restore">
                  Forgot password?
                </NavLink>

                <button disabled={!isFormValid} type="submit" className="email-form__btn">
                  Sign In
                </button>
              </form>
            </RequirePrivacyAgreement>
          }
        />
        <Route
          path="restore"
          element={
            <form className="email-form" onSubmit={handleRestorePassword}>
              <CustomInput
                className="email-form__input email-form__input--single"
                placeholder="email@example.com"
                data-error={formErrors.email}
                label="Email"
                name="email"
                onChange={handleChange}
              />

              <button disabled={!isFormValid} type="submit" className="email-form__btn">
                Send e-mail
              </button>
            </form>
          }
        />
      </Routes>
    </div>
  );
};

const _Login = observer(Login);
export { _Login as Login };

const RequirePrivacyAgreement = ({ isAgree, children }: { isAgree: boolean; children: JSX.Element }) => {
  if (isAgree) return children;
  else return <Navigate to="/login" />;
};

const InfoSysLogo = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="86px"
    height="32px"
    viewBox="0 309.9 595.3 224"
    enable-background="new 0 309.9 595.3 224"
    className="logo"
  >
    <path
      id="logo"
      fill="#fff"
      d="M577.1,347.4c9.7,0,18.2-8,18.2-18.2c0-9.7-8-18.2-18.2-18.2c-9.7,0-18.2,8-18.2,18.2
	C559.4,339.4,567.4,347.4,577.1,347.4L577.1,347.4z M562.1,329.3c0-8.4,7.1-15.5,15.5-15.5c8.4,0,15.5,7.1,15.5,15.5
	c0,8.4-7.1,15.5-15.5,15.5C568.7,344.8,562.1,337.7,562.1,329.3L562.1,329.3z M570.5,338.6h3.5v-8h4l3.1,7.5h4l-3.5-8.4
	c1.3-0.4,3.1-1.3,3.1-4.9c0-5.3-4-6.2-7.5-6.2h-6.2v19.9H570.5z M574,322.6h4c2.2,0,3.1,0.9,3.1,2.7s-1.3,2.7-3.1,2.7h-4V322.6
	L574,322.6z M0,337.2L0,337.2V316h21.3v21.3l0,0v134.2v0.4v20.8H0v-21.3V471V337.2L0,337.2z M216.6,428.5
	c0.4,32.3,20.4,58,44.7,57.6c24.4-0.4,43.4-27,43-58.9c-0.4-32.3-20.4-58-44.7-57.6C235.2,370,216.1,396.1,216.6,428.5L216.6,428.5z
	 M392.9,389.5c0.4,1.3-0.9-1.8-0.9-1.8L392.9,389.5L392.9,389.5z M407.5,522.8c0.9-1.8,2.2-4,4-7.5L407.5,522.8L407.5,522.8z
	 M408.8,378.4c0.4,0.9,0.4,1.3,0.9,2.2l7.1,17.7L408.8,378.4L408.8,378.4z M319.3,467c13.3,12,24.8,18.2,40.3,18.2
	c15.5,0,29.2-7.5,29.2-22.1c0-12.4-10.6-19.5-30.1-27.5c-16.4-7.1-28.3-12-35-22.1c0.9,4.4,1.3,8.9,1.3,13.7
	c0,39.4-28.8,71.3-64.2,71.3s-64.2-31.9-64.2-71.3c0-23.9,10.6-44.7,26.6-58h-43.4v102.3v0.4v21.3h-19v-20.4c0-0.4,0-0.9,0-0.9
	V369.1h-16.8v-12.8h17.3c2.2-26.1,18.2-40.7,40.7-40.7c14.6,0,22.6,1.3,27,3.5v17.7c0,1.3,0,2.2,0,2.2h-0.9
	c-7.1-6.6-13.7-11.1-29.7-11.1c-12.8,0-21.3,10.2-18.2,28.3h72.2c3.1-0.4,6.2-0.9,9.3-0.9c25.7,0,47.8,16.8,58,41.2
	c0-1.3,0-2.7,0-4.4c0-25.2,24.8-36.3,50-36.3c0.9,0,1.8,0,2.7,0c8.9,0,21.7,2.7,29.7,4.9l7.1,17.3l8,19.9l21.3,53.1l2.7,8.4l2.7-8.4
	l27.5-67.8c4.9-18.6,25.2-26.6,46.9-26.6c11.1,0,19,1.3,28.8,4.9V382c-12-8.9-20.4-13.7-34.1-13.7c-12,0-26.1,5.3-26.1,23
	c-0.4,13.3,7.5,15.5,31,27c19,8.4,36.8,17.3,36.8,38.5c0,35.4-35,41.6-51.8,41.6c-15.1,0-29.2-3.5-36.8-10.2v-23.5l1.3,0.9
	c13.7,12.8,26.1,19.5,41.6,19.5s29.2-7.5,29.2-22.1c0-12.4-10.6-19.5-30.1-27.5c-18.6-8-30.6-13.3-35.4-26.6
	c-7.5,18.2-42.5,103.6-50.9,121.8h-18.6l4.4-8.4l4-7.5v-0.4c4.9-9.3,11.5-23.9,17.7-38.1l-36.3-87.7c-0.4-0.4-0.9-1.8-2.2-4.4
	c-2.7-6.2-5.3-12.4-13.3-15.1c-4-1.3-8.9-2.2-13.7-2.2c-12,0-27,5.3-27,23c-0.4,13.3,8.4,15.5,31.9,27c19,8.4,36.8,17.3,36.8,38.5
	c0,35.4-35,41.6-51.8,41.6c-13.3,0-26.1-3.1-34.1-8L319.3,467L319.3,467z M61.1,357.6l0.4,15.5v0.9l0,0c0.4-0.4,0.4-1.3,0.9-1.8
	c8-8.4,17.7-16.8,41.2-16.8c22.6,0,36.8,19,37.6,31V471v0.4v21.3h-19v-25.2v-72.2c0-15.1-13.3-25.7-29.2-25.7
	c-13.3,0-30.6,12-31,24.4v77.5v0.4v20.8H42.5v-21.3v-96.1v-17.7h3.5h10.6L61.1,357.6L61.1,357.6z"
    ></path>
  </svg>
);
