import {
  AvatarCustomization,
  AvatarInfo,
  getHostname,
  localData,
  notifyError,
  page_params,
  ResourcesPlacement,
} from '@in3d/common';
import { auth } from '@in3d/store';
import { when } from 'mobx';

import { commonClient } from './client';

import { resources } from './resource_manager';
import { sdk } from './sdk_integration';

class AvatarData {
  id: string;
  glb_url: string;
  preview_url: string;

  description = '';
  is_loaded = false;
  is_active = false;

  mesh_name: string;
}

type DefaultAvatarCustomization = {
  male: Record<string, AvatarCustomization>;
  female: Record<string, AvatarCustomization>;
};

class AvatarList {
  avatarDataList: AvatarData[] = [];

  [Symbol.iterator]() {
    return this.avatarDataList.values();
  }

  get length() {
    return this.avatarDataList.length;
  }

  get(i: number) {
    return this.avatarDataList[i];
  }

  get_all() {
    return this.avatarDataList;
  }

  find_by_id(id: string) {
    return this.avatarDataList.find((item) => item.id == id);
  }

  remove_all() {
    this.avatarDataList = [];
  }

  load() {
    return;
  }

  load_from_list(l: any[]) {
    for (const el of l) {
      const ad = Object.assign(new AvatarData(), el);
      avatarList.avatarDataList.push(ad);
    }
    resources.setAvatarList(avatarList.avatarDataList);

    // events_manager.emit('dataLoaded');
  }
}

////
const default_assets: DefaultAvatarCustomization = {
  male: {
    default: {
      assets: {
        Fine_Knit_Hat_White: { placement: 'head' },
        brown_leather2: { placement: 'look' },
        shoes_2: { placement: 'shoes' },
      },
      avatar: { body: 'model_T_1' },
    },
    'tetonridge.avaturn.dev': {
      // assets: { MP_Hairstyle_Top: {}, viking: {}, shoes_2: {} },
      assets: { cowboy_cloth5: { placement: 'look' }, cody_hat: { placement: 'head' } },
      avatar: { body: 'model_T_1' },
    },
  },
  female: {
    default: {
      assets: {
        pu_ponytail: { placement: 'head' },
        woman_business_suite: { placement: 'look' },
        shoes_2: { placement: 'shoes' },
      },
      avatar: { body: 'model_T_0' },
    },
    'tetonridge.avaturn.dev': {
      assets: {
        cowboy_cloth2: { placement: 'look' },
        cody_hat: { placement: 'head' },
        shoes_2: { placement: 'shoes' },
      },
      avatar: { body: 'model_T_1' },
    },
  },
};

function _deep_copy(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

async function get_default_customization_data(gender: 'male' | 'female') {
  const sdk_customization = (await sdk.getCustomization()) as AvatarCustomization;

  if (sdk_customization) {
    return _deep_copy(sdk_customization);
  }

  const d = default_assets[gender];
  const customization_data = d[getHostname()];
  const res = typeof customization_data !== 'undefined' ? customization_data : d['default'];
  return _deep_copy(res);
}

class CustomizationInfo {
  avatar_url: string;
  gender: 'male' | 'female' = 'male';
  data: AvatarCustomization;
  default_data: AvatarCustomization;
  is_default = false;
  is_animatable = false;

  async loadFromDict(data: AvatarInfo) {
    this.data = data.customization;

    this.avatar_url = data.scan_glb_url;
    this.gender = data.scan.scan_metadata.gender || 'male';

    this.default_data = await get_default_customization_data(this.gender);

    // if url param animatable is set to true, then we will use the animatable version of the avatar
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('animatable') == 'true') {
      this.is_animatable = true;
    } else {
      this.is_animatable = data.scan.scan_metadata.animatable || false;
    }

    if (Object.keys(this.data.assets).length == 0) {
      // console.log('default');
      this.data = await get_default_customization_data(this.gender);
      // console.log(this.data);
      this.is_default = true;
    }
  }

  async load() {
    if (!page_params.customization_id) {
      // Set gender
      const gender = page_params.gender ? page_params.gender : 'male';
      customizationInfo.gender = page_params.gender;

      // Set customization
      let customization;
      if (page_params.avatar_link && (customization = localData.getItem(page_params.avatar_link))) {
        customizationInfo.data = JSON.parse(customization);
      } else {
        customizationInfo.data = await get_default_customization_data(gender);
      }
      customizationInfo.default_data = await get_default_customization_data(gender);

      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('animatable') == 'true') customizationInfo.is_animatable = true;

      return;
    }
    return new Promise((resolve, reject) => {
      when(
        () => auth.isAuth,
        () => {
          commonClient
            .loadCustomizations(page_params.customization_id)
            .then(this.loadFromDict.bind(this))
            .then(() => {
              resolve(true);
            })
            .catch((e) => {
              console.log(e);
              const error_msg = 'Error during loading avatar info, has this avatar been deleted?';
              console.log(error_msg);
              notifyError(error_msg, { autoClose: false });
              reject(new Error(error_msg));
            });
        }
      );
    });
  }
}

export function getDefaultAsset(placement: string) {
  const default_assets = customizationInfo.default_data.assets as Record<string, any>;
  let _id: string;
  Object.entries(default_assets).forEach(([id, asset]) => {
    if (asset.placement == placement) {
      // check that this asset available
      if (resources.clothById[id]) {
        _id = id;
      } else {
        const cloth = resources.clothes[placement as ResourcesPlacement][0];
        if (cloth) {
          _id = cloth.id;
        }
      }
    }
  });
  return _id!;
}

export function fixMissing() {
  for (const key of Object.keys(customizationInfo.data.assets)) {
    if (!resources.clothById[key]) {
      const placement: string = customizationInfo.data.assets[key].placement || 'look';
      customizationInfo.data.assets[getDefaultAsset(placement)] = {};

      // Finally, remove the one that does not exist
      delete customizationInfo.data.assets[key];
    }
  }
}

const avatarList = new AvatarList();
const customizationInfo = new CustomizationInfo();

export { avatarList, customizationInfo, AvatarList };
