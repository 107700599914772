

import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
// import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { AvatarView } from '../../../AvatarView';

import { SceneExperienceBase } from "../tetonridge/scene_experience_base";
import { ACESFilmicToneMapping, AmbientLight, Color, DirectionalLight, EquirectangularReflectionMapping, Fog, Group, PerspectiveCamera, Scene, SpotLight, WebGLRenderer } from "three";
import { addDirectionalLightDebug } from "../debug_gui";
import env from '@in3d/environment';

async function setup_light(renderer: WebGLRenderer, camera: PerspectiveCamera, scene: Scene, scene_group: Group) {

  // AvatarView.avatar_envMapIntensity = 0.5;
  // AvatarView.cloth_envMapIntensity = 1.2;

  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1.0;

  scene.background = new Color('#F8F8F8'); // dark : #383838

  renderer.physicallyCorrectLights = true; // for rendering env

  let texture = await new RGBELoader().loadAsync(`${env.editorResourcesUrl}/textures/brown_photostudio_01_256.hdr`)
  texture.mapping = EquirectangularReflectionMapping;
  scene.environment = texture;

  renderer.physicallyCorrectLights = false;

  scene.fog = null;
  const light = new AmbientLight(0x777777, 0.5); // soft white light
  light.name = 'ambient_light';
  scene_group.add(light);

  let dirLight = new DirectionalLight(new Color("#8b3609"), 3.5);
  dirLight.name = 'dirlight'
  dirLight.position.set(-34.05, 65.65, 1);
  dirLight.castShadow = true;
  dirLight.shadow.bias = -0.0006;

  dirLight.shadow.camera.top = 45.4;
  dirLight.shadow.camera.bottom = 17;
  dirLight.shadow.camera.left = -7.55;
  dirLight.shadow.camera.right = 32;
  // camera.parent!.add(dirLight);
  scene_group.add(dirLight);
  if (IS_DEBUG && AvatarView.gui) {
    addDirectionalLightDebug(dirLight, AvatarView.gui, 20)
  }
}


export class SceneFootball extends SceneExperienceBase {

  override async build() {

    const scene_group = this.scene.getObjectByName('scene_group') as Group;

    await this.build_from_glbs(
      `${env.editorResourcesUrl}/scenes/football/avatar_opt.glb`,
      `${env.editorResourcesUrl}/scenes/football/scene2_opt.glb`,
      `${env.editorResourcesUrl}/scenes/football/stadium.mp3`,
      true
    )

    await setup_light(this.renderer, this.camera, this.scene, scene_group);

    // Compile materials
    this.renderer.compile(this.scene, this.camera);

    this.mixer = AvatarView.mixer;

    this.scene_loaded = true;

    this.play()

    this.is_ready = true
  }
}
