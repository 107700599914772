import { SettingsJson } from '@in3d/core';
import { useEffect } from 'react';

export const AssetSettings = <T extends Record<string, any>>({
  settings,
  onUpdate,
}: {
  settings: T;
  onUpdate: (s: T) => void;
}) => {
  useEffect(() => {
    console.log(settings);
  }, [settings]);
  return (
    <>
      {Object.keys(settings)
        .filter((k) => k !== 'haircap')
        .map((key) => (
          <label className="upload__toggle upload-toggle" key={key}>
            <span className="upload-toggle__title">{key.replace('_', ' ')}</span>
            <input
              className="upload-toggle__field"
              type="checkbox"
              checked={settings[key as keyof SettingsJson]}
              onChange={() => onUpdate({ ...settings, [key]: !settings[key as keyof SettingsJson] })}
            />
          </label>
        ))}
    </>
  );
};
