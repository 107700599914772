/* eslint-disable @typescript-eslint/ban-ts-comment */

import env from '@in3d/environment';
// import { ExportAvatarResult } from '@in3d/sdk';
import { page_params } from './page_params';

export enum UrlType {
  HttpURL = 'httpURL',
  DataURL = 'dataURL',
}

let _sdkExportFn: (data: any) => void = (data) => {
  return;
};
export const setSdkExportFn = (fn: typeof _sdkExportFn) => {
  _sdkExportFn = fn;
};

export const sendMessageWithHttpUrl = (url: string, isAnimatable: boolean) => {
  send_message_with_avatar_v2(url, UrlType.HttpURL);
  if (page_params.session_id) {
    send_message_with_avatar_v3(url, UrlType.HttpURL);
  }
  send_message_with_avatar_sdk(url, UrlType.HttpURL, isAnimatable);
};

// export const sendMessageWithBlobUrl = (url: string) => {
//   send_message_with_avatar_v2(url, UrlType.HttpURL);
// };

export function send_message_with_avatar(blob: Blob, isAnimatable: boolean) {
  return new Promise<void>((resolve, reject) => {
    const a = new FileReader();
    a.onload = (e: any) => {
      // Send both v1 and v2 messages
      send_message_with_avatar_url_v1(e.target.result);
      send_message_with_avatar_v2(e.target.result, UrlType.DataURL);
      if (page_params.session_id) {
        send_message_with_avatar_v3(e.target.result, UrlType.DataURL);
      }
      send_message_with_avatar_sdk(e.target.result, UrlType.DataURL, isAnimatable);
      resolve();
    };
    a.readAsDataURL(blob);
  });
}

export function send_message_with_avatar_url_v1(url: string) {
  const message = JSON.stringify({
    source: 'avaturn',
    eventName: 'v1.avatar.exported',
    date: Date.now(),
    data: {
      blobURI: url,
    },
  });
  window.parent.postMessage(message, '*');

  // For android
  //
  // @ts-ignore
  if (typeof native_app !== 'undefined') {
    // @ts-ignore
    native_app.postMessage(message);
  }
}

export function send_message_with_avatar_v2(url: string, urlType: UrlType) {
  const message = JSON.stringify({
    source: 'avaturn',
    eventName: 'v2.avatar.exported',
    date: Date.now(),
    data: {
      url: url,
      urlType: urlType,
    },
  });
  window.parent.postMessage(message, '*');

  // For android
  //
  // @ts-ignore
  if (typeof native_app !== 'undefined') {
    // @ts-ignore
    native_app.postMessage(message);
  }
}

export function send_message_with_avatar_sdk(url: string, urlType: UrlType, isAnimatable: boolean) {
  const msg = {
    url: url,
    urlType: urlType,
    avatarId: page_params.customization_id,
    sessionId: page_params.session_id,
    avatarSupportsFaceAnimations: isAnimatable,
  };
  _sdkExportFn(msg);
}

export function send_message_with_avatar_v3(url: string, urlType: UrlType) {
  const msg = {
    url: url,
    urlType: urlType,
    avatarId: page_params.customization_id,
    sessionId: page_params.session_id,
  };

  const message = JSON.stringify({
    source: 'avaturn',
    eventName: 'v3.avatar.exported',
    date: Date.now(),
    data: msg,
  });
  window.parent.postMessage(message, '*');

  // For android
  //
  // @ts-ignore
  if (typeof native_app !== 'undefined') {
    // @ts-ignore
    native_app.postMessage(message);
  }
}
