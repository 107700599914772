export const cacheImages = async (list: string[]): Promise<any> => {
  const promises = list.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  });
  return await Promise.all(promises);
};
