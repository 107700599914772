import { MessageType, SdkIncomingMessages, SdkMessageResponse } from '@in3d/sdk';
import { Messaging, SdkMessage } from 'libs/sdk/src/lib/base_sdk';
import { exportAvatar, generateThumbnail } from './export_api';
import { changeBody, changeCloth, getCurrentCustomization } from './editor_api';
import { resources } from './resource_manager';
import { makeObservable, observable } from 'mobx';

// interface Settings{
//   generateThumbnailOnExport
// }

class SdkIntegration extends Messaging<SdkIncomingMessages, SdkMessageResponse> {
  inited = false;
  allowedAdvanced = false;
  settings = {};

  constructor() {
    super('v1.avaturn-sdk-server', 'v1.avaturn-sdk');
    makeObservable(this, {
      inited: observable,
    });
  }

  init(allowed: boolean) {
    this.allowedAdvanced = allowed;
    this.recipient = window.parent;
    this.setupMessaging();
    // Send RESPONSE because there might be client SDK that is already waiting for the response
    this.sendResponse(MessageType.SDKHandshake, 'sdk_handshake', {
      isOk: true,
      data: JSON.stringify({ advancedFeatures: this.allowedAdvanced }),
    });
  }

  /**
   * Responses to messages from outside
   */
  override handleMessage(message: SdkMessage<any, keyof SdkMessageResponse>) {
    if (IS_DEBUG) {
      console.log('[DEBUG] SDK-server received MESSAGE: ', message);
    }
    switch (message.eventName) {
      case MessageType.SDKHandshake: {
        this.inited = true;
        this.sendResponse(MessageType.SDKHandshake, message.key, { isOk: this.allowedAdvanced, data: null });
        return;
      }
      case MessageType.SDKHandshakeConfirmation: {
        if (IS_DEBUG) {
          console.log('[DEBUG] INITED TRUE');
        }
        this.inited = true;
        return;
      }
    }

    if (!this.allowedAdvanced) {
      this.sendResponse(message.eventName, message.key, {
        isOk: false,
        data: JSON.stringify('This feature cannot be accessed on free plan.'),
      });
      return;
    }

    switch (message.eventName) {
      case MessageType.Ping: {
        this.sendResponse(MessageType.Ping, message.key);
        break;
      }
      case MessageType.GetAssetsList: {
        this.sendResponse(MessageType.GetAssetsList, message.key, {
          isOk: true,
          data: JSON.stringify(resources.getAssetList()),
        });
        break;
      }
      case MessageType.GetActiveAssets: {
        this.sendResponse(MessageType.GetAssetsList, message.key, {
          isOk: true,
          data: JSON.stringify(resources.active),
        });
        break;
      }
      case MessageType.SetActiveAsset: {
        changeCloth(message.data)
          .then(() =>
            this.sendResponse(MessageType.SetActiveAsset, message.key, {
              isOk: true,
              data: JSON.stringify(message.data),
            })
          )
          .catch(() => this.sendResponse(MessageType.SetActiveAsset, message.key, { isOk: false, data: '' }));

        break;
      }
      case MessageType.ExportAvatar: {
        exportAvatar()
          .then((res) => {
            this.sendResponse(MessageType.ExportAvatar, message.key, { isOk: true, data: JSON.stringify(res) });
          })
          .catch((err) =>
            this.sendResponse(MessageType.ExportAvatar, message.key, { isOk: false, data: JSON.stringify(err) })
          );
        break;
      }
      case MessageType.GenerateThumbnail: {
        generateThumbnail()
          .then((res) => {
            this.sendResponse(MessageType.ExportAvatar, message.key, { isOk: true, data: JSON.stringify(res) });
          })
          .catch((err) =>
            this.sendResponse(MessageType.ExportAvatar, message.key, { isOk: false, data: JSON.stringify(err) })
          );
        break;
      }
      case MessageType.GetAvatarInfo: {
        const info = getCurrentCustomization();
        this.sendResponse(MessageType.GetAvatarInfo, message.key, { isOk: true, data: JSON.stringify(info.avatar) });
        break;
      }
      case MessageType.GetBodiesList: {
        this.sendResponse(MessageType.GetBodiesList, message.key, {
          isOk: true,
          data: JSON.stringify(resources.getBodyList()),
        });
        break;
      }
      case MessageType.SetActiveBody: {
        changeBody(message.data).then(() =>
          this.sendResponse(MessageType.SetActiveBody, message.key, { isOk: true, data: JSON.stringify(message.data) })
        );
        break;
      }
      case MessageType.SetAssetsList: {
        resources.setClothesList(message.data);
        this.sendResponse(MessageType.SetAssetsList, message.key, { isOk: true, data: null });
        break;
      }
      // // Handled Above, breaks compilation if uncommented

      // case MessageType.SDKHandshake: {
      //   this.inited = true;
      //   this.sendResponse(MessageType.SDKHandshake, message.key, { isOk: this.allowedAdvanced, data: null });
      //   break;
      // }
      // case MessageType.SDKHandshakeConfirmation: {
      //   this.inited = true;
      //   break;
      // }
    }
  }

  getCustomization(): any {
    if (!this.inited || !this.allowedAdvanced) return;

    return this.sendMessage(MessageType.AskForDefaults, '').then((data) => {
      // alert(1)
      if (Object.keys(data).length == 0) {
        return undefined;
      }
      return data;
      // const allAssets = resources.getAssetList();

      // const assets: Record<string, any> = data.active_assets.map((s: string) => {
      //   return allAssets.find((x) => {
      //     return x.id == s;
      //   });
      // });
      // console.log('all', allAssets)
      // return {
      //   assets,
      //   avatar: { body: data.body },
      // };
    });
  }
  dispatch(event: string, data: any = {}) {
    if (!this.inited) return;

    if (event != 'export' && !this.allowedAdvanced) {
      return;
    }

    this.sendMessage(MessageType.CallbackEvent, JSON.stringify(data), event, false);
  }

  sendAvatar(data: any) {
    if (!this.inited) return;

    if (this.allowedAdvanced) {
      this.sendResponse(MessageType.ExportAvatar, 'export_avatar', { isOk: true, data: JSON.stringify(data) });
    }
    this.dispatch('export', data);
  }
}
export const sdk = new SdkIntegration();
