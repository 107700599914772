import { AppEvent, avatarClient } from '@in3d/api';
import { useEffect } from 'react';

export interface UseEventConfig {
  userId: string;
  onMessage: (message: AppEvent) => void;
}
export const useEvent = ({ userId, onMessage }: UseEventConfig) => {
  useEffect(() => {
    const unsubscribe = avatarClient.subscribeAvatars(userId, (updates) => {
      updates.forEach((update) => {
        onMessage(update);
      });
    });
    return () => {
      unsubscribe();
    };
  }, [onMessage, userId]);
};
