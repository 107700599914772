export interface AssetData {
  alias: string;
  id: string;
  isDraft?: boolean;
  type: ResourcePlacementStr;
  user_id: string;
  gender: 'male' | 'female';
  glb_url: string;
  material_preset: string;
  placement: ResourcesPlacement | symbol[];
  preview_url: string;
  rank?: number;
  path?: string;
  maps?: Record<string, any>;
  maps_preview?: Record<string, string>;
  filenames?: string[];
  settings: {
    cloth_id: string;
    embeded_retarget_info: boolean;
    is_single_mesh: boolean;
    mesh_name: string;
    retargeting_offsets_attribute: string;
  };
  created_at: string;
  updated_at: string;
}
export interface RankedAsset {
  rank: number;
  asset: AssetData;
}

export enum ResourcesPlacement {
  Eyes = 'eyes',
  Head = 'head',
  Look = 'look',
  Shoes = 'shoes',
  FaceMask = 'faceMask',
  Animations = 'animations',
}

export type ResourcePlacementStr = 'eyes' | 'head' | 'look' | 'shoes' | 'faceMask' | 'animations';
