import { cn } from '@in3d/common';
import { CategoryName } from '@in3d/core';
import { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

const textures = ['#F12C2C', '#60D9FF'];
const colors = {
  [CategoryName.Hair]: ['#6d4539', '#242322', '#a07c5a', '#a64725', '#e557d2',   '#8c8282', '#57b2e6'], // '#463329', '#5570FC', '#FCB955', '#45BB43'],
  [CategoryName.Glasses]: ['transparent', '#000'],
};

type ColorPanelProps = {
  handleChangeColor: (category: CategoryName, color: string) => void;
  activeCategory: CategoryName;
  name?: string;
};

export const ColorPanel = ({ activeCategory, handleChangeColor, name }: ColorPanelProps) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState('');

  const [colorBtnElement, setColorBtnElement] = useState<HTMLButtonElement | null>(null);
  const [colorPickerElement, setColorPickerElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (e.target && colorPickerElement) {
        if (!colorPickerElement.contains(e.target as Node)) {
          setPickerOpen(false);
        }
      }
    };

    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [colorPickerElement, pickerOpen]);

  const { styles, attributes } = usePopper(colorBtnElement, colorPickerElement, {
    modifiers: [
      { name: 'arrow' },
      { name: 'offset', options: { offset: [0, 10] } },
      {
        name: 'preventOverflow',
        options: { mainAxis: true, altAxis: true, padding: 30 },
      },
      { name: 'computeStyles' },
    ],
  });

  const handleSelectColor = (color: string) => {
    handleChangeColor(activeCategory, color);
    setSelectedColor(color);
  };

  const handleOpenColorPicker = (e: any) => {
    e.stopPropagation();
    setPickerOpen(true);
  };

  const showTextures = false; // for clothes

  if (activeCategory !== CategoryName.Hair && activeCategory !== CategoryName.Glasses) return null;

  const props = { color: selectedColor, onClick: (e: Event) => e.stopPropagation(), onChange: handleSelectColor } as any;
  return (
    <div className="color-panel">
      {name && <p className="color-panel__name">{name}</p>}
      <div className="color-panel__presets">
        <button ref={setColorBtnElement} onClick={handleOpenColorPicker} className="color-panel__picker">
          <img src="/assets/editor/plus.svg" alt="color_pick" />
          {pickerOpen &&
            createPortal(
              <div
                ref={setColorPickerElement}
                style={styles['popper']}
                {...attributes['popper']}
                className="color-input__picker"
              >
                {/* {activeCategory === CategoryName.Glasses ? (
                <HexAlphaColorPicker {...props} />
              ) : ( */}

                <HexColorPicker {...props} />
                {/* )} */}
              </div>,
              document.querySelector('#portal') as Element
            )}
        </button>
        {showTextures && (
          <>
            <hr className="color-panel__divider" />
            <div className="color-panel__textures">
              {textures.map((texture) => (
                <div
                  key={texture}
                  onClick={() => {
                    // handleChangeTexture(activeCategory, texture);
                  }}
                  className="color-panel__texture"
                  style={{ backgroundColor: texture }}
                />
              ))}
            </div>
          </>
        )}
        <hr className="color-panel__divider" />
        <div className="color-panel__colors">
          {colors[activeCategory].map((color) => (
            <div
              key={color}
              onClick={() => {
                handleChangeColor(activeCategory, color);
              }}
              className={cn(['color-panel__color', color === 'transparent' && 'color-panel__color_transparent'])}
              style={{ backgroundColor: color === 'transparent' ? 'white' : color }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
