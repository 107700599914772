import { AvatarInfo, ConfigType, getHostname } from '@in3d/common';
import env from '@in3d/environment';
import { Timestamp, addDoc, collection, onSnapshot, query, where } from 'firebase/firestore';

import { AUTH_TOKEN, ClientBuilder, createSettings } from './api';
import { db } from '..';

const AVATARS_BASE_URL = env.baseUrl;

const get_headers = (token?: string) => {
  const params = new URLSearchParams(window.location.search);
  const override_origin = params.get('override_origin') || getHostname();
  return {
    Authorization: `Bearer ${token || AUTH_TOKEN}`,
    'X-Override-Origin': override_origin,
  };
};

export enum AvatarScanStatus {
  Pending = 'pending',
}

export interface ScanData {
  created_at: string;
  id: string;
  status: ScanStatus;
  preview_url?: string;
  queued_at?: string;
  scan_metadata: { gender?: 'male' | 'female'; animatable?: boolean };
  config: ConfigType;
}

export interface NewScanInfo {
  scan: ScanData;
  scanning_url: string;
  upload_url: string;
}

export enum ScanStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  READY = 'ready',
  FAILED = 'failed',
}

export interface ExportCompletedData {
  status: ExportStatus;
  export_id: string;
}

export interface AvatarData {
  id: string;
  scan_id: string;
  preview_url: string;
  gender: 'male' | 'female';
  scan_config: 'old' | 'animatable' | 'animatable_single_view';
}

export interface ScansAndAvatars {
  scans: ScanData[];
  avatars: { items: AvatarData[]; total: number };
}

export type AvatarsUpdate = {
  [key: string]: ScanData;
};

export type IconUpdate = {
  id: string;
  data: { preview: { url: string; valid_until: number } };
};

export enum ApiEventType {
  ScanStatusChanged = 'scan_status_changed',
  AvatarPreviewUpdate = 'avatar_preview_updated',
  AvatarExportCompleted = 'avatar_export_completed',
}

export interface ScanEventData {
  scan_id: string;
  queued_at: string;
  status: ScanStatus;
}
export interface PreviewUpdateData {
  avatar_id: string;
  url: string;
}

export interface UpdateEvent {
  type: ApiEventType.ScanStatusChanged;
  data: ScanData;
}
export interface ExportEvent {
  type: ApiEventType.AvatarExportCompleted;
  data: ExportCompletedData;
}
export interface PreviewEvent {
  type: ApiEventType.AvatarPreviewUpdate;
  data: PreviewUpdateData;
}

export interface NewExportInfo {
  id: string;
  status: ExportStatus;
  url: string;
}
export enum ExportStatus {
  Processing = 'processing',
  Ready = 'ready',
  Failed = 'failed',
}

export interface TshirtGeneratorData {
  prompt: string;
  user_id: string;
  created_at: string;
}
export type TshirtGeneratorResponse =
  | (TshirtGeneratorData & {
      status: 'QUEUED';
    })
  | (TshirtGeneratorData & {
      status: 'DONE';
      image_url: string;
      processed_at: string;
      cloth_id: string;
    });

/*

{
  "prompt": "tshirt with big green \"A\" symbol in center",
  "status": "DONE",
  "created_at": "03/16/2023, 01:15:48",
  "user_id": "5zLw1aJhiVMCkEKxIuWOFd7TlWZ2",
  "image_url": "",
  "processed_at": "03/16/2023, 01:16:04"
}

*/

export type AppEvent = UpdateEvent | ExportEvent | PreviewEvent;

type GenerateTextureParams = { user_id: string; prompt: string; cloth_id?: string; image?: string; mask?: string };

class AvatarClient {
  constructor(api: ClientBuilder) {
    this.api = api;
  }

  token: string | null = null;
  api: ClientBuilder;

  async generateTexture(
    { user_id, prompt, cloth_id, image, mask }: GenerateTextureParams,
    cb: (data: TshirtGeneratorResponse) => void
  ) {
    const additional_options = {
      inpaint_full_res: 0,
      denoising_strength: 0.88,
      inpainting_fill: 2, //['fill', 'original', 'latent noise', 'latent nothing']
      resize_mode: 1, // crop and resize
      height: 384,
      width: 384,
      inpaint_full_res_padding: 16,
      mask_blur: 2,
      cfg_scale: 7,
      image_cfg_scale: 1.5,
      steps: 35,
      negative_prompt:
        '(nsfw), low quality, worst quality, naked, nude, dick, boobs, breast visible, sex, cock, vagina',
    };
    // const mask = 'https://assets.avaturn.me/mask_ff076d511e.png?updated_at=2023-05-25T09:50:06.633Z';
    // const image = 'https://assets.avaturn.me/Image_8_002_7931959bd8.jpg?updated_at=2023-05-25T09:42:07.526Z';
    const record = await addDoc(collection(db, 'tshirt-tasks'), {
      prompt,
      user_id,
      // cloth_id,
      additional_options,
      image,
      mask,
    });
    return onSnapshot(record, (response) => {
      const data = response.data() as TshirtGeneratorResponse;
      if (data && data['status']) cb(data);
    });
  }

  async createUrlExport(token: string | null, avatar_id?: string): Promise<NewExportInfo> {
    if (!avatar_id) return Promise.reject(new Error('Avatar id not specified'));
    if (!token) return Promise.reject(new Error('Authentication token required'));

    return await this.api.put('/avatars/exports/create?avatar_id=' + avatar_id, null, get_headers(token));
  }

  subscribeAvatars(user_id: string, cb: (data: AppEvent[]) => void) {
    let lastUpdateTime = Date.now();
    return onSnapshot(
      query(collection(db, 'user', user_id, 'events'), where('timestamp', '>=', new Date())),
      (snapshots) => {
        const list: Array<AppEvent> = [];

        snapshots.forEach((response) => {
          const update = response.data();
          const eventType = update['event']['type'] as ApiEventType;
          const eventTime = update['timestamp'].toDate().getTime();
          const eventData = generateEvent(eventType, update);

          if (eventData && eventTime > lastUpdateTime) {
            list.push(eventData);
          }
        });

        lastUpdateTime = Date.now();
        cb(list);
      }
    );
  }

  subscribeIcons(user_id: string, cb: (data: IconUpdate[]) => void) {
    return onSnapshot(
      query(collection(db, 'UserAvatars', user_id, 'Avatars'), where('preview.valid_until', '>', new Date())),
      (snapshots) => {
        const list: IconUpdate[] = [];
        snapshots.forEach((response) => {
          const data = response.data() as {
            preview: { url: string; valid_until: Timestamp };
          };
          const time = data.preview.valid_until ? data.preview.valid_until.toDate().getTime() : 0;
          list.push({
            id: response.id,
            data: {
              preview: {
                url: data.preview.url,
                valid_until: time,
              },
            },
          });
        });
        cb(list);
      }
    );
  }

  async createAvatar(token?: string, config?: ConfigType) {
    const params = new URLSearchParams(config ? { config } : {});
    return await this.api.post<NewScanInfo>(`/scans/new?${params.toString()}`, null, get_headers(token));
  }
  async restartAvatar(token: string, scan_id: string) {
    return await this.api.post<NewScanInfo>(`/scans/${scan_id}/restart`, null, get_headers(token));
  }

  async deleteAvatar(token: string, id: string) {
    return await this.api.delete(`/avatars/${id}`, null, get_headers(token));
  }

  async getAvatarInfo(token: string, id: string): Promise<AvatarInfo> {
    return await this.api.get(`/avatars/${id}`, null, get_headers(token));
  }

  async upgradeAvatar(token: string, id: string, overrideOrigin?: string) {
    const headers = get_headers(token);

    if (overrideOrigin) {
      headers['X-Override-Origin'] = overrideOrigin;
    }

    return await this.api.put(`/avatars/${id}/upgrade`, null, headers);
  }

  async getAvatarUpgrade(token: string, id: string) {
    const headers = get_headers(token);
    return await this.api.get(`/avatars/${id}/upgrade`, null, headers);
  }

  async getAvatarVto(id: string) {
    return await this.api.get(`/avatars/${id}/vto`);
  }

  async listScans(
    token: string,
    { limit, offset }: { limit: number; offset: number }
  ): Promise<{ items: ScanData[]; total: number }> {
    return await this.api.get<{ items: ScanData[]; total: number }>(
      `/scans?limit=${limit}&offset=${offset}`,
      null,
      get_headers(token)
    );
  }

  async deleteScan(token: string, id: string) {
    return await this.api.delete(`/scans/${id}`, null, get_headers(token));
  }

  async getScanInfo(token: string, id: string): Promise<ScanData> {
    return await this.api.get(`/scans/${id}`, null, get_headers(token));
  }

  async listAll(token: string, { limit = 3, offset = 0 }: { limit: number; offset: number }): Promise<ScansAndAvatars> {
    return await this.api.get(
      `/avatars?${limit ? 'limit=' + limit : ''}${offset ? '&offset=' + offset : ''}`,
      null,
      get_headers(token)
    );
  }

  async getAvatarModelUrl(token: string, id: string): Promise<{ url: string; id: string }> {
    return await this.api.get(`/scans/${id}/model`, null, get_headers(token));
  }

  async newCustomization(token: string, scan_id: string): Promise<{ id: string; scan_id: string }> {
    return await this.api.post(
      `/avatars/new?scan_id=${scan_id}&override_origin=${getHostname()}`,
      null,
      get_headers(token)
    );
  }
}

function generateEvent(type: ApiEventType, data: any) {
  const generators = {
    [ApiEventType.ScanStatusChanged]: generateUpdateScanEvent,
    [ApiEventType.AvatarPreviewUpdate]: generateUpatePreviewEvent,
    [ApiEventType.AvatarExportCompleted]: generateExportCompletedEvent,
  };
  if (generators[type]) return generators[type](data);
  return null;
}

function generateUpatePreviewEvent(update: any): PreviewEvent {
  return {
    type: ApiEventType.AvatarPreviewUpdate,
    data: {
      avatar_id: update['event']['avatar_id'],
      url: update['event']['url'],
    },
  };
}

function generateUpdateScanEvent(update: any): UpdateEvent {
  return {
    type: ApiEventType.ScanStatusChanged,
    data: {
      status: update['event']['status'],
      id: update['event']['scan_id'],
      queued_at: update['event']['queued_at'].toDate(),
      created_at: update['event']['queued_at'].toDate(),
      scan_metadata: {},
      config: update['config'],
    },
  };
}
function generateExportCompletedEvent(update: any): ExportEvent {
  return {
    type: ApiEventType.AvatarExportCompleted,
    data: {
      export_id: update['event']['export_id'],
      status: update['event']['status'],
    },
  };
}

export const avatarClient = new AvatarClient(new ClientBuilder(createSettings(AVATARS_BASE_URL)));
