import env from '@in3d/environment';
import environment from '@in3d/environment';
import { AnimationClip, Color, DataTexture, FloatType, Group, RGBAFormat } from 'three';

import { AvatarView } from '../AvatarView';
import { getDefaultAsset } from '../content';
import { CategoryName, resources } from '../resource_manager';
import { attach_skeleton } from './animation_utils';
import { Animation } from './resources/animation';
import { FaceAnimation } from './resources/animation_face';
import { load_animation_clip, processMorphTargetTrack } from './resources/animation_loader';
import { Asset, Cloth, Glasses, Hair } from './resources/assets';
import { Avatar, avatars } from './resources/avatar';
import { AvatarAnimatable } from './resources/avatar_animatable';
import { DownloadState, Slots } from './resources/common';
import { changeHaircap, haircapId } from './resources/haircap';
import { resetFaceTracking } from './resources/tracking';
import { TrackingRecorder } from './resources/tracking_recorder';
import { getDefaultAvatarUrl } from '../avatar_list';
import { AvatarCustomization, HaircapIdType, ResourcesPlacement } from '@in3d/common';
import { toJS } from 'mobx';

const mask_width = 512;
const mask_height = 512;

export class CurrentState {
  avatar: Avatar | AvatarAnimatable;
  cloth: Record<string, Asset | null> = {};
  previous_assets: Record<string, string> = {};
  alpha_map_body: DataTexture; // DataTexture, r: nothing, g: cc body / fs alpha map, b: nothing, a: nothing
  alpha_map_head: DataTexture; // DataTexture, g: avatar head alpha map

  animation: Animation | null = null;
  faceAnimation: FaceAnimation | null = null;
  cur_cloth_loading: Record<string, Asset[]> = {}; // store here what we are loading now to prevent loading 2 clothes while the 1st not loaded yet
  tracking_recorder: TrackingRecorder | null = null;

  get hair() {
    return this.cloth[Slots.Head];
  }

  get all_assets(): Array<Glasses | Cloth | Hair> {
    if (!resources) return [];
    return Object.values(resources.clothes).flat();
  }

  get current_assets() {
    const array: Asset[] = [];
    const ids: string[] = [];
    for (const key in Slots) {
      const t = this.cloth[key];
      if (t && ids.indexOf(t.id) == -1) {
        array.push(t);
        ids.push(t.id);
      }
    }

    return array;
  }

  constructor() {
    // Init with nulls
    for (const key in Slots) {
      this.cloth[key] = null;
    }

    const array = new Float32Array(mask_width * mask_height * 4);
    const array2 = new Float32Array(mask_width * mask_height * 4);

    // Init alpha as 1 initially
    // for (let i = 0; i < mask_height; i++) {
    //   for (let j = 0; j < mask_width; j++) {
    //     const position = (i + mask_width * j) * 4;
    //     array[position + 0] = 1; // avatar alpha
    //     array[position + 1] = 1; // avatar alpha
    //   }
    // }

    this.alpha_map_body = new DataTexture(array, mask_width, mask_height, RGBAFormat, FloatType);
    this.alpha_map_body.needsUpdate = true;

    this.alpha_map_head = new DataTexture(array2, mask_width, mask_height, RGBAFormat, FloatType);
    this.alpha_map_head.needsUpdate = true;
  }

  startTrackingRecording() {
    const num_blendshapes = Object.keys((this.avatar as AvatarAnimatable).head_mesh.morphTargetDictionary!).length;
    this.tracking_recorder = new TrackingRecorder(num_blendshapes);
  }

  finishTrackingRecording() {
    if (!this.tracking_recorder) return;

    this.tracking_recorder.enabled = false;
    const tracks = this.tracking_recorder.stopRecording('Head_Mesh');
    if (!tracks) return;

    this.tracking_recorder.cleanup();
    this.tracking_recorder = null;

    // prepare tracks for other meshes
    const avatar = this.avatar as AvatarAnimatable;
    const g = new Group();
    avatar.meshes.forEach((x) => g.children.push(x));
    const allKeyframeTracks = processMorphTargetTrack(tracks.blenshapesKeys, avatar.head_mesh as any, g);

    // Head bone
    allKeyframeTracks.push(tracks.headKeys);

    // Now you can do something with allKeyframeTracks, e.g., create an animation clip.
    const clip = new AnimationClip('BlendshapeAnimation', -1, allKeyframeTracks);

    const action = load_animation_clip(clip);
    console.log('action', action);

    // reset
    resetFaceTracking();

    // Add animation to the list
    const anim = new FaceAnimation();
    anim.setClip(clip);
    anim.id = Date.now().toString(36) + Math.random().toString(36).substring(2);
    anim.glb_url = '';
    anim.setDownloadState(DownloadState.Loaded);

    resources.addFaceAnimation(anim);
    this.changeFaceAnimation(anim);
  }

  changeFaceAnimationWhenLoaded(new_animation: FaceAnimation, force = false) {
    if (new_animation == this.faceAnimation) {
      if (env.production && !force) {
        return;
      }
      this.faceAnimation?.stop();

      for (const [k, v] of Object.entries(avatars)) {
        if (v.is_downloaded) {
          v.toRestPose();
        }
      }
      currentState.avatar.setScaleAll();
      this.faceAnimation = null;
      return;
    }

    this.faceAnimation?.stop();

    new_animation.play();

    this.faceAnimation = new_animation;
  }

  changeAnimationWhenLoaded(new_animation: Animation, force = false) {
    if (new_animation == this.animation) {
      if (env.production && !force) {
        return;
      }
      this.animation?.stop();

      for (const [k, v] of Object.entries(avatars)) {
        if (v.is_downloaded) {
          v.toRestPose();
        }
      }
      currentState.avatar.setScaleAll();
      // this.avatar.mesh.skeleton.pose();
      // this.avatar.mesh.needsUpdate = true;
      this.animation = null;
      return;
    }

    this.animation?.stop();

    // if (this.animation != unde)
    new_animation?.correctHips(this.avatar.root_bone_elevation);
    new_animation.play();

    this.animation = new_animation;

    // this.bind_cloth_skeleton(this.avatar);
  }

  changeAvatarWhenLoaded(new_avatar: Avatar) {
    this.avatar?.set_visibility(false);
    new_avatar.set_visibility(true);

    // TODO: don't retarget one asset several times if it is assigned to multiple slots
    for (const slot in Slots) {
      this.cloth[slot]?.retarget(new_avatar);
    }

    this.bind_cloth_skeleton(new_avatar);

    this.animation?.correctHips(new_avatar.root_bone_elevation);
    if (this.animation?.is_pose) {
      this.animation?.stop();
      this.animation?.play();
    }

    this.avatar = new_avatar;
  }

  bind_cloth_skeleton(avatar: Avatar) {
    for (const slot in Slots) {
      if (this.cloth[slot]) attach_skeleton(this.cloth[slot]!, avatar);
    }
  }

  remove_cloth_from_its_slots(cloth: Asset) {
    const slots = cloth.placement;
    for (const slot of slots) {
      this.cloth[slot] = null;
    }
  }

  clear_slots(slots: Slots[]) {
    for (const slot of slots) {
      // If there is currenty an item in the slot -- remove it from all it's slots
      const t = this.cloth[slot];
      if (t) {
        this.remove_cloth_from_its_slots(t);
        t.set_visibility_to_set(false);
      }
    }
  }

  // remove_cloth(new_cloth) {

  //     remove_from_slots(new_cloth);
  // }
  update_slots(new_cloth: Asset) {
    for (const slot of new_cloth.placement) {
      this.cloth[slot] = new_cloth;
    }
  }

  // update_offsets(new_cloth: Asset) {
  //   let p = new_cloth.placement[0];
  //   if (!(p == Slots.Bottom || p == Slots.Top)) {
  //     return;
  //   }
  //   /* Assume that the new cloth is already in its slots */

  //   let has_top = this.cloth[Slots.Top] !== null;
  //   let has_bottom = this.cloth[Slots.Bottom] !== null;

  //   if (new_cloth.placement.length == 1 && (p == Slots.Bottom || p == Slots.Top)) {
  //     if (has_top && has_bottom) {
  //       this.update_offset_maps();
  //     }
  //   }

  //   if (new_cloth.placement.length == 2) {
  //     this.reset_offset_maps();
  //   }

  //   if (!has_top || !has_bottom) {
  //     this.reset_offset_maps();
  //   }
  // }
  changeAssetWhenLoaded(new_cloth: Asset, force = false) {
    // Check if the cloth is fully downloaded
    // We can get here when e.g. offsets are loaded but mesh is not
    if (!new_cloth.is_downloaded) {
      return;
    }

    // Check if it's the last cloth we queried. If not --
    let t: any = this.cur_cloth_loading[new_cloth.placement[0]];
    if (t[t.length - 1] != new_cloth) {
      t = t.filter((value: any, index: any, arr: any) => {
        return value != new_cloth;
      });
      return;
    }

    // Check if it's on already, in this case we want to take it off
    if (this.cloth[new_cloth.placement[0]] == new_cloth) {
      if (!force && env.production && [Slots.Top, Slots.Bottom, Slots.Head].includes(new_cloth.placement[0])) {
        return;
      }

      // new_cloth.set_visibility(false);
      this.clear_slots(new_cloth.placement);
      // this.update_offsets(new_cloth);

      return;
    }

    // Retarget the cloth
    new_cloth.retarget(this.avatar);

    // Clear the needed slots and make current items invisible
    this.clear_slots(new_cloth.placement);

    // Update current slots with the new cloth
    this.update_slots(new_cloth);

    // currentState.cloth is now actualized, compute offsets if needed
    // this.update_offsets(new_cloth);

    // Attach skeleton
    this.bind_cloth_skeleton(this.avatar);

    new_cloth.set_visibility_to_set(true);

    const decalMaker = AvatarView.decalMaker;
    if (decalMaker) {
      // if new_cloth.placement includes Slots.Top or Slots.Bottom
      if (new_cloth.placement.includes(Slots.Top) || new_cloth.placement.includes(Slots.Bottom)) {
        decalMaker.checkCustomization();
      }
    }
  }

  private reset_offset_maps() {
    const current_offset_image = this.alpha_map_body.image;

    /* this function updates is only called for the TOPs */
    for (let i = 0; i < mask_height; i++) {
      for (let j = 0; j < mask_width; j++) {
        const position = i + mask_width * j;
        current_offset_image.data[position * 4] = 0; // r channel
        current_offset_image.data[position * 4 + 2] = 0; // b channel
      }
    }
    this.alpha_map_body.needsUpdate = true;
  }

  private update_offset_maps() {
    // TODO: reset offset maps for the case when we take off cloth
    const current_offset_image = this.alpha_map_body.image;
    const offset_map_top = this.cloth[Slots.Top]!.offset_map;
    const offset_map_bottom = this.cloth[Slots.Bottom]!.offset_map;

    /* this function updates is only called for the TOPs */
    for (let i = 0; i < mask_height; i++) {
      for (let j = 0; j < mask_width; j++) {
        const position = i + mask_width * j;

        const top_disp = offset_map_top[position];
        const bottom_disp = offset_map_bottom[position];

        // let m = (top_disp > 0) * (bottom_disp > 0);

        // let disp = (Math.abs(bottom_disp - top_disp * 0.4) + top_disp * 0.6) * m * (Math.abs(bottom_disp - top_disp * 0.5) != 0);
        // disp = disp * 0.5;
        // let disp = Math.abs(bottom_disp - top_disp) * 0.5 * m;
        // let disp = Math.abs(bottom_disp);

        // let disp = 0.2;

        current_offset_image.data[position * 4] = top_disp; // r channel
        current_offset_image.data[position * 4 + 2] = bottom_disp; // b channel
      }
    }

    this.alpha_map_body.needsUpdate = true;

    // this.cloth[Slots.Top].update_material();
    // this.cloth[Slots.Bottom].update_material();
  }

  private update_avatar_mask() {
    const alpha_body = this.alpha_map_body.image.data;
    const alpha_head = this.alpha_map_head.image.data;

    // Set alpha back to one first
    for (let i = 0; i < mask_height; i++) {
      for (let j = 0; j < mask_width; j++) {
        const position = i + mask_width * j;
        alpha_head[position * 4 + 1] = 255;
        alpha_body[position * 4 + 1] = 255;
        // array[position * 4 + 3] = 255;
      }
    }

    // Add mask from each cloth
    for (const key in Slots) {
      const v = this.cloth[key];
      if (!v || !v.avatar_mask) {
        continue;
      }

      for (let i = 0; i < mask_height; i++) {
        for (let j = 0; j < mask_width; j++) {
          const position = i + mask_width * j;

          // this is basically OR operation
          const mask_val_uv1 = 255.0 - v.avatar_mask![position];
          alpha_body[position * 4 + 1] = Math.max(alpha_body[position * 4 + 1] - mask_val_uv1, 0);

          // head mask in animatable avatars
          const mask_val_uv2 = 255.0 - v.avatar_mask_uv2![position];
          alpha_head[position * 4 + 1] = Math.max(alpha_head[position * 4 + 1] - mask_val_uv2, 0);
        }
      }
    }

    this.alpha_map_body.needsUpdate = true;
    this.alpha_map_head.needsUpdate = true;
  }

  get_current_customization(): AvatarCustomization {
    const customization: AvatarCustomization = {
      assets: {},
      avatar: {
        body: '',
      },
    };
    for (const k in Slots) {
      if (!this.cloth[k]) continue;

      const cloth = this.cloth[k]!;
      customization.assets[cloth.id] = cloth.get_customization();
      customization.assets[cloth.id]['placement'] = cloth.placement_str;
      customization.assets[cloth.id]['local'] = environment.localResources;
    }
    let eye_texture: string | undefined = undefined;
    const animatable = resources.isAnimatable;
    if (animatable) {
      eye_texture = (this.avatar as AvatarAnimatable).eye_texture;
    }
    customization.avatar = {
      body: this.avatar.mesh_name,
      curve_coeff: Avatar.curve_params.curve_coeff.value,
      bone_scale: Object.assign({}, Avatar.boneScale), // copy values
      eye_texture,
      haircap_id: haircapId,
    };

    if (AvatarView.decalMaker) {
      const decals = AvatarView.decalMaker.getDecals();
      if (decals.length > 0) customization.avatar.decals = decals;
    }

    return customization;
  }

  changeGlassesColor(color: string, is_transparent: boolean) {
    Glasses.change_color(color, is_transparent);
  }
  changeHairColor(color: string, with_haircap = true) {
    Hair.set_color(color);
    if (with_haircap) {
      Avatar.set_haircap_color(color);
    }
  }

  changeEyeColor(color: string) {
    AvatarAnimatable.set_eye_color(color);
  }

  async changeEyeTexture(name?: string) {
    await (currentState.avatar as AvatarAnimatable).set_eye_texture(name);
  }

  changeSkinBrightness(value: number) {
    Avatar.curve_params.curve_coeff.value = value / 100 + 0.5;
  }

  changeScale(type: keyof typeof Avatar.boneScale, value: number) {
    Avatar.boneScale[type] = value / 100 + 0.5;
    if (type == 'head') {
      this.avatar.setHeadScale();
    } else if (type == 'chest') {
      this.avatar.setChestScale();
      // } else if (type == 'legs') {
      // Avatar.legs_scale = value / 100 + 0.5;
      // this.avatar.setLegsScale();
    } else if (type == 'arms') {
      this.avatar.setArmsScale();
    }
  }

  async changeHaircap(id: HaircapIdType) {
    await changeHaircap(id);
  }

  set_customization(id: string, asset_customizations: any) {
    const asset = resources.clothById[id] as Asset;
    asset.set_customization(asset_customizations);

    if (asset.placement_str == ResourcesPlacement.Head && asset_customizations.color) {
      Avatar.set_haircap_color(
        '#' + new Color().fromArray(asset_customizations.color.r_color2).convertLinearToSRGB().getHexString()
      );
    }
  }

  /*
    Entry point for changing avatar.
    */
  async changeAvatar(new_avatar: Avatar) {
    if (new_avatar.glb_url === null) {
      // TODO super hacky workaround to fix waiting for unready avatar
      new_avatar.glb_url = getDefaultAvatarUrl();
    }
    if (!new_avatar.is_downloaded) {
      await new_avatar.load_mesh();
    }
    new_avatar.setScaleAll();
    resources.setActive(CategoryName.Body, new_avatar.id);
    this.changeAvatarWhenLoaded(new_avatar);
  }

  private async _changeAsset(new_asset: Asset | string | undefined) {
    if (new_asset === undefined) {
      return;
    }
    if (typeof new_asset === 'string') {
      const new_asset_ = resources.clothById[new_asset] as Asset | undefined;
      // here we already have url for new image
      if (!new_asset_) {
        // log.info(
        //   `[!] Cant' change asset ${new_asset}, as it does not exist in the database.`
        // );
        return false;
      }
      new_asset = new_asset_ as Asset;
    }

    if (new_asset.is_loading) {
      return true;
    }

    resources.setActive(new_asset.category, new_asset.id);
    this.cur_cloth_loading[new_asset.placement[0]] ??= [];
    this.cur_cloth_loading[new_asset.placement[0]].push(new_asset);

    if (!new_asset.is_downloaded) {
      await new_asset.load_all();
    }
    this.changeAssetWhenLoaded(new_asset);

    if (this.cloth[new_asset.placement[0]] != new_asset) {
      resources.setActive(new_asset.category, 'null');
    }

    return true;
  }
  /*
    Entry point for changing look.
  */
  async changeAsset(new_asset: Asset | string, ensure_no_empty_slots = false) {
    const res = await this._changeAsset(new_asset);

    console.log('Change asset to: ', res, toJS(resources.clothes));

    if (!res) return false;

    if (ensure_no_empty_slots) {
      if (!this.cloth[Slots.Bottom]) {
        await this._changeAsset(this.previous_assets[Slots.Bottom] || getDefaultAsset('look'));
      }

      if (!this.cloth[Slots.Head]) {
        await this._changeAsset(this.previous_assets[Slots.Head] || getDefaultAsset('head'));
      }

      if (!this.cloth[Slots.Shoes]) {
        await this._changeAsset(this.previous_assets[Slots.Shoes] || getDefaultAsset('shoes'));
      }
    }

    // Update avatar's alpha
    this.update_avatar_mask();

    // Update visibility
    for (const s of this.all_assets) {
      if (typeof s.visibility_to_set == 'boolean') {
        s.set_visibility(s.visibility_to_set);
        s.visibility_to_set = undefined;
      }
    }

    return true;
  }

  async changeFaceAnimation(new_animation: FaceAnimation | string | null) {
    if (!new_animation) {
      return;
    }

    if (typeof new_animation === 'string') {
      new_animation = resources.getFaceAnimationById(new_animation)!;
    }

    resources.setActive(CategoryName.FaceAnimations, new_animation.id);
    if (!new_animation.is_downloaded) {
      await new_animation.load_animation();
    }

    this.changeFaceAnimationWhenLoaded(new_animation);
    if (this.faceAnimation != new_animation) {
      resources.setActive(CategoryName.FaceAnimations, 'null');
    }
  }

  /*
    Entry point for changing animation.
  */
  async changeAnimation(new_animation: Animation | string | null) {
    if (typeof new_animation === 'string') {
      new_animation = resources.getAnimationById(new_animation);
    }

    if (!new_animation) return;

    // console.log('Changing animation: ', new_animation);
    resources.setActive(CategoryName.Animations, new_animation.id);
    if (!new_animation.is_downloaded) {
      await new_animation.load_animation();
    }

    this.changeAnimationWhenLoaded(new_animation);
  }

  resetAnimation() {
    this.changeAnimationWhenLoaded(this.animation!, true);
  }

  resetFaceAnimation() {
    this.changeFaceAnimationWhenLoaded(this.faceAnimation!, true);
  }

  takeOffClothes() {
    this.current_assets.forEach((v) => {
      this.changeAssetWhenLoaded(v, true);
    });

    // Update avatar's alpha
    this.update_avatar_mask();

    // Update visibility
    for (const s of this.all_assets) {
      if (typeof s.visibility_to_set == 'boolean') {
        s.set_visibility(s.visibility_to_set);
        s.visibility_to_set = undefined;
      }
    }
  }

  takeOffGlasses() {
    if (this.cloth[Slots.Eyes]) {
      this.changeAssetWhenLoaded(this.cloth[Slots.Eyes]!, true);
      // Update visibility
      for (const s of this.all_assets) {
        if (typeof s.visibility_to_set == 'boolean') {
          s.set_visibility(s.visibility_to_set);
          s.visibility_to_set = undefined;
        }
      }
    }
  }
  async setDefaultAnimation(withFace = false) {
    const default_animation = {
      male: '__idle_anim',
      female: 'idle_female_dec19',
    };

    this.resetAnimation();

    let face_promise = Promise.resolve();
    if (withFace) {
      face_promise = this.changeFaceAnimation('face__idle_anim');
    }

    await Promise.all([this.changeAnimation(default_animation[this.avatar.gender]), face_promise]);
  }
  async restartAnimation() {
    this.animation?.reset();
    this.faceAnimation?.reset();
  }
}

const currentState = new CurrentState();

export { currentState };
