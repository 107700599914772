import { startExperience } from '@in3d/core';
import { ScreenshotButton } from '../screenshot-btn';
import { VideoRecordingButton } from '../video-recording-btn';

export const TetonridgeActions = () => {
  return (
    <div className="customizer__actions">
      <div className="controls">
        <button className="control-btn" onClick={() => startExperience('sceneDefault')}>
          <img src="/assets/scene/reset.svg" alt="reset" />
        </button>
        <ScreenshotButton />
      </div>
      <button className="expirience-btn" onClick={() => startExperience('tetonridge_Bull')}>
        {/* <button className="expirience-btn" onClick={() => loadScene("sceneDark4")}> */}
        <div className="expirience-btn__content">
          <img src="/assets/scene/exp_1.png" alt="exp_1" />
        </div>
      </button>
      <button className="expirience-btn" onClick={() => startExperience('tetonridge_AWE')}>
        {/* <button className="expirience-btn" onClick={() => loadScene("sceneDark3")}> */}
        <div className="expirience-btn__content">
          <img src="/assets/scene/exp_2.png" alt="exp_2" />
        </div>
      </button>
      <button className="expirience-btn" onClick={() => startExperience('tetonridge_FanMag')}>
        {/* <button className="expirience-btn" onClick={() => startExperience("sceneDark2")}> */}
        <div className="expirience-btn__content">
          <img src="/assets/scene/exp_3.png" alt="exp_3" />
        </div>
      </button>
      {/* <VideoRecordingButton/> */}
    </div>
  );
};
