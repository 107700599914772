import { avatars } from '@in3d/store';
import { useSearchParams } from 'react-router-dom';
import { Modal } from './modal';

export const DeleteAvatarPopup = ({ onClose }: { onClose: () => void }) => {
  const [searchParams] = useSearchParams();

  const handleDeleteAvatar = () => {
    const id = searchParams.get('avatarId');
    if (id) {
      avatars.deleteAvatar(id);
      onClose();
    }
  };
  return (
    <Modal actions={false}>
      <div className="avatar-delete">
        <div className="avatar-delete__text">Delete this avatar?</div>
        <div className="avatar-delete__actions">
          <button className="avatar-delete__btn avatar-delete__btn--invert" onClick={handleDeleteAvatar}>
            Yes
          </button>
          <button className="avatar-delete__btn" onClick={onClose}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};
