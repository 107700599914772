
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

// import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { AvatarView } from '../../../AvatarView';

import { SceneExperienceBase } from "./scene_experience_base";
import { ACESFilmicToneMapping, AmbientLight, Color, DirectionalLight, EquirectangularReflectionMapping, Group, PerspectiveCamera, Scene, SpotLight, WebGLRenderer } from "three";
import env from "@in3d/environment";

async function setup_light(renderer: WebGLRenderer, camera: PerspectiveCamera, scene: Scene, scene_group: Group) {

  AvatarView.avatar_envMapIntensity = 0.5;
  AvatarView.cloth_envMapIntensity = 1.2;

  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1.0;

  scene.background = new Color('#F8F8F8'); // dark : #383838

  renderer.physicallyCorrectLights = true; // for rendering env

  let texture = await new RGBELoader().loadAsync(`${env.editorResourcesUrl}/textures/dry_field_1k.hdr`)
  texture.mapping = EquirectangularReflectionMapping;
  scene.environment = texture;

  renderer.physicallyCorrectLights = false;

  scene.fog = null;
  const light = new AmbientLight(0x777777, 0.5); // soft white light
  light.name = 'ambient_light';
  scene_group.add(light);

  let dirLight = new DirectionalLight(new Color(1, 0.25, 0.7), 3);
  dirLight.name = 'dirlight'
  dirLight.position.set(1, 5, -5);
  dirLight.castShadow = true;
  dirLight.shadow.bias = -0.00000472;

  dirLight.shadow.camera.top = 1.80;
  dirLight.shadow.camera.bottom = - 10.00;
  dirLight.shadow.camera.left = - 4.20;
  dirLight.shadow.camera.right = 4.20;
  scene_group.add(dirLight);

  if (IS_DEBUG && AvatarView.gui) {
    AvatarView.gui.add(dirLight.shadow, 'bias', -0.01, 0.01, 0.000001);
    AvatarView.gui.add(dirLight, 'intensity', 0, 10, 0.01);
  }

  // Spot light 1
  let spotLight = new SpotLight(new Color(1, 0.25, 0.7), 33.5);
  spotLight.name = 'spotlight1';
  spotLight.position.set(3, 5, -5);
  spotLight.angle = 0.5;
  spotLight.penumbra = 0.5;
  spotLight.castShadow = true;
  spotLight.distance = 15;
  spotLight.shadow.mapSize.width = 256;
  spotLight.shadow.mapSize.height = 256;

  scene_group.add(spotLight);
  if (IS_DEBUG && AvatarView.gui) {
    // AvatarView.gui.add(spotLight, 'in', -0.01, 0.01, 0.000001);
    AvatarView.gui.add(spotLight, 'intensity', 0, 10, 0.01);
  }


}


export class SceneAWE extends SceneExperienceBase {

  override async build() {

    // const soundEffect = new Audio();
    // soundEffect.autoplay = false;

    // // onClick of first interaction on page before I need the sounds
    // // (This is a tiny MP3 file that is silent and extremely short - retrieved from https://bigsoundbank.com and then modified)
    // window.soundEffect.src = './${env.editorResourcesUrl}/Rodeo.mp3'
    // // soundEffect.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";



    // AvatarView.renderer.domElement.style.display = 'none'
    // let renderer = new WebGLRenderer({
    //   antialias: false,
    //   powerPreference: 'high-performance',
    //   preserveDrawingBuffer: true,
    // });
    // renderer.setPixelRatio(window.devicePixelRatio);
    // renderer.shadowMap.enabled = true;
    // // AvatarView.renderer = renderer;
    // // renderer.toneMapping = SRGBColorSpace;
    // // renderer.toneMappingExposure = 1.0;
    // renderer.outputEncoding = sRGBEncoding;
    // renderer.setSize(AvatarView.parent_element.offsetWidth, AvatarView.parent_element.offsetHeight);
    // AvatarView.parent_element.appendChild(renderer.domElement);
    // this.renderer = renderer;
    const scene_group = this.scene.getObjectByName('scene_group') as Group;

    // const soundEffect = new Audio();
    // soundEffect.autoplay = true;
    // soundEffect.preload = true;
    // soundEffect.loop = true;
    // // onClick of first interaction on page before I need the sounds
    // // (This is a tiny MP3 file that is silent and extremely short - retrieved from https://bigsoundbank.com and then modified)
    // soundEffect.src = './${env.editorResourcesUrl}/Rodeo.mp3'
    // soundEffect.addEventListener('canplaythrough', loadedAudio, false);
    // soundEffect.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
    // soundEffect.play()

    const dir = `${env.editorResourcesUrl}/scenes/tetonridge_dance`
    await this.build_from_glbs(
      `${dir}/tetonridge_dance_avatar_opt.glb`,
      `${dir}/tetonridge_dance_scene_opt.glb`,
      `${dir}/audio.mp3`
    )

    // window.soundEffect.play()

    await setup_light(this.renderer, this.camera, this.scene, scene_group);

    // await new Promise(r => setTimeout(r, 2000));
    // await setTimeout(() => { this.is_ready = true }, 1000)

    // Compile materials
    this.renderer.compile(this.scene, this.camera);
    // this.renderer.render(this.scene, this.camera)
    // this.composer?.render();
    this.mixer = AvatarView.mixer;



    // setTimeout(() => { this.play() }, 400)
    this.scene_loaded = true;

    this.play()

    this.is_ready = true
  }

  // render() {
  //   this.composer?.render();

  //   // this.scene.traverse((node) => {
  //   //   if (node.isVolumetricSpotlight)
  //   //     node.update();
  //   // })
  // }

}
