import { AvatarView } from './AvatarView';

import { currentState } from './core/CurrentState';
import { init_avatars, load_avatar } from './core/resources/avatar';
import { AvatarAnimatable, init_avatars_animatable } from './core/resources/avatar_animatable';

import { customizationUpdater } from './addons/customization_updater';

import { getDefaultAvatarUrl, loadAvatarList } from './avatar_list';
import { avatarList, customizationInfo, fixMissing } from './content';

import { init_threejs } from './init_threejs';
import { resources } from './resource_manager';

import { downloadModel } from './export_api';
import { init_headless, setHeadlessCamera } from './headless_api';

import { getHostname, page_params } from '@in3d/common';
// import { checkWebpFeatures, isWebGL2Available } from './addons/feature_checker';
import { addKeyDownListner } from './debug_keyboard';
import { editorParams } from './editor_api';
import { sdk } from './sdk_integration';
import { AvaturnSDK } from '@in3d/sdk';
import { when } from 'mobx';
import { auth, developerConfig } from '@in3d/store';
import env from '@in3d/environment';

declare global {
  interface Window {
    avaturnSDK: AvaturnSDK;
  }
}

export function init_editor(avatar_dom: HTMLElement, config?: { bgColor: string | null }) {
  // eslint-disable-next-line
  return new Promise<void>(async (resolve, reject) => {
    'use strict';

    window.soundEffect = new Audio();

    if (getHostname().includes('createavatar.io') || getHostname().includes('avaturn.dev')) {
      const sdk = new AvaturnSDK();
      sdk.init(null, {}).then((sdk) => {
        if (sdk) window.avaturnSDK = sdk;
      });
    }

    // checkWebpFeatures();
    // isWebGL2Available();

    if (AvatarView.inited) {
      return resolve();
    }
    if (page_params.headless === 'true') {
      AvatarView.isHeadlessMode = true;
      init_headless();
    }
    if (IS_DEBUG) {
      addKeyDownListner();
    }

    let def = false;
    if (page_params.avatar_link === undefined && page_params.customization_id === undefined) {
      page_params.avatar_link = decodeURIComponent(getDefaultAvatarUrl());
      def = true;
    }

    const resources_promise = resources.isLoaded();
    const corresp_ids_promise = resources.loadCorrespondingIds();
    const customization_list_promise = customizationInfo.load();

    const avatar_promise = customization_list_promise.then(() => {
      if (AvatarView.isHeadlessMode) {
        // No need loading avatar list
        return;
      }
      const avatar_glb_list = loadAvatarList(customizationInfo.avatar_url, customizationInfo.gender);
      avatarList.load_from_list(avatar_glb_list);

      if (def && env.hostsAnimatable.includes(getHostname())) {
        customizationInfo.is_animatable = true;
      }

      if (customizationInfo.is_animatable) {
        AvatarView.is_animatable = true;
        init_avatars_animatable(avatarList);
      } else {
        init_avatars(avatarList);
      }
    });

    init_threejs(avatar_dom);
    customizationUpdater.set_customization_id(page_params.customization_id);

    AvatarView.scene.visible = false;

    const on_load = async () => {
      /* Make scene visible when ready */

      if (customizationInfo.data.avatar.curve_coeff) {
        editorParams.skinBrightness = (customizationInfo.data.avatar.curve_coeff - 0.5) * 100;
      }

      const bs = customizationInfo.data.avatar.bone_scale;
      if (bs) {
        Object.entries(bs).forEach(([k, v]) => {
          editorParams.setScale(k as keyof typeof bs, (v - 0.5) * 100);
        });
      }
      if (!customizationInfo.data.avatar.eye_color && AvatarView.is_animatable) {
        AvatarAnimatable.set_eye_color('#633D23');
      }
      if (AvatarView.is_animatable && customizationInfo.data.avatar.eye_texture) {
        const tex = customizationInfo.data.avatar.eye_texture;
        await currentState.changeEyeTexture(tex);
        // console.log('try to set eye texture', tex);
      }
      // if info has eye texture, set it
      if (page_params.download_immediately === 'true') {
        downloadModel();
      }

      AvatarView.scene.visible = true;

      // AvatarView.renderer.compile(AvatarView.scene, AvatarView.camera);
      AvatarView.render(0.0);

      currentState.restartAnimation();
      AvatarView.is_rendering_active = true;
      resources.setSceneLoadStatus(true);

      // POST MESSAGE TO SDK
      // console.log('SEND_LOAD: ', auth.loaded, sdk.inited);
      when(
        () => auth.loaded && sdk.inited,
        () => {
          sdk.dispatch('load');
        }
      );

      //

      resolve();

      setTimeout(() => (AvatarView.adaptiveDPR.enabled = page_params.adaptive_dpr == 'true'), 500);
      if (customizationInfo.is_default) {
        customizationUpdater.update_customization(true);
      }
    };

    const load_assets = async () => {
      await Promise.all([resources_promise, corresp_ids_promise]);

      fixMissing();
      
      if (!env.isAssetsAdmin) {
        resources.sortByGender(customizationInfo.gender);
        resources.filterByGender(customizationInfo.gender);
      }

      currentState.changeHaircap(customizationInfo.data.avatar.haircap_id || 'stripes');
      // Assets
      const promises = Object.entries(customizationInfo.data.assets).map(async ([id, asset_customizations]) => {
        return currentState.changeAsset(id, false).then(() => {
          currentState.set_customization(id, asset_customizations);
        });
      });
      // Animation
      promises.push(AvatarView.currentState.setDefaultAnimation(AvatarView.is_animatable));

      await Promise.allSettled(promises);

      await on_load();
    };

    await customization_list_promise;
    await avatar_promise;

    if (AvatarView.isHeadlessMode) {
      await Promise.all([resources_promise, corresp_ids_promise]);
      setHeadlessCamera();
      AvatarView.scene.visible = true;
      resources.setSceneLoadStatus(true);
      AvatarView.is_rendering_active = false;
      AvatarView.render(0.1);
      resolve();
      window.loaded_promise.resolve();
    } else {
      customizationUpdater.set_previous_customization(customizationInfo.data);

      load_avatar(customizationInfo.data.avatar.body)
        .then(load_assets)
        .then(() => {
          customizationUpdater.is_active = true;
        });
    }
  });
}

let inited = false;
window.addEventListener(
  'touchstart',
  function () {
    if (!inited) {
      window.soundEffect = new Audio();
      inited = true;
    }
  },
  false
);
