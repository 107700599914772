import { CategoryName, ResourcePlacementStr } from '../types';

export const placementToCategory = (placement: ResourcePlacementStr): CategoryName => {
  const categories: { [key in ResourcePlacementStr]: CategoryName } = {
    eyes: CategoryName.Glasses,
    head: CategoryName.Hair,
    look: CategoryName.Outfit,
    shoes: CategoryName.Shoes,
    animations: CategoryName.Animations,
    faceMask: CategoryName.FaceAnimations,
  };
  return categories[placement];
};
