import { Route, Routes, useSearchParams } from 'react-router-dom';
import { Admin } from './admin/admin';
import { useAppHeight, useAuth } from '@in3d/hooks';
import { Login, RequireAuth } from '@in3d/auth';
import { useEffect } from 'react';
import { when } from 'mobx';
import { auth } from '@in3d/store';
import { devPortalClient } from '@in3d/api';

export function App() {
  const [_, setParams] = useSearchParams();
  useAuth();
  useAppHeight();

  useEffect(() => {
    when(
      () => auth.isAuth,
      () => {
        devPortalClient.getProjectsList(auth.token).then((list) => {
          setParams((p) => {
            if (!p.has('project')) {
              if (list.length > 0) {
                p.set('project', list[0].slug);
              } else {
                p.set('project', 'demo');
              }
            }
            return p;
          });
        });
      }
    );
  }, []);
  return (
    <Routes>
      <Route path="/login/*" element={<Login />} />
      <Route path="/" element={<RequireAuth />}>
        <Route path="/" element={<Admin />} />
      </Route>
    </Routes>
  );
}

export default App;
