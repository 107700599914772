import { Mesh, OrthographicCamera, Scene, BufferGeometry, Material } from 'three';

export const prepareScene = (size: { width: number, height: number }, material: Material, decalGeometry: BufferGeometry,
) => {
  const { width, height } = size;
  const scene = new Scene();
  const camera = new OrthographicCamera(-width / 2, width / 2, height / 2, -height / 2, 0.001, 10000);
  camera.position.z = 10;
  scene.add(camera);

  const square = new Mesh(decalGeometry, material);
  scene.add(square);
  square.frustumCulled = false;

  return { scene, camera };
};

export const createSimpleScene = (width: number, height: number) => {
  const scene = new Scene();
  const camera = new OrthographicCamera(-width / 2, width / 2, height / 2, -height / 2, 0.001, 10000);
  camera.position.z = 10;
  scene.add(camera);

  return { scene, camera };
};
