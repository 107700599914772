import { customizationUpdater } from './addons/customization_updater';
import { AvatarView, gltf_loader } from './AvatarView';
import { customizationInfo } from './content';
import { currentState } from './core/CurrentState';
import editorParams from './core/EditorParams';
import { FaceAnimation } from './core/resources/animation_face';
import { load_avatar } from './core/resources/avatar';
import { initLandmarker, maybePredictBlendshapes } from './core/resources/tracking';
import { CategoryName, resources } from './resource_manager';
import { sdk } from './sdk_integration';
import { Asset, Cloth, Glasses, Hair } from './core/resources/assets';
import { Mesh, MeshStandardMaterial, SkinnedMesh, sRGBEncoding, TextureLoader } from 'three';
import { Settings, Slots, loadFileFromDisk } from './core/resources/common';
import { skyboxes } from './core/scenes/scene_dark2';
import { Animation } from './core/resources/animation';
import { shareOrDownloadToDisk } from './export_api';
import { AvatarCustomization, ResourcePlacementStr } from '@in3d/common';
import { MaterialSettings } from './core/Materials';
import { getDefaultAvatarUrl } from './avatar_list';
import { toJS } from 'mobx';

export async function trackFace(video: HTMLVideoElement) {
  initLandmarker(video).then(maybePredictBlendshapes);
}

export async function takeScreenshot(extension = 'jpg') {
  return AvatarView.screenshoter.takeScreenshot();
}
export async function takeScreenshot2(extension = 'jpg') {
  // let strMime = `image/${extension == 'jpg' ? 'jpeg' : extension}`;

  const ctx = AvatarView.renderer.domElement.getContext('webgl2')!;
  const pixels = new Uint8Array(ctx.drawingBufferWidth * ctx.drawingBufferHeight * 4);
  ctx.readPixels(0, 0, ctx.drawingBufferWidth, ctx.drawingBufferHeight, ctx.RGBA, ctx.UNSIGNED_BYTE, pixels);

  // let imgData = ctx.getImageData(0, 0, 480, 640);

  return pixels;
}

export async function startVideoRecording() {
  AvatarView.recorder.start();
}

export async function stopVideoRecording() {
  AvatarView.recorder.stop();
  const blob = AvatarView.recorder.save();
  return shareOrDownloadToDisk(blob, 'avaturn_video.mp4', 'video/mp4');
}

export const startFaceTrackingRecording = () => {
  currentState.startTrackingRecording();
};

export const stopFaceTrackingRecording = () => {
  currentState.finishTrackingRecording();
};

export async function changeBody(id: string, type?: 'male' | 'female') {
  await load_avatar(id);
  customizationUpdater.update_customization();

  sdk.dispatch('bodySet', id);

  return id;
}

export const changeAsset = (category: CategoryName, id: string) => {
  switch (category) {
    case CategoryName.Animations: {
      changeAnimation(id);
      break;
    }
    case CategoryName.FaceAnimations: {
      changeFaceAnimation(id);
      break;
    }
    case CategoryName.Body: {
      changeBody(id);
      break;
    }
    default: {
      changeCloth(id);
    }
  }
};

export function takeOffGlasses() {
  currentState.takeOffGlasses();
}

export function addCloth(
  url: string,
  type: ResourcePlacementStr,
  config: { id: string; settings: Record<string, any>; name: string; preview?: string }
): string {
  const assets: { [key in ResourcePlacementStr]: any } = {
    head: Hair,
    look: Cloth,
    shoes: Cloth,
    eyes: Glasses,
    animations: Animation,
    faceMask: Cloth,
  };
  const asset = new assets[type]();
  asset.isDraft = true;
  asset.glb_url = url;
  asset.placement_str = type;
  asset.type = type;

  asset.id = config.id;

  if (type === 'animations') {
    asset.gender = 'unisex';
  } else {
    asset.material_settings = new MaterialSettings();
    asset.filename = 'model_v2.glb';
    asset.set_settings(new Settings());
    asset.settings_json = config.settings;
  }

  asset.preview_url = config?.preview || 'new';
  asset.name = config.name;

  //eslint-disable-next-line
  //@ts-ignore
  resources.clothes[type].unshift(asset);

  console.log('Add ', type, toJS(resources.clothes[type]));

  return asset;
}

export async function changeCloth(id: string) {
  await currentState.changeAsset(id, true);
  customizationUpdater.update_customization();

  sdk.dispatch('assetSet', id);
  return id;
}
export async function updateClothRender(id: string) {
  await changeCloth(id);
  await changeCloth(id);
}
export function getDefaultAvatar() {
  return getDefaultAvatarUrl();
}

export async function changeTexture(url: string, cloth_id: string) {
  const c: Cloth = currentState.cloth[Slots.Bottom] as Cloth;

  if (!c) return;

  const mesh = c.group.children.find((o: any) => {
    return o.isMesh === true;
  }) as SkinnedMesh;

  if (!mesh) return;

  const map = await new TextureLoader().loadAsync(url);
  map.encoding = sRGBEncoding;
  map.flipY = false;
  map.generateMipmaps = false;
  // map.needsUpdate = true;

  (mesh.material as MeshStandardMaterial).map?.dispose();
  (mesh.material as MeshStandardMaterial).map = map;
}

export async function changeAnimation(id: string) {
  await currentState.changeAnimation(id);
  // AvatarView.set_camera(animationList.find_by_id(id).camera);
  return id;
}

export async function changeFaceAnimation(id: string) {
  await currentState.changeFaceAnimation(id);
  // AvatarView.set_camera(animationList.find_by_id(id).camera);
  // return id;
}

export function stopFaceAnimation() {
  currentState.resetFaceAnimation();
  // AvatarView.set_camera(animationList.find_by_id(id).camera);
  // return id;
}

export function changeExpirienceSoundStatus() {
  console.log('Change sound');
}

/// Scenes and experiences
export async function loadScene(name: string) {
  AvatarView.scene_manager.switch(name);
}
export async function changeSkyboxByName(name: string, height = 2.7, radius = 10.0) {
  (AvatarView.scene_manager.current_scene as any).loadSkybox(
    name.startsWith('https://') ? name : `assets/test_assets/skyboxes/${name}`,
    height,
    radius
  );
}
export async function changeSkybox(idx: number) {
  (AvatarView.scene_manager.current_scene as any).loadSkybox(skyboxes[idx]);
}

export function getNumSkyboxes() {
  return skyboxes.length;
}
export async function startExperience(name: string) {
  if (!currentState.animation && (name == 'tetonridge_FanMag' || name == 'sceneDefault')) {
    await currentState.changeAnimation('idle_male_dec19');
  }

  if (
    currentState.animation &&
    (name == 'tetonridge_Bull' || name == 'tetonridge_AWE' || name == 'deepmotion_Football')
  ) {
    currentState.resetAnimation();
  }

  AvatarView.scene_manager.switch(name);
}

export function getCurrentCustomization(withAvatarUrl = false) {
  const t = currentState.get_current_customization() as AvatarCustomization & {
    avatar_url?: string;
  };
  if (withAvatarUrl) {
    t['avatar_url'] = customizationInfo.avatar_url;
  }
  return t;
}

export function addBodyAnimation(url: string) {
  const anim = new Animation();
  anim.glb_url = url;
  anim.id = Date.now().toString(36) + Math.random().toString(36).substring(2);

  resources.animations.push(anim);
}

export function addFaceAnimation(url: string) {
  const anim = new FaceAnimation();
  anim.glb_url = url;
  anim.id = Date.now().toString(36) + Math.random().toString(36).substring(2);

  resources.addFaceAnimation(anim);
}

export function loadGn3raGlb() {
  loadFileFromDisk().then((url: string) => {
    gltf_loader.loadAsync(url).then((g) => {
      const obj = g.scene.getObjectByProperty('isMesh', true) as Mesh;
      if (obj) {
        (currentState.cloth[Slots.Top] as Cloth).overrideMaterial = obj.material as MeshStandardMaterial;
      }
    });
  });
}

export { editorParams };
