import { Box3, Vector3 } from 'three';

export function centerObject(object: THREE.Object3D): void {
  const box = new Box3().setFromObject(object);

  // Get the center of the bounding box
  const center = box.getCenter(new Vector3());

  // Translate the object to the center of the 3D space

  const vector = new Vector3();
  vector.x = center.x;
  vector.z = center.z;
  object.position.sub(vector);
}
