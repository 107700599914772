import { getHostname, notifyError, page_params } from '@in3d/common';
import env from '@in3d/environment';
import isMobile from 'is-mobile';
import GUI from 'lil-gui';
import {
  AnimationMixer,
  AnimationObjectGroup,
  Cache,
  Clock,
  Group,
  PerspectiveCamera,
  Scene,
  WebGL1Renderer,
  WebGLRenderer,
  sRGBEncoding,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// eslint-disable-next-line @typescript-eslint/no-var-requires
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { captureException, captureMessage } from '@sentry/react';

import { AdaptiveDPR, AdaptiveSceneFeatures } from './addons/fps_optimizer';
import { AvatarView } from './AvatarView';
import { canvasRecorder } from './canvas_recorder';
import { currentState } from './core/CurrentState';
import { SceneManager } from './core/scenes/scene_manager';
import { Screenshoter } from './screenshoter';

function init_threejs(parent_element: HTMLElement) {
  Cache.enabled = true;

  AvatarView.set_current_state(currentState);
  AvatarView.parent_element = parent_element;

  // Renderer
  const antialias = page_params.antialias === 'true';
  let renderer: WebGLRenderer;
  if (IS_DEBUG && page_params.webgl1 === 'true') {
    renderer = new WebGL1Renderer({
      antialias: antialias,
      powerPreference: 'high-performance',
      preserveDrawingBuffer: false,
    });
  } else {
    try {
      renderer = new WebGLRenderer({
        antialias: antialias,
        alpha: true,
        powerPreference: 'high-performance',
        preserveDrawingBuffer: false,
      });
    } catch (e: any) {
      captureException(e);
      captureMessage('Trying without powerPreference');
      if ((e.message as string).includes('selected attributes')) {
        try {
          renderer = new WebGLRenderer({
            antialias: antialias,
            alpha: true,
            // powerPreference: 'high-performance',
            preserveDrawingBuffer: false,
          });
        } catch (e: any) {
          notifyError(e.message as string);
          captureException(e);
          throw e;
        }
      } else {
        notifyError(e.message as string);
        captureException(e);
        throw e;
      }
    }
  }
  if (AvatarView.isHeadlessMode) {
    renderer.setPixelRatio(1.0);
  } else {
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2.0));
  }

  // Camera
  const camera = new PerspectiveCamera(25, parent_element.offsetWidth / parent_element.offsetHeight, 0.1, 45);
  AvatarView.camera = camera;

  let AvatarViewGui: GUI;

  if (IS_DEBUG && AvatarView.gui) {
    AvatarViewGui = AvatarView.gui.addFolder('General').close();
  }

  if (IS_DEBUG && AvatarView.gui) {
    const p = { fov: camera.fov + 0 };
    AvatarViewGui!
      .add(p, 'fov', 1, 60, 1)
      .listen()
      .onChange((x: number) => {
        const c = camera.fov / x;
        const d = AvatarView.camera.position;
        AvatarView.camera.position.set(d.x * c, d.y, d.z * c);
        AvatarView.camera.fov = x;
        AvatarView.camera.updateProjectionMatrix();
        AvatarView.controls.update();
      });
  }

  // Controls
  const controls = new OrbitControls(camera, renderer.domElement);
  AvatarView.controls = controls;
  controls.autoRotate = false;
  controls.autoRotateSpeed = 0.5;
  // controls.enablePan = false;
  // controls.addEventListener('change', this.render); // use if there is no animation loop
  controls.minDistance = 0.1; //2;
  controls.maxDistance = 18;

  // controls.minAzimuthAngle = - Math.PI / 2;
  // controls.maxAzimuthAngle = Math.PI / 2;
  controls.maxPolarAngle = Math.PI / 2.0;
  controls.minPolarAngle = Math.PI / 2.6;
  controls.enableDamping = true;
  controls.dampingFactor = 0.08;

  let d;
  if (isMobile()) {
    // alert (3)
    d = {
      target: { x: 0, y: 0.9224574103730636, z: 0 },
      position: { x: -0.6534144644416641, y: 1.1649183398638605, z: 4.453729483417054 },
      zoom: 1,
    };
  } else {
    d = {
      target: { x: 0, y: 0.9224574103730636, z: 0 },
      position: { x: (-1.2956458138992988 * 15) / 25, y: 1.513574277049802, z: (8.638329041029 * 15) / 25 },
      zoom: 1,
    };
    // d = {
    //   target: { x: 0, y: 0.9224574103730636, z: 0 },
    //   position: { x: -0.7621104702629122, y: 1.2052519046034729, z: 5.1946108568794935 },
    //   zoom: 1,
    // };
  }
  AvatarView.set_camera(d);

  // Recorder
  AvatarView.recorder = canvasRecorder;
  AvatarView.recorder.setCanvas(renderer.domElement);

  //
  AvatarView.screenshoter = new Screenshoter();

  renderer.shadowMap.enabled = true;
  AvatarView.renderer = renderer;
  // renderer.toneMapping = THREE.SRGBColorSpace;
  // renderer.toneMappingExposure = 1.0;
  renderer.outputEncoding = sRGBEncoding;
  renderer.setSize(parent_element.offsetWidth, parent_element.offsetHeight);

  const scene = new Scene();
  AvatarView.scene = scene;

  // scene.rotation.set(0, 1, 0)
  const scene_group = new Group();
  scene_group.name = 'scene_group';
  scene.add(scene_group);

  const objects_group = new Group();
  objects_group.name = 'avaturn_avatar';
  scene.add(objects_group);
  AvatarView.scene_objects_group = objects_group;

  AvatarView.scene_manager = new SceneManager(
    renderer,
    scene,
    camera,
    IS_DEBUG && AvatarView.debug ? AvatarView.gui!.addFolder('Scene') : undefined
  );
  // this.scene_manager.switch('sceneDark3');

  let scene_name = 'sceneDefault';

  if (getHostname().startsWith('artisant')) {
    scene_name = 'sceneBright12cArtisant';
  } else if (getHostname().startsWith('bonjourlab')) {
    scene_name = 'sceneBright12cBonjourlab';
  } else if (env.hostsAnimatable.includes(getHostname())) {
    scene_name = 'sceneDefaultAnimatable';
  }

  if (AvatarView.isHeadlessMode) {
    scene_name = 'sceneHeadless';
  }

  AvatarView.scene_manager.switch(scene_name);

  AvatarView.clock = new Clock();

  if (IS_DEBUG && AvatarView.gui) {
    AvatarViewGui!.add(renderer.info.render, 'calls').listen();
    AvatarViewGui!.add(renderer.info.render, 'triangles').listen();
    AvatarViewGui!.add(renderer.info.programs!, 'length').listen();
    AvatarViewGui!.add(parent_element, 'offsetWidth').listen();
    AvatarViewGui!.add(parent_element, 'offsetHeight').listen();
    AvatarViewGui!.add(window, 'devicePixelRatio', 0, 3, 0.1).onChange(() => {
      renderer.setPixelRatio(window.devicePixelRatio);
    });

    AvatarViewGui!.add(controls, 'autoRotate');

    AvatarViewGui!
      .add(renderer.shadowMap, 'enabled')
      .name('shadows')
      .onChange(() => {
        AvatarView.scene.traverse(function (child: any) {
          if (child.material) {
            child.material.needsUpdate = true;
          }
        });
      });
  }

  if (AvatarView.debug) {
    AvatarView.stats = Stats();
    // console.log(AvatarView.stats);
    parent_element.appendChild(AvatarView.stats.domElement);
  }

  AvatarView.animationGroup = new AnimationObjectGroup();
  AvatarView.mixer = new AnimationMixer(AvatarView.animationGroup);

  if (IS_DEBUG && AvatarView.gui) {
    AvatarView.parent_element.appendChild(AvatarView.gui.domElement);
  }

  parent_element.appendChild(renderer.domElement);

  window.addEventListener('resize', AvatarView.onWindowResize);
  AvatarView.onWindowResize();

  AvatarView.init_loaders();

  AvatarView.inited = true;

  // const axesHelper = new THREE.AxesHelper(1);
  // scene.add(axesHelper);

  AvatarView.adaptiveDPR = new AdaptiveDPR();
  AvatarView.adaptiveSceneFeatures = new AdaptiveSceneFeatures();
  if (IS_DEBUG && AvatarView.gui) {
    AvatarViewGui!.add(AvatarView.adaptiveDPR, 'cur_dpr').listen();
    AvatarViewGui!.add(AvatarView.adaptiveDPR, 'avg_frame_time').listen();
  }

  AvatarView.animate();
}

export { init_threejs };
