import './custom-input.scss';

import { cn } from '@in3d/common';
import { InputHTMLAttributes, useRef } from 'react';

interface CustomTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  isMobile?: boolean;
  label: string;
  'data-error'?: string | boolean;
  submit: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  loading?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  children?: React.ReactNode;
}
export const CustomTextArea: React.FC<CustomTextAreaProps> = (props) => {
  const { label, className, isMobile, submit, loading, onChange, children, ...rest } = props;
  const dataError = props['data-error'];

  const ref = useRef<HTMLTextAreaElement>(null);

  return (
    <div className={cn(['custom-input', className])}>
      {children ? children : null}
      {label ? <div className="custom-input__label">{label}</div> : null}
      <textarea
        ref={ref}
        onKeyDown={(e) => {
          if (e.key === 'Enter') submit(e);
        }}
        onChange={(e) => {
          const textarea = ref.current;
          if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
          }
          onChange(e);
        }}
        rows={1}
        autoComplete="off"
        disabled={loading}
        {...rest}
        className={cn(['custom-input__field', dataError && 'error'])}
      />
      <div className="custom-input__bot">
        {dataError && typeof dataError === 'string' && <div className="custom-input__error">{dataError}</div>}
      </div>
    </div>
  );
};
