import { motion } from 'framer-motion';

const transition = {
  times: [0, 0.5, 1],
  repeat: Infinity,
  duration: 0.9,
};

const initial = {
  rotate: 0,
  scale: 1,
  x: 0,
  y: 0,
};

const starVariants = {
  initial,
  hover: {
    rotate: 170,
    scale: 1.5,
    x: -2,
    y: 2,
  },
  loading: {
    rotate: [0, 170, 0],
    scale: [1, 1.5, 1],
    x: [0, -2, 0],
    y: [0, 2, 0],
    transition,
  },
};

const bigStarVariants = {
  initial,
  hover: {
    rotate: 170,
    scale: 0.5,
    x: -3,
    y: 1,
  },
  loading: {
    rotate: [0, 170, 0],
    scale: [1, 0.5, 1],
    x: [0, -3, 0],
    y: [0, 1, 0],
    transition,
  },
};

export const SparksIcon = () => (
  <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_7692_20065)">
      <motion.path
        variants={bigStarVariants}
        d="M10.3971 6.96873C10.3139 7.06937 10.2642 7.19341 10.2548 7.32362L10.2559 7.3278L10.047 10.8501C9.95358 12.4267 9.12327 13.8644 7.8042 14.7336L4.8543 16.681C4.7462 16.7542 4.66361 16.8593 4.61801 16.9816C4.5724 17.1039 4.56606 17.2374 4.59984 17.3635C4.63363 17.4896 4.70587 17.602 4.80652 17.6851C4.90718 17.7683 5.03124 17.818 5.16147 17.8273L8.68567 18.04C9.46284 18.0861 10.2185 18.3127 10.8927 18.7019C11.567 19.091 12.1411 19.632 12.5697 20.2818L14.5171 23.2308C14.5903 23.3389 14.6954 23.4214 14.8178 23.467C14.9401 23.5126 15.0736 23.5189 15.1997 23.4851C15.3259 23.4513 15.4383 23.3791 15.5215 23.2784C15.6046 23.1778 15.6544 23.0537 15.6638 22.9235L15.8758 19.3959C15.922 18.6189 16.1488 17.8633 16.5381 17.1892C16.9274 16.5151 17.4685 15.941 18.1185 15.5125L21.0684 13.5651C21.1765 13.4919 21.2591 13.3868 21.3047 13.2644C21.3503 13.1421 21.3567 13.0086 21.3229 12.8826C21.2891 12.7565 21.2169 12.644 21.1162 12.5609C21.0156 12.4778 20.8915 12.4281 20.7613 12.4187L17.2329 12.2072C15.656 12.114 14.218 11.2841 13.3489 9.96539L11.4015 7.01636C11.3282 6.9083 11.2231 6.82574 11.1008 6.78017C10.9784 6.73459 10.8449 6.72826 10.7188 6.76205C10.5927 6.79584 10.4802 6.86808 10.3971 6.96873Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter1_d_7692_20065)">
      <motion.path
        variants={starVariants}
        d="M24.3645 0.9652C24.3043 0.970922 24.2474 0.995242 24.2017 1.03478L24.2007 1.03652L22.9795 2.12096C22.433 2.6064 21.6907 2.8052 20.9745 2.65794L19.3717 2.32982C19.3123 2.31841 19.2509 2.32577 19.1959 2.35088C19.141 2.37599 19.0952 2.4176 19.065 2.46992C19.0348 2.52225 19.0216 2.58269 19.0274 2.64284C19.0331 2.70299 19.0574 2.75986 19.097 2.80556L20.1808 4.02858C20.4201 4.29804 20.5933 4.61954 20.6866 4.96757C20.7799 5.3156 20.7907 5.68056 20.7183 6.03349L20.3905 7.63605C20.3791 7.69538 20.3865 7.7568 20.4116 7.81176C20.4367 7.86672 20.4784 7.91249 20.5307 7.9427C20.583 7.97292 20.6435 7.98608 20.7036 7.98036C20.7638 7.97464 20.8207 7.95032 20.8664 7.91078L22.0903 6.82561C22.3597 6.58643 22.6812 6.41336 23.0293 6.32015C23.3774 6.22694 23.7424 6.21614 24.0953 6.28863L25.6982 6.61675C25.7575 6.62816 25.8189 6.6208 25.8739 6.59569C25.9289 6.57058 25.9746 6.52897 26.0048 6.47664C26.0351 6.42432 26.0482 6.36388 26.0425 6.30373C26.0367 6.24358 26.0124 6.1867 25.9728 6.14101L24.8873 4.91698C24.4017 4.37038 24.2027 3.62813 24.3498 2.91208L24.6776 1.30952C24.689 1.25018 24.6816 1.18876 24.6565 1.13381C24.6314 1.07885 24.5898 1.03308 24.5374 1.00286C24.4851 0.972642 24.4246 0.959479 24.3645 0.9652Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_7692_20065"
        x="0.578125"
        y="6.74023"
        width="24.7656"
        height="24.7656"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7692_20065" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7692_20065" result="shape" />
      </filter>
      <filter
        id="filter1_d_7692_20065"
        x="15.0254"
        y="0.964844"
        width="15.0176"
        height="15.0176"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7692_20065" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7692_20065" result="shape" />
      </filter>
    </defs>
  </svg>
);
