import { Pass } from 'postprocessing';
import {
  BufferGeometry,
  Camera,
  Color,
  DepthTexture,
  FloatType,
  FramebufferTexture,
  Matrix4,
  Mesh,
  MeshStandardMaterial,
  NearestFilter,
  PerspectiveCamera,
  RGBAFormat,
  Scene,
  ShaderMaterial,
  SkinnedMesh,
  UnsignedByteType,
  Vector2,
  WebGLMultipleRenderTargets,
  WebGLRenderTarget,
  WebGLRenderer,
} from 'three';
import {
  copyNecessaryProps,
  getVisibleChildren,
  isChildMaterialRenderable,
  saveBoneTexture,
  updateVelocityDepthNormalMaterialAfterRender,
  updateVelocityDepthNormalMaterialBeforeRender,
} from './utils';
import { VelocityDepthNormalMaterial } from './velocityDepthNormalMaterial';

const backgroundColor = new Color(0);
const zeroVec2 = new Vector2();
const tmpProjectionMatrix = new Matrix4();
const tmpProjectionMatrixInverse = new Matrix4();

// class WebGLMultipleRenderTargetsMy extends WebGLRenderTarget {
//   // @ts-ignore
//   texture: Texture[];
// }

export class VelocityDepthNormalPass extends Pass {
  cachedMaterials = new WeakMap();
  visibleMeshes: SkinnedMesh<BufferGeometry, MeshStandardMaterial>[] = [];
  override needsSwap = false;
  _scene: Scene;
  _camera: PerspectiveCamera;
  renderTarget: any;
  renderDepth: boolean;
  lastDepthTexture: any;

  constructor(scene: Scene, camera: Camera, renderDepth = true) {
    super('velocityDepthNormalPass');

    this.needsSwap = false;

    if (!(camera instanceof PerspectiveCamera)) {
      throw new Error(
        this.constructor.name +
          " doesn't support cameras of type '" +
          camera.constructor.name +
          "' yet. Only cameras of type 'PerspectiveCamera' are supported."
      );
    }

    this._scene = scene;
    this._camera = camera;

    const bufferCount = renderDepth ? 2 : 1;

    this.renderTarget = new WebGLMultipleRenderTargets(1, 1, bufferCount, {
      minFilter: NearestFilter,
      magFilter: NearestFilter,
    }) as any;

    this.renderTarget.depthTexture = new DepthTexture(1, 1);
    this.renderTarget.depthTexture.type = FloatType;

    if (renderDepth) {
      this.renderTarget.texture[0].type = UnsignedByteType;
      this.renderTarget.texture[0].needsUpdate = true;

      this.renderTarget.texture[1].type = FloatType;
      this.renderTarget.texture[1].needsUpdate = true;
    }

    this.renderDepth = renderDepth;
  }

  setVelocityDepthNormalMaterialInScene() {
    this.visibleMeshes = getVisibleChildren(this._scene) as typeof this.visibleMeshes;

    for (const c of this.visibleMeshes) {
      const originalMaterial = c.material;

      let [cachedOriginalMaterial, velocityDepthNormalMaterial] = this.cachedMaterials.get(c) || [];

      if (originalMaterial !== cachedOriginalMaterial) {
        velocityDepthNormalMaterial = new VelocityDepthNormalMaterial();

        copyNecessaryProps(originalMaterial, velocityDepthNormalMaterial);

        c.material = velocityDepthNormalMaterial;

        if (c.skeleton?.boneTexture) saveBoneTexture(c as any);

        this.cachedMaterials.set(c, [originalMaterial, velocityDepthNormalMaterial]);
      }

      c.material = velocityDepthNormalMaterial;

      c.visible = isChildMaterialRenderable(c as any, originalMaterial as any);

      if (this.renderDepth) velocityDepthNormalMaterial.defines.renderDepth = '';

      const map =
        originalMaterial.map ||
        originalMaterial.normalMap ||
        originalMaterial.roughnessMap ||
        originalMaterial.metalnessMap;

      if (map) velocityDepthNormalMaterial.uniforms.uvTransform.value = map.matrix;

      updateVelocityDepthNormalMaterialBeforeRender(c as any, this._camera);
    }
  }

  unsetVelocityDepthNormalMaterialInScene() {
    for (const c of this.visibleMeshes) {
      c.visible = true;

      updateVelocityDepthNormalMaterialAfterRender(c as any, this._camera);

      c.material = this.cachedMaterials.get(c)[0];
    }
  }

  override setSize(width: number, height: number) {
    this.renderTarget.setSize(width, height);

    this.lastDepthTexture?.dispose();

    this.lastDepthTexture = new FramebufferTexture(width, height, RGBAFormat);
    this.lastDepthTexture.minFilter = NearestFilter;
    this.lastDepthTexture.magFilter = NearestFilter;
  }

  override dispose() {
    super.dispose();

    this.renderTarget.dispose();
  }

  get texture() {
    return Array.isArray(this.renderTarget.texture) ? this.renderTarget.texture[1] : this.renderTarget.texture;
  }

  get depthTexture() {
    return this.renderTarget.texture[0];
  }

  override render(renderer: WebGLRenderer) {
    tmpProjectionMatrix.copy(this._camera.projectionMatrix);
    tmpProjectionMatrixInverse.copy(this._camera.projectionMatrixInverse);

    if (this._camera.view) this._camera.view.enabled = false;
    this._camera.updateProjectionMatrix();

    // in case a RenderPass is not being used, so we need to update the camera's world matrix manually
    this._camera.updateMatrixWorld();

    this.setVelocityDepthNormalMaterialInScene();

    const { background } = this._scene;

    this._scene.background = backgroundColor;

    renderer.setRenderTarget(this.renderTarget);
    renderer.copyFramebufferToTexture(zeroVec2, this.lastDepthTexture);

    renderer.render(this._scene, this._camera);

    this._scene.background = background;

    this.unsetVelocityDepthNormalMaterialInScene();

    if (this._camera.view) this._camera.view.enabled = true;
    this._camera.projectionMatrix.copy(tmpProjectionMatrix);
    this._camera.projectionMatrixInverse.copy(tmpProjectionMatrixInverse);
  }
}
