import { blockScroll } from '@in3d/common';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { isLoadedAsApi } from '@in3d/hooks';
import { AnimatePresence, motion } from 'framer-motion';

export const AppLoader = () => {
  const [customLoader] = useState(isLoadedAsApi());
  useEffect(() => {
    blockScroll(true);
    return () => blockScroll(false);
  }, []);
  return createPortal(
    <div id="app-loader" style={{ display: 'flex' }}>
      {!customLoader ? (
        <>
          <div className="app-loader__logo">
            <img src="/assets/main-logo.svg" alt="main_logo" />
          </div>
          Avaturn.
        </>
      ) : (
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
    </div>,
    document.querySelector('#portal') as Element
  );
};
