import { Placement } from '@popperjs/core';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

export const useCustomPopper = (sceneLoaded = false, offset = [14, 10], showTime: number | "always" = 3000, placement: Placement = 'top-start') => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | HTMLDivElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset as never
        },
      },
    ],
    placement
  });

  const [popper, setPopper] = useState(false);

  useEffect(() => {
    if (showTime === "always") return
    if (sceneLoaded) {
      // show popper after scene loaded
      // and then hide it
      setPopper(true);
      setTimeout(() => {
        setPopper(false);
      }, showTime);
    }
  }, [sceneLoaded, showTime]);

  return {
    referenceElement,
    setReferenceElement,
    popperElement,
    setPopperElement,
    styles,
    attributes,
    popper,
    setPopper,
  };
};
