/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  AnimationClip,
  Bone,
  InterleavedBufferAttribute,
  KeyframeTrack,
  Matrix4,
  Quaternion,
  SkinnedMesh,
  Vector3,
} from 'three';

import { Asset } from './resources/assets';
import { Avatar } from './resources/avatar';

export function adjust_skeleton(skinned_mesh: SkinnedMesh) {
  const target_bone_names = [
    'Hips',
    'Spine',
    'Spine1',
    'Spine2',
    'Neck',
    'Head',
    'LeftShoulder',
    'LeftArm',
    'LeftForeArm',
    'LeftHand',
    'LeftHandThumb1',
    'LeftHandThumb2',
    'LeftHandThumb3',
    'LeftHandIndex1',
    'LeftHandIndex2',
    'LeftHandIndex3',
    'LeftHandMiddle1',
    'LeftHandMiddle2',
    'LeftHandMiddle3',
    'LeftHandRing1',
    'LeftHandRing2',
    'LeftHandRing3',
    'LeftHandPinky1',
    'LeftHandPinky2',
    'LeftHandPinky3',
    'RightShoulder',
    'RightArm',
    'RightForeArm',
    'RightHand',
    'RightHandThumb1',
    'RightHandThumb2',
    'RightHandThumb3',
    'RightHandIndex1',
    'RightHandIndex2',
    'RightHandIndex3',
    'RightHandMiddle1',
    'RightHandMiddle2',
    'RightHandMiddle3',
    'RightHandRing1',
    'RightHandRing2',
    'RightHandRing3',
    'RightHandPinky1',
    'RightHandPinky2',
    'RightHandPinky3',
    'LeftUpLeg',
    'LeftLeg',
    'LeftFoot',
    'LeftToeBase',
    'RightUpLeg',
    'RightLeg',
    'RightFoot',
    'RightToeBase',
    'LeftEye',
    'RightEye',
  ];

  // let target_bone_names = currentState.skeleton.bone_names;

  let map: { [key: number]: number } = {};
  const inv_map: { [key: number]: number } = {};
  for (let i = 0; i < skinned_mesh.skeleton.bones.length; i++) {
    const name = skinned_mesh.skeleton.bones[i].name;

    map[i] = target_bone_names.findIndex((x) => {
      const first_part = name.split('_')[0];

      if (first_part == x || first_part == 'mixamorig' + x) {
        return true;
      }
      return false;
    });

    if (map[i] == -1) {
      alert('semething is wrong');
      // map
      // if (!name.endsWith('_end')) {
      //   continue;

      //   // break;
      // }
    }

    inv_map[map[i]] = i;
  }

  // Update bones order, if called second time, this is identity map
  skinned_mesh.skeleton.bones = skinned_mesh.skeleton.bones.map((_, idx, arr) => arr[inv_map[idx]]);
  for (let i = 0; i < skinned_mesh.skeleton.bones.length; i++) {
    if (skinned_mesh.skeleton.bones[i]) skinned_mesh.skeleton.bones[i].name = target_bone_names[i];
  }
  skinned_mesh.skeleton.boneInverses = skinned_mesh.skeleton.boneInverses.map((_, idx, arr) => arr[inv_map[idx]]);

  // Now some tricks to make sure that objects that have more that one submesh are processed correctly.
  // We save the map for the first time and use it for all submeshes

  // @ts-ignore
  skinned_mesh.skeleton.map ??= map;

  // @ts-ignore
  map = skinned_mesh.skeleton.map;

  // Update skin ids
  const skin_att = skinned_mesh.geometry.attributes['skinIndex'];
  if (skin_att instanceof InterleavedBufferAttribute) {
    for (let i = 0; i < skin_att.data.count; i++) {
      skin_att.setXYZW(i, map[skin_att.getX(i)], map[skin_att.getY(i)], map[skin_att.getZ(i)], map[skin_att.getW(i)]);
    }
  } else {
    const skin_index = skin_att.array;
    for (let i = 0; i < skin_index.length; i++) {
      //@ts-ignore
      skin_index[i] = map[skin_index[i]];
    }
  }
}

export function filter_animation(animation: AnimationClip, use_hips_position = true, with_armature_animation=true) {
  const filtered_tracks: KeyframeTrack[] = [];
  animation.tracks.forEach((track) => {
    if (track.name.startsWith('Armature')) {
      track.name = 'Armature' + '.' + track.name.split('.')[1];
      if (!with_armature_animation) return;
    }
    if (track.name.startsWith('mixamorig')) {
      track.name = track.name.slice(9);
    }
    if (track.name.endsWith('position')) {
      if (use_hips_position && track.name.endsWith('Hips.position')) {
        filtered_tracks.push(track);
      }
    } else if (track.name.endsWith('scale')) {
      console.log()
    } else {
      filtered_tracks.push(track);
    }
  });
  animation.tracks = filtered_tracks;
}
export function attach_skeleton(cloth: Asset, avatar: Avatar) {
  /* Bind cloth's skeleton to avatar's skeleton*/
  if (cloth.cur_skeleton != undefined && cloth.cur_skeleton == avatar.skeleton.uuid) {
    return;
  }

  cloth.group.traverse((node: any) => {
    if (!node.isMesh) {
      return;
    }

    node.skeleton = avatar.skeleton;
    // not sure if we actually need this, but it breaks retargeting if avatar has parents with not trivial position
    // node.bind(avatar.skeleton, avatar.mesh.matrixWorld);
  });

  cloth.cur_skeleton = avatar.skeleton.uuid;
}

export function make_animation_relative_to_rest_pose(
  target_mesh: SkinnedMesh,
  source_root_bone: Bone,
  animation_clip: AnimationClip
) {
  /*
    animation_clip stores relative transform (.matrix) for each bone, but it is relative to parent bone space, and not to rest pose space.
    it means that if this animation applied to another skeleton, the skeleton rest pose won't matter and the animation will look differently to Blender
    so we fist convert transform of each bone into space relative to animation rest pose, and then apply it to the target rest pose
  */
  animation_clip.tracks.forEach((track) => {
    // if track does not end  with .quaternion, skip it
    if (!track.name.endsWith('.quaternion')) return;

    // get the bone name from the track name
    const bone_name = track.name.split('.')[0];

    // get the bone from the target skeleton
    const target_bone = target_mesh.skeleton.bones.find((b) => b.name === bone_name);

    // if bone is not found, skip it
    if (!target_bone) return;

    // get the bone from the source skeleton
    const source_bone = source_root_bone.getObjectByName(bone_name);

    // if source bone is not found, skip it
    if (!source_bone) return;

    const targetPos = new Vector3();
    const targetQuat = new Quaternion();
    const targetScale = new Vector3();
    for (let j = 0; j < track.values.length; j += 4) {
      const restRelBoneTransform = source_bone.matrix;
      const poseRelBoneTransform = new Matrix4().makeRotationFromQuaternion(
        new Quaternion().fromArray(track.values, j).normalize()
      );

      const transformRelToRestPose = poseRelBoneTransform.clone().multiply(restRelBoneTransform.clone().invert());
      const resBoneTransform = new Matrix4().multiplyMatrices(transformRelToRestPose, target_bone.matrix);

      resBoneTransform.decompose(targetPos, targetQuat, targetScale);
      track.values[j] = targetQuat.x;
      track.values[j + 1] = targetQuat.y;
      track.values[j + 2] = targetQuat.z;
      track.values[j + 3] = targetQuat.w;
    }
  });
  return animation_clip;
}
