import { getHostname, page_params } from '@in3d/common';
import env from '@in3d/environment';
import environment from '@in3d/environment';
import { avatarList } from './content';

function basename(url: string) {
  return url.split(/[\\/]/).pop();
}
function basedir(url: string) {
  return url.split(/[\\/]/).pop();
}
function getCookie(name: string) {
  let matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function loadAvatarFromCookies() {
  let avatar_link = getCookie('avatar_link');
  if (avatar_link == undefined) {
    return [];
  }

  let avatarList = [{ aid: 'user_avatar', glb_url: avatar_link }];
  return avatarList;
}

export function getDefaultAvatarUrl() {
  if (env.hostsAnimatable.includes(getHostname())) {
    return `${env.editorResourcesUrl}/animatable/default_avatar_animatable.glb`;
  } else {
    return `${env.editorResourcesUrl}/default_avatar.glb`;
  }
}
function genList(url: string, gender: string, postfix: string, limit: number = 50) {
  let avatarList = [];

  for (let i = 0; i < 5; ++i) {
    if (i == limit) {
      break;
    }

    avatarList.push({
      id: `model_T_${i}${postfix}`,
      gender: gender,
      body_id: `${i}`,
      glb_url: url,
      mesh_name: `model_T_${i}`,
      preview_url: `/assets/editor/${gender}/body_${i + 1}.svg`,
    });
  }
  return avatarList;
}

function loadAvatarFromParams() {
  if (page_params.avatar_link == undefined) {
    page_params.avatar_link = getDefaultAvatarUrl();
  }

  // let url = decodeURIComponent(page_params.avatar_link);
  let url = page_params.avatar_link;
  let gender = page_params.gender || 'male';
  let avatarList = genList(url, gender, '');

  // if (true && !environment.production) {
  //   avatarList = [
  //     ...genList("assets/local/model_T_test.glb", gender, ""),
  //     ...genList("assets/local/model_T_test_png_resize.glb", gender, "2"),
  //     ...genList("assets/local/model_T_test_png_resize2.glb", gender, "3"),
  //   ];
  // }
  return avatarList;
}

export function loadAvatarList(url: string, gender = 'male') {
  //   if (true && !environment.production) {
  //   const avatarList = [
  //     ...genList("assets/test_assets/lighting_test/model_T.glb", gender, "", 1),
  //     // ...genList("assets/test_assets/lighting_test/model_T.glbwebp_90.glb", gender, "2"),

  //     // ...genList("assets/test_assets/lighting_test/model_T.glb", gender, "3", 1),
  //     // ...genList("assets/test_assets/lighting_test/model_T.glbwebp_90.glb", gender, "4"),

  //     ...genList("assets/test_assets/lighting_test/model_T_black.glb", gender, "55", 1),
  //     // ...genList("assets/test_assets/lighting_test/model_T_black.glbwebp_90.glb", gender, "5"),

  //     ...genList("assets/test_assets/lighting_test/model_T_black2.glb", gender, "66", 1),
  //     // ...genList("assets/test_assets/lighting_test/model_T_black2.glbwebp_90.glb", gender, "6"),

  //     ...genList("assets/test_assets/lighting_test/model_T_dark.glb", gender, "77", 1),
  //     // ...genList("assets/test_assets/lighting_test/model_T_dark.glbwebp_90.glb", gender, "7"),

  //     ...genList("assets/test_assets/lighting_test/model_T_ideets.glb", gender, "88", 1),
  //     // ...genList("assets/test_assets/lighting_test/model_T_ideets.glbwebp_90.glb", gender, "8"),

  //     ...genList("assets/test_assets/lighting_test/model_T_indian.glb", gender, "99", 1),
  //     // ...genList("assets/test_assets/lighting_test/model_T_indian.glbwebp_90.glb", gender, "9"),
  //   ];
  //   return avatarList;
  // }

  if (page_params.avatar_link) {
    gender = page_params.gender || gender;

    let list: any[] = [];
    let i = 0;
    for (let link of [page_params.avatar_link, page_params.avatar_link2, page_params.avatar_link3]) {
      if (link) {
        list = [...list, ...genList(link, gender, i == 0 ? '' : String(i))];
      }
      i++;
    }

    return list;
  }

  return genList(url, gender, '');
}

export function loadAvatarFromURL(url: string, gender = 'male') {
  return genList(url, gender, "");
}
export { loadAvatarFromCookies, loadAvatarFromParams, page_params };
