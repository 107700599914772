import env from '@in3d/environment';

export * from './resources';

export enum ScanStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  READY = 'ready',
  FAILED = 'failed',
}

export enum ConfigType {
  v1 = 'old',
  v2 = 'animatable',
  V3 = 'animatable_single_view',
}

export interface ScanData {
  created_at: string;
  id: string;
  status: ScanStatus;
  preview_url?: string;
  queued_at?: string;
  scan_metadata: { gender?: 'male' | 'female'; animatable?: boolean };
  config: ConfigType
}

export type CustomizationColor = Record<string, [number, number, number]>;

export const base_path = `${env.editorResourcesUrl}/textures/haircaps`;

export const haircaps = {
  stripes: `${base_path}/stripes.webp`,
  regular: `${base_path}/regular.webp`,
  bald_jeoffrey: `${base_path}/bald_jeoffrey_fs.webp?q=2`,
  bald_statham: `${base_path}/bald_statham_fs.webp?q=2`,
  bald_soft: `${base_path}/bald_soft.webp`,
  bald_zero: `${base_path}/bald_zero.webp`,
};

export type HaircapIdType = keyof typeof haircaps;

export type AvatarCustomization = {
  assets: Record<string, { color?: CustomizationColor; placement?: string; local?: boolean }>;
  avatar: {
    body: string;
    curve_coeff?: number;
    bone_scale?: {
      head?: number;
      arms?: number;
      chest?: number;
    };
    eye_color?: string;
    eye_texture?: string;
    haircap_id?: HaircapIdType;
    decals?: { cloth_id: string; url: string }[];
  };
};

export type DefaultAvatarCustomization = {
  male: Record<string, AvatarCustomization>;
  female: Record<string, AvatarCustomization>;
};

export interface AvatarInfo {
  scan_glb_url: string;
  customization: AvatarCustomization;
  scan: ScanData;
}
export enum BodyType {
  Male = 'male',
  Female = 'female',
}
export enum CategoryName {
  Body = 'body',
  Outfit = 'outfit',
  Hair = 'hair',
  Glasses = 'glasses',
  Shoes = 'shoes',
  Animations = 'animations',
  FaceAnimations = 'face_animations',
  // Decals = 'decals',
}

export enum Slots {
  Eyes = 'Eyes',
  Head = 'Head',
  Shoes = 'Shoes',
  // Look = 'Look',
  Top = 'Top',
  Bottom = 'Bottom',
  Face = 'Face',
}
export type ResourcePlacementStr = 'eyes' | 'head' | 'look' | 'shoes' | 'faceMask' | 'animations';
export interface NewAssetConfig {
  user_id: string;
  gender: 'male' | 'female';
  placement: ResourcePlacementStr;
  prject_slug: string;
  settings: Record<string, any>;
}

export interface EditorConfig {
  withPoweredBtn: boolean;
  withSaveBtn: boolean;
  withHomeBtn: boolean;
  withAnimationTab: boolean;
  withFaceAnimationTab: boolean;
  customCss: boolean;
  customLogo: boolean;
  sceneBg: null | string;
  customBodies: boolean;
  logo: string;
  exportWithThumbnail: boolean;
  defaultHaircap: HaircapIdType;
  textureSize: number;
}

export interface AppConfig {
  withNav: boolean;
  customLogo: string;
  allowSelectAvatarVersion: boolean;
  disableSocialLinks: boolean;
}

export interface ScanConfig {
  withPhotoUploader: boolean;
}
