export enum MessageType {
  // Internal
  AskForDefaults = 'ask_for_defaults',
  SDKHandshake = 'sdk_handshake',
  SDKHandshakeConfirmation = 'sdk_handshake_confirmation',
  LoadEvent = 'load',
  Ping = 'ping',
  Pong = 'pong',
  CallbackEvent = 'callback_event',

  //         //
  // Actions //
  //         //
  // assets list
  GetAssetsList = 'get_assets_list',
  SetAssetsList = 'set_assets_list',
  // active assets
  GetActiveAssets = 'get_active_assets',
  SetActiveAsset = 'set_active_asset',
  // bodies
  GetBodiesList = 'get_bodies_list',
  GetActiveBody = 'get_active_body',
  SetActiveBody = 'set_active_body',
  // export
  ExportAvatar = 'export_avatar',
  GenerateThumbnail = 'generate_thumbnail',
  //
  GetAvatarInfo = 'get_avatar_info',
  SetAvatarInfo = 'set_avatar_info',
}
